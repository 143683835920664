/* eslint-disable prettier/prettier */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import SaffronNav from './SaffronNav'

const NavTabs = ({
  pages,
  tabWrapperClasses = 'header-tabs',
  linkClickedHandler = () => {},
  pathname,
  hash,
  NavLink
}) => {
  return (
    <SaffronNav
      className={classNames(
        'd-flex justify-content-center justify-content-md-start',
        tabWrapperClasses
      )}
    >
      {pages.map((page, index) => (
        <>
          {page?.isDiscardTab ? null : (
            <SaffronNav.Item key={page?.path} className='position-relative'>
              <SaffronNav.Link
                as={NavLink}
                to={
                  page?.hashURL ? `${page?.path}${page?.hashURL}` : page?.path
                }
                className={classNames(
                  {
                    'mr-0': pages?.length === index + 1
                  },
                  page?.classNames
                )}
                isActive={(match) => {
                  if (
                    page?.hashURL &&
                    `${page?.path}${page?.hashURL}` === `${pathname}${hash}`
                  ) {
                    return true
                  }
                  if (page?.defaultUrl && page?.defaultUrl === pathname) {
                    return true
                  }
                  if (page?.discardDefaultMatch && pathname !== page?.path) {
                    return false
                  }
                  if (match && !page?.hashURL) {
                    return true
                  }
                  return false
                }}
                onClick={() => linkClickedHandler(page?.name)}
              >
                {page?.name}
              </SaffronNav.Link>
            </SaffronNav.Item>
          )}
        </>
      ))}
    </SaffronNav>
  )
}

NavTabs.propsTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      defaultUrl: PropTypes.string,
      hashURL: PropTypes.string,
      noUseDefaultMatch: PropTypes.bool,
      classNames: PropTypes.string
    })
  ).isRequired,
  linkClickedHandler: PropTypes.func
}

export default NavTabs
