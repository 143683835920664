import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button as CommonButton, surveyType } from '@abroad/components'

const AssessmentCommonButton = ({ selectedSurveys }) => {
  const [isCompareBtnDisabled, setIsCompareBtnDisabled] = useState(
    selectedSurveys?.length !== 2,
  )
  const { clientId } = useParams()

  useEffect(() => {
    if (selectedSurveys?.length !== 2) {
      setIsCompareBtnDisabled(true)
      return
    }
    const isSameTypeOfSurvey = selectedSurveys
      .map((survey) => survey?.surveyType)
      .every((val, i, arr) => val === arr[0])
    setIsCompareBtnDisabled(!isSameTypeOfSurvey)
  }, [selectedSurveys])

  return (
    <CommonButton
      variant='gray'
      className='text-uppercase ml-2 line-height-normal border-radius-3'
      onClick={() => {
        if (!isCompareBtnDisabled) {
          window.open(
            `/user/${clientId}/comparison_report?survey=${
              selectedSurveys[0]?.id
            }&survey=${selectedSurveys[1]?.id}&surveyType=${
              selectedSurveys[1]?.surveyType === surveyType.WISDOM
                ? surveyType.WISDOM
                : surveyType.AWARENESS
            }`,
          )
        }
      }}
      disabled={isCompareBtnDisabled}>
      Compare Assessments
    </CommonButton>
  )
}

export default AssessmentCommonButton
