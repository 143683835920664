import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  Button,
  Spinner,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import {
  useErrorService,
  ABSpinner,
  useNotificationService,
} from '@abroad/components'
import SearchUserInput from './SearchUserInput'
import { CoachingPlansList, PageHeader } from '../common'
import { StaffCoachIcon } from '../assetsComponents'
import { formatDate } from '../../utils/date'
import API from '../../utils/API'
import labels from '../../constants/labels'

const ClientList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [clients, setClients] = useState([])
  const Error = useErrorService()
  const onHide = () => setShowModal(false)
  let { coachId } = useParams()
  const Notification = useNotificationService()

  useEffect(() => {
    const getClientsList = async () => {
      try {
        const { data } = await API.AdminAPI.clientsList.getclientsList(coachId)
        if (data) {
          setIsLoading(false)
          setClients(data)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (isLoading) getClientsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const handleRemoveClient = async (removeType) => {
    setIsSubmitting(true)
    try {
      const { data } = await API.AdminAPI.coachingList.removeClient(
        userInfo?._id,
        coachId,
        { intent: removeType },
      )
      if (data) {
        setShowModal(false)
        setIsSubmitting(false)
        Notification.showNotification('Client has been removed!', 'success')
        setIsLoading(true)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }
  const renderStaffCoachIcon = (isStaffCoach = false) => {
    if (!isStaffCoach) return null
    return <StaffCoachIcon classes='staff-coach-indicator mb-2' />
  }

  const renderClientPlanTable = () => {
    return (
      <Table hover responsive className='custom-table'>
        <thead>
          <tr className='text-uppercase'>
            <th width='50%'>Individual Coaching Plans</th>
            <th width='50%'>B2B Coaching Plans</th>
          </tr>
        </thead>
        <tbody>
          <CoachingPlansList
            coachingPlans={userInfo?.coachingPlans}
            b2bPlans={userInfo?.b2bPlans}
            isListRenderOnModal={true}
            handleRemoveClient={handleRemoveClient}
            buttonDisabledProps={isLoading || isSubmitting}
          />
        </tbody>
      </Table>
    )
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <PageHeader
        titleFn={() => {
          return (
            <h3 className='text-dark-600 text-uppercase page-label'>
              {clients?.coach?.fullName}
              {renderStaffCoachIcon(clients?.coach?.isStaffCoach)}
            </h3>
          )
        }}
        descriptionFn={() => {
          return (
            <p className='font-montserrat font-medium'>
              {clients?.coach?.email}
            </p>
          )
        }}
        colClasses='px-0'
      />
      <Modal
        show={showModal}
        className='border-modal custom__modal'
        size='lg'
        onHide={onHide}
        scrollable={true}
        centered>
        <Modal.Body>
          <div className='d-flex'>
            <h3 className='s2 title mb-2'>Remove Client</h3>
            {isSubmitting && (
              <Spinner animation='border' className='text-saffron-700 ml-3' />
            )}
          </div>
          <h3 className='text-dark-600 text-uppercase s5 mb-3'>
            Coach: {clients?.coach?.fullName}
            {renderStaffCoachIcon(clients?.coach?.isStaffCoach)}
          </h3>
          {renderClientPlanTable()}
        </Modal.Body>
        <Modal.Footer>
          <div className='text-right'>
            <Button
              variant='none'
              size='md'
              className='border-radius-3 line-height-normal mr-3 secondary-popup-button'
              disabled={isSubmitting}
              onClick={onHide}>
              Cancel
            </Button>
            <Button
              variant='danger'
              size='md'
              className='border-radius-3 confirm-btn line-height-normal ml-3'
              isLoading={isSubmitting}
              disabled={isLoading || isSubmitting}
              onClick={() =>
                handleRemoveClient(
                  labels.removeCoachFromUsersTypes.ALL_TYPES_OF_SOWS,
                )
              }>
              {userInfo?.coachingPlans?.length > 0 ||
              userInfo?.b2bPlans?.length > 0
                ? 'Remove From All SOW'
                : 'Remove User'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Container fluid className='custom-offset-lg-1 custom-col-lg-12 px-0'>
        <Row>
          <Col className='clients-list'>
            <SearchUserInput
              setIsLoading={setIsLoading}
              isCurrentStaffCoach={clients?.coach?.isStaffCoach}
              currentCoachName={clients?.coach?.fullName}
            />
            <Table hover responsive className='custom-table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Client Name</th>
                  <th>Organization</th>
                  <th>Role</th>
                  <th>Gender</th>
                  <th>Country</th>
                  <th>Signed On Date</th>
                  <th>Coaching Plan</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody className='font-16'>
                {clients?.clients?.length === 0 ? (
                  <tr>
                    <td className='text-center' colSpan='9'>
                      No Clients. Assign now.
                    </td>
                  </tr>
                ) : (
                  clients?.clients?.map((client, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={`/admin/users/${client._id}`}
                          className='text-dark-600 under-line'>
                          {client.fullName}
                        </Link>
                      </td>
                      <td>
                        {client.company !== null ? client.company : '- (N/S)'}
                      </td>
                      <td>
                        {Object.keys(client.profile).length !== 0
                          ? client.profile.roleInCompany
                          : '- (N/S)'}
                      </td>
                      <td>
                        {Object.keys(client.profile).length !== 0
                          ? client.profile.gender
                          : '- (N/S)'}
                      </td>
                      <td>
                        {Object.keys(client.profile).length !== 0
                          ? client.profile.country
                          : '- (N/S)'}
                      </td>
                      <td>{formatDate(client.createdAt)}</td>
                      <td>
                        <CoachingPlansList
                          coachingPlans={client?.coachingPlans}
                          b2bPlans={client?.b2bPlans}
                        />
                      </td>
                      <td>
                        {/* eslint-disable */}
                        <a
                          href='javascript:void(0)'
                          className='text-red-500 cursor-pointer'
                          onClick={(e) => {
                            e.preventDefault()
                            setUserInfo({
                              _id: client?._id,
                              coachingPlans: client?.coachingPlans
                                ? client?.coachingPlans
                                : [],
                              b2bPlans: client?.b2bPlans
                                ? client?.b2bPlans
                                : [],
                            })
                            setShowModal(true)
                          }}>
                          Remove
                        </a>
                        {/* eslint-enable */}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ClientList
