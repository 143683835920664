import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { AccordionContext, useAccordionToggle } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { RightClickIcon } from '@abroad/components'

const ContextAwareToggle = ({
  title,
  percent,
  eventKey,
  isEmpty,
  callback,
  qkey,
}) => {
  const { clientId } = useParams()
  const [hasLogValue, setHasLogValue] = useState(false)
  const currentEventKey = useContext(AccordionContext)
  const logs = localStorage.getItem(`logs-${clientId}`)
  const parseValue = JSON.parse(logs)

  useEffect(() => {
    setHasLogValue(!isEmpty && parseValue?.answers?.[qkey] ? true : false)
  }, [logs, parseValue, qkey, isEmpty])

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div
      onClick={() => {
        decoratedOnClick()
      }}
      className='border-0 cursor-pointer'>
      <div className='d-flex'>
        {(!isEmpty || hasLogValue) && (
          <div className='align-self-center note-complete-indicator'>
            <RightClickIcon className='rightclick-icon' />
          </div>
        )}
        {title && (
          <span className='s5 font-semibold'>
            {title}
            {percent ? ` - ` : ''}
            <b>{percent}</b>
          </span>
        )}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='12'
          height='12'
          viewBox='0 0 9 6'
          fill='none'
          className={classNames('align-self-center ml-4', {
            'rotate-original-position': isCurrentEventKey,
            'rotate-minus-90': !isCurrentEventKey,
          })}>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M8.58081 0.203112C8.85162 0.473928 8.85162 0.913007 8.58081 1.18382L4.88232 4.88232C4.6115 5.15313 4.17242 5.15313 3.9016 4.88232L0.203112 1.18382C-0.0677039 0.913007 -0.0677039 0.473928 0.203112 0.203112C0.473928 -0.0677036 0.913007 -0.0677037 1.18382 0.203112L4.39196 3.41125L7.6001 0.203112C7.87091 -0.067704 8.30999 -0.067704 8.58081 0.203112Z'
            fill='black'
          />
        </svg>
      </div>
    </div>
  )
}
export default ContextAwareToggle
