import React from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { CoachRequests } from '../components'
import PrivateRoute from '../routes/PrivateRoute'
import { coachRequestsType } from '../constants/common'

const ClientOnboarding = () => {
  const location = useLocation()
  return (
    <Switch location={location}>
      <PrivateRoute
        exact
        path={`/admin/client-onboarding/${coachRequestsType.PENDING}-requests`}>
        <CoachRequests
          key={coachRequestsType.PENDING}
          requestsType={coachRequestsType.PENDING}
        />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`/admin/client-onboarding/${coachRequestsType.COMPLETED}-requests`}>
        <CoachRequests
          key={coachRequestsType.COMPLETED}
          requestsType={coachRequestsType.COMPLETED}
        />
      </PrivateRoute>
    </Switch>
  )
}

export default ClientOnboarding
