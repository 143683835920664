import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { NavLink, useLocation } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { CrossIcon, NavTabs, sows, useErrorService } from '@abroad/components'
import AssignCoachToSOWModal from './AssignCoachToSOWModal'
import { Table, TableInstance } from '../table'
import {
  DeleteConfirmationPrompt,
  FilterSelection,
  PageHeader,
} from '../common'
import API from '../../utils/API'
import { formatDate } from '../../utils/date'
import { coachRequestsType } from '../../constants/common'

const coachRequestsPage = [
  {
    path: `/admin/client-onboarding/${coachRequestsType.PENDING}-requests`,
    name: 'Pending Requests',
  },
  {
    path: `/admin/client-onboarding/${coachRequestsType.COMPLETED}-requests`,
    name: 'Completed Requests',
  },
]

const sowTypeOptions = [
  {
    key: sows.planType.individual,
    value: `${sows.label.clientSideIndividual} Coaching Plan`,
  },
  {
    key: sows.planType.growth,
    value: `${sows.label.growth} Coaching Plan`,
  },
]

const intialGlobalFilter = {
  filterBySOWType: sows.planType.individual,
}

const renderPageHelmet = () => {
  return (
    <Helmet>
      <title>{'Client Onboarding | Abroad'}</title>
      <meta name='title' content={'Client Onboarding | Abroad'}></meta>
      <meta property='og:title' content={'Client Onboarding | Abroad'}></meta>
      <meta
        property='twitter:title'
        content={'Client Onboarding | Abroad'}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/admin/client-onboarding`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/admin/client-onboarding`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/admin/client-onboarding`}></meta>
    </Helmet>
  )
}

const CoachRequests = ({ requestsType }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [reqData, setReqData] = useState(null)
  const [unassignedSOW, setUnassignedSOW] = useState([])
  const [sowInformation, setSOWInformation] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showHidePlanModal, setShowHidePlanModal] = useState(false)
  const [isHiding, setIsHiding] = useState(false)
  const hasMore = useRef(false)
  const Error = useErrorService()
  const location = useLocation()

  useEffect(() => {
    if (!showModal) {
      setSOWInformation(null)
    }
  }, [showModal])

  const getUnassignedSOW = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.coachPlan.getUnassignedSOW(
        reqData?.filterBySOWType,
        requestsType,
      )
      if (data) {
        const modifiedSOWData = data.map((sow) => ({
          ...sow,
          planType: sow?.isGrowth
            ? sows.planType.growth
            : sows.planType.individual,
        }))
        setUnassignedSOW(modifiedSOWData)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getUnassignedSOW(reqData)
    }
  }, [getUnassignedSOW, reqData])

  const discardPlanHandler = async () => {
    setIsHiding(true)
    try {
      const { data } = await API.AdminAPI.coachPlan.discardPlan(
        sowInformation?.userId,
      )
      if (data) {
        const modifiedSOWData = unassignedSOW.filter(
          (sow) => sow?._id !== sowInformation?.sowId,
        )
        setUnassignedSOW(modifiedSOWData)
        setShowHidePlanModal(false)
      }
      setIsHiding(false)
    } catch (e) {
      setIsHiding(false)
      Error.showError(e)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'number',
        Header: '#',
        width: '2%',
        accessor: (properties) => properties,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
        disableSortBy: true,
      },
      {
        id: 'fullname',
        Header: 'Full Name',
        width: '18%',
        accessor: (properties) => ({
          fullName: properties?.clientId?.fullName,
          _id: properties?.clientId?._id,
          email: properties?.clientId?.email,
        }),
        Cell: ({ cell }) => (
          <span>
            <NavLink
              to={`/coach/clients/${cell?.value._id}`}
              className='text-dark-600 underline-link'>
              {cell?.value?.fullName}
              <br />
              {cell?.value?.email}
            </NavLink>
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'sowname',
        Header: 'Coaching Plan',
        width: '20%',
        accessor: (properties) => ({
          planName: properties?.planName,
          clientId: properties?.clientId?._id,
          planId: properties?._id,
          isGrowth: properties?.isGrowth,
        }),
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.isGrowth
              ? `${sows.label.growth} Plan`
              : cell?.value?.planName}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'paidon',
        Header: 'Paid on',
        width: requestsType === coachRequestsType.PENDING ? '20%' : '12%',
        accessor: (properties) =>
          properties?.payments?.createAt ||
          properties?.clientId?.payments?.createAt,
        Cell: ({ cell }) => (
          <span>{cell?.value ? formatDate(cell?.value) : '-'}</span>
        ),
        disableSortBy: true,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
      },
      {
        id: 'coachassignedon',
        Header: 'Coach Assigned on',
        width: '12%',
        accessor: (properties) => properties?.coachAssignedAt,
        Cell: ({ cell }) => (
          <span>{cell?.value ? formatDate(cell?.value) : '-'}</span>
        ),
        disableSortBy: true,
        dataProps: {
          className: classNames('text-center', {
            'd-none': requestsType === coachRequestsType.PENDING,
          }),
        },
        headerProps: {
          className: classNames('text-center', {
            'd-none': requestsType === coachRequestsType.PENDING,
          }),
          wrapperClassName: 'd-flex-unset',
        },
      },
      {
        id: 'sessiondate',
        Header: 'First Session Date',
        width: '12%',
        accessor: (properties) => properties?.firstSessionDate,
        Cell: ({ cell }) => (
          <span>{cell?.value ? formatDate(cell?.value) : '-'}</span>
        ),
        disableSortBy: true,
        dataProps: {
          className: classNames('text-center', {
            'd-none': requestsType === coachRequestsType.PENDING,
          }),
        },
        headerProps: {
          className: classNames('text-center', {
            'd-none': requestsType === coachRequestsType.PENDING,
          }),
          wrapperClassName: 'd-flex-unset',
        },
      },
      {
        id: 'signupdate',
        Header: 'Sign up date',
        width: requestsType === coachRequestsType.PENDING ? '20%' : '12%',
        accessor: (properties) => properties?.clientId?.createdAt,
        Cell: ({ cell }) => <span>{formatDate(cell?.value)}</span>,
        disableSortBy: true,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
      },
      {
        id: 'assign',
        Header: 'Assign',
        width: requestsType === coachRequestsType.PENDING ? '20%' : '12%',
        dataProps: {
          className: classNames('text-center', {
            'd-none': requestsType === coachRequestsType.COMPLETED,
          }),
        },
        headerProps: {
          className: classNames('text-center', {
            'd-none': requestsType === coachRequestsType.COMPLETED,
          }),
          wrapperClassName: 'd-flex-unset',
        },
        accessor: (properties) => ({
          sowId: properties?._id,
          userId: properties?.clientId?._id,
          planName: properties?.planName,
          fullName: properties?.clientId?.fullName,
          isGrowth: properties?.isGrowth,
        }),
        Cell: ({ cell }) => (
          <div className='d-flex justify-content-center align-items-center'>
            <span
              className='text-saffron-700 cursor-pointer'
              onClick={() => {
                setSOWInformation({
                  sowId: cell?.value?.sowId,
                  userId: cell?.value?.userId,
                  planName: cell?.value?.isGrowth
                    ? `${sows.label.growth} Plan`
                    : cell?.value?.planName,
                  fullName: cell?.value?.fullName,
                  isGrowth: cell?.value?.isGrowth,
                })
                setShowModal(true)
              }}>
              Assign Coach
            </span>
          </div>
        ),
        disableSortBy: true,
      },
      {
        id: 'hide',
        Header: 'Hide',
        width: '6%',
        dataProps: {
          className: classNames('text-center', {
            'd-none':
              requestsType === coachRequestsType.COMPLETED ||
              reqData?.filterBySOWType !== sows.planType.growth,
          }),
        },
        headerProps: {
          className: classNames('text-center', {
            'd-none':
              requestsType === coachRequestsType.COMPLETED ||
              reqData?.filterBySOWType !== sows.planType.growth,
          }),
          wrapperClassName: 'd-flex-unset',
        },
        accessor: (properties) => ({
          sowId: properties?._id,
          userId: properties?.clientId?._id,
          planName: properties?.planName,
          fullName: properties?.clientId?.fullName,
          isGrowth: properties?.isGrowth,
        }),
        Cell: ({ cell }) => (
          <div className='d-flex justify-content-center align-items-center'>
            <CrossIcon
              className={classNames({
                'cursor-pointer': !isHiding,
                'pointer-event-none': isHiding,
              })}
              onClick={() => {
                setSOWInformation({
                  sowId: cell?.value?.sowId,
                  userId: cell?.value?.userId,
                  planName: cell?.value?.isGrowth
                    ? `${sows.label.growth} Plan`
                    : cell?.value?.planName,
                  fullName: cell?.value?.fullName,
                  isGrowth: cell?.value?.isGrowth,
                })
                setShowHidePlanModal(true)
              }}
            />
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [requestsType, isHiding, reqData],
  )

  const fetchData = useCallback(({ filterBySOWType, page, limit }) => {
    if (page === 1) {
      setUnassignedSOW([])
    }
    const req = {
      limit,
      page: page,
      filterBySOWType,
    }
    setReqData(req)
  }, [])

  return (
    <>
      {renderPageHelmet()}
      <main>
        <PageHeader
          sectionClassName='page-heading'
          titleText='Client Onboarding'
          hasBackArrow={false}
          titleClasses='sentence-case-text'
          colClasses='px-0'
        />
        <AssignCoachToSOWModal
          sowInformation={sowInformation}
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={() => {
            setUnassignedSOW((prev) =>
              prev.filter((e) => e?._id !== sowInformation?.sowId),
            )
          }}
        />
        <DeleteConfirmationPrompt
          showModal={showHidePlanModal}
          setShowModal={setShowHidePlanModal}
          isDeleteLoading={isHiding}
          handleDelete={discardPlanHandler}
          message='This can not be reverted. Are you sure you want to hide this plan from onboarding?'
        />
        <TableInstance
          columns={columns}
          data={unassignedSOW}
          initialState={{
            globalFilter: intialGlobalFilter,
          }}>
          {({
            preGlobalFilteredRows,
            state: { globalFilter, ...other },
            setGlobalFilter,
            ...rest
          }) => (
            <>
              <Container
                className='custom-offset-lg-1 custom-col-lg-12 px-0'
                fluid>
                <section className='org-tabs-wrapper'>
                  <NavTabs
                    pages={coachRequestsPage}
                    pathname={location?.pathname}
                    hash={location?.hash}
                    NavLink={NavLink}
                  />
                </section>
                <Row className='pb-3'>
                  <Col className='col-3'>
                    <FilterSelection
                      {...{
                        globalFilter,
                        setGlobalFilter,
                      }}
                      name='filterBySOWType'
                      inputClasses='ms-0 border-radius-5'
                      placeholder='Select Coaching Plan'
                      options={sowTypeOptions}
                      disabled={isLoading}
                      key={globalFilter?.filterBySOWType}
                    />
                  </Col>
                </Row>
                <Table
                  ref={hasMore}
                  emptyTableMessage='No Users found.'
                  tableInstance={{
                    preGlobalFilteredRows,
                    state: { globalFilter, ...other },
                    setGlobalFilter,
                    ...rest,
                  }}
                  isLoading={isLoading}
                  fetchData={fetchData}
                />
              </Container>
            </>
          )}
        </TableInstance>
      </main>
    </>
  )
}

export default CoachRequests
