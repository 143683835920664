import React, { useEffect, useState } from 'react'
import { Table, Container } from 'react-bootstrap'
import { PageHeader } from '../components/common'
import { Button, useErrorService, ABSpinner } from '@abroad/components'
import API from '../utils/API'
import { useHistory } from 'react-router-dom'
import { formatDate } from '../utils/date'

const Blog = () => {
  const history = useHistory()
  const Error = useErrorService()
  const [blogAllDetails, setBlogAllDetails] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const getBlogAll = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getBlogAll()
        setBlogAllDetails(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getBlogAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <PageHeader
        titleText='BLOG'
        colClasses='px-0'
        titleClasses='sentence-case-text'
      />
      <Container fluid className='custom-offset-lg-1 custom-col-lg-12 px-3'>
        <div className='d-flex justify-content-end'>
          <div>
            <Button
              variant='gray'
              className='px-4 py-3 abroad-blue mt-3'
              type='button'
              onClick={() => history.push(`/admin/blogs/create-new`)}>
              ADD NEW BLOG
            </Button>
          </div>
        </div>
        <Table responsive hover className='mt-4 custom-table'>
          <thead>
            <tr>
              <th>#</th>
              <th>TITLE</th>
              <th>AUTHOR</th>
              <th className='text-center'>CREATED AT</th>
              <th className='text-center'>ACTION(S)</th>
            </tr>
          </thead>
          {isLoading && (
            <tr>
              <td className='text-center border-0 pt-3' colSpan='5'>
                <ABSpinner />
              </td>
            </tr>
          )}
          {blogAllDetails.length === 0 && !isLoading && (
            <tr>
              <td className='text-center s6 py-3' colSpan='5'>
                No blogs yet!
              </td>
            </tr>
          )}
          <tbody>
            {blogAllDetails.length > 0 &&
              blogAllDetails.map((blog, index) => (
                <tr key={blog.id}>
                  <td>{index + 1}</td>
                  <td>{blog.title}</td>
                  <td>{blog.author}</td>
                  <td className='text-center'>
                    {blog.createdAt === null ? '-' : formatDate(blog.createdAt)}
                  </td>
                  <td
                    className='text-saffron-700 cursor-pointer text-center'
                    onClick={() =>
                      history.push(`/admin/blogs/${blog.id}/detail`)
                    }>
                    Manage
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </>
  )
}

export default Blog
