import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Table, Form as RBForm } from 'react-bootstrap'
import Imgix from 'react-imgix'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { numberToWords } from 'number-to-words'
import {
  Button,
  useErrorService,
  ABSpinner,
  useResolutionService,
  useNotificationService,
} from '@abroad/components'
import TimeSelection from '../form/TimeSelection'
import API from '../../utils/API'
import { QuestInputField } from '../form'
import {
  UTCFormatItineary,
  getUTCcurrentDateUsingProps,
  getItineraryDate,
} from '../../utils/date'

const QuestsItinerary = () => {
  const Error = useErrorService()
  const [uploadingIndex, setUploadingIndex] = useState(null)
  const { isMDScreen } = useResolutionService()
  const { questId } = useParams()
  const imageRefs = useRef([])
  const [isLoading, setIsLoading] = useState(false)
  const [getItineraryData, setItineraryData] = useState([])
  const Notification = useNotificationService()
  const [days, setDays] = useState([
    {
      date: '',
      subTitle: '',
      imageFileName: '',
      itineraries: [{ fromTime: '', toTime: '', itinerary: '' }],
    },
  ])

  useEffect(() => {
    const generateDates = (startDate, endDate) => {
      const dates = []
      let currentDate = getUTCcurrentDateUsingProps(startDate)

      while (currentDate <= getUTCcurrentDateUsingProps(endDate)) {
        dates.push(UTCFormatItineary(currentDate))
        currentDate.add(1, 'day')
      }

      return dates
    }

    const startDate = getItineraryData.startDate
      ? UTCFormatItineary(getItineraryData.startDate)
      : null

    const endDate = getItineraryData.endDate
      ? UTCFormatItineary(getItineraryData.endDate)
      : null

    if (startDate && endDate) {
      const dateList = generateDates(startDate, endDate)

      setDays(
        dateList.map((date) => {
          const matchingDay = getItineraryData?.days?.find(
            (day) => UTCFormatItineary(day?.date) === date,
          )

          return {
            date,
            subTitle: matchingDay ? matchingDay.subTitle : '',
            imageFileName: matchingDay ? matchingDay.imageFileName : '',
            itineraries: matchingDay
              ? matchingDay.itineraries.map((itinerary) => ({
                  fromTime: itinerary.fromTime,
                  toTime: itinerary.toTime,
                  itinerary: itinerary.itinerary,
                }))
              : [],
          }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItineraryData.startDate, getItineraryData.endDate, questId])

  const addNewRow = (dayIndex) => {
    const newItinerary = { fromTime: '', toTime: '', itinerary: '' }

    setDays((prevDays) => [
      ...prevDays.slice(0, dayIndex),
      {
        ...prevDays[dayIndex],
        itineraries: [...prevDays[dayIndex].itineraries, newItinerary],
      },
      ...prevDays.slice(dayIndex + 1),
    ])
  }

  const handleTableInputChange = (dayIndex, rowIndex, field, value) => {
    const updatedDays = [...days]
    updatedDays[dayIndex].itineraries[rowIndex][field] = value
    setDays(updatedDays)
  }

  const handleSubtitleChange = (dayIndex, newValue) => {
    const updatedDays = [...days]
    updatedDays[dayIndex].subTitle = newValue
    setDays(updatedDays)
  }
  const handleItitenary = (dayIndex, rowIndex, field) => {
    const updatedDays = [...days]
    const fromTimeValue =
      updatedDays[dayIndex].itineraries[rowIndex].fromTime.trim()
    if (fromTimeValue === '') {
      Notification.showNotification('From Time is required.', 'danger')
      return
    }
    setDays(updatedDays)
    handleBlur()
  }

  const handleTimeSelectionChange = (dayIndex, rowIndex, fromTime, toTime) => {
    const updatedDays = [...days]
    updatedDays[dayIndex].itineraries[rowIndex].fromTime = fromTime
    updatedDays[dayIndex].itineraries[rowIndex].toTime = toTime
    setDays(updatedDays)
  }

  const handleBlur = async () => {
    try {
      const requestBody = {
        days: days.map((day) => ({
          date: day.date,
          subTitle: day.subTitle,
          imageFileName: day.imageFileName,
          itineraries: day.itineraries
            .filter(
              (itinerary) =>
                itinerary.itinerary.trim() !== '' &&
                itinerary.fromTime.trim() !== '',
            )
            .map((itinerary) => ({
              fromTime: itinerary.fromTime,
              toTime: itinerary.toTime,
              itinerary: itinerary.itinerary,
            })),
        })),
      }

      await API.AdminAPI.quests.updateItinerary(questId, requestBody)
    } catch (e) {
      Error.showError(e)
    }
  }

  const getItinerary = async () => {
    try {
      setIsLoading(true)
      const { data } = await API.AdminAPI.quests.getItinerary(questId)
      setItineraryData(data)

      setIsLoading(false)
    } catch (e) {
      Error.showError(e)
    }
  }
  useEffect(() => {
    getItinerary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  const removeRow = (dayIndex, rowIndex) => {
    setDays((prevDays) => {
      const updatedDays = [...prevDays]
      updatedDays[dayIndex].itineraries.splice(rowIndex, 1)
      handleBlur()
      return updatedDays
    })
  }
  const handleFileClick = (index) => {
    if (imageRefs.current[index]) {
      imageRefs.current[index].click()
    }
  }
  const handleFileChange = (index, e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      uploadItineraryImage(index, imageFile)
    }
  }

  const uploadItineraryImage = async (index, imageFile) => {
    try {
      const contentType = imageFile.name
        .substr(imageFile.name.lastIndexOf('.') + 1)
        .toUpperCase()

      const { data } = await API.AdminAPI.quests.getQuestImage(
        questId,
        contentType,
      )
      setUploadingIndex(index)

      if (data) {
        let uploadImage = await axios.put(data.url.url, imageFile, {
          headers: {
            'Content-Type': data.url.contentType,
          },
        })

        if (uploadImage) {
          setDays((prevDays) => {
            const updatedDays = [...prevDays]
            updatedDays[index].imageFileName = data.fileName
            handleBlur()
            return updatedDays
          })
        }
      }
    } catch (e) {
      Error.showError(e)
    } finally {
      setTimeout(() => {
        setUploadingIndex(null)
      }, 850)
    }
  }

  if (isLoading) {
    return (
      <div>
        <ABSpinner />
      </div>
    )
  }
  return (
    <>
      <Helmet>
        <title>{`Quest-Itinerary | Abroad`}</title>
        <meta name='title' content={'Quest-Itinerary | Abroad'}></meta>
        <meta property='og:title' content={'Quest-Itinerary | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Quest-Itinerary | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/itinerary`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/itinerary`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/itinerary`}></meta>
      </Helmet>
      <div>
        <div className='mt-5'>
          <h3 className='mt-40 text-center sa1'>
            Please add start and end date in details page
          </h3>
          <p className='mt-3 text-center s5'>
            Once you add the dates, this page will auto generate day-wise
            itinerary to add the timing
          </p>
          {days.map((day, dayIndex) => (
            <div key={dayIndex}>
              <Row>
                <Col lg={6}>
                  <h3 className='s3 text-capitalize'>{`Day ${numberToWords.toWords(
                    dayIndex + 1,
                  )}: ${getItineraryDate(day.date)}`}</h3>
                  <div className='mt-2'>
                    <label htmlFor={`subtitleField-${dayIndex}`} className='s8'>
                      SUBTITLE
                    </label>
                    <QuestInputField
                      type='text'
                      name='subtitle'
                      value={day.subTitle}
                      id={`subtitleField-${dayIndex}`}
                      className='w-100 sa2 selection-field'
                      handleBlur={handleBlur}
                      handleInputChange={(newValue) =>
                        handleSubtitleChange(dayIndex, newValue)
                      }
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className='cursor-pointer'
                    onClick={() => handleFileClick(dayIndex)}>
                    <label className='s8'>
                      {' '}
                      HEADER IMAGE (Click to update)
                    </label>

                    <div>
                      {uploadingIndex === dayIndex && (
                        <div className={`text-saffron-700 uploading-text`}>
                          Uploading...
                        </div>
                      )}

                      {day.imageFileName ? (
                        <Imgix
                          imgixParams={{
                            fit: 'crop',
                            crop: 'center',
                            ar: isMDScreen ? '2:1' : '5:2',
                          }}
                          src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${day.imageFileName}`}
                          attributeConfig={{
                            src: 'data-src',
                            srcSet: 'data-srcset',
                            sizes: 'data-sizes',
                          }}
                          htmlAttributes={{
                            src: `${
                              process.env.REACT_APP_IMG_SOURCE
                            }/quest/${questId}/${day.imageFileName}?ar=${
                              isMDScreen ? '2:1' : '5:2'
                            }&fit=crop&crop=center&fill=blur&blur=300&px=16&auto=format`,
                          }}
                          alt='image'
                          className={`border-radius-10 w-100 questImage-container lazyload ${
                            uploadingIndex === dayIndex ? 'itenaryImage' : ''
                          }`}
                        />
                      ) : (
                        <Imgix
                          imgixParams={{
                            fit: 'crop',
                            crop: 'format',
                            ar: isMDScreen ? '2:1' : '5:2',
                          }}
                          src={`${process.env.REACT_APP_IMG_SOURCE}/quest-placeholder.png`}
                          width={'100%'}
                          alt='image'
                          htmlAttributes={{
                            src: `${
                              process.env.REACT_APP_IMG_SOURCE
                            }/quest-placeholder.png?ar=${
                              isMDScreen ? '2:1' : '5:2'
                            }&fit=crop&crop=format&fill=blur&blur=300&px=16&auto=format`,
                          }}
                          className={`border-radius-10 w-100 lazyload`}
                        />
                      )}
                      <RBForm.Control
                        type='file'
                        ref={(el) => (imageRefs.current[dayIndex] = el)}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(dayIndex, e)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Table responsive className='custom-table mt-3'>
                <thead>
                  <tr>
                    <th width='15%' className='text-center'>
                      <div className='d-flex'>
                        <div className='ml-4' style={{ marginRight: '100px' }}>
                          FROM
                        </div>
                        <div>TO</div>
                      </div>
                    </th>

                    <th width='60%' className='text-center'>
                      ITINERARY
                    </th>
                    <th width='15%'>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {day.itineraries.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td width='15%'>
                        <TimeSelection
                          handleBlur={handleBlur}
                          initialFromTime={row.fromTime}
                          initialToTime={row.toTime}
                          onTimeChange={(from, to) =>
                            handleTimeSelectionChange(
                              dayIndex,
                              rowIndex,
                              from,
                              to,
                            )
                          }
                        />
                      </td>

                      <td width='60%'>
                        <QuestInputField
                          className='w-100 sa2 selection-field'
                          type='text'
                          name='itinerary'
                          id={`fromTime-${dayIndex}-${rowIndex}`}
                          value={row.itinerary}
                          handleBlur={() =>
                            handleItitenary(dayIndex, rowIndex, 'itinerary')
                          }
                          handleInputChange={(newValue) =>
                            handleTableInputChange(
                              dayIndex,
                              rowIndex,
                              'itinerary',
                              newValue,
                            )
                          }
                        />
                      </td>
                      <td width='15%'>
                        <span
                          className='ml-3 cursor-pointer'
                          style={{ color: '#A71818' }}
                          onClick={() => removeRow(dayIndex, rowIndex)}>
                          Remove
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                variant='gray'
                className='px-4 py-3 abroad-blue mt-3 mb-5'
                type='button'
                onClick={() => addNewRow(dayIndex)}>
                ADD NEW ROW
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default QuestsItinerary
