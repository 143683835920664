import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Form as RBForm, Spinner } from 'react-bootstrap'
import useDebounce from './useDebounce'

const UpsertAttachAudio = ({
  search,
  setSearch,
  searchData,
  isSearch,
  inputFocus,
  setInputFocus,
  audios,
  setAudios,
  maxLimit = 10,
  disabled = false,
  showModules = true,
  placeholder = 'Search for an audio from the Abroad Library to share alongside Post-Session Practices.',
  handleAddAudio = () => {},
  isEditable = true,
}) => {
  const [currentIndex, setCurrentIndex] = useState()
  let [prevSelectedAudios, setPrevSelectedAudios] = useState()
  let [localSearch, setLocalSearch] = useState()
  const debouncedSearch = useDebounce(localSearch, 1000)

  useEffect(() => {
    if (localSearch !== undefined) {
      setSearch(debouncedSearch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  const handleChange = (i, event, isString, type) => {
    let values = [...audios.values]
    if (isString) {
      values[i] = {
        ...values[i],
        title: event.target.value,
      }
    } else {
      values[i] = { ...event, type: type }
    }
    setCurrentIndex(i)
    setAudios({ values })
  }

  const addClick = (media) => {
    if (audios.values.length !== maxLimit) {
      setAudios((prevState) => ({ values: [...prevState.values, {}] }))
      handleAddAudio(media, 'add')
    }
  }

  const removeClick = (i) => {
    let values = [...audios.values]
    if (values.length !== 1) {
      handleAddAudio(values[i], 'remove')
      values.splice(i, 1)
      setAudios({ values })
    }
  }

  const handleCheckTitleIsCorrect = (value, index) => {
    let values = [...audios.values]
    let combinedData =
      searchData && searchData?.medias && searchData?.modules
        ? [...searchData?.medias, ...searchData?.modules]
        : []
    if (values[currentIndex]?._id) {
      if (combinedData.length > 1) {
        values.find((e, index) => index === currentIndex).title =
          prevSelectedAudios[index]?.title
      } else if (value !== combinedData[0]?.title) {
        values.find((e, index) => index === currentIndex).title =
          combinedData[0]?.title
      }
    }
  }

  return (
    <>
      {audios.values.map((el, i) => (
        <div key={i} className='mb-2'>
          <RBForm.Control
            type='text'
            placeholder={placeholder}
            onChange={(e) => {
              handleChange(i, e, !('_id' in e))
              setLocalSearch(e.target.value)
            }}
            onFocus={(e) => {
              setPrevSelectedAudios([...audios.values])
              setInputFocus(true)
              setCurrentIndex(i)
              setLocalSearch(e.target.value)
            }}
            onBlur={(e) => {
              handleCheckTitleIsCorrect(e.target.value, i)
              setTimeout(() => {
                setInputFocus(false)
                setCurrentIndex(null)
                setLocalSearch('')
              }, 200)
            }}
            value={el?.title || ''}
            id='search-media'
            autoComplete='off'
            className={classNames(
              'd-inline user-input black-color-placeholder text-dark-900',
              {
                'w-11/12': !disabled,
              },
            )}
            disabled={disabled || (!isEditable && el?._id)}
          />
          {(searchData?.medias?.length > 0 ||
            (showModules && searchData?.modules?.length > 0)) &&
          inputFocus &&
          search !== '' &&
          currentIndex === i ? (
            isSearch ? (
              <div className='d-flex justify-content-center align-items-center search-media-list py-5'>
                <Spinner animation='border' />
              </div>
            ) : (
              <ul className='search-media-list p-0'>
                {showModules &&
                  searchData?.modules?.map((moduleInfo) => (
                    <React.Fragment key={moduleInfo._id}>
                      <li
                        onClick={(e) => {
                          handleChange(i, moduleInfo, false, 'module')
                          setCurrentIndex(null)
                          setLocalSearch('')
                          addClick(moduleInfo)
                        }}
                        className='user-input'>
                        <strong>{moduleInfo.title}</strong> /{' '}
                        {`${moduleInfo?.parentId?.title} (Program Module)`}
                      </li>
                      <hr className='m-0' />
                    </React.Fragment>
                  ))}
                {searchData?.medias?.map((mediaInfo) => (
                  <React.Fragment key={mediaInfo._id}>
                    <li
                      onClick={(e) => {
                        handleChange(i, mediaInfo, false, 'media')
                        setCurrentIndex(null)
                        setLocalSearch('')
                        addClick(mediaInfo)
                      }}
                      className='user-input'>
                      <strong>{mediaInfo.title}</strong> /{' '}
                      {mediaInfo.description}
                    </li>
                    <hr className='m-0' />
                  </React.Fragment>
                ))}
              </ul>
            )
          ) : null}
          {!disabled && el?._id && (
            <div className='w-1/12 text-center d-inline-block'>
              <input
                className='add-client-team-session-btn font-weight-bold'
                type='button'
                value='x'
                onClick={() => removeClick(i)}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default UpsertAttachAudio
