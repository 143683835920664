import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useErrorService, ABSpinner, useUserService } from '@abroad/components'
import { UpdateSessionForm } from '../components/team'
import API from '../utils/API'
import labels from '../constants/labels'

export const SessionInformation = () => {
  const [sessionData, setSessionData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isDiffTeamCoach, setIsDiffTeamCoach] = useState(false)
  const Error = useErrorService()
  const { teamId, sessionId } = useParams()
  const { user } = useUserService()
  const history = useHistory()
  const [teamName, setTeamName] = useState('')
  const [url, setUrl] = useState(null)

  useEffect(() => {
    const getSessionData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getSessionData(sessionId)
        if (data) {
          if (!user?.isAdmin && user?._id !== data?.coachId) {
            setIsDiffTeamCoach(true)
          }
          const sessionData = { ...data }
          const keySkillsData = sessionData?.keySkills?.map((keySkill) => ({
            _id: keySkill?._id,
            title: keySkill?.keySkill?.title,
            description: keySkill?.keySkill?.description,
          }))
          sessionData.keySkills = keySkillsData
          setSessionData(sessionData)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getTeamData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(teamId, {
          fields: [
            'isArchived',
            'name',
            'groupType',
            'groupId',
            'groupIdModel',
            'b2bSOWId',
            'coaches',
          ],
          populateArray: [
            {
              path: 'coaches',
              select: 'fullName',
            },
            {
              path: 'groupId',
              select: 'name',
            },
          ],
        })
        setTeamName(data?.name)
        const isCompanySOWGroup =
          data?.groupType === labels.allGroupType.COMPANY_WITH_SOW
        const isNetworkSOWGroup =
          data?.groupType === labels.allGroupType.NETWORK_WITH_SOW
        if (isCompanySOWGroup || isNetworkSOWGroup) {
          setUrl(
            `/admin/${isCompanySOWGroup ? 'organization' : 'network'}/${
              data?.groupId?.name
            }/sow/${data?.b2bSOWId}/sow-group/${data?._id}/sessions`,
          )
        }
        if (
          data &&
          (!data.coaches.map((data) => data._id).includes(user._id) ||
            data.isArchived) &&
          !user?.isAdmin
        ) {
          history.push('/')
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getTeamData()
    getSessionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${teamName}'s Session | Abroad`}</title>
        <meta name='title' content={`${teamName}'s Session | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${teamName}'s Session | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${teamName}'s Session | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
      </Helmet>
      <UpdateSessionForm
        sessionData={sessionData}
        isDiffTeamCoach={isDiffTeamCoach}
        url={url}
      />
    </>
  )
}
