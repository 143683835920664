import React, { useState, useRef } from 'react'
import Imgix from 'react-imgix'
import axios from 'axios'
import { Form as RBForm } from 'react-bootstrap'
import { Button } from '@abroad/components'
import API from '../../utils/API'

const QuestGallery = ({
  questId,
  setGalleryImageFileNames,
  galleryImages,
  setGalleryImages,
  handleBlur,
}) => {
  const galleryRef = useRef([])
  const imageRef = useRef(null)
  const [galeryImageUpload, setGalleryImageUpload] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const handleMouseEnter = (index) => {
    setHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredIndex(null)
  }

  const handleDeleteImage = (index) => {
    const updatedImages = [...galleryImages]
    updatedImages.splice(index, 1)
    setGalleryImages(updatedImages)
    setGalleryImageFileNames(updatedImages)
  }

  const handleGalleryClick = () => {
    galleryRef.current.click()
  }
  const handleFileChangeGallery = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      uploadGalleryImage(imageFile)
    }
  }

  const uploadImage = async (imageFile) => {
    try {
      const contentType = imageFile.name
        .substr(imageFile.name.lastIndexOf('.') + 1)
        .toUpperCase()

      if (!questId) {
        Notification.showNotification('Title is Required', 'danger')
        return
      }

      const { data } = await API.AdminAPI.quests.getQuestImage(
        questId,
        contentType,
      )
      if (data) {
        await axios.put(data.url.url, imageFile, {
          headers: {
            'Content-Type': data.url.contentType,
          },
        })

        return data.fileName
      }
    } catch (e) {
      imageRef.current.value = ''
      Error.showError(e)
    }
  }
  const uploadGalleryImage = async (imageFile) => {
    try {
      setGalleryImageUpload(true)
      const fileName = await uploadImage(imageFile)
      setGalleryImages((prevImages) => [...prevImages, fileName])
    } catch (e) {
    } finally {
      setTimeout(() => {
        setGalleryImageUpload(false)
      }, 850)
    }
  }
  return (
    <>
      <div className='mt-4'>
        <h3 className='s3'> Gallery</h3>
        <div className='d-flex flex-wrap ' onBlur={() => handleBlur()}>
          {galleryImages.map((image, index) => (
            <div
              key={index}
              onClick={() => handleDeleteImage(index)}
              className='mr-4 mb-3 image-container-gallery'
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}>
              <Imgix
                imgixParams={{
                  fit: 'crop',
                  crop: 'edges',
                  w: 400,
                  h: 400,
                }}
                src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${image}`}
                height={199}
                width={199}
                alt='image'
                className='border-radius-10'
              />

              {hoveredIndex === index && (
                <div className='overlayImage'>
                  <div className='delete-text'>Delete</div>
                </div>
              )}
            </div>
          ))}
        </div>
        <Button
          variant='gray'
          className='px-4 py-3 abroad-blue mt-3'
          type='button'
          onClick={() => handleGalleryClick()}>
          {galeryImageUpload ? 'Uploading...' : 'ADD NEW IMAGE'}
        </Button>
        <RBForm.Control
          type='file'
          accept='image/jpg, image/jpeg, image/png'
          style={{ display: 'none' }}
          onChange={(e) => handleFileChangeGallery(e)}
          ref={galleryRef}
        />
      </div>
    </>
  )
}

export default QuestGallery