import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router'
import {
  useErrorService,
  useNotificationService,
  Button,
} from '@abroad/components'
import useDebounce from '../common/useDebounce'
import API from '../../utils/API'
import { emailRegex } from '../../utils/utility'
import { QuestInputField } from '../form'
const AddUserModal = ({
  showModal,
  handleClose,
  useUserListAPI = false,
  updateParticipants,
}) => {
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])
  const [clientInfo, setClientInfo] = useState([])
  const [isSearch, setIsSearch] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [inputFocus, setInputFocus] = useState(false)

  const Error = useErrorService()
  let { questId } = useParams()
  const Notification = useNotificationService()
  const debouncedSearch = useDebounce(search, 500)

  const handleAssignUser = async () => {
    if (emailRegex.test(search.toLowerCase())) {
      const userInfo = clientInfo.find((e) => e.email === search)
      if (userInfo) {
        const userId = userInfo?._id
        if (questId) {
          await handelForceAssignUser(userId)
        }
      }
    }
  }
  const handelForceAssignUser = async (userId) => {
    try {
      const { data } = await API.AdminAPI.quests.addUserQuest(questId, {
        userId: userId,
      })
      if (data) {
        Notification.showNotification(
          'User has been add successfully',
          'success',
        )
        setSearch('')
        handleClose()
        updateParticipants()
      }
    } catch (e) {
      Error.showError(e)
    }
  }
  const modalClose = () => {
    handleClose()
    setSearch('')
  }

  useEffect(() => {
    if (searchData.length > 0) {
      setIsValid(searchData[0].email === search)
    } else {
      setIsValid(false)
    }
  }, [searchData, search])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.coachClient.getSearchUser(
          debouncedSearch,
        )
        if (data) {
          setSearchData(data)
          setIsSearch(false)
          data.length > 0 && setClientInfo(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    const getUserList = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.userList.getUserList({
          limit: 25,
          page: 1,
          search: debouncedSearch,
          filter: {},
        })
        if (data) {
          setSearchData(data)
          setIsSearch(false)
          data.length > 0 && setClientInfo(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (debouncedSearch !== '' && !useUserListAPI) getSearchData()
    if (debouncedSearch !== '' && useUserListAPI) getUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  const handleBlur = () => {
    setTimeout(() => setInputFocus(false), 200)
  }
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='payment-modal'>
        <Modal.Header className='payment-modal-header d-flex justify-content-center'>
          <h3 className='payment-modal-title mb-0'>
            Search users to add them to quest
          </h3>
        </Modal.Header>
        <Modal.Body className='payment-modal-body'>
          <>
            <label htmlFor='selectionField' className='s8'>
              SEARCH USER
            </label>
            <div className='position-relative w-100'>
              <QuestInputField
                className='w-100 sa2 selection-field'
                type='text'
                value={search}
                handleInputChange={setSearch}
                setInputFocus={setInputFocus}
                name='search'
                handleBlur={handleBlur}
              />
              {searchData.length > 0 && inputFocus && search !== '' ? (
                <ul className='search-user-list position-absolute w-100 p-0'>
                  {searchData.map((userInfo) => (
                    <li
                      onClick={() => {
                        setSearch(`${userInfo.email}`)
                        setSearchData([userInfo])
                      }}
                      className='user-input border-bottom'
                      key={userInfo._id}
                      style={{ overflowX: 'hidden' }}>
                      <strong>{userInfo.fullName}</strong> / {userInfo.email}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
          <Button
            variant='secondary-light'
            onClick={modalClose}
            className='px-3 secondary-popup-button'
            size='sm'>
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            disabled={
              isSearch || search === '' || searchData?.length === 0 || !isValid
            }
            onClick={handleAssignUser}
            className='text-uppercase border-radius-normal text-white hover:text-white'>
            {isSearch ? 'Loading..' : 'ADD USER'}{' '}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddUserModal
