import React, { useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { RightClickIcon, useErrorService } from '@abroad/components'
import ReturnLink from './ReturnLink'
import GoToNextStepBtn from './GoToNextStepBtn'
import API from '../../utils/API'
import CONSTANTS from '../../utils/constants'

const CallOutcome = ({ callOutcome, prevStepHandler, nextStepHandler }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { clientId, sowId } = useParams()
  const [sessionOutCome, setSessionOutCome] = useState(callOutcome)
  const outComeObject = React.useMemo(
    () =>
      Object.entries(CONSTANTS.callOutcomeObj).map((entry) => {
        return { outcomeKey: entry[0], outcomeText: entry[1] }
      }),
    [],
  )
  const Error = useErrorService()

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      const { data } = await API.CoachAPI.coachingSession.updateGrowthSession(
        clientId,
        sowId,
        { callOutcome: sessionOutCome },
      )
      if (data) {
        setIsSubmitting(false)
        nextStepHandler()
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  return (
    <section className='explore-offerings d-flex flex-column'>
      <h3 className='mb-3 font-open-sans font-22 font-bold review-title'>
        Please select the outcome that best describes your client
      </h3>
      {outComeObject?.map((outCome, index) => (
        <div
          className={classNames(
            'outcome-selection d-flex justify-content-between cursor-pointer s6 border-radius-3',
            {
              active: outCome.outcomeKey === sessionOutCome,
            },
          )}
          index={index}
          onClick={() => setSessionOutCome(outCome.outcomeKey)}>
          <span>{outCome?.outcomeText}</span>
          {outCome.outcomeKey === sessionOutCome && (
            <RightClickIcon className='rightclick-icon align-self-center' />
          )}
        </div>
      ))}
      <div className='d-flex justify-content-between mt-3'>
        <ReturnLink
          prevStepHandler={prevStepHandler}
          isDisabled={isSubmitting}
        />
        <GoToNextStepBtn
          nextStepHandler={handleSubmit}
          isDisabled={isSubmitting || !sessionOutCome}
          isLoading={isSubmitting}
          btnText='FINISH'
        />
      </div>
    </section>
  )
}

export default CallOutcome
