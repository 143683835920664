import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import classNames from 'classnames'
import { Button, useErrorService, ABSpinner } from '@abroad/components'
import API from '../../utils/API'
import useDebounce from './useDebounce'
import labels from '../../constants/labels'

const SearchInput = ({
  search,
  render,
  setsource,
  setClientInfo,
  handleSubmit,
  buttonText,
  placeholder,
  btnColumnClasses = '',
  inputColumnClasses = '',
  wrapperClasses = '',
  listClasses = '',
  inputClasses = '',
  parentComponentLoading = false,
  useUserListAPI = false,
  isShowLoader = false,
  showUserInfo = false,
  handleUserSelect,
}) => {
  const [searchData, setSearchData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedUserInfo, setSelectedUserInfo] = useState(null)
  const [inputFocus, setInputFocus] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const Error = useErrorService()
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    if (searchData.length > 0) {
      setIsValid(searchData[0].email === search)
    } else {
      setIsValid(false)
    }
  }, [searchData, search])

  useEffect(() => {
    if (search === '') {
      setSearchData([])
      setSelectedUserInfo(null)
    }
  }, [search])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.coachClient.getSearchUser(
          debouncedSearch,
        )
        if (data) {
          setSearchData(data)
          setIsSearch(false)
          data.length > 0 && setClientInfo(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    const getUserList = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.userList.getUserList({
          limit: 25,
          page: 1,
          search: debouncedSearch,
          filter: {},
        })
        if (data) {
          setSearchData(data)
          setIsSearch(false)
          data.length > 0 && setClientInfo(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (debouncedSearch !== '' && !useUserListAPI) getSearchData()
    if (debouncedSearch !== '' && useUserListAPI) getUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  const fetchUserInfo = async (userId) => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.userList.allUserData(userId)
      setSelectedUserInfo(data)
      setIsLoading(false)
    } catch (e) {
      setsource('')
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <Row className={wrapperClasses}>
      <Col className={inputColumnClasses}>
        <Form.Control
          type='text'
          placeholder={placeholder}
          onChange={(e) => render(e.target.value)}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setTimeout(() => setInputFocus(false), 200)}
          value={search}
          id='search-user'
          size='md'
          className={classNames('user-input', inputClasses)}
        />
        {searchData.length > 0 && inputFocus && search !== '' ? (
          isSearch ? (
            <div className='d-flex justify-content-center align-items-center search-user-list py-5'>
              <Spinner animation='border' />
            </div>
          ) : (
            <ul className={classNames('search-user-list p-0', listClasses)}>
              {searchData?.map((userInfo) => (
                <React.Fragment key={userInfo?._id}>
                  <li
                    onClick={() => {
                      render(userInfo?.email)
                      setSearchData([userInfo])
                      fetchUserInfo(userInfo?.id)
                      if (handleUserSelect) {
                        handleUserSelect(userInfo?.id)
                      }
                    }}
                    className='user-input'>
                    <strong>{userInfo?.fullName}</strong> / {userInfo?.email}/
                    Organization:
                    {userInfo?.company === null || userInfo?.company === ''
                      ? 'Not Specified'
                      : userInfo?.company}{' '}
                    / Role :
                    {userInfo?.profile?.roleInCompany === undefined ||
                    userInfo?.profile?.roleInCompany === null
                      ? 'Not Specified'
                      : userInfo?.profile?.roleInCompany}{' '}
                    / Gender :{' '}
                    {userInfo?.profile?.gender === undefined
                      ? 'Not Specified'
                      : userInfo?.profile?.gender}{' '}
                    / Country :{' '}
                    {userInfo?.profile?.country === undefined
                      ? 'Not Specified'
                      : userInfo?.profile?.country}
                  </li>
                  <hr className='m-0' />
                </React.Fragment>
              ))}
            </ul>
          )
        ) : null}
      </Col>
      <Col className={btnColumnClasses}>
        {isLoading && showUserInfo ? (
          <div className='d-flex justify-content-center align-items-center py-3'>
            <ABSpinner />
          </div>
        ) : showUserInfo ? (
          selectedUserInfo ? (
            <Table responsive hover className='mt-4 custom-table'>
              <tbody>
                <tr>
                  <td>Full name: </td>
                  <td>{selectedUserInfo?.user.fullName}</td>
                </tr>
                <tr>
                  <td>Email: </td>
                  <td>{selectedUserInfo?.user.email}</td>
                </tr>
                <tr>
                  <td>Company: </td>
                  <td>{selectedUserInfo?.user?.company || '-'}</td>
                </tr>
                <tr>
                  <td>Static company: </td>
                  <td>{selectedUserInfo?.user.staticCompany || '-'}</td>
                </tr>
                <tr>
                  <td>Affiliation: </td>
                  <td>
                    {selectedUserInfo?.user?.affiliation.length === 0
                      ? '-'
                      : selectedUserInfo?.user?.affiliation}
                  </td>
                </tr>
                <tr>
                  <td>Plan: </td>
                  <td>
                    {labels.plans.find(
                      (plan) => plan.value === selectedUserInfo?.user.planCode,
                    )?.label || selectedUserInfo?.user.planCode}
                  </td>
                </tr>

                <tr>
                  <td>Coach name: </td>
                  <td>
                    {selectedUserInfo?.coaches?.length > 0
                      ? selectedUserInfo.coaches
                          .map((coach) => coach.coachId.fullName)
                          .join(' | ')
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Coaching plan(s): </td>
                  <td>{selectedUserInfo?.coachingPlansCount}</td>
                </tr>
                <tr>
                  <td>Session(s): </td>
                  <td>{selectedUserInfo?.sessionsCount}</td>
                </tr>
                <tr>
                  <td>Survey(s): </td>
                  <td>{selectedUserInfo?.surveyCount}</td>
                </tr>
                <tr>
                  <td>Simple360 request(s): </td>
                  <td>{selectedUserInfo?.simple360Count}</td>
                </tr>
                <tr>
                  <td>Custom360 request(s): </td>
                  <td>{selectedUserInfo?.custom360Count}</td>
                </tr>
                <tr>
                  <td>Leader request(s): </td>
                  <td>{selectedUserInfo?.leaderRequestCount}</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <div className='s5 p-0 mt-3'>No user available at the moment</div>
          )
        ) : (
          <Button
            variant='gray'
            size='md'
            className='black-color-placeholder text-uppercase my-3 border-radius-3'
            isLoading={isShowLoader ? parentComponentLoading : false}
            onClick={handleSubmit}
            disabled={
              search === '' ||
              searchData?.length === 0 ||
              !isValid ||
              isSearch ||
              parentComponentLoading
            }>
            {isSearch ? 'Loading...' : buttonText}
          </Button>
        )}
      </Col>
    </Row>
  )
}

export default SearchInput
