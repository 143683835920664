import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, Link, NavLink } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'
import {
  InfoIcon,
  useErrorService,
  surveyType,
  PrintableArea,
} from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import { WisdomAssessmentResult } from '../components'
import API from '../utils/API'
import i18n from '../utils/i18n'

const UserCombinedSurveyReport = () => {
  let { state } = useLocation()
  let history = useHistory()
  const Error = useErrorService()
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!state?.surveyId) {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, history])

  useEffect(() => {
    const getCombinedResult = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.combinedResult({
          surveyIds: state?.surveyId,
        })
        setSurvey(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          //history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (state?.surveyId) {
      getCombinedResult()
    } else {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{'User Combined Survey Report | Abroad'}</title>
        <meta
          name='title'
          content={'User Combined Survey Report | Abroad'}></meta>
        <meta
          property='og:title'
          content={'User Combined Survey Report | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'User Combined Survey Report | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/users_combined_report`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/users_combined_report`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/users_combined_report`}></meta>
      </Helmet>
      <main>
        {survey?.surveyType === surveyType?.WISDOM ? (
          <WisdomAssessmentResult
            survey={survey}
            isLoading={isLoading}
            headerText='Organizational Wisdom Assessment'
            subHeaderText='COMBINED SUMMARY RESULTS'
            subHeaderDescription='Below are the aggregate results for how your group rated your organization and themselves during the specified time period.'
          />
        ) : (
          <Result
            survey={survey}
            isLoading={isLoading}
            i18n={i18n}
            navigateToSurveyCategory={(subcategory) => {
              return (
                <Link to={`/survey/category/${subcategory}`}>
                  <InfoIcon />
                </Link>
              )
            }}
            headerText={
              <Trans>
                {state?.isNetwork ? 'Network' : 'Organizational'} Awareness
                Assessment
              </Trans>
            }
            subHeaderText={<Trans>COMBINED SUMMARY RESULTS</Trans>}
            subHeaderDescription={
              <Trans>
                Below are the aggregate results for how your group rated your
                organization and themselves during the specified time period.
              </Trans>
            }
            showBreakthroughProgramInfo={false}
            onGoBack={() => history.goBack()}
            API={API}
            NavLink={NavLink}
          />
        )}
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}

export default withTranslation('translation')(UserCombinedSurveyReport)
