import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import {
  ABSpinner,
  useErrorService,
  Button,
  CloseIcon,
} from '@abroad/components'
// import { ModuleInfo } from '../common'
import API from '../../utils/API'
const HeaderCategory = React.lazy(() => import('../layout/HeaderCategory'))

const ModuleList = ({ t }) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  let history = useHistory()
  const Error = useErrorService()
  let { mediaCategory, catId, subCatId } = useParams()

  const getMediasById = () => {
    setIsLoading(true)
    if (mediaCategory === 'program') {
      return API.AdminAPI.mediaLibrary.getMediasByModuleId(subCatId)
    } else {
      return API.AdminAPI.mediaLibrary.getMediasBySubcategoryId(subCatId)
    }
  }
  useEffect(() => {
    const getMedias = async () => {
      try {
        const { data } = await getMediasById()
        setData(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (subCatId) {
      getMedias()
    } else {
      history.replace('/media')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteMedia = async (mediaId) => {
    try {
      setIsLoading(true)
      const intent = 'all' // delete image, audio and transcript all
      await API.AdminAPI.mediaLibrary.deleteMediaById(mediaId, intent)
      setIsLoading(false)
      window.location.reload()
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Container
      className='bt-module d-flex flex-column custom-offset-lg-1 custom-col-lg-12 px-0'
      fluid>
      <Row className='h-100 flex-grow-1 overflow-hidden mt-5'>
        <Col className='col-12 h-100 overflow-auto space-y-3 ml-3'>
          <HeaderCategory />
          <Button
            variant='saffron'
            size='sm'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={(e) => {
              history.push(
                `/admin/upsert/${mediaCategory}/${catId}/${subCatId}/create`,
              )
            }}
            className='px-3'>
            Upload
          </Button>
        </Col>
      </Row>
      <Row className='h-100 flex-grow-1 overflow-hidden mt-5'>
        <Col className='col h-100 overflow-auto space-y-3'>
          {data.map((media) => (
            <div
              key={media.id}
              className='border-radius-10 font-14 overflow-hidden d-flex align-items-center justify-content-between media-list title'>
              <div
                className='pl-3 pr-2 py-1 text-truncate font-20 cursor-pointer'
                onClick={(e) => {
                  history.push(
                    `/admin/upsert/${mediaCategory}/${catId}/${subCatId}/${media.id}`,
                  )
                }}>
                <span>{media.title}</span>
              </div>
              <span
                className='cursor-pointer'
                onClick={() => deleteMedia(media.id)}>
                <CloseIcon />
              </span>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

export default withTranslation('translation')(ModuleList)
