import React, { useRef, useState } from 'react'
import {
  Button,
  plans,
  useErrorService,
  useNotificationService,
  useUserService,
  sows,
} from '@abroad/components'
import { Link, useHistory, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { StaffCoachIcon } from '../assetsComponents'
import { DeleteConfirmationPrompt } from '../common'
import { Table, TableInstance } from '../table'
import { getMomentConvertedDateForSOW } from '../../utils/utility'
import { messagesConstant } from '../../constants/messages'
import labels from '../../constants/labels'
import API from '../../utils/API'

const planType = sows.planType
const { coachingPlanStatus } = labels

const ClientSOWTable = ({
  userPlan,
  isUserPlanExpired,
  setClientSOWs,
  clientSOWs,
  isB2BPlanTable = false,
  isLoading,
}) => {
  const history = useHistory()
  const { user } = useUserService()
  const { clientId } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [selectedSOWId, setSelectedSOWId] = useState(null)
  const Notification = useNotificationService()
  const Error = useErrorService()
  const hasMore = useRef(false)

  const renderAddPlanButton = (coachId, isDisabled, btnClasses = '') => {
    return (
      <React.Fragment>
        <Button
          variant='black'
          className={classNames(
            'line-height-normal px-2',
            {
              'cursor-disabled': isDisabled,
            },
            btnClasses,
          )}
          title={
            isDisabled
              ? 'You can only create up to 10 coaching plans for users for each coach.'
              : ''
          }
          disabled={isDisabled}
          size='md'
          onClick={() => {
            if (isDisabled) return
            history.push(
              `/admin/coach/${coachId}/clients/${clientId}/plans/create`,
            )
          }}>
          Add
        </Button>
        <br />
      </React.Fragment>
    )
  }

  const renderCoaches = (coaches, type) => {
    if (type === planType.b2b) {
      return (
        <React.Fragment>
          {user.isAdmin ? (
            <React.Fragment>
              {coaches?.map((coach, index) => (
                <React.Fragment key={index}>
                  <Link
                    className='text-dark-900'
                    to={`/admin/coaches/${coach?.coachId?._id}`}>
                    {coach?.coachId?.fullName}
                    {coach?.coachId?.isStaffCoach ? (
                      <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                    ) : null}
                  </Link>
                  <br />
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {coaches?.map((coach, index) => (
                <React.Fragment key={index}>
                  {coach?.coachId?.fullName}
                  {coach?.coachId?.isStaffCoach ? (
                    <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                  ) : null}
                  <br />
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {user.isAdmin ? (
            <React.Fragment>
              <Link
                className='text-dark-900'
                to={`/admin/coaches/${coaches[0]?._id}`}>
                {coaches[0]?.fullName}
                {coaches[0]?.isStaffCoach ? (
                  <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                ) : null}
              </Link>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {coaches[0]?.fullName}
              {coaches[0]?.isStaffCoach ? (
                <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
              ) : null}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    }
  }

  const renderAddSession = () => {
    return (
      <Button
        variant='none'
        className='add-session-btn p-0 s6'
        onClick={() => {
          if (userPlan === plans.free.code) {
            Notification.showNotification(
              messagesConstant?.upgradeErrorMsg,
              'danger',
            )
          } else if (isUserPlanExpired) {
            Notification.showNotification(
              messagesConstant?.planExpiredErrorMsg,
              'danger',
            )
          } else {
            history.push(`/coach/clients/${clientId}/session`)
          }
        }}>
        Add A Session
      </Button>
    )
  }

  const renderPlanNameLink = (planDetails) => {
    if (planDetails?.type === planType.individual) {
      return (
        <React.Fragment>
          {(planDetails?.coachPlans?.length === 0 ||
            !planDetails?.coachPlans) &&
            !user?.isAdmin && <span>-</span>}
          {planDetails?.coachPlans?.map((plan) => (
            <React.Fragment key={plan?._id}>
              <Link to={`/coach/clients/${clientId}/plans/${plan?._id}`}>
                <b className='custom-text-truncate'>
                  {sows.prefix.individual}
                  {plan.planName}
                </b>
              </Link>
              <br />
            </React.Fragment>
          ))}
          {user?.isAdmin && (
            <React.Fragment>
              {renderAddPlanButton(
                planDetails?.coachId?._id,
                planDetails?.isDisabled,
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    } else if (
      planDetails?.type === planType.b2b &&
      !planDetails?.isDeleted &&
      user?.isAdmin
    ) {
      return (
        <React.Fragment>
          <Link
            to={`/admin/${
              planDetails?.companyId ? 'organization' : 'network'
            }/${
              planDetails?.companyId?.name
                ? planDetails?.companyId?.name
                : planDetails?.networkId?.name
            }/sow/${planDetails?._id}/users`}
            target='_blank'>
            <b className='custom-text-truncate'>{`${sows.prefix.b2b}${planDetails.planName}`}</b>
          </Link>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <b className='custom-text-truncate'>{`${sows.prefix.b2b}${planDetails.planName}`}</b>
        </React.Fragment>
      )
    }
  }

  const getSOWDate = (planDetails) => {
    return (
      <React.Fragment>
        {(!planDetails || planDetails?.length === 0) && !user?.isAdmin && (
          <span>-</span>
        )}
        {planDetails?.map((plan) => (
          <React.Fragment key={plan?._id}>
            <span className='table-truncate-text'>
              {getMomentConvertedDateForSOW(plan?.firstSessionDate)}
            </span>
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }

  const getSOWStatus = (planDetails) => {
    return (
      <React.Fragment>
        {(!planDetails || planDetails?.length === 0) && !user?.isAdmin && (
          <span>-</span>
        )}
        {planDetails?.map((plan) => (
          <React.Fragment key={plan?._id}>
            <span className='table-truncate-text'>
              {coachingPlanStatus.find((e) => e.value === plan.status)?.label ||
                '-'}
            </span>
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'Coach',
        Header: 'Coach',
        width: '20%',
        accessor: (properties) => ({
          coaches: properties?.coachId
            ? [properties?.coachId]
            : properties?.coaches,
          type: properties?.type,
        }),
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            {renderCoaches(cell?.value?.coaches, cell?.value?.type)}
          </span>
        ),
      },
      {
        id: 'sowname',
        Header: 'SOW Name',
        width: '30%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => <span>{renderPlanNameLink(cell?.value)}</span>,
        disableSortBy: true,
      },
      {
        id: 'Start Date',
        Header: 'Start Date',
        width: '15%',
        accessor: (properties) => ({
          sowStartDate: properties?.sowStartDate,
          type: properties?.type,
          coachPlans: properties?.coachPlans,
          coachId: properties?.coachId,
        }),
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.type === sows.planType.b2b ? (
              <span className='table-truncate-text'>
                {getMomentConvertedDateForSOW(cell?.value?.sowStartDate)}
              </span>
            ) : (
              getSOWDate(cell?.value?.coachPlans)
            )}
            {user?.isAdmin &&
              cell?.value?.type !== sows.planType.b2b &&
              renderAddPlanButton(
                cell?.value?.coachId?._id,
                true,
                'visibility-hidden',
              )}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'Status',
        Header: 'Status',
        width: '15%',
        accessor: (properties) => ({
          isDeleted: properties?.isDeleted,
          coachPlans: properties?.coachPlans,
          type: properties?.type,
          coachId: properties?.coachId,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.type === sows.planType.individual && (
              <span>{getSOWStatus(cell?.value?.coachPlans)}</span>
            )}
            {cell?.value?.hasOwnProperty('isDeleted') &&
              cell?.value?.isDeleted !== undefined && (
                <span className='table-truncate-text'>
                  {cell?.value?.isDeleted ? 'Inactive' : 'Active'}
                </span>
              )}
            {user?.isAdmin &&
              cell?.value?.type !== sows.planType.b2b &&
              renderAddPlanButton(
                cell?.value?.coachId?._id,
                true,
                'visibility-hidden',
              )}
          </React.Fragment>
        ),
        disableSortBy: true,
      },
      {
        id: 'Actions',
        Header: 'Actions',
        width: '20%',
        accessor: (properties) => ({
          isAdmin: user?.isAdmin,
          _id: properties?._id,
          type: properties?.type,
          status: properties?.status,
          isDeleted: properties?.isDeleted,
          isCoachOfCurrentPlan: properties?.isCoachOfCurrentPlan,
          isShowAddSessionLink: properties?.isShowAddSessionLink,
        }),
        headerProps: {
          className: 'text-right',
          wrapperClassName: 'd-flex-unset',
        },
        Cell: ({ cell }) => (
          <div
            className={classNames('text-right', {
              'mb-2':
                cell?.value?.isAdmin &&
                cell?.value?.type === planType.b2b &&
                !cell?.value?.isDeleted,
            })}>
            {cell?.value?.isCoachOfCurrentPlan &&
              cell?.value?.type === planType.b2b &&
              !cell?.value?.isDeleted && (
                <span className='table-truncate-text'>
                  {renderAddSession()}
                </span>
              )}
            {!cell?.value?.isShowAddSessionLink &&
              (!cell?.value?.isCoachOfCurrentPlan || !user?.isAdmin) && (
                <span
                  className={classNames({
                    'table-truncate-text': cell?.value?.type === planType.b2b,
                  })}>
                  -
                </span>
              )}
            {cell?.value?.isShowAddSessionLink && (
              <span className='table-truncate-text'>{renderAddSession()}</span>
            )}
            {cell?.value?.type === planType.b2b &&
              cell?.value?.isDeleted &&
              user?.isAdmin && <span className='table-truncate-text'>-</span>}
            {cell?.value?.isAdmin &&
              cell?.value?.type === planType.b2b &&
              !cell?.value?.isDeleted && (
                <span className='s6 delete-link'>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      setSelectedSOWId(cell?.value?._id)
                      setShowModal(true)
                    }}
                    className='delete-link pointer-cursor'>
                    Remove User
                  </a>
                </span>
              )}
          </div>
        ),
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId, isB2BPlanTable],
  )

  const removeUserFromSOW = async () => {
    setIsDeleteLoading(true)
    try {
      const { status } = await API.AdminAPI.companyList.removeUsersFromSOW(
        selectedSOWId,
        {
          ids: [clientId],
        },
      )
      if (status) {
        setClientSOWs((prev) =>
          prev?.filter((plan) => plan?._id !== selectedSOWId),
        )
        setSelectedSOWId(null)
        setIsDeleteLoading(false)
        setShowModal(false)
        Notification.showNotification(
          'User has been removed from SOW',
          'success',
        )
      }
    } catch (e) {
      setIsDeleteLoading(false)
      Error.showError(e)
    }
  }

  return (
    <React.Fragment>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isDeleteLoading}
        handleDelete={removeUserFromSOW}
        message='This can not be reverted. Are you sure you want to remove this
    client from SOW?'
      />
      <TableInstance
        columns={columns}
        data={clientSOWs}
        initialState={{
          sortBy: [{ id: 'sowname', desc: false }],
        }}
        manualSortBy={false}
        disableMultiRemove={false}>
        {({ ...rest }) => (
          <Table
            ref={hasMore}
            emptyTableMessage='No Client SOWs.'
            tableInstance={{
              ...rest,
            }}
            isLoading={isLoading}
            fetchData={() => {}}
          />
        )}
      </TableInstance>
    </React.Fragment>
  )
}

export default ClientSOWTable
