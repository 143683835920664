import React, { useEffect, useState } from 'react'
import { Button, ABSpinner } from '@abroad/components'
import { Table } from 'react-bootstrap'

const Custom360ResultRecipient = ({ reciveQuesWithAns, isLoading }) => {
  const [activeRecipientEmail, setActiveRecipientEmail] = useState(
    reciveQuesWithAns[0]?.recipients[0]?.email,
  )

  useEffect(() => {
    if (reciveQuesWithAns?.length > 0) {
      setActiveRecipientEmail(reciveQuesWithAns[0]?.recipients[0]?.email)
    }
  }, [reciveQuesWithAns])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <div>
      <div className='mb-4 mt-1'>
        {reciveQuesWithAns[0]?.recipients?.map((recipient, index) => (
          <Button
            key={recipient.email}
            variant='none'
            className={`navTabs mt-2`}
            style={{
              background:
                activeRecipientEmail === recipient?.email ? '#0A223C' : '',
              color: activeRecipientEmail === recipient?.email ? '#fff' : '',
            }}
            onClick={() => setActiveRecipientEmail(recipient?.email)}>
            {recipient?.firstName}
          </Button>
        ))}
      </div>
      <div className='d-flex'>
        <div className='table-responsive mt-32px'>
          <Table className='align-middle table-hover'>
            <thead>
              <tr className='sa4 font-16px tableBorder'>
                <th width='30%'>Question</th>
                <th width='5%'></th>
                <th width='65%'>Response</th>
              </tr>
            </thead>
            <tbody className='sa4 font-15px tableBorder'>
              {reciveQuesWithAns?.map((answer, index) => (
                <tr key={index}>
                  <td width='30%' className='align-middle'>
                    {answer?.question?.text}&nbsp;
                    {answer?.question?.type === 'numerical' ? (
                      <span className='font-italic'>
                        (Scale 1-6, strongly disagree to strongly agree)
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td width='5%'></td>
                  <td width='65%' className='align-middle'>
                    {answer?.recipients?.find((recipient) => {
                      return recipient?.email === activeRecipientEmail
                    })?.answer?.text || 'The feedback is not submitted.'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default Custom360ResultRecipient
