import React, { useState, useRef } from 'react'
import axios from 'axios'
import Imgix from 'react-imgix'
import { Row, Col, Form as RBForm, Container } from 'react-bootstrap'
import classNames from 'classnames'
import CKEditor from '../components/editor'
import { Formik } from 'formik'
import { string, object } from 'yup'
import {
  useResolutionService,
  Button,
  useNotificationService,
  useErrorService,
} from '@abroad/components'
import API from '../utils/API'
import { PageHeader } from '../components'
import { InputField } from '../components/form'
import { useHistory } from 'react-router-dom'

const validationSchema = object().shape({
  title: string()
    .max(200, 'Title must be at most 200 characters')
    .required('Required'),
  author: string()
    .max(100, 'Author must be at most 100 characters')
    .required('Required'),
  subTitle: string()
    .max(500, 'Short Description must be at most 500 characters')
    .required('Required'),
  blog: string().required('Required'),
})

const CreateBlog = ({ blogDetail, blogId }) => {
  const isMDScreen = useResolutionService()
  const Notification = useNotificationService()
  const history = useHistory()
  const Error = useErrorService()
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [blogImageUpload, setBlogImageUpload] = useState(false)
  const [blogDetails, setBlogDetails] = useState({
    title: blogDetail?.title || '',
    author: blogDetail?.author || '',
    subTitle: blogDetail?.subTitle || '',
    image: blogDetail?.image || '',
    blog: blogDetail?.blog || '',
  })
  const imageRef = useRef()

  const getTitle = () => {
    if (blogId) {
      return (
        <div className='d-flex'>
          <div
            onClick={() => history.replace('/admin/blogs')}
            className='cursor-pointer'>
            Blog
          </div>
          &nbsp;/&nbsp;
          <>{blogDetail.title ? `${blogDetail.title}` : ''}</>
        </div>
      )
    } else {
      return (
        <div className='d-flex'>
          <div
            onClick={() => history.replace('/admin/blogs')}
            className='cursor-pointer'>
            Blog
          </div>
          &nbsp;/ New
        </div>
      )
    }
  }

  const getImage = () => {
    imageRef.current.click()
  }

  const uploadImage = async (imageFile) => {
    try {
      const contentType = imageFile.name.split('.').pop().toUpperCase()
      const { data } = await API.AdminAPI.quests.getBlogImage(contentType)
      if (data) {
        await axios.put(data.url.url, imageFile, {
          headers: { 'Content-Type': data.url.contentType },
        })
        return data.fileName
      }
    } catch (e) {
      Error.showError(e)
      imageRef.current.value = ''
      throw new Error(e.message)
    }
  }

  const handleImageChange = async (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      try {
        setIsUploading(true)
        setBlogImageUpload(true)
        const imageFile = files[0]
        setFile(URL.createObjectURL(imageFile))
        const fileName = await uploadImage(imageFile)
        setBlogDetails((prevDetails) => ({
          ...prevDetails,
          image: fileName,
        }))
        setIsUploading(false)
      } catch (e) {
        setIsUploading(false)
        setFile(null)
        Error.showError(e)
      } finally {
        setBlogImageUpload(false)
      }
    }
  }
  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true)
      const reqData = {
        title: values.title,
        author: values.author,
        subTitle: values.subTitle,
        image: blogDetails.image || file,
        blog: values.blog,
      }

      if (blogId) {
        const { data } = await API.AdminAPI.quests.getUpdateBlog(
          blogId,
          reqData,
        )
        setIsSubmitting(false)
        if (data) {
          Notification.showNotification('Blog updated!', 'success')
          history.push('/admin/blogs')
        }
      } else {
        const { data } = await API.AdminAPI.quests.getCreateBlog(reqData)
        setIsSubmitting(false)
        if (data) {
          Notification.showNotification('Blog created!', 'success')
          history.push('/admin/blogs')
        }
      }
    } catch (err) {
      console.error('Error submitting form:', err)
      Error.showError(err)
      setIsSubmitting(false)
    }
  }

  return (
    <main>
      <PageHeader titleText={getTitle()} hasBackArrow={true} />
      <Formik
        initialValues={
          blogDetail || {
            title: '',
            subTitle: '',
            author: '',
            blog: '',
            image: '',
          }
        }
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize>
        {({ handleSubmit, setFieldValue, dirty, isValid }) => {
          return (
            <Container
              className='pt-2 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
              fluid>
              <RBForm onSubmit={handleSubmit}>
                <Row className='mt-5'>
                  <Col lg={6}>
                    <div onClick={getImage} className='cursor-pointer'>
                      {isUploading && (
                        <div className='text-saffron-700 uploading-text-quest'>
                          Uploading...
                        </div>
                      )}
                      {file ? (
                        <img
                          src={file}
                          alt='local'
                          style={{
                            aspectRatio: isMDScreen ? '2/1' : '4/2',
                            objectFit: 'cover',
                          }}
                          className={classNames(
                            'border-radius-10 questImage-container lazyload w-100 h-auto',
                            {
                              itenaryImage: blogImageUpload,
                            },
                          )}
                        />
                      ) : blogDetails.image ? (
                        <Imgix
                          imgixParams={{
                            fit: 'crop',
                            crop: 'format',
                            ar: isMDScreen ? '2:1' : '4:2',
                          }}
                          src={`${process.env.REACT_APP_IMG_SOURCE}/blogs/${blogDetails.image}`}
                          attributeConfig={{
                            src: 'data-src',
                            srcSet: 'data-srcset',
                            sizes: 'data-sizes',
                          }}
                          htmlAttributes={{
                            src: `${process.env.REACT_APP_IMG_SOURCE}/blogs/${
                              blogDetails.image
                            }?ar=${
                              isMDScreen ? '2:1' : '4:2'
                            }&fit=crop&crop=center&fill=blur&blur=300&px=16&auto=format`,
                          }}
                          width='100%'
                          alt='Uploaded image'
                          className={classNames(
                            'border-radius-10 questImage-container lazyload',
                            {
                              itenaryImage: blogImageUpload,
                            },
                          )}
                        />
                      ) : (
                        <Imgix
                          imgixParams={{
                            fit: 'crop',
                            crop: 'format',
                            ar: isMDScreen ? '2:1' : '4:2',
                          }}
                          src={`${process.env.REACT_APP_IMG_SOURCE}/quest-placeholder.png`}
                          width='100%'
                          alt='Placeholder'
                          className='border-radius-10 w-100 lazyload'
                        />
                      )}
                      <RBForm.Control
                        type='file'
                        size='lg'
                        placeholder='Select File'
                        accept='image/jpg, image/jpeg, image/png'
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        ref={imageRef}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <label htmlFor='title' className='s8'>
                      TITLE
                    </label>
                    <InputField
                      id='title'
                      type='text'
                      name='title'
                      size='lg'
                      showErrorMessage={true}
                    />
                    <label htmlFor='author' className='s8'>
                      AUTHOR
                    </label>
                    <InputField
                      id='author'
                      name='author'
                      size='lg'
                      showErrorMessage={true}
                    />
                    <label htmlFor='subTitle' className='s8'>
                      SHORT DESCRIPTION
                    </label>
                    <InputField
                      as='textarea'
                      id='subTitle'
                      name='subTitle'
                      size='lg'
                      showErrorMessage={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className='mt-3'>
                    <label htmlFor='blog' className='s8 m-2'>
                      BLOG
                    </label>
                    <CKEditor
                      name='blog'
                      data={blogDetails.blog}
                      onChange={(event, editor) => {
                        setFieldValue('blog', editor.getData())
                      }}
                      blog={true}
                    />
                    <div className='text-right mt-3'>
                      <Button
                        variant='gray'
                        size='md'
                        className='line-height-normal border-radius-3 text-uppercase'
                        type='submit'
                        disabled={
                          blogId
                            ? isSubmitting
                            : !(isValid && dirty) || !file || isSubmitting
                        }>
                        {blogId ? 'UPDATE' : 'SAVE'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </RBForm>
            </Container>
          )
        }}
      </Formik>
    </main>
  )
}

export default CreateBlog
