import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import GaugeChart from './GaugeChart'
import LearnMore from './LearnMore'
import LeaderSelect from './LeaderSelect'
import PolygonChart from './PolygonChart'
import CategoryModal from './CategoryModal'
import ModalProvider from '../common/ModalProvider'
import useResolutionService from '../../services/useResolutionService'

const LeaderAssessmentResult = ({
  isRenderOnClientSide = true,
  source,
  i18n = null,
  id,
  compareDateWise,
  awarenessSurvey,
  leaderSurvey,
  isFetchData,
  isDateArrayLoading,
  dateArray,
  clickOnLearnMoreBtn = () => {},
  onGoBack = () => {}
}) => {
  const showGoBackButton = true
  const headerText = 'Leader Assessment Results'
  const tdClass = 'pt-point-5 pb-point-5 border-bottom-gray'
  const { categories } = leaderSurvey
  const [comparisonChartTitleArray, setComparisonChartTitleArrray] = useState(
    []
  )
  const { isXSScreen, isLGScreen } = useResolutionService()
  const [perceivedArray, setPerceivedArray] = useState([])
  const [selfAwarenessArray, setSelfAwarenessArray] = useState([])
  const [comparisonArray, setComparisonArray] = useState([])
  const [selfAwarenessKeyTitle, setSelfAwarenessKeyTitle] = useState('')
  const [managerEfficacyKeyTitle, setManagerEfficacyKeyTitle] = useState('')
  const [awarenessSurveyTitlePercent, setAwarenessSurveyTitlePercent] =
    useState({})
  const isSMScreen = window.innerWidth <= 425
  const isExSMScreen = window.innerWidth <= 375

  const navigateToSurveyCategory = (text) => {
    return <Popup contentText={text} />
  }

  const managerTitle = (text, className, marginLeft = 2) => {
    return (
      <div
        className={classNames(
          `d-inline-block ml-${marginLeft} font-open-sans font-semibold font-14 leading-6 letter-spacing-minus-02`,
          className
        )}
      >
        {text}
      </div>
    )
  }

  const getValueByIdFromLeaderSurvey = (id, queryKey) => {
    return leaderSurvey?.results[id][queryKey]
  }

  const getSubCategoryValueByKey = (subcategories, key, queryKey) => {
    return subcategories[key][queryKey]
  }

  const getCategoryName = (id) => {
    return categories?.find((e) => e._id === id)?.title
  }

  const getCategoryDescription = (id) => {
    return categories?.find((e) => e._id === id)?.description
  }

  const getPerceivedArrayForComparison = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order'
    )
    const perceivedSubcategories = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'subcategories'
    )

    const resultArr = idsArray.map(
      (idProp) => perceivedSubcategories[idProp]?.percent
    )

    setPerceivedArray(resultArr)
  }

  const getSelfAwarenessArrayForComparison = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order'
    )
    const keyArray = []
    idsArray.forEach((id) => {
      keyArray.push(categories?.find((e) => e?._id === id)?.key)
    })
    const { subcategories } = awarenessSurvey?.results?.self_awareness
    const resultArray = keyArray.map((key) => subcategories[key]?.percent)
    setSelfAwarenessArray(resultArray)
  }

  const getComparisonChartTitleArray = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order'
    )
    const keyArray = []
    idsArray.forEach((id) => {
      keyArray.push(categories?.find((e) => e?._id === id)?.key)
    })

    const mainArr = []
    const selfAwarenessSubcategories =
      awarenessSurvey?.results?.self_awareness?.subcategories

    keyArray.forEach((keyProp) => {
      mainArr.push(selfAwarenessSubcategories[keyProp]?.title)
    })
    setComparisonChartTitleArrray(mainArr)
  }

  const getComparisonArray = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order'
    )
    const keyArray = []
    idsArray.forEach((id) => {
      keyArray.push(categories?.find((e) => e?._id === id)?.key)
    })

    const mainArr = []
    const selfAwarenessSubcategories =
      awarenessSurvey?.results?.self_awareness?.subcategories
    const perceivedSubcategories = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'subcategories'
    )

    keyArray.forEach((keyProp) => {
      mainArr.push({
        id: categories?.find((e) => e?.key === keyProp)?._id,
        title: selfAwarenessSubcategories[keyProp]?.title,
        selfAwarenessValue: selfAwarenessSubcategories[keyProp]?.percent,
        perceivedValue:
          perceivedSubcategories[
            categories?.find((c) => c?.key === keyProp)?._id
          ]?.percent
      })
    })
    setComparisonArray(mainArr)
  }

  useEffect(() => {
    if (awarenessSurvey) {
      getComparisonChartTitleArray()
      getPerceivedArrayForComparison()
      getSelfAwarenessArrayForComparison()
      getComparisonArray()
      setSelfAwarenessKeyTitle(
        categories?.find((e) => e._id === '5b46b176ce416b175ff98e17')?.title
      )
      setManagerEfficacyKeyTitle(
        categories?.find((e) => e._id === '62963961a279e16eeb3aa867')?.title
      )
      setAwarenessSurveyTitlePercent({
        title: awarenessSurvey?.results?.self_awareness?.title,
        percent: awarenessSurvey?.results?.self_awareness?.percent
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awarenessSurvey])

  if (!awarenessSurvey || !leaderSurvey) {
    return null
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Leader Result | Abroad</title>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <meta name='title' content='Leader Result | Abroad'></meta>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <meta property='og:title' content='Leader Result | Abroad'></meta>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <meta property='twitter:title' content='Leader Result | Abroad'></meta>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <link rel='canonical' href={`${source}/survey/leader/${id}`}></link>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <meta
          property='og:url'
          content={`${source}/survey/leader/${id}`}
        ></meta>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <meta
          property='twitter:url'
          content={`${source}/survey/leader/${id}`}
        ></meta>
      </Helmet>
      <main className='abroad-fs-exclude'>
        <ModalProvider>
          <section
            className={classNames({
              'custom-offset-lg-1 custom-col-lg-12 px-0': !isRenderOnClientSide
            })}
          >
            <Container
              className={classNames('pt-4 mb-2-5', {
                'px-0': !isRenderOnClientSide
              })}
              fluid
            >
              <Row className='mb-3'>
                <Col className='text-center'>
                  <div className='d-flex justify-content-center align-items-center'>
                    {showGoBackButton && (
                      <div className='back-btn mr-lg-4 mr-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='36'
                          viewBox='0 0 20 36'
                          fill='none'
                        >
                          <path
                            d='M18.5748 1.35352L1.4248 17.9997'
                            stroke='black'
                            strokeWidth='2.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M18.5748 34.6462L1.4248 18'
                            stroke='black'
                            strokeWidth='2.5'
                            strokeLinecap='round'
                          />
                        </svg>

                        <svg
                          onClick={onGoBack}
                          className='cursor-pointer'
                          width='20'
                          height='36'
                          viewBox='0 0 22 38'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
                            fill='black'
                          />
                        </svg>
                      </div>
                    )}
                    <div className='d-flex'>
                      <h2 className='font-montserrat font-bold font-32 text-dark-900 line-height-39 letter-spacing-minus-02 mb-0'>
                        {headerText}
                      </h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section
            className={classNames({
              'custom-offset-lg-1 custom-col-lg-12 px-0': !isRenderOnClientSide
            })}
          >
            <Container
              className={classNames('pb-4 category-chart-section', {
                'px-0': !isRenderOnClientSide
              })}
              fluid
            >
              <div className='d-lg-flex justify-content-center'>
                <div className='position-relative text-center mr-lg-99px'>
                  <div className='text-center bg-yellow-manager leader-score-card'>
                    <GaugeChart
                      backgroundColor='#e5e5e5'
                      color='#ffffff'
                      percentage={getValueByIdFromLeaderSurvey(
                        '62963961a279e16eeb3aa867',
                        'percent'
                      )}
                      labelProps={{
                        scoreClassName: 'category-score',
                        percentageClassName: 'seperate-percentage'
                      }}
                    />
                    <label className='category-title-label'>
                      {managerEfficacyKeyTitle}
                    </label>
                    {/* <ABTooltip
                      placement='auto-end'
                      controllId='xs-awareness-tooltip'
                      contentText='Now that you have completed your assessment you can quickly see what areas could benefit from growth and development.'
                      className='d-flex d-lg-none xs-awareness-tooltip-icon'
                    /> */}
                  </div>
                  <LearnMore
                    i18n={i18n}
                    category={categories?.find(
                      (e) => e._id === '62963961a279e16eeb3aa867'
                    )}
                    onLearnMoreClick={() => {
                      if (!isRenderOnClientSide) return
                      clickOnLearnMoreBtn(
                        categories?.find(
                          (e) => e._id === '62963961a279e16eeb3aa867'
                        )
                      )
                    }}
                  />
                </div>
                <div className='text-center'>
                  <div className='position-relative text-center bg-blue-manager leader-score-card'>
                    {/* <ABTooltip
                      controllId='assessment-tooltip'
                      contentText='Now that you have completed your assessment you can quickly see what areas could benefit from growth and development.'
                      className='d-none d-lg-flex team-cohesion-tooltip-icon'
                    /> */}
                    <GaugeChart
                      backgroundColor='#e5e5e5'
                      color='#ffffff'
                      percentage={getValueByIdFromLeaderSurvey(
                        '5b46b176ce416b175ff98e17',
                        'percent'
                      )}
                      labelProps={{
                        scoreClassName: 'category-score',
                        percentageClassName: 'seperate-percentage'
                      }}
                    />
                    <label className='category-title-label'>
                      {selfAwarenessKeyTitle}
                    </label>
                  </div>
                  <LearnMore
                    category={categories?.find(
                      (e) => e._id === '5b46b176ce416b175ff98e17'
                    )}
                    onLearnMoreClick={() => {
                      if (!isRenderOnClientSide) return
                      clickOnLearnMoreBtn(
                        categories?.find(
                          (e) => e._id === '5b46b176ce416b175ff98e17'
                        )
                      )
                    }}
                    i18n={i18n}
                  />
                </div>
              </div>
            </Container>
          </section>
          <section
            className={classNames({
              'custom-offset-lg-1 custom-col-lg-12 px-0': !isRenderOnClientSide
            })}
          >
            <Container
              className={classNames({
                'px-0': !isRenderOnClientSide
              })}
              fluid
            >
              <div
                className={classNames(
                  'allover-score-section d-flex justify-content-center',
                  {
                    'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                      isRenderOnClientSide
                  }
                )}
              >
                <Row className={classNames({ 'w-75': isLGScreen })}>
                  <Col className='col-lg-4 col-12 mb-lg-0 mb-3'>
                    <div className='d-flex flex-column'>
                      <h3 className='font-open-sans text-center manager-score font-42 font-bold letter-spacing-minus-02 mb-1'>{`${leaderSurvey?.totalRequestsCompletedCount}/${leaderSurvey?.totalRequestsCount}`}</h3>
                      <span className='font-open-sans text-center font-16 font-semibold leading-7 letter-spacing-minus-02'>
                        Responses Complete
                      </span>
                    </div>
                  </Col>
                  <Col className='col-lg-4 col-12'>
                    <div className='d-flex flex-column'>
                      <h3 className='font-open-sans text-center manager-score font-42 font-bold letter-spacing-minus-02 mb-1'>
                        {leaderSurvey?.results?.workingRelationshipPercentage}%
                      </h3>
                      <span className='font-open-sans text-center font-16 font-semibold leading-7 letter-spacing-minus-02'>
                        Professional Connection
                      </span>
                    </div>
                  </Col>
                  <Col className='col-lg-4 col-12'>
                    <div className='d-flex flex-column'>
                      <h3 className='font-open-sans text-center manager-score font-42 font-bold letter-spacing-minus-02 mb-1'>
                        {leaderSurvey?.results?.personalRelationshipPercentage}%
                      </h3>
                      <span className='font-open-sans text-center font-16 font-semibold leading-7 letter-spacing-minus-02'>
                        Personal Connection
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <section className='bg-gray-104'>
            <Container
              fluid
              className={classNames({
                'custom-offset-lg-1 custom-col-lg-12 px-0':
                  !isRenderOnClientSide
              })}
            >
              <div
                className={classNames({
                  'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                    isRenderOnClientSide
                })}
              >
                <Row className='justify-content-center'>
                  <Col
                    className={classNames(
                      'd-flex flex-column mt-3-5 mb-3-5 col-12 col-lg-11 px-md-30'
                    )}
                  >
                    <span className='explore-insight-title font-bold leading-9 mb-3'>
                      Interpreting Results
                    </span>
                    <p className='explore-insight-description font-semibold leading-7 letter-spacing-minus-02'>
                      Below we have further broken down your assessment results.
                      To learn more about each subcategory, click the info
                      buttons. We are also including here your personal and
                      professional connection scores with your direct reports.
                      This measures how close your colleagues feel to you, which
                      impacts engagement and your results.
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <section
            className={classNames(
              {
                'custom-offset-lg-1 custom-col-lg-12 px-0':
                  !isRenderOnClientSide
              },
              {
                'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                  isRenderOnClientSide
              }
            )}
          >
            <div className='mt-3-5 mb-3-5 font-open-sans'>
              <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                <div className='assessment-circle text-center'>
                  <GaugeChart
                    backgroundColor='#e5e5e5'
                    color='#DCB232'
                    percentage={getValueByIdFromLeaderSurvey(
                      '62963961a279e16eeb3aa867',
                      'percent'
                    )}
                    className='score'
                    size='85%'
                    labelProps={{
                      scoreClassName:
                        'category-total-score text-yellow-manager',
                      percentageClassName: 'percentage-icon text-yellow-manager'
                    }}
                  />
                  <div className='text-yellow-manager font-semibold leading-7-half letter-spacing-minus-02 category-assessment-label'>
                    {managerEfficacyKeyTitle}
                  </div>
                  <LearnMore
                    category={categories?.find(
                      (e) => e._id === '62963961a279e16eeb3aa867'
                    )}
                    onLearnMoreClick={() => {
                      if (!isRenderOnClientSide) return
                      clickOnLearnMoreBtn(
                        categories?.find(
                          (e) => e._id === '62963961a279e16eeb3aa867'
                        )
                      )
                    }}
                    i18n={i18n}
                  />
                </div>
                <div className='assessment-score'>
                  <div className='self-awareness-courses-wrp'>
                    <div className='position-relative self-awareness-courses-lable leading-5 letter-spacing-2 text-uppercase font-montserrat text-yellow-manager'>
                      {managerEfficacyKeyTitle?.toUpperCase()} SUBCATEGORIES
                      {/* <ABTooltip
                        controllId='Key Aspects of Self Awareness'
                        contentText="Clicking the 'Information buttons' next to any subcategory will provide you instant access to a video and write-up to understand your results."
                        className='d-none d-lg-flex self-awareness-tooltip-icon'
                      />
                      <ABTooltip
                        placement='auto-end'
                        controllId='Key Aspects of Self Awareness'
                        contentText="Clicking the 'Information buttons' next to any subcategory will provide you instant access to a video and write-up to understand your results."
                        className='d-flex d-lg-none self-awareness-tooltip-icon'
                      /> */}
                    </div>
                    <div className='self-awareness-courses-title-wrp'>
                      {getValueByIdFromLeaderSurvey(
                        '62963961a279e16eeb3aa867',
                        'order'
                      ).map((subcategoryId) => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          key={getCategoryName(subcategoryId)}
                          className='survey-subcat-link'
                        >
                          <div
                            className='self-awareness-courses-title-row'
                            data-id={getCategoryName(subcategoryId)}
                          >
                            <div className='courses-title'>
                              <span className='course-title-span font-semibold font-open-sans leading-6 letter-spacing-minus-02'>
                                {getCategoryName(subcategoryId)}
                              </span>
                            </div>
                            <div className='d-flex'>
                              <div className='courses-score font-semibold font-open-sans leading-6 letter-spacing-minus-02 mr-3 ms-3 align-self-center'>
                                {Number.isInteger(
                                  getSubCategoryValueByKey(
                                    getValueByIdFromLeaderSurvey(
                                      '62963961a279e16eeb3aa867',
                                      'subcategories'
                                    ),
                                    subcategoryId,
                                    'percent'
                                  )
                                )
                                  ? `${getSubCategoryValueByKey(
                                      getValueByIdFromLeaderSurvey(
                                        '62963961a279e16eeb3aa867',
                                        'subcategories'
                                      ),
                                      subcategoryId,
                                      'percent'
                                    )}%`
                                  : 'N/A'}
                              </div>
                              {navigateToSurveyCategory(
                                getCategoryDescription(subcategoryId)
                              )}
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-gray-104'>
            <Container
              fluid
              className={classNames({
                'custom-offset-lg-1 custom-col-lg-12 px-0':
                  !isRenderOnClientSide
              })}
            >
              <div
                className={classNames({
                  'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                    isRenderOnClientSide
                })}
              >
                <Row className='justify-content-center'>
                  <Col
                    className={classNames(
                      'd-flex flex-column mt-3-25 col-12 col-lg-11 px-md-30'
                    )}
                  >
                    <span className='explore-insight-title font-bold leading-9 mb-3'>
                      Self Awareness Gap Analysis
                    </span>
                    <p className='explore-insight-description font-semibold leading-7 letter-spacing-minus-02 mb-0'>
                      Below is a tool to compare your Self Awareness scores from
                      your Abroad Awareness Assessment with the Perceived Self
                      Awareness scores provided by your direct reports. As busy
                      leaders, there may be times when we are not seeing
                      ourselves clearly. For example, we might believe we are
                      handling our stress gracefully, while our team members
                      feel that our stress is having an impact on our energy and
                      actions. If you have any variance in scores, the goal is
                      not to be hard on yourself, but rather to see this as
                      another tool for Self Awareness and growth.
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <section className='bg-gray-104'>
            <Container
              fluid
              className={classNames({
                'custom-offset-lg-1 custom-col-lg-12 px-0':
                  !isRenderOnClientSide
              })}
            >
              <div
                className={classNames({
                  'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                    isRenderOnClientSide
                })}
              >
                <Row className='justify-content-center'>
                  <Col
                    className={classNames(
                      'd-flex flex-column col-12 col-lg-11 px-md-30'
                    )}
                  >
                    <Row className='mt-3 mt-lg-0'>
                      <Col className='col-12 col-lg-4'>
                        <Container
                          fluid='lg'
                          className='d-flex flex-column h-100 justify-content-between align-items-left py-2 px-0'
                        >
                          <div className='mt-40 order-lg-1 order-2'>
                            <div className='d-flex mb-lg-4 mr-lg-0 mr-3 align-items-center'>
                              <div className='bg-blue-manager manager-result-compare-circle m-0 mr-3 mb-3 mb-lg-0' />
                              <div
                                className={classNames(
                                  'font-open-sans font-semibold font-16 leading-7 letter-spacing-minus-02 mb-3 mb-lg-0 z-index-24',
                                  { 'overflow-category-name': !isXSScreen }
                                )}
                              >
                                {selfAwarenessKeyTitle} (
                                {getValueByIdFromLeaderSurvey(
                                  '5b46b176ce416b175ff98e17',
                                  'percent'
                                )}
                                %)
                              </div>
                            </div>
                            <div className='d-flex align-items-center'>
                              <div className='bg-light-blue-manager manager-result-compare-square m-0 mr-3' />
                              <div className='text-center font-open-sans font-semibold font-16 leading-7 letter-spacing-minus-02'>
                                {awarenessSurveyTitlePercent?.title} (
                                {awarenessSurveyTitlePercent?.percent}%)
                              </div>
                            </div>
                          </div>
                          <div className='mb-lg-40 leader-select order-lg-2 order-1'>
                            {isDateArrayLoading ? (
                              <Spinner
                                animation='border'
                                className='text-saffron-700'
                                role='status'
                                size='sm'
                              />
                            ) : (
                              <React.Fragment>
                                <LeaderSelect
                                  setFirstDefault='true'
                                  name='user-pl'
                                  options={dateArray}
                                  onChange={(e) => compareDateWise(e?.value)}
                                  isLoading={isFetchData}
                                />
                              </React.Fragment>
                            )}
                          </div>
                        </Container>
                      </Col>
                      <Col className='col-12 col-lg-8 bg-gray-104'>
                        <div className='d-flex flex-column align-items-center'>
                          <PolygonChart
                            className='mb-0'
                            categories={comparisonChartTitleArray}
                            backgroundColor='#f3f6f6'
                            // height='100%'
                            colors={['#345995', '#69b1d8']}
                            surveyOne={perceivedArray}
                            surveyTwo={selfAwarenessArray}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <section className='bg-gray-104'>
            <Container
              fluid
              className={classNames('pb-3-813', {
                'custom-offset-lg-1 custom-col-lg-12 px-0':
                  !isRenderOnClientSide
              })}
            >
              <div
                className={classNames({
                  'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                    isRenderOnClientSide
                })}
              >
                <Row className='justify-content-center'>
                  <Col
                    className={classNames(
                      'd-flex flex-column col-12 col-lg-11 px-md-30'
                    )}
                  >
                    <div className='self-awareness-courses-wrp w-100'>
                      <Table responsive className='custom-table mt-lg-0 mt-3'>
                        <thead>
                          <tr>
                            <th
                              className='text-uppercase font-montserrat font-bold font-10 leading-3 tracking-lg-wide-point-25 p-0 pb-point-5 vertical-align-middle'
                              width='40%'
                            >
                              Subcategory
                            </th>
                            <th
                              width='10%'
                              className='leading-3 tracking-lg-wide-point-25 p-0 pb-point-5 vertical-align-middle'
                            >
                              <div className='bg-blue-manager manager-result-compare-circle small-size-circle m-0-auto' />
                            </th>
                            <th
                              width='10%'
                              className='leading-3 tracking-lg-wide-point-25 p-0 pb-point-5 vertical-align-middle'
                            >
                              <div className='bg-light-blue-manager manager-result-compare-square small-size-square m-0-auto' />
                            </th>
                            <th
                              className={classNames(
                                'text-uppercase font-montserrat font-bold font-10 leading-3 tracking-lg-wide-point-25 p-0 pb-point-5 vertical-align-middle text-right',
                                {
                                  'word-break': isSMScreen
                                }
                              )}
                              width={isSMScreen ? '5%' : '40%'}
                            >
                              difference (+/-)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {comparisonArray.map((e, index) => (
                            <tr className='survey-subcat-link' key={index}>
                              <td className={classNames(tdClass, 'pl-0')}>
                                <Row className='d-flex justify-content-center align-items-center align-self-center'>
                                  <Col
                                    className={classNames(
                                      '',
                                      {
                                        'col-1': !isExSMScreen
                                      },
                                      { 'col-3': isExSMScreen }
                                    )}
                                  >
                                    {navigateToSurveyCategory(
                                      getCategoryDescription(e?.id)
                                    )}
                                  </Col>
                                  <Col
                                    className={classNames('', {
                                      'col-9': isExSMScreen
                                    })}
                                  >
                                    {managerTitle(e.title)}
                                  </Col>
                                </Row>
                              </td>
                              <td
                                className={classNames(tdClass, 'text-center')}
                              >
                                {managerTitle(
                                  `${e?.perceivedValue}%`,
                                  'text-color-blue-manager',
                                  0
                                )}
                              </td>
                              <td
                                className={classNames(tdClass, 'text-center')}
                              >
                                {managerTitle(
                                  `${e?.selfAwarenessValue}%`,
                                  'text-color-light-blue-manager',
                                  0
                                )}
                              </td>
                              <td className={classNames(tdClass, 'text-right')}>
                                {managerTitle(
                                  `${
                                    e?.perceivedValue - e?.selfAwarenessValue
                                  }%`
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <CategoryModal i18n={i18n} />
        </ModalProvider>
      </main>
    </React.Fragment>
  )
}

const Popup = ({
  className = '',
  contentText = 'test',
  title = 'test',
  placement = 'bottom'
}) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip
          id={`tooltip-${placement}-${title}`}
          className='font-open-sans font-16 font-semibold leading-7 text-center header-tooltip'
        >
          {contentText}
        </Tooltip>
      }
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        preserveAspectRatio='none'
        minwidth='16'
        minheight='16'
        viewBox='0 0 16 16'
        fill='none'
        className={classNames('info-icon align-self-center', className)}
      >
        <circle cx='8' cy='8' r='7.5' stroke='black' />
        <circle
          className='info-circle'
          cx='8'
          cy='4.33594'
          r='1'
          fill='black'
        />
        <rect x='7' y='6.66943' width='2' height='6' rx='1' fill='black' />
      </svg>
    </OverlayTrigger>
  )
}

export default LeaderAssessmentResult
