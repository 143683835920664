import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import { DropdownCaretDownIcon, DropdownCaretUpIcon } from '@abroad/components'

const ABSelect = ({
  name,
  options,
  defaultValue = null,
  setFirstDefault = false,
  onChange = () => {},
  placeholder = '',
  disabled = false,
  customDropdown = false,
  onOptionClick = () => {},
  keyProp = null,
  portal = document.body,
  ...rest
}) => {
  const [dropdownValue, setDropdownValue] = useState(defaultValue)

  useEffect(() => {
    if (options?.length > 0 && setFirstDefault) {
      setDropdownValue(options[0])
    } else {
      setDropdownValue(defaultValue)
    }
  }, [defaultValue, options, setFirstDefault, keyProp])

  const customStyles = {
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderBottom: '1px solid #000',
      borderLeft: '1px solid #000',
      borderRight: '1px solid #000',
      boxShadow: 'none',
      borderRadius: 'none',
      borderBottomLeftRadius: '3px',
      borderBottomRightRadius: '3px',
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      boxShadow: 'none',
    }),
    option: (provided, state) => {
      return {
        ...provided,
        display: state.isSelected ? 'none' : 'block',
        backgroundColor: 'transparent',
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
        fontFamily: 'Open Sans',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '1rem',
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 'auto',
        boxShadow: 0,
        padding: 0,
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        borderBottomColor: state.menuIsOpen ? 'transparent !important' : '#000',
        borderBottomLeftRadius: state.menuIsOpen ? '0px' : '3px',
        borderBottomRightRadius: state.menuIsOpen ? '0px' : '3px',
      }
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        opacity: 0,
      }
    },
  }
  if (customDropdown) {
    customStyles.singleValue = (provided, state) => {
      return {
        ...provided,
        opacity: 1,
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }
    }
  }

  const Option = (props) => {
    return (
      <components.Option
        {...props}
        innerProps={{
          ...props.innerProps,
          onClick: (e) => onOptionClick(props),
        }}
      />
    )
  }

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <span onClick={(e) => onOptionClick(props.data)}>{children}</span>
      </components.SingleValue>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <DropdownCaretUpIcon />
          ) : (
            <DropdownCaretDownIcon />
          )}
        </components.DropdownIndicator>
      )
    )
  }
  let customComponents = {}
  if (customDropdown) {
    customComponents = {
      Option,
      SingleValue,
    }
  }

  return (
    <Select
      name={name}
      options={options}
      classNamePrefix='ab-select'
      styles={customStyles}
      isSearchable={false}
      components={{ DropdownIndicator, ...customComponents }}
      menuPortalTarget={portal}
      onChange={(e) => {
        setDropdownValue(e)
        onChange(e)
      }}
      placeholder={placeholder}
      isDisabled={disabled}
      openMenuOnClick={true}
      // controlShouldRenderValue={false}
      // menuIsOpen
      value={dropdownValue}
      {...rest}
    />
  )
}

export default ABSelect
