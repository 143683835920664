import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'
import { useErrorService, useNotificationService } from '@abroad/components'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const QuestDropdown = ({
  wrapperClasses = '',
  formGroupClasses = '',
  selectionClasses = '',
  disabled = false,
}) => {
  const [questList, setQuestList] = useState([])
  const { companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)
  const [selectedQuest, setSelectedQuest] = useState(
    companyAndEcoInfo?.questId || '',
  )
  const [selectQuestLoading, setSelectedQuestLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  useEffect(() => {
    const getLocation = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getQuestList()
        setQuestList(data)
      } catch (e) {
        Error.showError(e)
      }
    }
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companyAndEcoInfo?.questId) {
      setSelectedQuest(companyAndEcoInfo.questId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAndEcoInfo?.questId])

  const updateTargetPlanHandler = async (questId) => {
    const attach = questId !== ''
    const questToAttach = attach ? questId : companyAndEcoInfo?.questId
    setSelectedQuestLoading(true)
    try {
      const { status } = await API.AdminAPI.ecoSystem.attachNetworkQuest(
        companyAndEcoInfo?._id,
        questToAttach,
        {
          attach,
        },
      )
      if (status) {
        setCompanyAndEcoInfo((prev) => ({
          ...prev,
          questId: attach ? questId : '',
        }))
        setSelectedQuest(questId)
        setSelectedQuestLoading(false)
      }
    } catch (e) {
      Notification.showNotification(
        'Quest is attached with another network.',
        'danger',
      )
      setSelectedQuestLoading(false)
    }
  }

  return (
    <>
      <div className={wrapperClasses}>
        <Form.Group className={formGroupClasses}>
          <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label d-flex align-items-end'>
            Attach Network To Quest
          </Form.Label>
          <Form.Control
            as='select'
            name='quest'
            className={classNames(
              'text-uppercase',
              {
                'pointer-cursor': !selectQuestLoading && !disabled,
              },
              selectionClasses,
            )}
            size='md'
            value={selectedQuest}
            onChange={(e) => {
              updateTargetPlanHandler(e.target.value)
            }}
            disabled={selectQuestLoading || disabled}>
            <option value=''> No Quest </option>
            {questList.map((quest) => (
              <option key={quest?.id} value={quest?.id}>
                {quest?.name}
              </option>
            ))}
          </Form.Control>
          {selectQuestLoading && <span className='s7 mt-2'>Updating...</span>}
        </Form.Group>
      </div>
    </>
  )
}

export default QuestDropdown
