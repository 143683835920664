import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-multi-date-picker'
import classNames from 'classnames'
import moment from 'moment'
const MultipleDatePicker = ({
  className,
  spanClassName = '',
  placeholder,
  inputStyle,
  value,
  onChange,
}) => {
  const [isPickerDisabled, setIsPickerDisabled] = useState(false)
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length <= 5) {
      setIsPickerDisabled(selectedDates.length === 5)
      onChange(
        selectedDates
          .map((date) => date.format('MM/DD/YYYY'))
          .filter((date) => date)
          .sort((date1, date2) =>
            moment(date1, 'MM/DD/YYYY').diff(moment(date2, 'MM/DD/YYYY')),
          ),
      )
    }
  }

  return (
    <span
      className={classNames(
        'd-flex position-relative align-items-center w-100',
        spanClassName,
      )}>
      <div className='align-items-center w-100'>
        <DatePicker
          multiple
          value={value}
          onChange={handleDateChange}
          position='bottom-start'
          inputClass={classNames('form-control', className)}
          containerClassName={'w-100'}
          format='MM/DD/YYYY'
          disableDayPicker={isPickerDisabled}
          placeholder={placeholder}
          style={inputStyle}
        />
      </div>
    </span>
  )
}

MultipleDatePicker.defaultProps = {
  disabled: false,
  inputStyle: {
    border: '1px solid #000',
    background: '#fff',
  },
}

MultipleDatePicker.propTypes = {
  disabled: PropTypes.bool,
  inputStyle: PropTypes.object,
}

export default MultipleDatePicker
