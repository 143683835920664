import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  useParams,
  useHistory,
  Link,
  useLocation,
  NavLink,
} from 'react-router-dom'
import {
  InfoIcon,
  surveyType,
  useErrorService,
  PrintableArea,
} from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import { WisdomAssessmentResult } from '../components'
import API from '../utils/API'

const CombinedAwarenessAssessment = () => {
  const { ecoSystemName, companyName } = useParams()
  let history = useHistory()
  let { search } = useLocation()
  const Error = useErrorService()
  const query = new URLSearchParams(search)
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const year = query.get('year')
  const quarter = query.get('quarter')
  const querySurveyType = query.get('surveyType')

  useEffect(() => {
    if (ecoSystemName !== undefined) {
      const getCombinedAwarenessAssessment = async () => {
        try {
          const { data } =
            await API.AdminAPI.companyList.getEcoSystemCombinedAssessment(
              ecoSystemName,
              quarter,
              year,
              querySurveyType,
            )
          setSurvey(data)
          setIsLoading(false)
        } catch (e) {
          if (
            e?.code === 'ValidationError' ||
            e?.code === 'resource_not_found' ||
            e?.code === 'not_found'
          ) {
            history.replace('/404')
          } else {
            setIsLoading(false)
            Error.showError(e)
          }
        }
      }
      if (ecoSystemName || companyName) {
        getCombinedAwarenessAssessment()
      } else {
        history.replace(`/admin/home`)
      }
    } else {
      const getCombinedAwarenessAssessment = async () => {
        try {
          const { data } =
            await API.AdminAPI.companyList.getCompanyCombinedAssessment(
              companyName,
              quarter,
              year,
              querySurveyType,
            )
          setSurvey(data)
          setIsLoading(false)
        } catch (e) {
          if (
            e?.code === 'ValidationError' ||
            e?.code === 'resource_not_found' ||
            e?.code === 'not_found'
          ) {
            history.replace('/404')
          } else {
            setIsLoading(false)
            Error.showError(e)
          }
        }
      }
      if (ecoSystemName || companyName) {
        getCombinedAwarenessAssessment()
      } else {
        history.replace(`/admin/home`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${
          companyName ? companyName : ecoSystemName
        }'s Combined Assessment | Abroad`}</title>
        <meta
          name='title'
          content={`${
            companyName ? companyName : ecoSystemName
          }'s Combined Assessment | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${
            companyName ? companyName : ecoSystemName
          }'s Combined Assessment | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${
            companyName ? companyName : ecoSystemName
          }'s Combined Assessment | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></meta>
      </Helmet>
      <main>
        {querySurveyType === surveyType.WISDOM ? (
          <WisdomAssessmentResult
            survey={survey}
            isLoading={isLoading}
            headerText={`${
              companyName !== undefined ? 'Organization' : 'Network'
            } Wisdom Assessment`}
            subHeaderText={`${decodeURIComponent(
              companyName !== undefined ? companyName : ecoSystemName,
            ).toUpperCase()}'S COMBINED SUMMARY RESULTS`}
            subHeaderDescription='Below are the aggregate results for how all participants within Network rated their organizations and themselves during the specified time period'
          />
        ) : (
          <Result
            survey={survey}
            isLoading={isLoading}
            headerText={`${
              companyName !== undefined ? 'Organization' : 'Network'
            } Awareness Assessment`}
            subHeaderText={`${decodeURIComponent(
              companyName !== undefined ? companyName : ecoSystemName,
            ).toUpperCase()}'S COMBINED SUMMARY RESULTS`}
            subHeaderDescription='Below are the aggregate results for how all participants within Network rated their organizations and themselves during the specified time period'
            navigateToSurveyCategory={(subcategory) => {
              return (
                <Link to={`/survey/category/${subcategory}`}>
                  <InfoIcon />
                </Link>
              )
            }}
            showBreakthroughProgramInfo={false}
            onGoBack={() => history.goBack()}
            showGoBackButton={false}
            API={API}
            NavLink={NavLink}
          />
        )}
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}

export default CombinedAwarenessAssessment
