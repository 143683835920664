import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Button,
  useErrorService,
  useNotificationService,
  useUserService,
  simple360,
  sows,
  surveyType,
} from '@abroad/components'
import SurveyReportButtons from './SurveyReportButtons'
import ShowTakenByCoachName from './ShowTakenByCoachName'
import { TableInstance, Table, IndeterminateCheckbox } from '../table'
import API from '../../utils/API'
import { formatDate } from '../../utils/date'

const ClientAnalytics = ({
  clientCoachIds,
  setCoachHourlyRate,
  coachingPlanExist,
  impactReportIncluded,
  report360Included,
  isCoach,
  isAdmin,
  simple360Url,
}) => {
  const leaderType = 'leader'
  const [isLoading, setIsLoading] = useState(true)
  const [clientSurveyReport, setClientSurveyReport] = useState([])
  const [selectedSurveys, setSelectedSurveys] = useState([])
  const [reportSurveyId, setReportSurveyId] = useState(null)
  const [isReportSurveyDelete, setIsReportSurveyDelete] = useState(false)
  const [showModal, setShowModal] = useState({
    isReportModal: false,
    isSurveyModal: false,
  })
  const [isFilterData, setIsFilterData] = useState(false)
  const [reportData, setReportData] = useState({ data: [], isLoading: true })
  const [surveyData, setSurveyData] = useState({ data: [], isLoading: true })
  const [simple360Data, setSimple360Data] = useState({
    data: [],
    isLoading: true,
  })
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { user } = useUserService()
  const clientDocumentType = {
    1: 'Coaching Agreement',
    2: '360',
    3: 'Impact',
  }
  const leaderAndSimple360TypeArray = ['360', leaderType]
  const hasMore = useRef()
  const history = useHistory()
  const { clientId } = useParams()

  useEffect(() => {
    const fetchClientSurveyReport = async () => {
      try {
        const { data: surveyData } =
          await API.ClientAPI.survey.getLeaderAndAwarenessAssessments(clientId)
        if (surveyData) {
          setSurveyData({
            data: surveyData,
            isLoading: false,
          })
        }
      } catch (e) {
        Error.showError(e)
        setSurveyData({ data: [], isLoading: false })
      }
    }
    fetchClientSurveyReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchClientReports = async () => {
      try {
        const { data: reportData } =
          await API.CoachAPI.document.getClientDocumentList(clientId)
        if (reportData) {
          const newConverterArrayOfReport = reportData.map((doc) => {
            return { ...doc, submittedAt: doc?.uploadedAt }
          })
          setReportData({ data: newConverterArrayOfReport, isLoading: false })
        }
      } catch (e) {
        Error.showError(e)
        setReportData({ data: [], isLoading: false })
      }
    }
    fetchClientReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchClientSimple360Reports = async () => {
      try {
        const { data } = await API.CoachAPI.document.getClientSimple360List(
          clientId,
        )
        if (data) {
          setSimple360Data({
            data: Object.keys(data).length === 0 ? [] : [data],
            isLoading: false,
          })
        }
      } catch (e) {
        Error.showError(e)
        setSimple360Data({ data: [], isLoading: false })
      }
    }
    fetchClientSimple360Reports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !reportData.isLoading &&
      !surveyData.isLoading &&
      !simple360Data.isLoading
    ) {
      const combinedData = [
        ...reportData.data,
        ...surveyData.data,
        ...simple360Data.data,
      ]
      const sortByDate = combinedData.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(b?.createdAt || b?.submittedAt) -
          new Date(a?.createdAt || a?.submittedAt)
        )
      })
      setClientSurveyReport(sortByDate)
      setIsLoading(false)
    }
  }, [
    reportData.isLoading,
    surveyData.isLoading,
    simple360Data.isLoading,
    reportData.data,
    surveyData.data,
    simple360Data.data,
  ])

  useEffect(() => {
    if (isFilterData) {
      setIsLoading(true)
      const filterData = clientSurveyReport.filter(
        (data) => data._id !== reportSurveyId,
      )
      setClientSurveyReport(filterData)
      setIsLoading(false)
      setIsFilterData(false)
    }
  }, [isFilterData, isLoading, clientSurveyReport, reportSurveyId])

  const downloadDocument = async (docId, fileName, docType) => {
    let contentType = fileName.endsWith('.pdf', 4) ? 'PDF' : 'DOCX'
    try {
      const { data } = await API.CoachAPI.document.getDownloadDocumentUrl(
        clientId,
        docId,
        docType,
        fileName,
        contentType,
      )
      if (data) {
        window.open(data.url)
        Notification.showNotification(
          'Document has been downloaded.',
          'success',
        )
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const deleteReport = async () => {
    setIsReportSurveyDelete(true)
    try {
      const { data } = await API.CoachAPI.document.deleteClientDocument(
        clientId,
        reportSurveyId,
      )
      if (data?.status) {
        setIsFilterData(true)
        Notification.showNotification('Document has been deleted.', 'success')
      }
    } catch (e) {
      Error.showError(e)
    }
    setIsReportSurveyDelete(false)
    setShowModal({ isSurveyModal: false, isReportModal: false })
  }

  const handleDeleteSurvey = async () => {
    setIsReportSurveyDelete(true)
    try {
      await API.AdminAPI.companyList.deleteSurvey(reportSurveyId)
      setIsFilterData(true)
    } catch (e) {
      Error.showError(e)
    }
    setIsReportSurveyDelete(false)
    setShowModal({ isSurveyModal: false, isReportModal: false })
  }

  const getReportDeleteLink = (data) => {
    if (data?.type === '360' || data?.surveyType === leaderType) return
    if (user?.isAdmin || (user?._id === data?.uploadedBy && data?.type !== 1)) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={() => {
            setReportSurveyId(data?._id)
            setShowModal({
              isSurveyModal: false,
              isReportModal: true,
            })
          }}
          className='delete-link pointer-cursor analytics-links'
          // eslint-disable-next-line no-script-url
          href='javascript:void(0)'
          key={data?._id}>
          Delete
        </a>
      )
    }
    return <span className='analytics-links'>-</span>
  }

  const getSurveyDeleteLink = (data) => {
    if (user?.isAdmin) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={() => {
            setReportSurveyId(data?._id)
            setShowModal({
              isSurveyModal: true,
              isReportModal: false,
            })
          }}
          className='delete-link pointer-cursor analytics-links'
          // eslint-disable-next-line no-script-url
          href='javascript:void(0)'
          key={data?._id}>
          Delete
        </a>
      )
    }
    return <span className='analytics-links'>-</span>
  }

  const getReportType = (data) => {
    if (data.type === 4) return data?.reportType
    if (data?.type === '360') return simple360
    if (data?.surveyType === leaderType) return 'Leader Assessment'
    if (data?.type) return `${clientDocumentType[data?.type]} Report`
    if (data?.surveyType === surveyType?.WISDOM) return 'Wisdom Assessment'
    return 'Awareness Assessment'
  }

  const handleRedirectToFeedbackPage = (type, id) => {
    if (type === '360') {
      history.push(simple360Url)
    }
    if (type === leaderType) {
      history.push(`/survey/leader/${id}/clients/${clientId}`)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'Date',
        Header: 'Date',
        width: '10%',
        accessor: (properties) => properties?.createdAt,
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              {cell?.row?.original?.type ||
              cell?.row?.original?.surveyType === leaderType ? (
                <>
                  {cell?.row?.original?.type === '360' ? (
                    formatDate(cell?.value)
                  ) : cell?.row?.original?.surveyType === leaderType ? (
                    formatDate(cell?.value)
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        downloadDocument(
                          cell?.row?.original?._id,
                          cell?.row?.original?.fileName,
                          cell?.row?.original.type,
                        )
                      }}
                      className='text-dark-900 date-link font-bold pointer-cursor'
                      rel='noopener noreferrer'
                      target='_blank'
                      key={cell?.row?.original?._id}>
                      {formatDate(cell?.value)}
                    </a>
                  )}
                </>
              ) : (
                <Link
                  to={`/${
                    cell?.row?.original?.surveyType === surveyType?.WISDOM
                      ? 'wisdom'
                      : 'survey'
                  }/${cell?.row?.original?._id}/clients/${clientId}`}
                  target='_blank'
                  className='text-dark-900 date-link font-bold'
                  rel='noreferrer'>
                  {formatDate(cell?.value)}
                </Link>
              )}
            </React.Fragment>
          )
        },
      },
      {
        id: 'Type',
        Header: 'Type',
        width: '20%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => <span>{getReportType(cell?.value)}</span>,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
      {
        id: 'Coach',
        Header: 'Coach',
        width: '10%',
        accessor: (properties) => properties?.coach,
        Cell: ({ cell }) => (
          <ShowTakenByCoachName
            coach={cell?.value}
            userId={user?._id}
            isAdmin={user?.isAdmin}
          />
        ),
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
      {
        id: 'Additional action',
        Header: 'Additional action',
        width: '20%',
        accessor: (properties) => ({
          _id: properties?._id,
          type: properties?.type,
          surveyType: properties?.surveyType,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {leaderAndSimple360TypeArray.includes(
              cell?.value?.type || cell?.value?.surveyType,
            ) && (
              <span
                className='report-link cursor-pointer'
                onClick={() =>
                  handleRedirectToFeedbackPage(
                    cell?.value?.type || cell?.value?.surveyType,
                    cell?.value?._id,
                  )
                }>
                View
              </span>
            )}
          </React.Fragment>
        ),
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
      {
        id: 'SOW',
        Header: 'SOW',
        width: '20%',
        accessor: (properties) => properties.planId,
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.planName
              ? `${sows.prefix.individual}${cell?.value?.planName}`
              : 'N/A'}
          </span>
        ),
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId],
  )

  const selectionHook = useCallback((hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      // Let's make a column for selection
      {
        id: 'selection',
        width: '4%',
        Header: 'Compare',
        Cell: ({ row }) => {
          return (
            <div>
              {!row?.original?.type &&
                row?.original?.surveyType !== leaderType && (
                  <IndeterminateCheckbox
                    disabled={false}
                    _id={row.id}
                    {...row.getToggleRowSelectedProps()}
                  />
                )}
            </div>
          )
        },
        dataProps: {
          className: 'text-center',
        },
        disableSortBy: true,
      },
      {
        id: 'Action Link',
        Header: '',
        width: '10%',
        Cell: ({ row }) => (
          <React.Fragment>
            {row?.original?.type || row?.original?.surveyType === leaderType
              ? getReportDeleteLink(row?.original)
              : getSurveyDeleteLink(row?.original)}
          </React.Fragment>
        ),
        dataProps: {
          className: 'text-right',
        },
        disableSortBy: true,
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectedRow = (selectedRow) => {
    const selectedsurveysArray = selectedRow.map((row) => ({
      surveyType: row?.surveyType,
      id: row?._id,
    }))
    setSelectedSurveys(selectedsurveysArray)
  }

  return (
    <section className='my-3 client-analytics'>
      <Modal
        show={
          showModal.isSurveyModal
            ? showModal.isSurveyModal
            : showModal.isReportModal
        }
        onHide={() => {
          setShowModal({ isSurveyModal: false, isReportModal: false })
        }}
        centered>
        <Modal.Body>
          {showModal.isSurveyModal
            ? 'This can not be reverted. Are you sure you want to delete this survey?'
            : 'This can not be reverted. Are you sure you want to delete this report?'}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='none'
            className='confirmation-btn text-uppercase secondary-popup-button'
            size='sm'
            onClick={() =>
              setShowModal({ isSurveyModal: false, isReportModal: false })
            }>
            Cancel
          </Button>
          <Button
            variant='saffron'
            className='confirmation-btn text-uppercase'
            size='sm'
            onClick={
              showModal.isSurveyModal ? handleDeleteSurvey : deleteReport
            }
            isLoading={isReportSurveyDelete}
            disabled={isReportSurveyDelete}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <TableInstance
        columns={columns}
        data={clientSurveyReport}
        initialState={{
          sortBy: [{ id: 'Date', desc: false }],
        }}
        manualSortBy={false}
        disableMultiRemove={false}
        tableHooks={[selectionHook]}>
        {({ ...rest }) => (
          <Table
            ref={hasMore}
            emptyTableMessage='Looks like user have not yet completed any assessments and coach not uploaded any reports.'
            tableInstance={{
              ...rest,
            }}
            isLoading={
              isLoading ||
              reportData?.isLoading ||
              surveyData?.isLoading ||
              simple360Data?.isLoading
            }
            fetchData={() => {}}
            selectedRow={selectedRow}
            totalNumberOfColumns='7'
          />
        )}
      </TableInstance>
      <SurveyReportButtons
        selectedSurveys={selectedSurveys}
        setIsLoading={setIsLoading}
        setClientSurveyReport={setClientSurveyReport}
        clientSurveyReport={clientSurveyReport}
        clientCoachIds={clientCoachIds?.map((coach) => coach?._id)}
        setCoachHourlyRate={setCoachHourlyRate}
        coachingPlanExist={coachingPlanExist}
        impactReportIncluded={impactReportIncluded}
        report360Included={report360Included}
        isCoach={isCoach}
        isAdmin={isAdmin}
      />
    </section>
  )
}

export default ClientAnalytics
