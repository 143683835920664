import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { ActionButton, PageHeader } from '../common'
import { ReturnPageIcon } from '../assetsComponents'
import { OrgEcoIdsContext } from '../../utils/contexts'

const OrgEcoPageHeader = ({ setShowRightSidebar, sowName, groupName }) => {
  const [hideRightComponents, setHideRightComponents] = useState(true)
  const history = useHistory()
  const location = useLocation()
  const { companyName, ecoSystemName, sowId, sowGroupId, teamId } = useParams()
  const { selectedGroupisArchived } = useContext(OrgEcoIdsContext)
  const isInviteLinkPage = location?.pathname.split('/').pop() === 'invite-link'
  const isWelcomeEmailPage =
    location?.pathname.split('/').pop() === 'welcome-email'
  const url = companyName
    ? `organization/${companyName}`
    : `network/${ecoSystemName}`

  useEffect(() => {
    if (location?.pathname) {
      const routes = [
        'purpose-and-vision',
        'values-and-principles',
        'objectives',
      ]
      const str = location?.pathname.split('/').pop()
      if (routes.includes(str)) {
        setHideRightComponents(true)
      } else {
        setHideRightComponents(false)
      }
    }
  }, [location])
  return (
    <PageHeader
      sectionClassName={classNames({
        'bg-sow-header': sowId && !(sowGroupId || teamId),
        'bg-sow-group-header': sowGroupId || teamId,
      })}
      titleFn={() => {
        return (
          <div className='d-flex'>
            <ReturnPageIcon
              onClick={() => {
                setShowRightSidebar(false)
                isInviteLinkPage || isWelcomeEmailPage
                  ? history.goBack()
                  : sowGroupId
                  ? history.push(`/admin/${url}/sow/${sowId}/sow-groups`)
                  : sowId
                  ? history.push(`/admin/${url}/users`)
                  : hideRightComponents
                  ? history.push(`/admin/${url}/users`)
                  : history.push(
                      `/admin/${companyName ? 'organizations' : 'networks'}`,
                    )
              }}
            />
            <div className='mb-0 d-flex flex-column'>
              <div className='s2'>
                <Link
                  to={`/admin/${url}/${ecoSystemName ? 'users' : 'users'}`}
                  className='text-dark-900'>
                  {companyName
                    ? companyName.toUpperCase()
                    : ecoSystemName.toUpperCase()}
                </Link>
                &nbsp;
                {sowGroupId && sowName && (
                  <>
                    {`/`}&nbsp;
                    <Link
                      to={`/admin/${url}/sow/${sowId}/sow-groups`}
                      className='text-dark-900'>
                      {`${sowName}`}
                    </Link>
                    &nbsp;
                  </>
                )}
                {`${
                  hideRightComponents
                    ? ' / Purpose & Values'
                    : sowId && sowName && !sowGroupId
                    ? `/ ${sowName}`
                    : isInviteLinkPage
                    ? ' / Invite Link'
                    : ''
                }${groupName && sowGroupId ? `/ ${groupName}` : ''} ${
                  isWelcomeEmailPage ? '/ Welcome Email' : ''
                }`}
              </div>
              {sowGroupId && selectedGroupisArchived && (
                <span>This group is archived</span>
              )}
            </div>
          </div>
        )
      }}
      wrapperClassName='d-flex justify-content-between'
      extraFn={() => {
        return (
          <React.Fragment>
            {isInviteLinkPage || isWelcomeEmailPage ? null : (
              <ActionButton
                buttonClickedHandler={() => setShowRightSidebar(true)}
              />
            )}
          </React.Fragment>
        )
      }}
      colClasses='px-0'
    />
  )
}

export default OrgEcoPageHeader
