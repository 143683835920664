import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import {
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { ABSpinner, Button, useErrorService } from '@abroad/components'
import LoadableCategories from './ViewCategories'
import AttachAudioResource from './AttachAudioResource'
import API from '../../utils/API'
import CONSTANTS from '../../utils/constants'
import { Row, Col, Container } from 'react-bootstrap'
import GrowthPlanHeader from './GrowthPlanHeader'

const ViewGrowthPlan = () => {
  const [growthSessionData, setGrowthSessionData] = useState(null)
  const [reviewCall, setReviewCall] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [surveyType, setsurveyType] = useState()
  const [logs, setLogs] = useState({ loading: true, data: null })
  const { clientId, sowId, planId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { url } = useRouteMatch()
  const Error = useErrorService()
  const { isEditable } = location.state || queryParams.get('isEditable') === 'true' || {}

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['fullName', 'fname'],
          },
        )
        if (data) {
          setClientInfo(data)
        }
      } catch (e) {
        setClientInfo(null)
        Error.showError(e)
      }
    }
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setIsLoading(true)
    const getGrowthPlan = async () => {
      try {
        const { data } = await API.CoachAPI.coachingSession.getClientSession(
          planId,
          clientId,
        )
        if (data) {
          const keySkillsData = data?.keySkills?.map((keySkill) => ({
            _id: keySkill?._id,
            title: keySkill?.keySkill?.title,
            description: keySkill?.keySkill?.description,
          }))
          setGrowthSessionData({ ...data, keySkills: keySkillsData })
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (planId) getGrowthPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId])

  useEffect(() => {
    const getSurveyLogs = async () => {
      setLogs({ loading: true, data: null })
      try {
        const { data } = await API.AdminAPI.common.getClientSurveyLogs(clientId, sowId)
        if (data) {
          setLogs({ loading: false, data: data?.logs })
          setsurveyType(data?.surveyType)
          setReviewCall(data?.surveyLogs)
        }
      } catch (e) {
        setLogs({ loading: false, data: null })
        Error.showError(e)
      }
    }
    if (sowId) getSurveyLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowId])

  if (isLoading || logs?.loading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${
          clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
        } Growth Session Plan | Abroad`}</title>
        <meta
          name='title'
          content={`${
            clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
          } Growth Session Plan | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${
            clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
          } Growth Session Plan | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${
            clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
          } Growth Session Plan | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${url}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
      </Helmet>
      <GrowthPlanHeader
        fullName={clientInfo?.fullName}
        date={growthSessionData?.date || null}
      />
      <main>
        <>
          <Container
            className='custom-offset-lg-1 custom-col-lg-12 px-lg-0'
            fluid>
            <section className='mt-3 view-growth-plan'>
              <div className='d-flex'>
                <span className='s8 mr-3 growth-label align-self-center text-right'>
                  LOGGED BY:{' '}
                </span>
                <span className='s6'>{growthSessionData?.coach?.fullName}</span>
              </div>
              <div className='d-flex'>
                <span className='s8 mr-3 growth-label align-self-center'>
                  CALL OUTCOME:{' '}
                </span>
                <span className='s6'>
                  {growthSessionData?.callOutcome
                    ? CONSTANTS.callOutcomeObj[growthSessionData?.callOutcome]
                    : '-'}
                </span>
              </div>
              {isEditable && (
                <Button
                  variant='outline-dark'
                  className='btn s8 text-uppercase border-radius-3 line-height-normal mt-3 outline-dark-btn'
                  onClick={() => {
                    history.push(
                      `/coach/clients/${clientId}/growth-plan/${sowId}/${planId}`,
                    )
                  }}>
                  Edit Growth Plan
                </Button>
              )}
              <div>
                <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle'>
                  Key Takeaways
                </h3>
                <div
                  className='s5'
                  dangerouslySetInnerHTML={{
                    __html: growthSessionData?.coachNotes,
                  }}
                />
                <Row>
                  <Col lg={4}>
                    <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle mb-3'>
                      Category Notes -{' '}
                      {surveyType === 'awareness' ? 'Awareness' : 'Wisdom'}{' '}
                      Assessment
                    </h3>
                    <LoadableCategories
                      logs={logs?.data}
                      sessionLogs={{ answers: reviewCall || {} }}
                      saveLogHandler={() => {}}
                      disabled={true}
                      isViewGPSection={true}
                      type={surveyType === 'awareness' ? 'awareness' : 'wisdom'}
                    />
                  </Col>
                  <Col lg={4}>
                    <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle mb-3'>
                      Category Notes - Purpose & Life Alignment
                    </h3>
                    <LoadableCategories
                      logs={logs?.data}
                      sessionLogs={{ answers: reviewCall || {} }}
                      saveLogHandler={() => {}}
                      disabled={true}
                      isViewGPSection={true}
                      type='purposeAndLife'
                    />
                  </Col>
                  <Col lg={4}>
                    <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle mb-3'>
                      Category Notes -{' '}
                      {surveyType === 'awareness' ? 'Wisdom' : 'Awareness'}{' '}
                      Assessment
                    </h3>
                    <LoadableCategories
                      logs={logs?.data}
                      sessionLogs={{ answers: reviewCall || {} }}
                      saveLogHandler={() => {}}
                      disabled={true}
                      isViewGPSection={true}
                      type={surveyType === 'awareness' ? 'wisdom' : 'awareness'}
                    />
                  </Col>
                </Row>
                {growthSessionData?.postSession && (
                  <>
                    <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle mb-3'>
                      Post Session Practice
                    </h3>
                    <div
                      className='s5'
                      dangerouslySetInnerHTML={{
                        __html: growthSessionData?.postSession,
                      }}
                    />
                  </>
                )}
                <div
                  className={classNames({
                    'view-gp-subtitle':
                      !growthSessionData?.postSession ||
                      growthSessionData?.postSession === '',
                    'my-3': growthSessionData?.postSession,
                  })}>
                  <AttachAudioResource
                    growthPlanData={growthSessionData}
                    setAudioResource={() => {}}
                    setSaveResourcePdf={() => {}}
                    disabled={true}
                  />
                </div>
              </div>
            </section>
          </Container>
        </>
      </main>
    </>
  )
}

export default ViewGrowthPlan
