import React, { useContext, useEffect, useState } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import classNames from 'classnames'
import { useErrorService } from '@abroad/components'
import { InformationIcon } from '../assetsComponents'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const placement = 'left'

const CalenderSetupCheckbox = () => {
  const { companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)
  const [isRequireCalenderSetup, setIsRequireCalenderSetup] = useState(
    companyAndEcoInfo?.requireCalenderSetup || false,
  )
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()

  useEffect(() => {
    if (companyAndEcoInfo?.requireCalenderSetup) {
      setIsRequireCalenderSetup(true)
    } else {
      setIsRequireCalenderSetup(false)
    }
  }, [companyAndEcoInfo])

  const updateCalenderSetupFlag = async () => {
    setIsLoading(true)
    try {
      const { status } = await API.AdminAPI.ecoSystem.updateCalenderSetupFlag(
        { value: !isRequireCalenderSetup },
        companyAndEcoInfo?._id,
      )
      if (status) {
        setIsRequireCalenderSetup(!isRequireCalenderSetup)
        setCompanyAndEcoInfo((prev) => ({
          ...prev,
          requireCalenderSetup: !isRequireCalenderSetup,
        }))
        setIsLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  return (
    <div className='calender-setup-checkbox mb-3'>
      <div className='d-flex'>
        <Form.Check
          id='isRequireCalenderSetup'
          custom={true}
          onChange={updateCalenderSetupFlag}
          checked={isRequireCalenderSetup ? true : false}
          type='checkbox'
          label='Require calender before result?'
          className={classNames('s6', {
            'cursor-pointer': !isLoading,
            'pointer-events-none': isLoading,
          })}
          disabled={isLoading}
        />
        <OverlayTrigger
          key={placement}
          placement={placement}
          overlay={
            <Tooltip
              id={`tooltip-${placement}`}
              className='s11 text-center calendly-booking-tooltip'>
              User will need to set a calendly booking before they can see the
              result.
            </Tooltip>
          }>
          <InformationIcon iconClasses='align-self-center ml-2' />
        </OverlayTrigger>
      </div>
      {isLoading && <span className='s7'>Updating...</span>}
    </div>
  )
}

export default CalenderSetupCheckbox
