import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ABSpinner, useErrorService, useUserService } from '@abroad/components'
import BeginGrowthPlan from './BeginGrowthPlan'
import API from '../../utils/API'
import GrowthSessionsTable from './GrowSessionsTable'

const initialState = {
  isLoading: true,
  data: null,
}

const UserGrowthPlan = () => {
  const [growthSOWData, setGrowthSOWData] = useState(initialState)
  const [isLoading, setIsLoading] = useState(true)
  const [isSurveyTaken, setIsSurveyTaken] = useState(false)
  const [isWisdomTaken, setIsWisdomTaken] = useState(false)
  const { clientId } = useParams()
  const { user } = useUserService()
  const Error = useErrorService()

  const handleVariableStateChange = () => {
    setGrowthSOWData({
      isLoading: false,
      data: null,
    })
  }

  useEffect(() => {
    const getGrowthPlanDetails = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['surveyTaken', 'wisdomTaken'],
          },
        )
        if (data) {
          setIsSurveyTaken(data?.surveyTaken)
          setIsWisdomTaken(data?.wisdomTaken)
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getGrowthPlanDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getGrowthSOW = async () => {
      setGrowthSOWData(initialState)
      try {
        const { data } = await API.AdminAPI.coachPlan.getGrowthSOW(clientId)
        if (data) {
          setGrowthSOWData({
            isLoading: false,
            data,
          })
        } else {
          handleVariableStateChange()
        }
      } catch (e) {
        setGrowthSOWData({
          isLoading: false,
          data: null,
        })
        Error.showError(e)
      }
    }
    if (isSurveyTaken || isWisdomTaken) {
      getGrowthSOW()
    } else {
      handleVariableStateChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyTaken, isWisdomTaken])

  if (isLoading|| growthSOWData.isLoading) {
    return <ABSpinner />
  }

  if (!isSurveyTaken && !isWisdomTaken) {
    return (
      <p className='s5 text-center mt-4'>
        Looks like user has not yet completed any assessments.
      </p>
    )
  }

  const sessionsWithObjects = growthSOWData?.data?.filter((session) => session.session) || []
  const isCurrentPlanUserCoach = growthSOWData?.data?.some(
    (plan) => plan.coachId?._id === user?._id,
  )

  if ((growthSOWData?.data?.length === 0 && (user?.isAdmin || user?.isCoach)) || 
      (growthSOWData?.data?.length === 1 && (user?.isAdmin && !user?.isCoach)) ||
      (growthSOWData?.data?.length === 1 && (user?.isAdmin && user?.isCoach && !isCurrentPlanUserCoach)))
  {
    return (
      <p className='s5 text-center mt-4'>There is no growth plan taken yet.</p>
    )
  }

  return (
    <>
      {(isCurrentPlanUserCoach || (user?.isAdmin && user?.isCoach && isCurrentPlanUserCoach)) && growthSOWData?.data?.length === 1 &&
        sessionsWithObjects.length === 0 && <BeginGrowthPlan sowId={growthSOWData?.data[0]._id} />}
      {(growthSOWData?.data?.length > 1 || sessionsWithObjects.length === 1) && (
        <GrowthSessionsTable growthSOWData={growthSOWData?.data} />
      )}
    </>
  )
}

export default UserGrowthPlan
