import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import { Link, useParams, useHistory } from 'react-router-dom'
import {
  Button,
  useErrorService,
  useUserService,
  useNotificationService,
} from '@abroad/components'
import moment from 'moment'
import { Table, TableInstance } from '../table'
import API from '../../utils/API'
import { getManageGroupURL, isSOWGroups } from '../../utils/utility'

const OrgGroups = ({ orgEcoId }) => {
  const hasMore = useRef(false)
  const { companyName } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [groups, setGroups] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const history = useHistory()
  const { user } = useUserService()
  useEffect(() => {
    const getGroups = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getOrgGroups(
          companyName,
        )
        setGroups(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName])

  const getRestoreArchivedAPIFunction = (teamId) => {
    if (teamId?.isArchived) {
      return API.AdminAPI.teams.restoreTeam(teamId?._id)
    }
    return API.AdminAPI.teams.archivedTeam(teamId?._id, {
      isArchived: true,
    })
  }

  const handleRestoreArchivedTeam = async (teamId) => {
    try {
      const { data } = await getRestoreArchivedAPIFunction(teamId)
      if (data) {
        setGroups((prev) =>
          prev.map((team) => {
            if (team?._id === teamId?._id) {
              return {
                ...team,
                isArchived: !team?.isArchived,
              }
            }
            return team
          }),
        )
        setButtonLoading(false)
        Notification.showNotification(
          `Group has been ${teamId?.isArchived ? 'restored' : 'archived'}!`,
          'success',
        )
      }
    } catch (e) {
      setButtonLoading(false)
      Error.showError(e)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'number',
        Header: '#',
        width: '3%',
        accessor: (properties) => properties,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
        disableSortBy: true,
      },
      {
        id: 'group-name',
        Header: 'Group Name',
        width: '15%',
        accessor: (properties) => properties.name,
        Cell: ({ cell }) => <span className='word-break'>{cell?.value}</span>,
        disableSortBy: true,
      },
      {
        id: 'Coaches',
        Header: 'Coaches',
        width: '15%',
        accessor: (properties) => properties.coaches,
        Cell: ({ cell }) => (
          <span className='word-break'>
            {cell?.value.length === 0 && '-'}
            {cell?.value?.length > 0 &&
              cell?.value.map((d) => (
                <Link
                  key={d?._id}
                  to={`/admin/coaches/${d?._id}`}
                  className='text-saffron-700 cursor-pointer'>
                  {d?.fullName}
                  <br />
                </Link>
              ))}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'Circle',
        Header: 'Circle',
        width: '30%',
        accessor: (properties) => properties?.circle,
        Cell: ({ cell }) => (
          <>
            {cell.value && cell.value?.dates?.length > 0 ? (
              <>
                <span className='word-break'>{`(${cell.value?.dates
                  ?.toString()
                  .replaceAll('/', '-')
                  .replaceAll(',', ', ')})`}</span>
                <br />
                <span>{`${cell.value?.startTime} - ${cell.value?.endTime}`}</span>
              </>
            ) : (
              '-'
            )}
          </>
        ),
        disableSortBy: true,
      },
      {
        id: 'creation-date',
        Header: 'Creation Date',
        width: '10%',
        accessor: (properties) => properties?.createdAt,
        Cell: ({ cell }) => (
          <span>{moment(cell?.value).format('MM-DD-YYYY')}</span>
        ),
        disableSortBy: true,
      },
      {
        id: 'actions',
        Header: 'Action',
        width: '15%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <span>
            {/* eslint-disable */}
            <Link
              className={classnames('text-saffron-700 cursor-pointer', {
                'pr-2 archive-border-right': !isSOWGroups(
                  cell?.value?.groupType,
                ),
                'text-center': isSOWGroups(cell?.value?.groupType),
              })}
              target={
                isSOWGroups(cell?.value?.groupType) && user?.isAdmin
                  ? '_blank'
                  : '_self'
              }
              to={getManageGroupURL(cell?.value, user?.isAdmin)}>
              Manage
            </Link>
            {!isSOWGroups(cell?.value?.groupType) && (
              <a
                href='javascript:void(0)'
                className={classnames('text-red-500 cursor-pointer pl-2', {
                  'cursor-disabled': buttonLoading,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  if (!buttonLoading) {
                    setButtonLoading(true)
                    handleRestoreArchivedTeam(cell?.value)
                  }
                }}>
                {cell?.value?.isArchived ? 'Restore' : 'Archive'}
              </a>
            )}
            {/* eslint-enable */}
          </span>
        ),
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <>
      <TableInstance
        columns={columns}
        data={groups}
        initialState={{
          globalFilter: {
            search: '',
          },
          sortBy: [{ id: 'updated-date', desc: true }],
        }}
        key={'org-group'}>
        {({
          preGlobalFilteredRows,
          state: { globalFilter, ...other },
          setGlobalFilter,
          ...rest
        }) => (
          <>
            <div className='d-flex justify-content-end mb-3'>
              <Button
                variant='gray'
                size='md'
                className='line-height-normal border-radius-3 text-uppercase'
                onClick={() =>
                  history.push('/admin/group', {
                    companyName: companyName,
                    groupType: 'Organization',
                  })
                }>
                create group
              </Button>
            </div>
            <Table
              ref={hasMore}
              emptyTableMessage='No Groups found.'
              tableInstance={{
                preGlobalFilteredRows,
                state: { globalFilter, ...other },
                setGlobalFilter,
                ...rest,
              }}
              isLoading={isLoading}
              fetchData={() => {}}
            />
          </>
        )}
      </TableInstance>
    </>
  )
}

export default OrgGroups
