import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import {
  ABSpinner,
  useErrorService,
  surveyType,
  PrintableArea,
} from '@abroad/components'
import { LeaderAssessmentResult } from '@abroad/components/dist/survey'
import API from '../utils/API'
import i18n from '../utils/i18n'

const LAResult = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [leaderSurvey, setLeaderSurvey] = useState({})
  const [awarenessSurvey, setAwarenessSurvey] = useState()
  const [isFetchData, setIsFetchData] = useState(false)
  const [isDateArrayLoading, setIsDateArrayLoading] = useState(true)
  const [dateArray, setDateArray] = useState([])
  const Error = useErrorService()
  const history = useHistory()
  const { id, clientId } = useParams()

  useEffect(() => {
    const getLeaderResult = async () => {
      try {
        const { data } =
          await API.AdminAPI.companyList.getLeaderAssessmentResultForOP(
            clientId,
            id,
          )
        if (data) {
          setLeaderSurvey(data)
          setAwarenessSurvey(data?.latestAwarenessSurveyResult)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (id && clientId) getLeaderResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, clientId])

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } =
          await API.ClientAPI.survey.getLeaderAndAwarenessAssessments(clientId)
        if (data) {
          let sortedSurveyData = data?.sort((a, b) => {
            return moment(a.submittedAt) > moment(b.submittedAt) ? -1 : 1
          })
          sortedSurveyData = sortedSurveyData.filter(
            (e) =>
              ![surveyType.LEADER, surveyType.WISDOM].includes(e?.surveyType),
          )
          setDateArray(
            sortedSurveyData.map((e) => ({
              value: e?.id || e?._id,
              label: moment(e?.submittedAt).format('MM/DD/YY'),
            })),
          )
          setIsDateArrayLoading(false)
        }
        setIsDateArrayLoading(false)
      } catch (e) {
        setIsDateArrayLoading(false)
        Error.showError(e)
      }
    }
    getSurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const compareDateWise = async (id) => {
    try {
      setIsFetchData(true)
      const { data } = await API.CoachAPI.coachClient.getUserSurveyResult(
        id,
        clientId,
      )
      if (data) {
        setAwarenessSurvey(data)
        setIsFetchData(false)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setIsFetchData(false)
      Error.showError(e)
    }
  }

  if (isLoading) return <ABSpinner />

  return (
    <>
      <LeaderAssessmentResult
        i18n={i18n}
        source={process.env.REACT_APP_DOMAIN}
        id={id}
        compareDateWise={compareDateWise}
        awarenessSurvey={awarenessSurvey}
        leaderSurvey={leaderSurvey}
        isFetchData={isFetchData}
        isDateArrayLoading={isDateArrayLoading}
        dateArray={dateArray}
        isRenderOnClientSide={false}
        onGoBack={() => history.goBack()}
      />
      <PrintableArea printSelector='main' />
    </>
  )
}

export default LAResult
