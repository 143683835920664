import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useErrorService, ABSpinner } from '@abroad/components'
import API from '../utils/API'
import CreateBlog from './CreateBlog'
const BlogDetail = () => {
  const { blogId } = useParams()
  const Error = useErrorService()
  const [blogDetail, setBlogDetail] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getBlogDetail = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getBlogDetail(blogId)
        setBlogDetail(data)
        setIsLoading(false)
      } catch (error) {
        Error.showError(error)
        setIsLoading(false)
      }
    }

    if (blogId) getBlogDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogId])

  if (isLoading && blogId) {
    return <ABSpinner />
  }

  return (
    <>
      <CreateBlog blogDetail={blogDetail} blogId={blogId} />
    </>
  )
}
export default BlogDetail
