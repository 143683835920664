import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Button } from '@abroad/components'
import { QuestInputField } from '../form'

const QuestInclusion = ({  
  experientialInclusions, 
  setExperientialInclusions,
  digitalInclusions, 
  setDigitalInclusions 
}) => {

  const handleExperientialInclusion = (index, newValue) => {
    const updatedInclusions = [...experientialInclusions]
    updatedInclusions[index].inclusion = newValue
    setExperientialInclusions(updatedInclusions)
  }
  const handleDigitalInclusion = (index, newValue) => {
    const updatedInclusions = [...digitalInclusions]
    updatedInclusions[index].inclusion = newValue
    setDigitalInclusions(updatedInclusions)
  }

  const handleAddExperientialInclusion = () => {
    setExperientialInclusions([...experientialInclusions, { inclusion: '' }])
  }
  const handleAddDigitalInclusion = () => {
    setDigitalInclusions([...digitalInclusions, { inclusion: '' }])
  }

  const handleDeleteExperientialInclusion = (index) => {
    const updatedInclusions = experientialInclusions.filter((_, i) => i !== index)
    setExperientialInclusions(updatedInclusions)
  }
  const handleDeleteDigitalInclusion = (index) => {
    const updatedInclusions = digitalInclusions.filter((_, i) => i !== index)
    setDigitalInclusions(updatedInclusions)
  }

  return (
    <>
      <div className='mt-4'>
        <Row>
          <Col lg={6}>
            <h3 className='s3'>Experiential Inclusions</h3>
            <div>
              {experientialInclusions.map((experientialInclusion, index) => (
                <div className='mt-4' key={index}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='input-lable'>
                      INCLUSION
                      {index + 1}
                    </label>
                    <p
                      className='deleteBtn cursor-pointer'
                      onClick={() => handleDeleteExperientialInclusion(index)}>
                      Delete
                    </p>
                  </div>
                  <QuestInputField
                    id={`experientialInclusionField${index}`}
                    className='w-100 sa2 selection-field '
                    type='text'
                    name={`experientialInclusions.${index}.inclusion`}
                    value={experientialInclusion.inclusion}
                    handleBlur={(name,value) => handleExperientialInclusion(index, value)}
                  />
                </div>
              ))}
              <Button
                variant='gray'
                className='px-4 py-3 abroad-blue mt-3'
                type='button'
                onClick={handleAddExperientialInclusion}>
                ADD EXPERIENTIAL INCLUSION
              </Button>
            </div>
          </Col>
          <Col lg={6}>
            <h3 className='s3'>Digital Inclusions</h3>
            <div>
              {digitalInclusions.map((digitalInclusion, index) => (
                <div className='mt-4' key={index}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='input-lable'>
                      INCLUSION
                      {index + 1}
                    </label>
                    <p
                      className='deleteBtn cursor-pointer'
                      onClick={() => handleDeleteDigitalInclusion(index)}>
                      Delete
                    </p>
                  </div>

                  <QuestInputField
                    id={`digitalInclusionField${index}`}
                    className='w-100 sa2 selection-field '
                    type='text'
                    name={`digitalInclusions.${index}.inclusion`}
                    value={digitalInclusion.inclusion}
                    handleBlur={(name, value) => handleDigitalInclusion(index, value)}
                  />
                </div>
              ))}
              <Button
                variant='gray'
                className='px-4 py-3 abroad-blue mt-3'
                type='button'
                onClick={handleAddDigitalInclusion}>
                ADD DIGITAL INCLUSION
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default QuestInclusion