import React, { useEffect, useRef, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import classNames from 'classnames'
import {
  ABSpinner,
  ArrowWrapper,
  useErrorService,
  simple360,
  simple360Url,
} from '@abroad/components'
import API from '../utils/API'
import { formatDate } from '../utils/date'

const ClientSimple360 = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [clientSimple360, setClientSimple360] = useState([])
  const [clientProfile, setClientProfile] = useState(null)
  const [isProfileLoading, setIsProfileLoading] = useState(true)
  const Error = useErrorService()
  let { clientId } = useParams()
  let { url } = useRouteMatch()
  const tabItems = useRef(null)
  const totalValue = 6

  const getConnectionPercentage = (partialValue) => {
    const result = Math.ceil((100 * partialValue) / totalValue)
    return `${result}%`
  }

  useEffect(() => {
    const fetchClientSimple360 = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getClientSimple360(
          clientId,
        )
        if (data) {
          setClientSimple360(data)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    fetchClientSimple360()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchClientProfile = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getClientProfile(
          clientId,
        )
        if (data) {
          setClientProfile(data)
          setIsProfileLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsProfileLoading(false)
      }
    }
    fetchClientProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const replaceNewLineTag = (data) => data.replace(/(?:\r\n|\r|\n)/g, '<br>')

  if (isLoading || isProfileLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${clientProfile?.fname}'s ${simple360} | Abroad`}</title>
        <meta
          name='title'
          content={`${clientProfile?.fname}'s ${simple360} | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${clientProfile?.fname}'s ${simple360} | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${clientProfile?.fname}'s ${simple360} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${url}/${simple360Url}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}/${simple360Url}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}/${simple360Url}`}></meta>
      </Helmet>
      {!isLoading && clientSimple360.length === 0 ? (
        <div className='h-60 d-flex align-items-center justify-content-center'>
          <span className='font-open-sans font-24 font-semibold leading-9 letter-spacing-minus-04'>
            No Responses
          </span>
        </div>
      ) : (
        <section className='simple-360 w-75'>
          <Container fluid className='px-lg-0'>
            <Row>
              <Col id='numbers-tabs'>
                <h4 className='mt-38 font-open-sans font-24 font-bold leading-9 letter-spacing-minus-04'>
                  Question Answered
                </h4>
                <Tab.Container defaultActiveKey='#1'>
                  <Tab.Content>
                    {clientSimple360.map((values, index) => (
                      <Tab.Pane eventKey={`#${index + 1}`} key={index}>
                        {values?.results?.answer360?.question && (
                          <div className='answer360 font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02 mb-38'>
                            {values?.results?.answer360?.question}
                          </div>
                        )}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                  <div className='numbers-tabs border-bottom-none'>
                    <ArrowWrapper
                      parentRef={tabItems}
                      parentId='numbers-tabs'
                      childId='numbers-tab-items'>
                      <Nav
                        className='d-flex'
                        id='numbers-tab-items'
                        ref={tabItems}>
                        {clientSimple360.map((values, index) => (
                          <Nav.Item
                            key={index}
                            className='d-flex simple-360-tabs'>
                            <Nav.Link
                              eventKey={`#${index + 1}`}
                              className={classNames('mb-0', {
                                'mr-0': clientSimple360.length === index + 1,
                              })}>
                              {index + 1}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </ArrowWrapper>
                  </div>
                  <Tab.Content>
                    {clientSimple360.map((values, index) => (
                      <Tab.Pane eventKey={`#${index + 1}`} key={index}>
                        <h4 className='font-open-sans font-20 font-semibold leading-7-half letter-spacing-minus-02 mb-025'>
                          {values?.results?.reportingLevel?.answer
                            ? `${values?.surveyRequestId?.receiverFirstName} - ${values?.results?.reportingLevel?.answer}`
                            : values?.surveyRequestId?.receiverFirstName}
                        </h4>
                        <p className='mb-3 text-dark-900-5 font-open-sans font-12 font-semibold leading-5'>
                          {values?.byUserEmail}
                        </p>
                        {values?.updatedAt && (
                          <p className='mb-025 font-open-sans font-14 font-bold leading-6 letter-spacing-minus-02'>
                            Answered On: {formatDate(values?.updatedAt)}
                          </p>
                        )}
                        {values?.results?.workingRelationship?.answer && (
                          <p className='mb-025 font-open-sans font-14 font-bold leading-6 letter-spacing-minus-02'>
                            Professional Connection:{' '}
                            {getConnectionPercentage(
                              values?.results?.workingRelationship?.answer,
                            )}
                          </p>
                        )}
                        {values?.results?.personalRelationship?.answer && (
                          <p className='mb-4 font-open-sans font-14 font-bold leading-6 letter-spacing-minus-02'>
                            Personal Connection:{' '}
                            {getConnectionPercentage(
                              values?.results?.personalRelationship?.answer,
                            )}
                          </p>
                        )}
                        {values?.results?.answer360?.answer && (
                          <div
                            className='font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02'
                            dangerouslySetInnerHTML={{
                              __html: replaceNewLineTag(
                                values?.results?.answer360?.answer,
                              ),
                            }}
                          />
                        )}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default ClientSimple360
