import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react'
import { Link } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { OrgEcoListingTable, useQuery } from '../components/common'
import API from '../utils/API'
import { ListingFiltersContext } from '../utils/contexts'

export const EcoSystemListing = () => {
  const hasMore = useRef(false)
  const [isLoading, setIsLoading] = useState(true)
  const [ecoSystemData, setEcoSystemData] = useState([])
  const [reqData, setReqData] = useState(null)
  const Error = useErrorService()
  const query = useQuery()
  const search = query.get('search')
  const { setFilters } = useContext(ListingFiltersContext)

  const getEcoSystemList = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.companyList.getEcoSystemList(reqData)
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setEcoSystemData((prev) => [...prev, ...data])
      } else {
        setEcoSystemData(data)
      }
      setFilters((prev) => ({
        ...prev,
        sort: reqData?.filter?.sort,
      }))
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getEcoSystemList(reqData)
    }
  }, [getEcoSystemList, reqData])

  const columns = React.useMemo(
    () => [
      {
        id: 'Network',
        Header: 'Network',
        width: '50%',
        accessor: (properties) => properties.affiliation,
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            <Link to={`network/${cell.value}/users`} className='text-dark-900'>
              {cell.value}
            </Link>
          </span>
        ),
      },
      {
        id: 'Unique User Assessments',
        Header: 'Unique User Assessments',
        width: '50%',
        disableSortBy: true,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        accessor: (properties) => properties.surveyCount,
      },
    ],
    [],
  )

  const fetchData = useCallback(
    ({ search, isItProgram, isEmailSet, status, sortBy, page, limit }) => {
      if (page === 1) {
        setEcoSystemData([])
      }
      const req = {
        limit,
        page: page,
        filter: {
          ...(search && {
            search: search,
          }),
          isItProgram,
          isEmailSet,
          status: status,
          sort: !sortBy[0].desc ? 'ASC' : 'DESC',
        },
      }
      setReqData(req)
    },
    [],
  )

  return (
    <OrgEcoListingTable
      columns={columns}
      data={ecoSystemData}
      label='Networks'
      sortById='Network'
      search={search}
      fetchData={fetchData}
      isLoading={isLoading}
      hasMore={hasMore}
      type='network'
    />
  )
}
