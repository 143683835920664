import React, { useState, useEffect } from 'react'
import { Button, ABSpinner } from '@abroad/components'
import { Table } from 'react-bootstrap'
import AvarageScore from './AvarageScore'
const Custom360ResultQuestion = ({ reciveQuesWithAns, isLoading }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (reciveQuesWithAns?.length > 0) {
      setActiveIndex(0)
    }
  }, [reciveQuesWithAns])

  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <div>
      <div className='mb-4 mt-3'>
        {reciveQuesWithAns.map((ans, index) => (
          <Button
            key={index}
            variant='none'
            className={`navTabs`}
            style={{
              background: activeIndex === index ? '#0A223C' : '',
              color: activeIndex === index ? '#fff' : '',
            }}
            onClick={() => setActiveIndex(index)}>
            {index + 1}
          </Button>
        ))}
      </div>

      <div>
        <div>
          <h2 className='mt-5 sa3'>
            <span className='font-bold'> Question:</span>{' '}
            {reciveQuesWithAns[activeIndex]?.question?.text} &nbsp;
            {reciveQuesWithAns[activeIndex]?.question?.type === 'numerical' ? (
              <span className='font-italic'>
                (Scale 1-6, strongly disagree to strongly agree)
              </span>
            ) : (
              ''
            )}
          </h2>
          <div className='d-flex'>
            <div className='table-responsive mt-32px'>
              <Table className='align-middle table-hover'>
                <thead>
                  <tr className='sa4 font-16px tableBorder'>
                    <th width='30%'>Recipient</th>
                    <th width='70%'>Response</th>
                  </tr>
                </thead>
                <tbody className='sa4 font-15px tableBorder'>
                  {reciveQuesWithAns[activeIndex]?.recipients.map(
                    (answer, index) => (
                      <tr key={index}>
                        <td width='30%' className='align-middle'>
                          {answer?.email}
                        </td>
                        <td width='70%'>
                          {answer?.answer?.text
                            ? answer?.answer?.text
                            : 'The feedback is not submitted.'}
                        </td>
                      </tr>
                    ),
                  )}
                  {reciveQuesWithAns[activeIndex]?.question?.type ===
                    'numerical' && (
                    <tr>
                      <td>Average Score </td>
                      <td>
                        <AvarageScore
                          recipients={
                            reciveQuesWithAns[activeIndex]?.recipients
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Custom360ResultQuestion
