import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import { ABSpinner, useErrorService } from '@abroad/components'
import KeyTakeaways from './KeyTakeaways'
import ReviewAssessmentResults from './ReviewAssessmentResults'
import GoToNextStepBtn from './GoToNextStepBtn'
import API from '../../utils/API'

export const coachNotesKey = 'coachNotes'

const CategoriesTakeawaysForm = ({ coachNote, nextStepHandler }) => {
  const { clientId, sowId, planId } = useParams()
  const [reviewCall, setReviewCall] = useState(null)
  const [isNotesCharsOverLimit, setIsNotesCharsOverLimit] = useState(false)
  const [coachNotes, setCoachNotes] = useState(coachNote)
  const [clientLogs, setClientLogs] = useState({ isLoading: true, data: null })
  const [surveyType, setSurveyType] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isInitiallyLoad, setIsInitiallyLoad] = useState(true)
  const history = useHistory()
  const Error = useErrorService()
  const storageKey = `logs-${clientId}-${sowId}-${planId}`
  const logs = localStorage.getItem(storageKey)
  const parseValue = JSON.parse(logs)
  const isMatch =
    parseValue?.clientId === clientId &&
    parseValue?.planId === planId    
  useEffect(() => {
    if (isInitiallyLoad && isMatch && logs) {
      setCoachNotes(parseValue?.coachNotes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatch, isInitiallyLoad])

  useEffect(() => {
    const getSurveyLogs = async () => {
      try {
        const { data } = await API.AdminAPI.common.getClientSurveyLogs(clientId, sowId)
        if (data) {
          setClientLogs({ isLoading: false, data: data?.logs })
          setSurveyType(data?.surveyType)
          if (isMatch && logs) {
            setReviewCall((prev) => ({
              ...prev,
              ...parseValue?.answers,
              ...data?.surveyLogs,
            }))
            setCoachNotes(parseValue?.coachNotes)
          } else {
            setReviewCall(data?.surveyLogs)
          }
        }
      } catch (e) {
        setClientLogs({ isLoading: false, data: null })
        Error.showError(e)
      }
    }
    getSurveyLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (reviewCall || coachNotes) {
      if (logs && isInitiallyLoad) {
        setIsInitiallyLoad(false)
        return
      }
      localStorage.setItem(
        storageKey,
        JSON.stringify({
          answers: reviewCall,
          coachNotes,
          clientId,
          sowId,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCall, coachNotes, sowId, clientId])

  const saveLogHandler = (data) => {
    if (reviewCall) {
      setReviewCall({ ...reviewCall, ...data })
    } else {
      setReviewCall({ ...data })
    }
  }

  const saveCoachNotesHandler = (data) => {
    setCoachNotes(data)
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const isValuesAreEmpty =
      reviewCall &&
      Object.values(reviewCall).every((e) => e === '' || e === null)
    try {
      const { data } = await API.CoachAPI.coachingSession.updateGrowthSession(
        clientId,
        sowId,
        {
          reviewCall: isValuesAreEmpty ? null : reviewCall,
          coachNotes: !coachNotes || coachNotes === '' ? '-' : coachNotes,
          surveyType: surveyType,
        },
      )
      if (data) {
        setIsSubmitting(false)
        nextStepHandler(
          isValuesAreEmpty ? null : reviewCall,
          !coachNotes || coachNotes === '' ? '-' : coachNotes,
        )
        localStorage.removeItem(storageKey)
        if (planId === 'create') {
          history.replace(
            `/coach/clients/${clientId}/growth-plan/${sowId}/${data?._id}?planId=${data?._id}`,
          )
        }
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  if (clientLogs?.isLoading) {
    return <ABSpinner />
  }

  return (
    <section className='categories-takeaways h-100'>
      <Container fluid>
        <Row>
          <Col className='col-6 categories-notes'>
            <ReviewAssessmentResults
              logs={{ logs: clientLogs?.data }}
              sessionLogs={{ answers: reviewCall || {} }}
              saveLogHandler={saveLogHandler}
              disabled={isSubmitting}
              clientSurveyType={surveyType}
            />
          </Col>
          <Col className='col-6'>
            <KeyTakeaways
              qkey={coachNotesKey}
              key={coachNotesKey}
              saveLogHandler={saveCoachNotesHandler}
              answers={coachNotes}
              disabled={isSubmitting}
              handleCountNotesChars={(value) => {
                setIsNotesCharsOverLimit(value)
              }}
            />
            <div className='d-flex justify-content-end'>
              <GoToNextStepBtn
                nextStepHandler={handleSubmit}
                isLoading={isSubmitting}
                isDisabled={isSubmitting || isNotesCharsOverLimit}
                btnClasses='mt-3 position-absolute growth-step-btn'
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CategoriesTakeawaysForm
