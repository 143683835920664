import React, { useState, useEffect } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom'
import {
  NotificationProvider,
  AuthContext,
  NotificationContainerProvider,
  // useAuth,
  // useUserService,
  useErrorService,
  ErrorView,
} from '@abroad/components'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import sysend from 'sysend'
import AuthService from '../utils/AuthService'
import {
  NotFound,
  ClientRedirect,
  AdminSignOut,
  VerifyingTwoFactAuth,
  UnderMaintenance,
} from '../pages'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import packageJson from '../../package.json'
import API from '../utils/API'
import i18n from '../utils/i18n'
global.appVersion = packageJson.version

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request'],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGES,
  enabledReleaseStages: ['development', 'staging', 'production'],
  appVersion: global?.appVersion,
  appType: 'client',
  onError: function (event) {
    event.setUser(AuthService.getUserData()?._id)
  },
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// const AuthRoutes = ({ children }) => {
//   const { auth } = useAuth()
//   const { user } = useUserService()

//   if (auth && (user?.isAdmin || user?.isCoach)) {
//     return children
//   }
//   return (
//     <Switch>
//       <Redirect to='/' />
//     </Switch>
//   )
// }

/**
 * Navigaton routes for the app
 *
 * @info [react-router-dom](https://reactrouter.com/web/guides/quick-start)
 */
const Routes = () => {
  const userData = AuthService.getUserData()
  const [user, setUser] = useState(userData)
  const emailVerified = user?.emailVerified
  const isInIFrame = window.self !== window.top
  const isAdmin = user?.isAdmin
  const isCoach = user?.isCoach
  const Error = useErrorService()
  const location = useLocation()
  const query = new URLSearchParams(location?.search)
  const nextUrl = query.get('next')
  const history = useHistory()

  useEffect(() => {
    const getUserPriviliges = async () => {
      try {
        const { data } = await API.ClientAPI.user.getUserPriviliges()
        if (data) {
          const profile = data.profile || {}
          delete data.profile
          setUser({
            ...user,
            ...data,
            profile: {
              ...user.profile,
              ...profile,
            },
          })
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (emailVerified && !isInIFrame && (isAdmin || isCoach)) {
      getUserPriviliges()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (
          event.origin === process.env.REACT_APP_CLIENT_DOMAIN &&
          event?.data === 'redirect_to_client'
        ) {
          sysend.broadcast('admin_logout', { message: 'admin_logout' })
          window.location.replace(`${process.env.REACT_APP_CLIENT_DOMAIN}`)
        }
      },
      false,
    )
    sysend.on('admin_logout', async (data) => {
      if (data?.message === 'admin_logout') {
        window.location.replace(process.env.REACT_APP_ALTERNATE_DOMAIN)
      }
    })
    return () => {
      window.removeEventListener('message', () => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      AuthService.setUserData(user)
    }
  }, [user])

  const isAdminOrCoach = user?.isAdmin || user?.isCoach
  const isTwoFactorVerified = user?.isTwoFactorVerified
  const redirectToHomepage = () => {
    history.push('/admin/home')
  }

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <ErrorView redirectToHomepage={redirectToHomepage} i18n={i18n} />
      )}>
      <AuthContext.Provider value={{ user, setUser }}>
        <NotificationContainerProvider>
          <NotificationProvider>
            <Switch>
              <Route path='/verifying-2fa' component={VerifyingTwoFactAuth} />
              <Route exact path='/404' component={NotFound} />
              <Route
                exact
                path='/under-maintenance'
                component={UnderMaintenance}
              />
              <Route exact path='/client-redirect' component={ClientRedirect} />
              <Route exact path='/signout' component={AdminSignOut} />
              <Route
                exact
                path='/'
                render={() => {
                  if (isAdminOrCoach && !isTwoFactorVerified) {
                    window.location.replace(
                      `${process.env.REACT_APP_CLIENT_DOMAIN}/2fa-setup${
                        nextUrl ? `?next=${nextUrl}` : ''
                      }`,
                    )
                  } else if (user?.isAdmin) {
                    return <Redirect to='/admin/home' />
                  } else if (user?.isCoach) {
                    return <Redirect to='/coach/clients' />
                  }
                  return <NotFound />
                }}
              />
              <AuthenticatedRoutes />
            </Switch>
          </NotificationProvider>
        </NotificationContainerProvider>
      </AuthContext.Provider>
    </ErrorBoundary>
  )
}

export default Routes
