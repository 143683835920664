import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { PageHeader, CreatedQuestsTable } from '../components'

const Quests = () => {
  return (
    <>
      <Helmet>
        <title>{`Created-Quest | Abroad`}</title>
        <meta name='title' content={'Created-Quest | Abroad'}></meta>
        <meta property='og:title' content={'Created-Quest | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Created-Quest | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests`}></meta>
      </Helmet>
      <main>
        <section>
          <PageHeader titleText='Quests' />

          <Container fluid className='custom-offset-lg-1 custom-col-lg-12 px-3'>
            <CreatedQuestsTable />
          </Container>
        </section>
      </main>
    </>
  )
}

export default Quests
