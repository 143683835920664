import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { string, object, date } from 'yup'
import {
  useErrorService,
  ABSpinner,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { AddClientTeamSession } from '../components'
import API from '../utils/API'
import labels from '../constants/labels'

const formInitialValues = {
  sessionDate: null,
  sessionDuration: '',
  coach: '',
  memberAttendance: [],
}

export const AddTeamSession = () => {
  const validationSchema = object().shape({
    sessionDuration: string().required(),
    sessionDate: date()
      .required()
      .test('valid-date', 'Please enter valid date!', function (value) {
        const checkDate = moment(value, 'MM/DD/YYYY')
        return checkDate.isValid()
      }),
  })

  const [isLoading, setIsLoading] = useState(true)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [isSessionCreateLoading, setIsSessionCreateLoading] = useState(false)
  const [sessionInsights, setSessionInsights] = useState([])
  const [nonConfidentialsessionInsights, setNonConfidentialSessionInsights] =
    useState([])
  const [teamName, setTeamName] = useState('')
  const [postSession, setPostSession] = useState('')
  const [search, setSearch] = useState('')
  const [searchKeySkill, setSearchKeySkill] = useState('')
  const [searchData, setSearchData] = useState([])
  const [searchKeySkillData, setSearchKeySkillData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [inputFocusOfKeySkill, setInputFocusOfKeySkill] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [isSearchKeySkill, setIsSearchKeySkill] = useState(false)
  const [teamSessionData, setTeamSessionData] = useState([])
  const Error = useErrorService()
  const Notification = useNotificationService()
  const history = useHistory()
  const { teamId } = useParams()
  const { user } = useUserService()
  const [keySkillSearchData, setKeySkillSearchData] = useState([])
  const [groupMembers, setGroupMembers] = useState([])
  const [url, setUrl] = useState(null)

  useEffect(() => {
    const getTeamSessionData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(teamId, {
          fields: [
            'name',
            'groupType',
            'groupId',
            'groupIdModel',
            'b2bSOWId',
            'coaches',
          ],

          populateArray: [
            {
              path: 'coaches',
              select: 'fullName',
            },
            {
              path: 'groupId',
              select: 'name',
            },
          ],
        })
        const isCompanySOWGroup =
          data?.groupType === labels.allGroupType.COMPANY_WITH_SOW
        const isNetworkSOWGroup =
          data?.groupType === labels.allGroupType.NETWORK_WITH_SOW
        if (isCompanySOWGroup || isNetworkSOWGroup) {
          setUrl(
            `/admin/${isCompanySOWGroup ? 'organization' : 'network'}/${
              data?.groupId?.name
            }/sow/${data?.b2bSOWId}/sow-group/${data?._id}/sessions`,
          )
        }
        if (data) {
          if (!data.coaches.map((data) => data._id).includes(user._id)) {
            history.push('/')
          }
          setTeamSessionData(data)
          setTeamName(data?.name)
          setInitialValues((prev) => ({
            ...prev,
            ...(user?.isCoach && user?.isAdmin && { coach: user?.fullName }),
            ...(user?.isCoach && !user.isAdmin && { coach: user?.fullName }),
          }))
          const { data: membersData } =
            await API.AdminAPI.teams.getTeamSpecificData(teamId, {
              fields: ['members'],
              populateArray: [
                {
                  path: 'members',
                  select: 'fullName',
                },
              ],
            })
          if (membersData) {
            const convertedMembers = membersData?.members?.map((member) => ({
              value: member?._id,
              label: member?.fullName,
            }))
            setGroupMembers(convertedMembers)
            setIsLoading(false)
          }
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getTeamSessionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        if (search !== '') {
          const { data } = await API.AdminAPI.common.getSearchMedia(
            search,
            'team',
          )
          setSearchData(data)
          setIsSearch(false)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const getSearchData = () => {
      setIsSearchKeySkill(true)
      const data = keySkillSearchData.filter((data) =>
        data?.title?.toLowerCase()?.includes(searchKeySkill?.toLowerCase()),
      )

      setSearchKeySkillData(data)
      setIsSearchKeySkill(false)
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeySkill, keySkillSearchData])

  useEffect(() => {
    const getKeySkillData = async () => {
      try {
        const { data } = await API.AdminAPI.common.getKeySkillSearchPdf()
        if (data) {
          const keySkillsData = data.map((keySkill) => ({
            _id: keySkill?._id,
            title: keySkill?.keySkill?.title,
            description: keySkill?.keySkill?.description,
          }))
          setKeySkillSearchData(keySkillsData)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getKeySkillData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  const onSubmit = async (values) => {
    if (sessionInsights.length === 0) {
      Notification.showNotification('Please Enter Session Insights!', 'danger')
    } else {
      let findCoach = teamSessionData?.coaches?.map((data) => data)
      let findCoachId = findCoach.find((e) => e.fullName === values.coach)

      let params = {
        date: moment(values.sessionDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        length: values.sessionDuration,
        coachNotes: sessionInsights,
        coachNotesNonConfidential:
          nonConfidentialsessionInsights.length === 0
            ? ''
            : nonConfidentialsessionInsights,
        postSession: postSession,
        coachId: findCoachId?._id,
        attendeesIds: values.memberAttendance,
      }
      if (values?.keySkillsIds?.length > 0) {
        params.keySkillIds = values.keySkillsIds
      }
      if (values.mediaIds.length > 0) {
        params.mediaIds = values.mediaIds
      }
      if (values.moduleIds.length > 0) {
        params.moduleIds = values.moduleIds
      }

      Object.keys(params).forEach(
        (key) => params[key] === '' && delete params[key],
      )

      try {
        setIsSessionCreateLoading(true)
        const { data } = await API.AdminAPI.teams.sessionCreate(teamId, params)
        if (data) {
          setIsSessionCreateLoading(false)
          Notification.showNotification(
            'Group session has been created!',
            'success',
          )
          if (user?.isAdmin && url) {
            history.push(url)
          } else {
            history.push(`/admin/groups/${teamId}/session-report`)
          }
        }
      } catch (e) {
        if (e?.code === 'session_limit_exceeded') {
          setIsSessionCreateLoading(false)
        } else {
          setIsSessionCreateLoading(false)
          Error.showError(e)
        }
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${teamName}'s Session | Abroad`}</title>
        <meta name='title' content={`${teamName}'s Session | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${teamName}'s Session | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${teamName}'s Session | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/groups/${teamId}/session`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups/${teamId}/session`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups/${teamId}/session`}></meta>
      </Helmet>
      <AddClientTeamSession
        validationSchema={validationSchema}
        formInitialValues={formInitialValues}
        initialValues={initialValues}
        onSubmit={onSubmit}
        titleText={`Add Group Session For ${teamName}`}
        sessionInsights={sessionInsights}
        setSessionInsights={setSessionInsights}
        nonConfidentialsessionInsights={nonConfidentialsessionInsights}
        setNonConfidentialSessionInsights={setNonConfidentialSessionInsights}
        postSession={postSession}
        setPostSession={setPostSession}
        search={search}
        setSearch={setSearch}
        inputFocus={inputFocus}
        setInputFocus={setInputFocus}
        searchData={searchData}
        isSearch={isSearch}
        isSessionCreateLoading={isSessionCreateLoading}
        teamSessionData={teamSessionData}
        searchKeySkill={searchKeySkill}
        setSearchKeySkill={setSearchKeySkill}
        searchKeySkillData={searchKeySkillData}
        isSearchKeySkill={isSearchKeySkill}
        inputFocusOfKeySkill={inputFocusOfKeySkill}
        setInputFocusOfKeySkill={setInputFocusOfKeySkill}
        isRenderOnGroupSession={true}
        groupMembers={groupMembers}
      />
    </>
  )
}
