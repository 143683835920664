import React, { useEffect, useState } from 'react'
import {
  useParams,
  useHistory,
  Link,
  useLocation,
  NavLink,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  InfoIcon,
  surveyType,
  useErrorService,
  PrintableArea,
} from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import { WisdomAssessmentResult, useGroupName } from '../components'
import API from '../utils/API'

const TeamQuarterAssessmentResult = () => {
  const { teamId } = useParams()
  let history = useHistory()
  let { search } = useLocation()
  const Error = useErrorService()
  const query = new URLSearchParams(search)
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const year = query.get('year')
  const quarter = query.get('quarter')
  const querySurveyType = query.get('surveyType')
  const teamName = useGroupName(teamId)

  useEffect(() => {
    const getCombinedTeamAssessment = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getCombinedTeamAssessment(
          teamId,
          quarter,
          year,
          querySurveyType,
        )
        setSurvey(
          querySurveyType === surveyType.WISDOM
            ? data?.wisdom
            : data?.awareness,
        )
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (teamId) {
      getCombinedTeamAssessment()
    } else {
      history.replace(`/admin/home`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${
          teamName ? teamName : 'Group'
        } Assessment Result | Abroad`}</title>
        <meta
          name='title'
          content={`${
            teamName ? teamName : 'Group'
          } Assessment Result | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${
            teamName ? teamName : 'Group'
          } Assessment Result | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${
            teamName ? teamName : 'Group'
          } Assessment Result | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
      </Helmet>
      <main>
        {querySurveyType === surveyType.WISDOM ? (
          <WisdomAssessmentResult
            survey={survey}
            isLoading={isLoading}
            headerText='Group Wisdom Assessment'
            subHeaderText='GROUP COMBINED RESULTS'
            subHeaderDescription='Below are the aggregate results for how all participants within Network rated their organizations and themselves during the specified time period'
          />
        ) : (
          <Result
            survey={survey}
            isLoading={isLoading}
            navigateToSurveyCategory={(subcategory) => {
              return (
                <Link to={`/survey/category/${subcategory}`}>
                  <InfoIcon />
                </Link>
              )
            }}
            headerText='Group Awareness Assessment'
            subHeaderText='GROUP COMBINED RESULTS'
            subHeaderDescription='Below are the aggregate results for how all participants within Network rated their organizations and themselves during the specified time period'
            showBreakthroughProgramInfo={false}
            onGoBack={() => history.goBack()}
            showGoBackButton={false}
            API={API}
            NavLink={NavLink}
          />
        )}
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}

export default TeamQuarterAssessmentResult
