import React, { useContext, useState } from 'react'
import { Form as RBForm } from 'react-bootstrap'
import {
  useErrorService,
  Button,
  useNotificationService,
} from '@abroad/components'
import { Formik, Form } from 'formik'
import { number, object } from 'yup'
import { SingleInputField } from '../form'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'
import classNames from 'classnames'

const validationSchema = object().shape({
  actualAmount: number().min(1).required(),
  discountedAmount: number().when('actualAmount', {
    is: (val) => val > 0,
    then: number()
      .min(0)
      .test(function (value) {
        const actualAmount = this.parent.actualAmount
        return value <= actualAmount
      })
      .required(),
    otherwise: number().notRequired(),
  }),
})

const ActivePromo = ({ plan, disabled, wrapperClasses }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const updateNetworkPromoHandler = async (values) => {
    setIsLoading(true)
    try {
      await API.AdminAPI.ecoSystem.updateProgramData(
        companyAndEcoInfo?._id,
        values,
      )
      setCompanyAndEcoInfo((prev) => ({
        ...prev,
        ...values,
      }))
      setIsLoading(false)
      Notification.showNotification(
        'The amount has successfully updated!',
        'success',
      )
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{
        actualAmount: companyAndEcoInfo?.actualAmount
          ? companyAndEcoInfo?.actualAmount
          : '',
        discountedAmount:
          companyAndEcoInfo?.discountedAmount >= 0
            ? companyAndEcoInfo?.discountedAmount
            : '',
      }}
      validationSchema={validationSchema}
      onSubmit={updateNetworkPromoHandler}>
      {({ values, isValid, dirty }) => {
        return (
          <RBForm
            as={Form}
            className={classNames(wrapperClasses, {
              'network-plan-setup': !wrapperClasses,
              'space-x-4': wrapperClasses,
            })}>
            <RBForm.Group
              {...(wrapperClasses && { style: { width: '10rem' } })}>
              <RBForm.Label className='custom-mb-0-625 text-uppercase sidebar-label d-flex align-items-end'>
                Actual Amount
              </RBForm.Label>
              <SingleInputField
                type='number'
                name='actualAmount'
                className={!disabled ? 'pointer-cursor' : ''}
                disabled={disabled || isLoading}
              />
            </RBForm.Group>
            <RBForm.Group
              {...(wrapperClasses && { style: { width: '20rem' } })}>
              <RBForm.Label className='custom-mb-0-625 text-uppercase sidebar-label d-flex align-items-end'>
                Discounted Amount(Exact)
              </RBForm.Label>
              <SingleInputField
                type='number'
                name='discountedAmount'
                className={!disabled ? 'pointer-cursor' : ''}
                disabled={disabled || isLoading}
              />
            </RBForm.Group>
            {!wrapperClasses && (
              <div>
                <Button
                  variant='saffron'
                  size='lg'
                  disabled={
                    disabled ||
                    !dirty ||
                    !isValid ||
                    (values.actualAmount !== '' &&
                      values.discountedAmount === '') ||
                    isLoading
                  }
                  type='submit'
                  className='w-100 border-radius-3 line-height-normal mb-point-75'>
                  Save
                </Button>
              </div>
            )}
          </RBForm>
        )
      }}
    </Formik>
  )
}

export default ActivePromo
