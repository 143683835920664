import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import {
  Button,
  FeatherLoader,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import AddUserModal from './AddUserModal'
import API from '../../utils/API'
import { UTCFormatDate } from '../../utils/date'
const QuestsParticipants = () => {
  const [showModal, setShowModal] = useState(false)
  const [questParticipant, setQuestParticipants] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { questId } = useParams()
  const Error = useErrorService()
  const history = useHistory()
  const Notification = useNotificationService()

  const getParticipants = async () => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.quests.getParticipants(questId)
      setQuestParticipants(data)
      setIsLoading(false)
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }
  const deleteQuestUser = async (userId) => {
    try {
      await API.AdminAPI.quests.deleteQuestUser(questId, userId)
      Notification.showNotification('User has been deleted!', 'success')

      getParticipants()
    } catch (e) {
      Error.showError(e)
    }
  }
  useEffect(() => {
    getParticipants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  const handleAddNewQuestClick = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <Helmet>
        <title>{`Quest-Participants | Abroad`}</title>
        <meta name='title' content={'Quest-Participants | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Quest-Participants | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Quest-Participants | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/participants`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/participants`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/participants`}></meta>
      </Helmet>
      <div>
        <div className='d-flex justify-content-between'>
          <h3 className='mt-5 s3'>Users</h3>
          <div>
            {' '}
            <Button
              variant='gray'
              className='px-4 py-3 abroad-blue mt-3'
              type='button'
              onClick={handleAddNewQuestClick}>
              ADD NEW USER
            </Button>
          </div>
        </div>
        <Table responsive className='custom-table mt-3'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>DATE</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          {isLoading && (
            <tr>
              <td className='text-center border-0 pt-3' colSpan='5'>
                <FeatherLoader />
              </td>
            </tr>
          )}
          {questParticipant.length === 0 && !isLoading && (
            <tr>
              <td className='text-center s6 py-3' colSpan='5'>
                No participants for this quest yet. Please use add new user or
                go to user profile and add user to this quest.{' '}
              </td>
            </tr>
          )}
          <tbody>
            {questParticipant.length > 0 &&
              !isLoading &&
              questParticipant.map((participant, index) => (
                <tr key={index}>
                  <td className='text-saffron-700'>
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        history.push(`/admin/users/${participant?.userId?._id}`)
                      }>
                      {' '}
                      {participant?.userId?.fullName}
                    </span>
                  </td>
                  <td>{participant?.userId?.email}</td>
                  <td>{UTCFormatDate(participant?.createdAt)}</td>
                  <td className='d-flex'>
                    <span
                      className='text-saffron-700 cursor-pointer'
                      onClick={() =>
                        history.push(`/admin/users/${participant?.userId?._id}`)
                      }>
                      View
                    </span>
                    <span
                      className='ml-3 cursor-pointer'
                      style={{ color: '#A71818' }}
                      onClick={() => {
                        deleteQuestUser(participant?.userId?._id)
                      }}>
                      Remove
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <AddUserModal
          showModal={showModal}
          handleClose={handleCloseModal}
          updateParticipants={getParticipants}
        />
      </div>
    </>
  )
}

export default QuestsParticipants
