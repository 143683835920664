import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Form as RBForm, Row, Col, Container } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { ABSpinner, useErrorService } from '@abroad/components'
import API from '../utils/API'
import Labels from '../constants/labels'
import {
  CustomSelectionField,
  PriorityField,
  CurrentStatusField,
} from '../components/clientProfile'
import { SelectionField } from '../components'
import { isLGScreen } from '../utils/utility'
import { InputField } from '../components/form'

const formInitialValues = {
  gender: '',
  ethnicity: [],
  workStatus: '',
  companyName: '',
  staticCompany: '',
  affiliation: '',
  age: '',
  virtualWorkers: '',
  title: '',
}

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const ViewClientProfile = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [clientProfile, setClientProfile] = useState(null)
  const Error = useErrorService()
  let { clientId } = useParams()

  useEffect(() => {
    const fetchClientProfile = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getClientProfile(
          clientId,
        )
        if (data) {
          setClientProfile(data)
          setInitialValues((prev) => ({
            ...prev,
            gender: data.profile?.gender ? data.profile?.gender : '',
            age: data.profile?.age ? data.profile?.age : '',
            country: data.profile?.country ? data.profile?.country : '',
            ethnicity:
              data.profile?.ethnicity?.length > 0
                ? data.profile?.ethnicity?.map((ethnicity) => ({
                    value: ethnicity,
                    label: ethnicity,
                  }))
                : [],
            education: data.profile?.education ? data.profile?.education : '',
            coachingPriority1: data.profile?.coachingPriority1
              ? data.profile?.coachingPriority1
              : '',
            coachingPriority2: data.profile?.coachingPriority2
              ? data.profile?.coachingPriority2
              : '',
            coachingPriority3: data.profile?.coachingPriority3
              ? data.profile?.coachingPriority3
              : '',
            roleInCompany: data.profile?.roleInCompany
              ? data.profile?.roleInCompany
              : '',

            companyType: data.profile?.companyType
              ? data.profile?.companyType
              : '',
            numberOfPeopleInCompany: data.profile?.numberOfPeopleInCompany
              ? data.profile?.numberOfPeopleInCompany
              : '',
            investmentStage: data.profile?.investmentStage
              ? data.profile?.investmentStage
              : '',
            virtualWorkers: Number.isInteger(data.profile?.virtualWorkers)
              ? data.profile?.virtualWorkers
              : '',
            workStatus:
              data?.workStatus && data.workStatus === 'operating'
                ? Labels.userStatus[1]
                : Labels.userStatus[0],
            companyName: data?.company ? data?.company : '',
            staticCompany: data?.staticCompany ? data?.staticCompany : '',
            affiliation:
              data?.affiliation?.length > 0
                ? data.affiliation.join(', ')
                : 'Not Specified',
            title: data?.profile?.title,
          }))
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    fetchClientProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${clientProfile?.fname}'s Profile | Abroad`}</title>
        <meta
          name='title'
          content={`${clientProfile?.fname}'s Profile | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${clientProfile?.fname}'s Profile | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${clientProfile?.fname}'s Profile | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/client/${clientId}/view/client-profile`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/client/${clientId}/view/client-profile`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/client/${clientId}/view/client-profile`}></meta>
      </Helmet>
      <main>
        <section className='profile-form custom-offset-lg-1 custom-col-lg-12 px-0'>
          <Container className='mt-3 px-lg-0 pt-3' fluid>
            <Formik
              displayName='personal-info-form'
              initialValues={initialValues}
              enableReinitialize={true}
              validateOnMount
              onSubmit={() => {}}>
              {({ values, handleSubmit }) => {
                return (
                  <RBForm
                    onSubmit={handleSubmit}
                    as={Form}
                    className='profile px-4 px-lg-0'>
                    <Row noGutters className='mb-3'>
                      <Col className='col-sm-2 d-none d-lg-block' />
                      <Col className='col-lg-offset-2'>
                        <h3>Personal Information</h3>
                      </Col>
                    </Row>
                    <SelectionField
                      label='Gender'
                      labelProps={labelProps}
                      name='gender'
                      options={Labels.genders}
                      optionplaceholder='Select'
                      disabled
                    />
                    <InputField
                      label='Age'
                      labelProps={labelProps}
                      name='age'
                      type='number'
                      size='lg'
                      placeholder='Age'
                      sm='10'
                      disabled
                    />
                    <SelectionField
                      label='Country'
                      labelProps={labelProps}
                      name='country'
                      options={Object.keys(Labels.countries)}
                      optionplaceholder='Select'
                      disabled
                    />
                    <CustomSelectionField
                      name='ethnicity'
                      placeholder='Select'
                      label='THE ETHNICITY/RACE'
                      options={Object.keys(Labels.ethnicity).map((key) => ({
                        value: key,
                        label: key,
                      }))}
                      classNamePrefix='bg-gray-container ethnicity'
                      disabled
                    />
                    <SelectionField
                      label='EDUCATION'
                      labelProps={labelProps}
                      name='education'
                      options={Labels.education}
                      optionplaceholder='Select'
                      disabled
                    />
                    <PriorityField
                      label='FIRST PRIORITY'
                      labelProps={labelProps}
                      name='coachingPriority1'
                      disabledOptions={[
                        values?.coachingPriority2,
                        values?.coachingPriority3,
                      ]}
                      disabled
                    />
                    <PriorityField
                      label='SECOND PRIORITY'
                      labelProps={labelProps}
                      name='coachingPriority2'
                      disabledOptions={[
                        values?.coachingPriority1,
                        values?.coachingPriority3,
                      ]}
                      disabled
                    />
                    <PriorityField
                      label='THIRD PRIORITY'
                      labelProps={labelProps}
                      name='coachingPriority3'
                      disabledOptions={[
                        values?.coachingPriority1,
                        values?.coachingPriority2,
                      ]}
                      disabled
                    />
                    <Row noGutters className='py-3'>
                      <Col className='col-sm-2 d-none d-lg-block' />
                      <Col>
                        <h3>Organization Information</h3>
                      </Col>
                    </Row>
                    <CurrentStatusField labelProps={labelProps} disabled />
                    <InputField
                      label='User Entered Company'
                      labelProps={labelProps}
                      name='staticCompany'
                      type='text'
                      placeholder=''
                      size='lg'
                      sm='10'
                      disabled
                    />
                    <InputField
                      label='ORGANIZATION'
                      labelProps={labelProps}
                      name='companyName'
                      type='text'
                      placeholder='Organization'
                      size='lg'
                      sm='10'
                      disabled
                    />
                    <InputField
                      label='NETWORK'
                      labelProps={labelProps}
                      name='affiliation'
                      type='text'
                      placeholder='Network'
                      size='lg'
                      sm='10'
                      disabled
                    />
                    <SelectionField
                      label='ROLE AT ORGANIZATION'
                      optionplaceholder='Select'
                      labelProps={labelProps}
                      name='roleInCompany'
                      customKeyprop='value'
                      customValueprop='value'
                      customLabelProp='label'
                      getCustomKeyValuePropsAsOption={true}
                      options={Labels.roleListForEnterpriceUser}
                      disabled
                    />
                    <InputField
                      label='Title'
                      labelProps={labelProps}
                      name='title'
                      placeholder='Title'
                      type='text'
                      size='lg'
                      sm='10'
                      disabled
                    />
                    <SelectionField
                      label='ORGANIZATION TYPE'
                      labelProps={labelProps}
                      name='companyType'
                      options={Labels.companyType}
                      optionplaceholder='Select'
                      disabled
                    />
                    <SelectionField
                      label='NO. OF PEOPLE'
                      labelProps={labelProps}
                      name='numberOfPeopleInCompany'
                      options={Labels.companyPeople}
                      optionplaceholder='Select'
                      disabled
                    />
                    <SelectionField
                      label='INVESTMENT STAGE'
                      labelProps={labelProps}
                      name='investmentStage'
                      options={Labels.investmentStage}
                      optionplaceholder='Select'
                      disabled
                    />
                    <InputField
                      label='% OF VIRTUAL WORKERS'
                      labelProps={labelProps}
                      name='virtualWorkers'
                      type='number'
                      min='0'
                      max='100'
                      placeholder='Virtual Workers'
                      size='lg'
                      sm='10'
                      disabled
                    />
                  </RBForm>
                )
              }}
            </Formik>
          </Container>
        </section>
      </main>
    </>
  )
}

export default ViewClientProfile
