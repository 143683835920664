import { useEffect } from 'react'
import {
  paymentStatus as paymentStatusConst,
  paymentType as paymentTypeConst,
} from '@abroad/components'
import { useFormikContext } from 'formik'

export const ClearMonthAndReportValue = ({ coaches = [] }) => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (!values.isPlanIndefinite) {
      setFieldValue('isPlanIndefinite', false)
    }
    if (values.isPlanIndefinite) {
      setFieldValue('numberOfMonths', '')
    }
    if (!values.report360) {
      setFieldValue('reportPlan360', '')
      setFieldValue('report360hourlyRate', '')
    }
    if (!values.impactReport) {
      setFieldValue('impactReportPlan', '')
      setFieldValue('impactReporthourlyRate', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isPlanIndefinite, values.report360, values.impactReport])

  useEffect(() => {
    if (values.paymentStatus === paymentStatusConst.NO_BILLING_REQUIRED) {
      setFieldValue('paymentCycle', paymentTypeConst.RECURRING)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.paymentStatus])

  // useEffect(() => {
  //   const selectedCoach = coaches?.filter(
  //     (coach) => coach?.value === values?.sowCoachId,
  //   )
  //   if (selectedCoach && selectedCoach[0]?.isStaffCoach) {
  //     setFieldValue('report360hourlyRate', '')
  //     setFieldValue('impactReporthourlyRate', '')
  //     setFieldValue('hourlyRate', '')
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.sowCoachId])

  return null
}
