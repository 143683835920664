import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'
import { plans, useErrorService } from '@abroad/components'
import TargetInfoModal from './TargetInfoModal'
import ActivePromo from './ActivePromo'
import { OrgEcoIdsContext } from '../../utils/contexts'
import labels from '../../constants/labels'
import API from '../../utils/API'

const TargetPlanDropdown = ({
  wrapperClasses = '',
  formGroupClasses = '',
  selectionClasses = '',
  disabled = false,
  setIsAnyModalEventOccurs = () => {},
}) => {
  const [isPlanChangeLoading, setIsPlanChangeLoading] = useState(false)
  const { companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)
  const [plan, setPlan] = useState(
    companyAndEcoInfo?.targetPlan || plans.free.code,
  )
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()

  useEffect(() => {
    if (showModal) {
      setIsAnyModalEventOccurs(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const updateTargetPlanHandler = async (newTargetPlan) => {
    setIsPlanChangeLoading(true)
    try {
      const { status } = await API.AdminAPI.ecoSystem.updateProgramData(
        companyAndEcoInfo?._id,
        { targetPlan: newTargetPlan },
      )
      if (status) {
        setCompanyAndEcoInfo((prev) => ({
          ...prev,
          targetPlan: newTargetPlan,
          ...(newTargetPlan === plans.free.code && { networkPromo: null }),
        }))
        setPlan(newTargetPlan)
        setIsPlanChangeLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsPlanChangeLoading(false)
    }
  }

  // const renderInfoIcon = () => {
  //   return (
  //     <InformationIcon
  //       onClick={() => setShowModal(true)}
  //       iconClasses='align-self-center ml-2'
  //     />
  //   )
  // }

  return (
    <>
      <TargetInfoModal
        showModal={showModal}
        onHide={() => {
          setIsAnyModalEventOccurs(false)
          setShowModal(false)
        }}
      />
      <div className={wrapperClasses}>
        <Form.Group className={formGroupClasses}>
          <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label d-flex align-items-end'>
            Target Plan
          </Form.Label>
          <Form.Control
            as='select'
            name='targetPlan'
            className={classNames(
              'text-uppercase',
              {
                'pointer-cursor': !isPlanChangeLoading && !disabled,
              },
              selectionClasses,
            )}
            size='md'
            value={plan}
            onChange={(e) => {
              updateTargetPlanHandler(e.target.value)
            }}
            disabled={isPlanChangeLoading || disabled}>
            {labels.plans.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </Form.Control>
          {isPlanChangeLoading && <span className='s7 mt-2'>Updating...</span>}
        </Form.Group>
        <ActivePromo
          plan={plan}
          disabled={isPlanChangeLoading || plan === plans.free.code || disabled}
          wrapperClasses={wrapperClasses}
        />
      </div>
    </>
  )
}

export default TargetPlanDropdown
