import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Container, Spinner } from 'react-bootstrap'
import { Switch, NavLink, useLocation, useParams } from 'react-router-dom'
import {
  NavTabs,
  useErrorService,
  coachRecommends,
  coachRecommendsUrl,
} from '@abroad/components'
import { CompanyAssessments } from './CompanyAssessments'
import CompanyMetadata from './CompanyMetadata'
import OrgNetworkSOW from './OrgNetworkSOW'
import SOWDetails from './SOWDetails'
import SOWUsers from './SOWUsers'
import SOWGroups from './SOWGroups'
import SOWGroupDetails from './SOWGroupDetails'
import { CompanyListing } from './CompanyListing'
import {
  TeamProvider,
  RightSideBar,
  OrgEcoRightSection,
  OrgEcoPageHeader,
  SOWGroupSessions,
  SOWGroupCoaches,
  OrgEcoUsers,
  ListingFiltersProvider,
} from '../components'
import InviteOrgEcoLink from './InviteOrgEcoLink'
import GroupJourney from './GroupJourney'
import PrivateRoute from '../routes/PrivateRoute'
import API from '../utils/API'
import labels from '../constants/labels'
import { OrgEcoIdsContext } from '../utils/contexts'
import OrgCoach from '../components/OrgEco/OrgCoach'
import OrgGroups from '../components/OrgEco/OrgGroups'

const initialState = {
  hideBtn: true,
  showTable: true,
}

export const Company = () => {
  const location = useLocation()
  const { companyName, sowId, sowGroupId } = useParams()
  const rightSidebarRef = useRef(null)
  const [companyAndEcoInfo, setCompanyAndEcoInfo] = useState()
  const [showRightSidebar, setShowRightSidebar] = useState(false)
  const [sowName, setSOWName] = useState(null)
  const [ids, setIds] = useState([])
  const [isShowAddSOWBtn, setIsShowAddSOWBtn] = useState(false)
  const [isShowAddBtn, setIsShowAddBtn] = useState(initialState)
  const [selectedSOWUsers, setSelectedSOWUsers] = useState([])
  const [isShowAddSOWGroup, setIsShowAddSOWGroup] = useState(false)
  const [isLoadNewData, setIsLoadNewData] = useState(false)
  const [groupName, setGroupName] = useState(null)
  const [selectedGroupisArchived, setSelectedGroupisArchived] = useState(false)
  const [isCoachesEmpty, setIsCoachesEmpty] = useState(true)
  const [isCsvUploaded, setIsCsvUploaded] = useState({
    showRosterUI: true,
    showBtn: false,
  })
  const Error = useErrorService()

  const orgPages = [
    // {
    //   path: `/admin/organization/${companyName}/sows`,
    //   name: 'SOWs',
    //   classNames: 'text-transform-none',
    // },
    {
      path: `/admin/organization/${companyName}/users`,
      name: 'USERS',
    },
    {
      path: `/admin/organization/${companyName}/survey`,
      name: 'ASSESSMENTS',
    },
    {
      path: `/admin/organization/${companyName}/coach`,
      name: 'COACH',
    },
    {
      path: `/admin/organization/${companyName}/groups`,
      name: 'GROUPS',
    },
  ]

  const sowDetailsPages = [
    {
      path: `/admin/organization/${companyName}/sow/${sowId}/details`,
      name: 'SOW Details',
    },
    {
      path: `/admin/organization/${companyName}/sow/${sowId}/users`,
      name: 'SOW Users',
    },
    {
      path: `/admin/organization/${companyName}/sow/${sowId}/sow-groups`,
      name: 'SOW Groups',
    },
  ]

  const sowGroupDetailsPages = [
    {
      path: `/admin/organization/${companyName}/sow/${sowId}/sow-group/${sowGroupId}/users`,
      name: 'Users',
      defaultUrl: `/admin/organization/${companyName}/sow/${sowId}/sow-group/${sowGroupId}`,
    },
    {
      path: `/admin/organization/${companyName}/sow/${sowId}/sow-group/${sowGroupId}/sessions`,
      name: 'Sessions',
    },
    {
      path: `/admin/organization/${companyName}/sow/${sowId}/sow-group/${sowGroupId}/coaches`,
      name: 'Coaches',
    },
    {
      path: `/admin/organization/${companyName}/sow/${sowId}/sow-group/${sowGroupId}/${coachRecommendsUrl}`,
      name: coachRecommends,
    },
  ]

  useEffect(() => {
    setIds([])
    setIsShowAddBtn(initialState)
    setSelectedSOWUsers([])
  }, [location.pathname])

  useEffect(() => {
    const getSOWName = async () => {
      setSOWName(null)
      try {
        const { data } = await API.AdminAPI.companyList.getSpecificDetailsOfSOW(
          { fields: ['planName'] },
          sowId,
        )
        if (data) {
          setSOWName(data?.planName)
          setIsCoachesEmpty(data?.isCoachesEmpty)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (sowId) getSOWName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowId])

  useEffect(() => {
    const getSOWGroupName = async () => {
      setGroupName(null)
      setSelectedGroupisArchived(false)
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(
          sowGroupId,
          {
            fields: ['name', 'isArchived'],
          },
        )
        if (data) {
          setGroupName(data?.name)
          setSelectedGroupisArchived(data?.isArchived)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (sowGroupId) getSOWGroupName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowGroupId])

  useEffect(() => {
    const getCompanyCode = async () => {
      setCompanyAndEcoInfo(null)
      try {
        const { data } = await API.AdminAPI.companyList.getCompanyByName(
          companyName,
        )
        setCompanyAndEcoInfo(data)
      } catch (e) {
        Error.showError(e)
      }
    }
    if (companyName) getCompanyCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName])

  useEffect(() => {
    if (!sowGroupId) {
      setSelectedGroupisArchived(false)
    }
  }, [sowGroupId])

  return (
    <main>
      <ListingFiltersProvider>
        <TeamProvider>
          <OrgEcoIdsContext.Provider
            value={{
              ids,
              setIds,
              isLoadNewData,
              setIsLoadNewData,
              selectedSOWUsers,
              setSelectedSOWUsers,
              selectedGroupisArchived,
              isShowAddBtn,
              setIsShowAddBtn,
              isCsvUploaded,
              setIsCsvUploaded,
              setCompanyAndEcoInfo,
              companyAndEcoInfo,
            }}>
            {companyName && (
              <React.Fragment>
                <OrgEcoPageHeader
                  setShowRightSidebar={setShowRightSidebar}
                  sowName={sowName}
                  groupName={groupName}
                />
                <RightSideBar show={showRightSidebar} ref={rightSidebarRef}>
                  <OrgEcoRightSection
                    closeClickedHandler={() => setShowRightSidebar(false)}
                    rightSidebarRef={rightSidebarRef}
                    isShowAddSOWBtn={isShowAddSOWBtn}
                    isShowAddSOWGroup={isShowAddSOWGroup}
                  />
                </RightSideBar>
              </React.Fragment>
            )}
            {companyName ? (
              <React.Fragment>
                {companyAndEcoInfo ? (
                  <section className='custom-offset-lg-1 custom-col-lg-12 px-0'>
                    <Switch location={location}>
                      <PrivateRoute
                        exact
                        path={`/admin/organization/:companyName/users`}>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <OrgEcoUsers
                          orgEcoId={companyAndEcoInfo?._id}
                          type='company'
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={`/admin/organization/:companyName/survey`}>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <CompanyAssessments />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={`/admin/organization/:companyName/coach`}>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <OrgCoach orgEcoId={companyAndEcoInfo?._id} />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={`/admin/organization/:companyName/groups`}>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <OrgGroups orgEcoId={companyAndEcoInfo?._id} />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={`/admin/organization/:companyName/sows`}>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <OrgNetworkSOW
                          type='Organization'
                          name={companyName}
                          orgEcoId={companyAndEcoInfo?._id}
                          setIsShowAddSOWBtn={setIsShowAddSOWBtn}
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={`/admin/organization/:companyName/sow/:sowId/details`}>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <SOWDetails
                          type='Organization'
                          setIsCoachesEmpty={setIsCoachesEmpty}
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path='/admin/organization/:companyName/sow/:sowId/users'>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <SOWUsers
                          type='organization'
                          orgEcoId={companyAndEcoInfo?._id}
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path='/admin/organization/:companyName/sow/:sowId/sow-groups'>
                        <GetTabs
                          pages={sowId ? sowDetailsPages : orgPages}
                          location={location}
                        />
                        <SOWGroups
                          orgEcoId={companyAndEcoInfo?._id}
                          orgEcoType={labels?.allGroupType?.COMPANY_WITH_SOW}
                          sowName={sowName}
                          setIsShowAddSOWGroup={setIsShowAddSOWGroup}
                          setIsCoachesEmpty={setIsCoachesEmpty}
                          isCoachesEmpty={isCoachesEmpty}
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={[
                          '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId',
                          '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/users',
                        ]}>
                        <GetTabs
                          pages={sowGroupDetailsPages}
                          location={location}
                        />
                        <SOWGroupDetails
                          orgEcoId={companyAndEcoInfo?._id}
                          groupName={groupName}
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path='/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/sessions'>
                        <GetTabs
                          pages={sowGroupDetailsPages}
                          location={location}
                        />
                        <SOWGroupSessions groupName={groupName} />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path='/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/coaches'>
                        <GetTabs
                          pages={sowGroupDetailsPages}
                          location={location}
                        />
                        <SOWGroupCoaches groupName={groupName} />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={`/admin/organization/:companyName/sow/:sowId/sow-group/:teamId/${coachRecommendsUrl}`}>
                        <GetTabs
                          pages={sowGroupDetailsPages}
                          location={location}
                        />
                        <GroupJourney isShowHeader={false} />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path='/admin/organization/:companyName/invite-link'>
                        <InviteOrgEcoLink
                          companyAndEcoInfo={companyAndEcoInfo}
                        />
                      </PrivateRoute>
                      <PrivateRoute path={`/admin/organization/:companyName`}>
                        <CompanyMetadata
                          companyAndEcoInfo={companyAndEcoInfo}
                        />
                      </PrivateRoute>
                    </Switch>
                  </section>
                ) : (
                  <div className='text-center mt-4'>
                    <Spinner animation='border' className='text-saffron-700' />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <PrivateRoute exact path='/admin/organizations'>
                <CompanyListing />
              </PrivateRoute>
            )}
          </OrgEcoIdsContext.Provider>
        </TeamProvider>
      </ListingFiltersProvider>
    </main>
  )
}

const GetTabs = ({ pages, location, wrapperClasses = '' }) => {
  return (
    <section>
      <Container
        className={classNames('org-tabs-wrapper px-0', wrapperClasses)}
        fluid>
        <NavTabs
          pages={pages}
          pathname={location?.pathname}
          hash={location?.hash}
          NavLink={NavLink}
        />
      </Container>
    </section>
  )
}
