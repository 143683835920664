import React, { useState, useEffect, useCallback, useRef } from 'react'
import SOWPaymentFilter from './SOWPaymentFilter'
import { TableInstance, Table } from '../table'
import API from '../../utils/API'
import { formatDate } from '../../utils/date'
import { useErrorService } from '@abroad/components'

const UserSubscription = () => {
  const initialState = {
    coachingStatus: '',
    paymentStatus: '',
  }

  const hasMore = useRef(false)
  const [paymentData, setPaymentData] = useState([])
  const [reqData, setReqData] = useState(null)
  const [loading, setLoading] = useState(true)
  const Error = useErrorService()

  const getPaymentInfo = useCallback(async () => {
    if (reqData.page === 1) {
      setPaymentData([])
    }
    setLoading(true)

    try {
      const { data } = await API.AdminAPI.payment.getSowPayments(reqData)
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setPaymentData((prev) => [...prev, ...data])
      } else {
        setPaymentData(data)
      }
      setLoading(false)
    } catch (e) {
      Error.showError(e)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getPaymentInfo(reqData)
    }
  }, [getPaymentInfo, reqData])

  const fetchData = useCallback(
    ({ coachingStatus, paymentStatus, page, limit }) => {
      const req = {
        limit,
        page,
        filter: {
          ...(coachingStatus && { coachingStatus }),
          ...(paymentStatus && { paymentStatus }),
        },
      }
      setReqData(req)
    },
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        id: 'clientName',
        Header: 'Client Name',
        accessor: (properties) => properties?.clientId?.fullName,
        width: '20%',
        disableSortBy: true,
      },
      {
        id: 'planName',
        Header: 'Plan Name',
        accessor: 'planName',
        width: '30%',
        disableSortBy: true,
      },

      {
        id: 'amount',
        Header: 'Payment Amount',
        accessor: 'amount',
        width: '10%',
        Cell: ({ row }) => (
          <span
            style={{
              color:
                row.original.paymentStatus === 'FAILED' ? 'red' : 'inherit',
            }}>
            ${row.original.amount}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'createAt',
        Header: 'Payment Date',
        accessor: 'createAt',
        width: '10%',
        Cell: ({ value }) => <span>{formatDate(value)}</span>,
        disableSortBy: true,
      },
      {
        id: 'stripeLink',
        Header: 'Stripe Link',
        accessor: (properties) => ({
          stripeId: properties?.stripeCharges?.id,
          stripeMessage: properties?.stripeCharges?.message,
          paid: properties?.paid,
          amount: properties?.amount,
        }),
        width: '15%',
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.stripeId ? (
              <a
                href={`https://dashboard.stripe.com/payments/${cell?.value?.stripeId}`}
                rel='noopener noreferrer'
                className='text-saffron-700 text-center'
                target='_blank'>
                Payment Details
              </a>
            ) : cell?.value?.paid === false &&
              cell?.value?.amount > 0 &&
              cell?.value?.stripeMessage ? (
              <p>{cell?.value?.stripeMessage}</p>
            ) : (
              <p className='ml-4'>-</p>
            )}
          </React.Fragment>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <>
      <TableInstance
        data={paymentData}
        columns={columns}
        initialState={{
          globalFilter: initialState,
        }}>
        {({
          preGlobalFilteredRows,
          state: { globalFilter, ...other },
          setGlobalFilter,
          ...rest
        }) => (
          <div>
            <div className='mt-3'>
              <SOWPaymentFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                handleClearFilter={() => setGlobalFilter(initialState)}
                isLoading={loading}
              />
            </div>

            <Table
              ref={hasMore}
              emptyTableMessage={`No user payment found.`}
              tableInstance={{
                preGlobalFilteredRows,
                state: { globalFilter, ...other },
                setGlobalFilter,
                ...rest,
              }}
              isLoading={loading}
              fetchData={fetchData}
              totalNumberOfColumns='5'
            />
          </div>
        )}
      </TableInstance>
    </>
  )
}

export default UserSubscription
