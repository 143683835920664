import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form, Modal } from 'react-bootstrap'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import { SelectionField, useInput } from '../common'
import API from '../../utils/API'

const isValidCoachRate = (value) =>
  value !== '' && value <= 1000 && value >= 0 && Number.isInteger(Number(value))
const defaultValue = '0'

const AssignCoachToSOWModal = ({
  sowInformation,
  showModal,
  setShowModal,
  onSubmit,
}) => {
  const {
    value: coachRate,
    hasError: coachRateHasError,
    valueChangeHandler: coachRateChangeHandler,
    inputBlurHandler: coachRateBlurHandler,
    reset: resetCoachRate,
  } = useInput(isValidCoachRate, defaultValue)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [coaches, setCoaches] = useState([])
  const [coachId, setCoachId] = useState('')
  const [coachHasError, setCoachHasError] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()

  useEffect(() => {
    if (!showModal) {
      setCoachId('')
      setCoachHasError(false)
    }
  }, [showModal])

  const onHide = () => {
    if (isSubmitting) return
    setShowModal(false)
    resetCoachRate(defaultValue)
    setCoachId('')
    setCoachHasError(false)
  }

  useEffect(() => {
    setIsLoading(true)
    const getCoachData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getCoachData()
        if (data) {
          setCoaches(
            data?.map((coach) => ({
              label: `${coach?.fullName} ${
                coach?.isStaffCoach ? '(Staff Coach)' : ''
              }`,
              value: coach?._id,
              isStaffCoach: coach?.isStaffCoach || false,
              fullName: coach?.fullName,
              _id: coach?._id,
            })),
          )
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (showModal) getCoachData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const addGrowthPlanHandler = async () => {
    if (!coachId) setCoachHasError(true)
    if (coachRateHasError || !coachId) return
    setIsSubmitting(true)
    try {
      const { data } = await API.AdminAPI.coachPlan.assignCoachToSOW({
        coachId: coachId,
        userId: sowInformation?.userId,
        ...(!sowInformation?.isGrowth && { sowId: sowInformation?.sowId }),
        ...(sowInformation?.isGrowth && { hourlyRate: parseInt(coachRate) }),
      })
      if (data) {
        onSubmit()
        setIsSubmitting(false)
        setShowModal(false)
        resetCoachRate(defaultValue)
        Notification.showNotification(data?.message, 'success')
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  if (!showModal) return null

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      className='custom-modal normal-padding-modal'
      centered>
      <Modal.Header>
        <h3 className='s2 text-center mb-0'>{`Assign coach${
          sowInformation?.fullName ? ` to ${sowInformation?.fullName}` : ''
        } ${
          sowInformation?.planName ? `for ${sowInformation?.planName}` : ''
        }`}</h3>
      </Modal.Header>
      <Modal.Body className='profile'>
        <Form.Group>
          <Form.Label>COACH</Form.Label>
          <SelectionField
            name='coachId'
            size='lg'
            options={coaches?.map((coach) => ({
              label: `${coach?.fullName} ${
                coach?.isStaffCoach ? '(Staff Coach)' : ''
              }`,
              value: coach?._id,
            }))}
            getValueAsOption={true}
            optionplaceholder={
              isLoading ? 'Fetching Coaches...' : 'Select Coach'
            }
            sm='10'
            className={classNames('mb-3', {
              'bg-white cursor-pointer': !(isSubmitting || isLoading),
              'disabled-input': isSubmitting || isLoading,
              'is-invalid': coachHasError,
            })}
            isUseFormikSelectionField={false}
            value={coachId}
            onChange={(e) => {
              setCoachHasError(false)
              setCoachId(e.target.value)
            }}
            disabled={isSubmitting}
          />
        </Form.Group>
        {sowInformation?.isGrowth && (
          <Form.Group>
            <Form.Label>HOURLY RATE PAID TO COACH (USD)</Form.Label>
            <Form.Control
              name='coachRate'
              type='number'
              size='lg'
              placeholder='Hourly Rate'
              value={coachRate}
              onChange={coachRateChangeHandler}
              onBlur={coachRateBlurHandler}
              className='s6 border-radius-3'
              isInvalid={coachRateHasError}
              disabled={isSubmitting}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='text-right'>
          <Button
            variant='saffron'
            className='line-height-normal'
            disabled={isSubmitting || isLoading}
            isLoading={isSubmitting}
            size='md'
            onClick={addGrowthPlanHandler}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AssignCoachToSOWModal
