import React, { useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router'
import { useErrorService, useNotificationService } from '@abroad/components'
import SearchInput from '../common/SearchInput'
import { emailRegex } from '../../utils/utility'
import API from '../../utils/API'

const SearchUserInput = ({ setIsLoading, currentCoachName }) => {
  const [search, setSearch] = useState('')
  const [clientInfo, setClientInfo] = useState([])
  const [isUserAssign, setIsUserAssign] = useState(false)
  const Error = useErrorService()
  let { coachId } = useParams()
  const Notification = useNotificationService()
  let history = useHistory()

  // only to assign individual coach
  const handleAssignUser = async () => {
    if (emailRegex.test(search.toLowerCase())) {
      const userInfo = clientInfo.find((e) => e.email === search)
      const coachIds = userInfo?.coachIds
        ?.filter((coach) => !!coach.isIndiCoach)
        .map((coach) => coach?._id)
      if (coachIds?.includes(coachId)) {
        window.alert(`Client is already assigned to ${currentCoachName} coach.`)
      } else {
        await handelForceAssignUser(userInfo?._id, false)
      }
    }
  }

  const handelForceAssignUser = async (id, forceAssign = false) => {
    setIsUserAssign(true)
    try {
      const { data } = await API.AdminAPI.coachingList.assignClient(
        id,
        coachId,
        {
          forceAssign: forceAssign,
        },
      )
      setIsUserAssign(false)
      if (data) {
        Notification.showNotification(
          'User has been assigned to coach!',
          'success',
        )
        setSearch('')
        setIsLoading(true)
      }
    } catch (e) {
      setIsUserAssign(false)
      Error.showError(e)
    }
  }

  return (
    <Container className='my-3 profile p-0' fluid>
      <Button
        variant='gray'
        className='black-color-placeholder text-uppercase float-right border-radius-3'
        size='md'
        onClick={(e) => {
          e.preventDefault()
          history.push({
            pathname: `/admin/coach/${coachId}/profile`,
          })
        }}>
        COACH PROFILE
      </Button>
      <h3>Assign Clients</h3>
      <p>SEARCH USER</p>
      <SearchInput
        search={search}
        render={(data) => setSearch(data)}
        setClientInfo={setClientInfo}
        handleSubmit={handleAssignUser}
        buttonText='ASSIGN USER'
        placeholder='search user'
        inputColumnClasses='col-12'
        wrapperClasses='d-flex flex-column'
        parentComponentLoading={isUserAssign}
        isShowLoader={true}
      />
    </Container>
  )
}

export default SearchUserInput
