import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react'
import { Link } from 'react-router-dom'
import API from '../utils/API'
import { useErrorService } from '@abroad/components'
import { OrgEcoListingTable, useQuery } from '../components/common'
import { ListingFiltersContext } from '../utils/contexts'

export const CompanyListing = () => {
  const hasMore = useRef(false)
  const [isLoading, setIsLoading] = useState(true)
  const [companyData, setCompanyData] = useState([])
  const [reqData, setReqData] = useState(null)
  const Error = useErrorService()
  const query = useQuery()
  const search = query.get('search')
  const { setFilters } = useContext(ListingFiltersContext)

  const getCompaniesData = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.companyList.getCompanyList(reqData)
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setCompanyData((prev) => [...prev, ...data])
      } else {
        setCompanyData(data)
      }
      setFilters((prev) => ({
        ...prev,
        sort: reqData?.filter?.sort,
      }))
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getCompaniesData(reqData)
    }
  }, [getCompaniesData, reqData])

  // const updateOrganizationList = (sourceCompany) => {
  //   setCompanyData((prev) =>
  //     prev.filter((company) => company.company !== sourceCompany),
  //   )
  // }

  /**
   * Column name for bind members data
   */
  const columns = React.useMemo(
    () => [
      {
        id: 'Organization',
        Header: 'Organization',
        width: '50%',
        accessor: (properties) => properties.company,
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            <Link
              to={`organization/${cell.value}/users`}
              className='text-dark-900'>
              {cell.value}
            </Link>
          </span>
        ),
      },
      {
        id: 'Unique User Assessments',
        Header: 'Unique User Assessments',
        width: '50%',
        disableSortBy: true,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        accessor: (properties) => properties.surveyCount,
      },
    ],
    [],
  )

  const fetchData = useCallback(
    ({ search, type, status, sortBy, page, limit }) => {
      if (page === 1) {
        setCompanyData([])
      }
      const req = {
        limit,
        page: page,
        filter: {
          ...(search && {
            search: search,
          }),
          type: type,
          status: status,
          sort: !sortBy[0].desc ? 'ASC' : 'DESC',
        },
      }
      setReqData(req)
    },
    [],
  )

  return (
    <OrgEcoListingTable
      columns={columns}
      data={companyData}
      label='Organizations'
      sortById='Organization'
      type='organization'
      search={search}
      fetchData={fetchData}
      isLoading={isLoading}
      hasMore={hasMore}
    />
  )
}
