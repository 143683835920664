import React, { useState } from 'react'

const Select = ({ label, name, value, options, handleBlur }) => {
  const [inputText, setInputText] = useState(value)
  const handleSelectChange = (e) => {
    const newValue = e.target.value
    setInputText(newValue)
  }
  const handleBlurChange = (e) =>{
    const { name, value } = e.target
    handleBlur(name,value)
  }
  return (
      <select
        name={name}
        value={inputText}
        onBlur={handleBlurChange}
        onChange={handleSelectChange}
        className="w-100 sa2 selection-field"
      >
        <option value="" disabled>
          {`Select ${label}`}
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
  )
}

export default Select