import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ABSpinner, useErrorService } from '@abroad/components'
import UploadCSV from './UploadCSV'
import AssignCoachSOWButton from './AssignCoachSOWButton'
import { EditableUsersList } from '../company'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const OrgEcoUsers = ({ orgEcoId, type }) => {
  const { isCsvUploaded, setCompanyAndEcoInfo, setIsCsvUploaded } =
    useContext(OrgEcoIdsContext)
  const { companyName, ecoSystemName } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()

  const getAPIFunction = () => {
    if (companyName) {
      return API.AdminAPI.companyList.getCompanyByName(companyName)
    }
    return API.AdminAPI.ecoSystem.getEcoSystemByName(ecoSystemName)
  }

  useEffect(() => {
    const getCompanyEcoCode = async () => {
      setIsLoading(true)
      try {
        const { data } = await getAPIFunction()
        setCompanyAndEcoInfo(data)
        if (companyName) {
          setIsCsvUploaded({
            showRosterUI: !data?.isCsvUploaded && data?.usersCount === 0,
            showBtn: data?.usersCount > 0, // companyAndEcoInfo?.isCsvUploaded && companyAndEcoInfo?.usersCount > 0
          })
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (companyName) {
      getCompanyEcoCode()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <main>
      <section>
        {isCsvUploaded?.showRosterUI && !ecoSystemName ? (
          <UploadCSV orgEcoId={orgEcoId} />
        ) : (
          <EditableUsersList
            orgEcoId={orgEcoId}
            type={type}
            isShowAssessmentsColumn={true}
            renderRightSideButtons={(e) => {
              return <AssignCoachSOWButton {...e} type={type} />
            }}
            isShowCoachesAndSOWFilter={true}
          />
        )}
      </section>
    </main>
  )
}

export default OrgEcoUsers
