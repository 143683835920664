import React, { useState, useRef, useEffect } from 'react'
import { NavTabs } from '@abroad/components'
import {
  Route,
  Switch,
  useLocation,
  NavLink,
  useParams,
} from 'react-router-dom'
import Custom360ResultQuestion from '../components/custom360/Custom360ResultQuestion'
import Custom360ResultRecipient from '../components/custom360/Custom360ResultRecipient'
import {
  ProfileHeader,
  ProfileSidebarSection,
  RightSideBar,
} from '../components'
import API from '../utils/API'
const Custom360Resuls = () => {
  const location = useLocation()
  const rightSidebarRef = useRef(null)
  const { clientId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [showRightSidebar, setShowRightSidebar] = useState(false)
  const [clientProfile, setClientProfile] = useState(null)
  const [isShowActiveSessions, setIsShowActiveSessions] = useState(true)
  const [getQuesWithAns, setQuesWithAns] = useState([])
  const isUserComeFromMyClients = location?.pathname
    .split('/')
    .includes('coach')
  const pages = [
    {
      path: isUserComeFromMyClients
        ? `/coach/custom360/clients/${clientId}/results/recipient`
        : `/admin/custom360/users/${clientId}/results/recipient`,
      name: 'RESPONSES BY RECIPIENT',
    },
    {
      path: isUserComeFromMyClients
        ? `/coach/custom360/clients/${clientId}/results/question`
        : `/admin/custom360/users/${clientId}/results/question`,
      name: 'RESPONSES BY QUESTION',
    },
  ]
  useEffect(() => {
    const quesWithAnswer = async () => {
      try {
        const { data } = await API.AdminAPI.survey.getQuesWithAns(clientId)
        setQuesWithAns(data)
        setIsLoading(false)
      } catch {
        setIsLoading(false)
      }
    }
    quesWithAnswer()
  }, [clientId])

  useEffect(() => {
    const fetchClientProfile = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getClientProfile(
          clientId,
        )

        if (data) {
          setClientProfile(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (clientId) fetchClientProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  return (
    <section className='page-header'>
      <div>
        <ProfileHeader
          name='Custom 360 Responses'
          email={clientProfile?.email}
          buttonClickedHandler={() => setShowRightSidebar(!showRightSidebar)}
          hasBackArrow={true}
        />
        <div className='custom-offset-lg-1 custom-col-lg-12'>
          <div>
            <RightSideBar show={showRightSidebar} ref={rightSidebarRef}>
              <ProfileSidebarSection
                closeClickedHandler={() => setShowRightSidebar(false)}
                isCoach={clientProfile?.isCoach}
                isAdmin={clientProfile?.isAdmin}
                email={clientProfile?.email}
                clientProfile={clientProfile}
                rightSidebarRef={rightSidebarRef}
                isShowActiveSessions={isShowActiveSessions}
                setIsShowActiveSessions={setIsShowActiveSessions}
                planChangeHandler={(plan) =>
                  setClientProfile((prev) => ({
                    ...prev,
                    planCode: plan,
                  }))
                }
              />
            </RightSideBar>
          </div>
          <div className='mt-5 d-flex justify-content-between'>
            <NavTabs
              pages={pages}
              pathname={location?.pathname}
              hash={location?.hash}
              NavLink={NavLink}
            />
            {/* <Button variant='gray' className='abroad-blue px-4 resDowBtn'>
              DOWNLOAD RESPONSES
            </Button> */}
          </div>
          <Switch location={location}>
            <Route
              exact
              strict
              path={[
                '/admin/custom360/users/:clientId/results/recipient',
                '/coach/custom360/clients/:clientId/results/recipient',
              ]}>
              <Custom360ResultRecipient
                reciveQuesWithAns={getQuesWithAns}
                isLoading={isLoading}
              />
            </Route>
            <Route
              exact
              strict
              path={[
                '/admin/custom360/users/:clientId/results/question',
                '/coach/custom360/clients/:clientId/results/question',
              ]}>
              <Custom360ResultQuestion
                reciveQuesWithAns={getQuesWithAns}
                isLoading={isLoading}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </section>
  )
}

export default Custom360Resuls
