import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { string, object, date } from 'yup'
import {
  useErrorService,
  ABSpinner,
  useNotificationService,
  useUserService,
  sows,
} from '@abroad/components'
import { AddClientTeamSession } from '../components'
import API from '../utils/API'
import { getLoginUserCoachingPlan } from '../utils/utility'
import Labels from '../constants/labels'
const { coachingPlanStatusConst } = Labels

export const AddSession = () => {
  const validationSchema = object().shape({
    planId: string().required(),
    sessionDuration: string().required(),
    sessionDate: date()
      .required()
      .test('valid-date', 'Please enter valid date!', function (value) {
        const checkDate = moment(value, 'MM/DD/YYYY')
        return checkDate.isValid()
      }),
  })

  const [isLoading, setIsLoading] = useState(true)
  const [isSessionCreateLoading, setIsSessionCreateLoading] = useState(false)
  const [sessionInsights, setSessionInsights] = useState([])
  const [nonConfidentialsessionInsights, setNonConfidentialSessionInsights] =
    useState([])
  const [postSession, setPostSession] = useState('')
  const [search, setSearch] = useState('')
  const [searchKeySkill, setSearchKeySkill] = useState('')
  const [searchData, setSearchData] = useState([])
  const [searchKeySkillData, setSearchKeySkillData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [inputFocusOfKeySkill, setInputFocusOfKeySkill] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [isSearchKeySkill, setIsSearchKeySkill] = useState(false)
  const [clientInfo, setClientInfo] = useState([])
  const Error = useErrorService()
  const { user } = useUserService()
  const Notification = useNotificationService()
  const history = useHistory()
  const { clientId } = useParams()
  const [keySkillSearchData, setKeySkillSearchData] = useState([])
  const [coachingPlans, setCoachingPlans] = useState([])
  const [formInitialValues, setFormInitialValues] = useState({
    sessionDate: null,
    sessionDuration: '',
    planId: '',
  })

  useEffect(() => {
    const getCoachingPlans = async (id = null) => {
      try {
        const { data } = await API.AdminAPI.coachPlan.getClientsCoachingPlans(
          clientId,
        )
        if (data) {
          const filteredData = data.filter(
            (e) =>
              e.status === coachingPlanStatusConst.Draft ||
              e.status === coachingPlanStatusConst.InProgress,
          )
          const updatedCoachingPlans = getLoginUserCoachingPlan(
            filteredData,
            user?._id,
          )
          setCoachingPlans(updatedCoachingPlans?.labelValuesData)
          if (updatedCoachingPlans?.labelValuesData?.length === 1) {
            const tempInitialValues = {
              ...formInitialValues,
              planId: updatedCoachingPlans?.labelValuesData[0]?.value,
            }
            if (updatedCoachingPlans?.coachingPlanData[0]?.sessionDuration) {
              tempInitialValues.sessionDuration =
                updatedCoachingPlans.coachingPlanData[0].sessionDuration
            }
            setFormInitialValues(tempInitialValues)
          }
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getUserInfo = async () => {
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['fullName', 'coachIds', 'planCode', 'fname'],
          },
        )
        if (data) {
          const allCoachIds = data?.coachIds?.map((coach) => coach?._id)
          setClientInfo(data)
          if (!allCoachIds?.includes(user?._id)) {
            history.push('/')
          } else {
            getCoachingPlans()
          }
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        if (search !== '') {
          const { data } = await API.AdminAPI.common.getSearchMedia(
            search,
            'user',
            clientId,
          )
          if (data) {
            setSearchData(data)
            setIsSearch(false)
          }
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const getSearchData = () => {
      setIsSearchKeySkill(true)
      const data = keySkillSearchData.filter((data) =>
        data?.title?.toLowerCase()?.includes(searchKeySkill?.toLowerCase()),
      )

      setSearchKeySkillData(data)
      setIsSearchKeySkill(false)
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeySkill, keySkillSearchData])

  useEffect(() => {
    const getKeySkillData = async () => {
      try {
        const { data } = await API.AdminAPI.common.getKeySkillSearchPdf()
        if (data) {
          const keySkillsData = data.map((keySkill) => ({
            _id: keySkill?._id,
            title: keySkill?.keySkill?.title,
            description: keySkill?.keySkill?.description,
          }))
          setKeySkillSearchData(keySkillsData)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getKeySkillData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  const onSubmit = async (values) => {
    if (sessionInsights.length === 0) {
      Notification.showNotification('Please Enter Session Insights!', 'danger')
    } else {
      const filterSelectedPlan = coachingPlans?.filter(
        (plan) => plan?.value === values?.planId,
      )
      const isIndividualPlanSelected =
        filterSelectedPlan[0]?.type === sows.planType.individual
      let params = {
        date: moment(values.sessionDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        length: values.sessionDuration,
        coachNotes: sessionInsights,
        postSession: postSession,
        coachNotesNonConfidential:
          nonConfidentialsessionInsights.length === 0
            ? ''
            : nonConfidentialsessionInsights,
        planType: isIndividualPlanSelected
          ? sows.planType.individual
          : sows.planType.b2b,
      }
      if (values?.keySkillsIds?.length > 0) {
        params.keySkillIds = values.keySkillsIds
      }
      if (values.mediaIds.length > 0) {
        params.mediaIds = values.mediaIds
      }
      if (values.moduleIds.length > 0) {
        params.moduleIds = values.moduleIds
      }
      if (values.planId) {
        params.planId = values.planId
      }
      Object.keys(params).forEach(
        (key) => params[key] === '' && delete params[key],
      )
      try {
        setIsSessionCreateLoading(true)
        const { data } = await API.CoachAPI.coachClient.sessionCreate(
          clientId,
          params,
        )
        if (data) {
          setIsSessionCreateLoading(false)
          Notification.showNotification('session has been created!', 'success')
          history.goBack()
        }
      } catch (e) {
        if (e?.code === 'session_limit_exceeded') {
          setIsSessionCreateLoading(false)
        } else {
          setIsSessionCreateLoading(false)
          Error.showError(e)
        }
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${clientInfo?.fname}'s Session | Abroad`}</title>
        <meta
          name='title'
          content={`${clientInfo?.fname}'s Session | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${clientInfo?.fname}'s Session | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${clientInfo?.fname}'s Session | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/session`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/session`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/session`}></meta>
      </Helmet>
      <AddClientTeamSession
        validationSchema={validationSchema}
        formInitialValues={formInitialValues}
        onSubmit={onSubmit}
        titleText={`Add Client Session For ${clientInfo?.fullName}`}
        sessionInsights={sessionInsights}
        setSessionInsights={setSessionInsights}
        nonConfidentialsessionInsights={nonConfidentialsessionInsights}
        setNonConfidentialSessionInsights={setNonConfidentialSessionInsights}
        postSession={postSession}
        setPostSession={setPostSession}
        search={search}
        setSearch={setSearch}
        inputFocus={inputFocus}
        setInputFocus={setInputFocus}
        searchData={searchData}
        isSearch={isSearch}
        isSessionCreateLoading={isSessionCreateLoading}
        searchKeySkill={searchKeySkill}
        setSearchKeySkill={setSearchKeySkill}
        searchKeySkillData={searchKeySkillData}
        isSearchKeySkill={isSearchKeySkill}
        inputFocusOfKeySkill={inputFocusOfKeySkill}
        setInputFocusOfKeySkill={setInputFocusOfKeySkill}
        coachingPlans={coachingPlans}
      />
    </>
  )
}
