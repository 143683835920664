import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Nav } from 'react-bootstrap'
import classNames from 'classnames'
import API from '../../utils/API'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
} from '@abroad/components'
import { useParams } from 'react-router-dom'
import QuestVideos from './QuestVideos'
import QuestBlogs from './QuestBlogs'
import { LearningJourney } from '../journeys'
import { JourneyMediasContext } from '../../utils/contexts'

const QuestsLearning = ({ questName }) => {
  const Error = useErrorService()
  const Notification = useNotificationService()
  const [mediasList, setMediasList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isMediaFetching, setIsMediaFetching] = useState(true)
  const [resource, setResource] = useState()
  const [activeTab, setActiveTab] = useState('listen')
  const { questId } = useParams()

  useEffect(() => {
    const getUserJourneyMedia = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getQuestJourney({
          questId,
        })
        const audios = data?.audios?.map((data) => ({
          ...data.mediaOrModuleDetail,
          type: data.type,
          mediaOrModuleId: data.mediaOrModuleId,
        }))

        if (data) {
          setMediasList(Object.keys(data).length === 0 ? [] : audios)
        }
      } catch (e) {
        Error.showError(e)
      }
      setIsMediaFetching(false)
    }
    getUserJourneyMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveQuestJourney = async () => {
    setIsUpdating(true)
    try {
      const medias = mediasList.map((media) => ({
        mediaOrModuleId: media.mediaOrModuleId,
        type: media.type,
      }))
      const journeyData = {
        audios: medias,
      }
      const { status } = await API.AdminAPI.quests.updateQuestJourney(
        questId,
        journeyData,
      )
      if (status) {
        setIsUpdating(false)
        Notification.showNotification(
          'Quest Journey has been updated.',
          'success',
        )
      }
    } catch (e) {
      setIsUpdating(false)
      Error.showError(e)
    }
  }

  useEffect(() => {
    const getQuestResources = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getQuestResources(questId)
        setResource({
          videos: data.videos || [],
          blogs: data.blogs || [],
        })
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getQuestResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  if (isLoading || isMediaFetching) {
    return <ABSpinner />
  }

  const removeVideo = (id) => {
    setResource((prev) => {
      return {
        ...prev,
        videos: prev.videos.filter((v) => v._id !== id),
      }
    })
  }

  const addVideo = (video) => {
    setResource((prev) => {
      return {
        ...prev,
        videos: [...(prev.videos || []), video],
      }
    })
  }

  const addBlog = (blog) => {
    setResource((prev) => {
      return {
        ...prev,
        blogs: [...(prev.blogs || []), blog],
      }
    })
  }

  const handleBlogUpdate = (updatedBlogs) => {
    const filteredBlogs = updatedBlogs.filter((blog) => blog.title)
    setResource((prev) => ({
      ...prev,
      blogs: filteredBlogs,
    }))
  }

  return (
    <>
      <JourneyMediasContext.Provider value={{ mediasList, setMediasList }}>
        <Container className='pt-3' fluid>
          <Nav variant='tabs' activeKey={activeTab}>
            <Nav.Item>
              <Nav.Link
                eventKey='listen'
                onClick={() => setActiveTab('listen')}
                className={classNames('hover:text-saffron-300 text-dark-900', {
                  'active-class': activeTab === 'listen',
                })}>
                Listen
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='watch'
                onClick={() => setActiveTab('watch')}
                className={classNames('hover:text-saffron-300 text-dark-900', {
                  'active-class': activeTab === 'watch',
                })}>
                Watch
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='read'
                onClick={() => setActiveTab('read')}
                className={classNames('hover:text-saffron-300 text-dark-900', {
                  'active-class': activeTab === 'read',
                })}>
                Read
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {activeTab === 'listen' && (
            <>
              <h3 className='s3 my-4'>Audio</h3>
              <LearningJourney title={questName} type='quest' />
              <div className='d-flex justify-content-end mt-4'>
                <Button
                  variant='gray'
                  className='line-height-normal'
                  size='md'
                  disabled={isUpdating}
                  isLoading={isUpdating}
                  onClick={saveQuestJourney}>
                  Update
                </Button>
              </div>
            </>
          )}

          {activeTab === 'read' && (
            <>
              <div className='overflow-visible'>
                <h3 className='s3 my-4'>Blogs</h3>
                <Row className='mt-3'>
                  <Col lg={12} md={12} sm={12} className='dropdown-section'>
                    <label
                      htmlFor='selectionField'
                      className='s8 mt-md-2 mt-sm-2 mt-2'>
                      ATTACH BLOG
                    </label>
                    <QuestBlogs
                      blogs={resource?.blogs}
                      addBlog={addBlog}
                      onBlogUpdate={handleBlogUpdate}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}

          {activeTab === 'watch' && (
            <>
              <h3 className='s3 my-4'>Videos</h3>
              {resource?.videos?.map((video) => {
                return (
                  <QuestVideos
                    key={video._id}
                    video={video}
                    removeVideo={removeVideo}
                    addVideo={addVideo}
                  />
                )
              })}
              <QuestVideos removeVideo={removeVideo} addVideo={addVideo} />
            </>
          )}
        </Container>
      </JourneyMediasContext.Provider>
    </>
  )
}
export default QuestsLearning
