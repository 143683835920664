import { useEffect, useState } from 'react'

function AvarageScore({ recipients }) {
  const [avg, setAvg] = useState()
  useEffect(() => {
    if (recipients?.length > 0) {
      const numericResponses = recipients
        .map((recipient) => recipient?.answer?.text)
        .filter(
          (response) => !isNaN(parseFloat(response)) && isFinite(response),
        )

      if (numericResponses.length > 0) {
        const totalScore = numericResponses.reduce((score, cur) => {
          return score + parseFloat(cur)
        }, 0)

        setAvg(totalScore / numericResponses.length)
      }
    }
  }, [recipients])

  return avg ? avg?.toFixed(2) : null
}

export default AvarageScore
