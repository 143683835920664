import React, { useState } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { Formik, Form } from 'formik'
import { string, number, object, date, mixed } from 'yup'
import { Form as RBForm, Row, Col, Accordion, Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  Button,
  useUserService,
  paymentType as paymentTypeConst,
  paymentStatus as paymentStatusConst,
  paymentStatusLabel,
  paymentStatus,
} from '@abroad/components'
import { ClearMonthAndReportValue } from './ClearMonthAndReportValue'
import SOWCopySelection from './SOWCopySelection'
import { SelectionField } from '../common'
import {
  SingleInputField,
  SingleInputLabel,
  InputField,
  Checkbox,
  SingleDatePicker,
} from '../form'
import Labels from '../../constants/labels'
import { isLGScreen } from '../../utils/utility'
import { coachingPlanWIP } from '../../constants/common'
import { SetTotalSessionDuration } from './SetTotalSessionDuration'
import CoachingPlanDetailsModal from './CoachingPlanDetailsModal'
import PausingCurrentSOWModal from './PausingCurrentSOWModal'
import { formatDate } from '../../utils/date'

const { coachingPlanStatusConst } = Labels

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}
const { SOWBillEvery } = Labels
const indiSOWSchema = object().shape({
  planName: string().required().max(100),
  // Billing
  paymentStatus: string().required().oneOf(Object.values(paymentStatusConst)),
  paymentCycle: string().required(),
  initialFee: number().test(
    'initialFee-test',
    'initialFee validation',
    function (value) {
      const { paymentCycle, paymentStatus, isOld } = this.parent
      if (isOld) {
        return true
      }
      if (paymentStatus !== paymentStatusConst.NO_BILLING_REQUIRED) {
        const recurringMinValue = 0,
          onetimeMinValue = 1,
          bothMaxValue = 100000
        if (
          paymentCycle === paymentTypeConst.RECURRING &&
          value < recurringMinValue
        ) {
          return false
        }
        if (
          paymentCycle === paymentTypeConst.ONETIME &&
          value < onetimeMinValue
        ) {
          return false
        }
        if (paymentCycle === paymentTypeConst.RECURRING && value === 0) {
          return true
        }
        if (!value) {
          return false
        }
        if (value > bothMaxValue) {
          return false
        }
      }
      return true
    },
  ),
  invoiceEvery: mixed().when(['paymentCycle', 'paymentStatus'], {
    is: (paymentCycle, paymentStatus) =>
      paymentCycle === paymentTypeConst.RECURRING &&
      paymentStatus !== paymentStatusConst.NO_BILLING_REQUIRED,
    then: number().required().oneOf(SOWBillEvery),
    otherwise: () => mixed().notRequired(),
  }),
  numberOfCoachingCycle: number().when(['paymentCycle', 'paymentStatus'], {
    is: (paymentCycle, paymentStatus) =>
      paymentCycle === paymentTypeConst.RECURRING &&
      paymentStatus !== paymentStatusConst.NO_BILLING_REQUIRED,
    then: number().min(1).max(1000).nullable(),
    otherwise: () => number().notRequired(),
  }),
  amount: number().when(['paymentCycle', 'paymentStatus'], {
    is: (paymentCycle, paymentStatus) =>
      paymentCycle === paymentTypeConst.RECURRING &&
      paymentStatus !== paymentStatusConst.NO_BILLING_REQUIRED,
    then: number().required().min(1).max(10000),
    otherwise: () => number().notRequired(),
  }),
  // Coaching
  status: string().required().oneOf(Object.values(coachingPlanStatusConst)),
  sessionPerCoachingCycle: number().required().min(1).max(36),
  numberOfMonths: number().required().oneOf(SOWBillEvery),
  sessionDuration: number().when('isOld', {
    is: true,
    then: number().notRequired(),
    otherwise: () => number().required().min(15).max(600),
  }),
  report360hourlyRate: number().when('report360', {
    is: true,
    then: number().required().min(0).max(1000),
    otherwise: () => number().notRequired(),
  }),
  impactReporthourlyRate: number().when('impactReport', {
    is: true,
    then: number().required().min(0).max(1000),
    otherwise: () => number().notRequired(),
  }),
  reportPlan360: number().when('report360', {
    is: false,
    then: number().notRequired(),
    otherwise: () => number().required(),
  }),
  impactReportPlan: number().when('impactReport', {
    is: false,
    then: number().notRequired(),
    otherwise: () => number().required(),
  }),
  hourlyRate: number().required().min(0).max(1000),
  clientTimeZone: string().required(),
  firstSessionDate: date()
    .required()
    .test('valid-date', 'Please enter valid date!', function (value) {
      const checkDate = moment(value, 'MM/DD/YYYY')
      return checkDate.isValid()
    }),
})

const IndividualSOWForm = ({
  // isStaffCoachSOW = false, // todo: remove staff coach condition
  // setIsStaffCoachSOW,
  initialValues,
  onSubmit,
  viewOnlyField,
  // showModal,
  // setShowModal,
  isPlanTerminated,
  isUpdatePlanLoading,
  coaches = [],
  isBulkAssignSOWSection = false,
  updateFormValuesHandle = () => {},
}) => {
  const { planId } = useParams()
  const { user } = useUserService()
  const [showModal, setShowModal] = useState(false)
  const [pausingCurrentSOWPopup, setPausingCurrentSOWPopup] = useState(false)

  const { RECURRING: recurringPayment } = paymentTypeConst

  const getPaymentStatusOptions = (
    isNotBulkSOWNorCreate,
    initialValues,
    latestValues,
  ) => {
    const {
      status: coachingStatus,
      paymentStatus,
      paymentCycle,
    } = initialValues
    let options = paymentStatusLabel
    // any type of create -> normal Or bulk create
    if (!isNotBulkSOWNorCreate) {
      options = paymentStatusLabel.filter(
        (e) =>
          e.value === paymentStatusConst.NOT_INITIATED ||
          e.value === paymentStatusConst.NO_BILLING_REQUIRED,
      )
    } else {
      // only update related cases
      if (
        coachingStatus === coachingPlanStatusConst.Draft &&
        paymentStatus === paymentStatusConst.NOT_INITIATED
      ) {
        options = paymentStatusLabel.filter(
          (e) =>
            e.value === paymentStatusConst.NOT_INITIATED ||
            e.value === paymentStatusConst.NO_BILLING_REQUIRED,
        )
      } else if (paymentStatus === paymentStatusConst.NO_BILLING_REQUIRED) {
        options = paymentStatusLabel.filter(
          (e) =>
            e.value === paymentStatusConst.NO_BILLING_REQUIRED ||
            e.value === initialValues.paymentStatus,
        )
      } else if (
        coachingStatus === coachingPlanStatusConst.Draft &&
        paymentStatus === paymentStatusConst.PENDING
      ) {
        options = paymentStatusLabel.filter(
          (e) =>
            e.value === paymentStatusConst.PENDING ||
            e.value === paymentStatusConst.NO_BILLING_REQUIRED,
        )
      } else if (paymentStatus === paymentStatusConst.PAID) {
        if (paymentCycle === recurringPayment) {
          options = paymentStatusLabel.filter(
            (e) =>
              e.value === paymentStatusConst.PAID ||
              e.value === paymentStatusConst.PAUSED ||
              e.value === paymentStatusConst.CANCELLED,
          )
        } else {
          options = paymentStatusLabel.filter(
            (e) => e.value === paymentStatusConst.PAID,
          )
        }
      } else if (paymentStatus === paymentStatusConst.PAUSED) {
        options = paymentStatusLabel.filter(
          (e) =>
            e.value === paymentStatusConst.PAUSED ||
            e.value === paymentStatusConst.RESUMED ||
            e.value === paymentStatusConst.CANCELLED,
        )
      } else if (
        coachingStatus === coachingPlanStatusConst.Terminate &&
        paymentStatus !== paymentStatusConst.CANCELLED
      ) {
        options = paymentStatusLabel.filter(
          (e) =>
            e.value === paymentStatusConst.PENDING ||
            e.value === paymentStatusConst.NO_BILLING_REQUIRED,
        )
      }
    }
    return options
  }

  const getStatusOptions = (
    initialStatus,
    paymentCycle,
    isNotBulkSOWNorCreate,
  ) => {
    let statusOptions = []
    if (planId === 'create' || isBulkAssignSOWSection) {
      statusOptions = Labels.coachingPlanStatus.slice(0, 2)
    } else {
      statusOptions = Labels.coachingPlanStatus
    }
    if (initialValues?.isOld) {
      statusOptions = Labels.coachingPlanStatus.filter(
        (e) =>
          e.value === coachingPlanStatusConst.InProgress ||
          e.value === coachingPlanStatusConst.Terminate,
      )
    } else if (initialStatus === coachingPlanStatusConst.Pending) {
      statusOptions = Labels.coachingPlanStatus.filter(
        (e) =>
          e.value === coachingPlanStatusConst.Terminate ||
          e.value === coachingPlanStatusConst.Pending,
      )
    } else if (initialStatus === coachingPlanStatusConst.Draft) {
      statusOptions = Labels.coachingPlanStatus.filter(
        (e) =>
          e.value === coachingPlanStatusConst.Terminate ||
          e.value === coachingPlanStatusConst.Draft,
      )
      if (!isNotBulkSOWNorCreate) {
        statusOptions = Labels.coachingPlanStatus.filter(
          (e) => e.value === coachingPlanStatusConst.Draft,
        )
      }
    } else if (initialStatus === coachingPlanStatusConst.InProgress) {
      if (paymentCycle !== paymentTypeConst.ONETIME) {
        statusOptions = Labels.coachingPlanStatus.filter(
          (e) =>
            e.value === coachingPlanStatusConst.Paused ||
            e.value === coachingPlanStatusConst.InProgress ||
            e.value === coachingPlanStatusConst.Terminate,
        )
      } else {
        statusOptions = Labels.coachingPlanStatus.filter(
          (e) =>
            e.value === coachingPlanStatusConst.Paused ||
            e.value === coachingPlanStatusConst.InProgress ||
            e.value === coachingPlanStatusConst.Terminate,
        )
      }
    } else if (initialStatus === coachingPlanStatusConst.Paused) {
      statusOptions = Labels.coachingPlanStatus.filter(
        (e) =>
          e.value === coachingPlanStatusConst.InProgress ||
          e.value === coachingPlanStatusConst.Paused ||
          e.value === coachingPlanStatusConst.Terminate,
      )
    } else if (initialStatus === coachingPlanStatusConst.Terminate) {
      statusOptions = Labels.coachingPlanStatus.filter(
        (e) => e.value === coachingPlanStatusConst.Terminate,
      )
    }

    return statusOptions
  }

  const isNotBulkSOWNorCreate = !isBulkAssignSOWSection && planId !== 'create'

  return (
    <>
      {viewOnlyField && (
        <>
          <h2 className='font-22 font-semibold'>
            Payment Status:{' '}
            {
              paymentStatusLabel.find(
                (e) => e.value === initialValues.paymentStatus,
              )?.label
            }{' '}
            {[paymentStatus.PAID, paymentStatus.PAUSED].includes(
              initialValues.paymentStatus,
            ) &&
              initialValues?.cancelledOn && (
                <span className='text-red-800'>
                  (*Set to be terminated on{' '}
                  {formatDate(initialValues?.cancelledOn)})
                </span>
              )}
          </h2>
          <h2 className='font-22 font-semibold'>
            Coaching Status:{' '}
            {
              Labels.coachingPlanStatus.find(
                (e) => e.value === initialValues.status,
              )?.label
            }
          </h2>
        </>
      )}
      <Formik
        displayName='sow-form'
        validationSchema={indiSOWSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnMount
        onSubmit={(values) => {
          if (!isBulkAssignSOWSection && planId === 'create') {
            if (
              values?.paymentStatus !== paymentStatusConst.NO_BILLING_REQUIRED
            ) {
              setShowModal(true)
            } else {
              onSubmit(values)
            }
          } else if (isBulkAssignSOWSection) {
            onSubmit(values)
          } else {
            if (
              values?.paymentStatus === paymentStatusConst.NO_BILLING_REQUIRED
            ) {
              onSubmit(values)
            } else if (values.status === coachingPlanStatusConst.Draft) {
              setShowModal(true)
            } else if (
              values.status === coachingPlanStatusConst.Paused &&
              values.paymentStatus === paymentStatusConst.PAUSED
            ) {
              setPausingCurrentSOWPopup(true)
            } else {
              onSubmit(values)
            }
          }
        }}>
        {({ values, handleSubmit, initialValues }) => {
          const toBeTerminatedSOW =
            [paymentStatus.PAID, paymentStatus.PAUSED].includes(
              initialValues.paymentStatus,
            ) && initialValues?.cancelledOn
          return (
            <>
              <RBForm
                onSubmit={handleSubmit}
                as={Form}
                className='profile promo-accordion'>
                <Accordion defaultActiveKey={'1'} key={'1'}>
                  <Card className='mb-3'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={'1'}
                      className={classnames(
                        'border-0 cursor-pointer font-18 font-semibold px-3',
                      )}>
                      SOW Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={'1'}>
                      <Card.Body className='px-3 d-flex flex-column'>
                        {(planId === 'create' || isBulkAssignSOWSection) && (
                          <SOWCopySelection
                            // updateFormValuesHandle={updateFormValuesHandle}
                            name='sowCopy'
                            placeholder='SOW Copy From...'
                            disabled={false}
                            label='Copy From'
                            isDiscardLabelPadding={!isBulkAssignSOWSection}
                            // isStaffCoachSOW={isStaffCoachSOW}
                            isInsidePopup={isBulkAssignSOWSection}
                          />
                        )}
                        {!isBulkAssignSOWSection && (
                          <SelectionField
                            label='Coach Name'
                            labelProps={labelProps}
                            name='sowCoachId'
                            size='lg'
                            options={coaches}
                            getValueAsOption={true}
                            optionplaceholder='Select Coach'
                            sm='10'
                            className={
                              planId !== 'create'
                                ? 'cursor-disabled'
                                : 'cursor-pointer'
                            }
                            disabled={
                              planId !== 'create' ||
                              !user?.isAdmin ||
                              showModal ||
                              coaches?.length === 1
                            }
                          />
                        )}
                        <InputField
                          label='Plan Name'
                          labelProps={labelProps}
                          name='planName'
                          type='text'
                          size='lg'
                          placeholder='Plan Name'
                          disabled={
                            coachingPlanWIP
                              ? viewOnlyField && !user?.isAdmin
                              : viewOnlyField || showModal
                          }
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                {user?.isAdmin && (
                  <Accordion defaultActiveKey={'2'} key={'2'}>
                    <Card className='mb-3'>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={'2'}
                        className={classnames(
                          'border-0 cursor-pointer font-18 font-semibold px-3',
                        )}>
                        Billing
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={'2'}>
                        <Card.Body className='px-3 d-flex flex-column'>
                          <SelectionField
                            label='Payment Status'
                            labelProps={labelProps}
                            formGroupProps={{
                              className: toBeTerminatedSOW ? 'mb-0' : 'mb-3',
                            }}
                            name='paymentStatus'
                            options={getPaymentStatusOptions(
                              isNotBulkSOWNorCreate,
                              initialValues,
                              {
                                status: values.status,
                                paymentStatus: values.paymentStatus,
                                paymentCycle: values.paymentCycle,
                              },
                            )}
                            getValueAsOption={true}
                            disabled={
                              initialValues.paymentStatus ===
                                paymentStatusConst.CANCELLED ||
                              initialValues.status ===
                                coachingPlanStatusConst.Terminate ||
                              initialValues?.paymentStatus ===
                                paymentStatusConst.NO_BILLING_REQUIRED ||
                              toBeTerminatedSOW ||
                              initialValues?.isOld
                            }
                          />
                          {[paymentStatus.PAID, paymentStatus.PAUSED].includes(
                            initialValues.paymentStatus,
                          ) &&
                            initialValues?.cancelledOn && (
                              <Row
                                xs={1}
                                sm={1}
                                md={1}
                                lg={2}
                                className='g-0 mb-3'>
                                <Col className='col-lg-2'></Col>
                                <Col className='col-lg-10'>
                                  <span className='s7 text-red-800'>
                                    (*Set to be terminated on{' '}
                                    {formatDate(initialValues?.cancelledOn)})
                                  </span>
                                </Col>
                              </Row>
                            )}
                          {/* {values?.paymentStatus !==
                            paymentStatusConst.NO_BILLING_REQUIRED && ( */}
                          <>
                            {(user.isAdmin || !viewOnlyField) && (
                              <SelectionField
                                label='PAYMENT TYPE'
                                labelProps={labelProps}
                                name='paymentCycle'
                                options={
                                  values?.paymentStatus ===
                                  paymentStatusConst.NO_BILLING_REQUIRED
                                    ? Labels.paymentCycle.slice(1)
                                    : Labels.paymentCycle
                                }
                                disabled={viewOnlyField || showModal}
                                size='lg'
                                getKeyAsOption={true}
                                className={classnames({
                                  'bg-white': isBulkAssignSOWSection,
                                })}
                              />
                            )}
                            {(user.isAdmin || !viewOnlyField) &&
                              values.paymentCycle === recurringPayment && (
                                <SelectionField
                                  label={
                                    values.paymentCycle === recurringPayment &&
                                    'Bill Every (Month(s))'
                                  }
                                  labelProps={labelProps}
                                  name='invoiceEvery'
                                  size='lg'
                                  options={SOWBillEvery.map((e) => ({
                                    key: e,
                                    value: e,
                                  }))}
                                  getKeyAsOption={true}
                                  // options={Labels.coachingPlanStatus}
                                  disabled={viewOnlyField || showModal}
                                  className={classnames({
                                    'd-none m-0':
                                      values.paymentCycle !== recurringPayment,
                                  })}
                                />
                              )}
                            {(user.isAdmin || !viewOnlyField) &&
                              values.paymentCycle === recurringPayment && (
                                <InputField
                                  label={
                                    values.paymentCycle === recurringPayment &&
                                    'Subscription Amount(USD)'
                                  }
                                  labelProps={labelProps}
                                  name='amount'
                                  type='number'
                                  size='lg'
                                  placeholder='Enter Subscription Fee'
                                  sm='10'
                                  className={classnames({
                                    'd-none m-0':
                                      values.paymentCycle !== recurringPayment,
                                  })}
                                  disabled={viewOnlyField || showModal}
                                />
                              )}
                            {(user.isAdmin || !viewOnlyField) && (
                              <InputField
                                label={
                                  values.paymentCycle === recurringPayment
                                    ? 'UPFRONT/SETUP FEE(USD)'
                                    : 'ONE TIME FEE(USD)'
                                }
                                labelProps={labelProps}
                                name='initialFee' // oneTimeFee
                                type='number'
                                size='lg'
                                placeholder='Enter One Time Fee'
                                sm='10'
                                disabled={viewOnlyField || showModal}
                              />
                            )}
                            {(user.isAdmin || !viewOnlyField) &&
                              values.paymentCycle === recurringPayment && (
                                <InputField
                                  label={
                                    values.paymentCycle === recurringPayment &&
                                    'Number of Billing Cycle'
                                  }
                                  labelProps={labelProps}
                                  name='numberOfCoachingCycle'
                                  type='number'
                                  size='lg'
                                  placeholder='Indefinite'
                                  sm='10'
                                  className={classnames({
                                    'd-none m-0':
                                      values.paymentCycle !== recurringPayment,
                                  })}
                                  disabled={viewOnlyField || showModal}
                                />
                              )}
                          </>
                          {/* )} */}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
                <Accordion defaultActiveKey={'3'} key={'3'}>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={'3'}
                      className={classnames(
                        'border-0 cursor-pointer font-18 font-semibold px-3',
                      )}>
                      Coaching
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={'3'}>
                      <Card.Body className='px-3 d-flex flex-column'>
                        <SelectionField
                          label='Coaching Status'
                          labelProps={labelProps}
                          name='status'
                          options={getStatusOptions(
                            initialValues.status,
                            initialValues.paymentCycle,
                            isNotBulkSOWNorCreate,
                          )}
                          disabled={
                            initialValues.paymentStatus ===
                              paymentStatusConst.CANCELLED ||
                            initialValues.status ===
                              coachingPlanStatusConst.Terminate ||
                            (viewOnlyField && !user?.isAdmin) ||
                            toBeTerminatedSOW
                          }
                          getValueAsOption={true}
                          className={classnames({
                            'bg-white': isBulkAssignSOWSection,
                          })}
                        />
                        <Row>
                          <Col lg={2} sm={12} className='pr-0'>
                            <SingleInputLabel
                              isDiscardWidth={isLGScreen ? false : true}
                              label='Number of Sessions'
                              divClasses='justify-content-end'
                              labelClasses={!isLGScreen ? 'h-auto' : ''}
                            />
                          </Col>
                          <Col
                            lg={10}
                            sm={12}
                            className={classnames({
                              'px-075': !isLGScreen,
                              'pl-2': isLGScreen,
                            })}>
                            <Row>
                              <Col lg={4} md={4} sm={12}>
                                <SingleInputField
                                  name='sessionPerCoachingCycle'
                                  type='number'
                                  size='lg'
                                  placeholder='Number of sessions'
                                  sm='3'
                                  // className={classnames({
                                  //   'cursor-disabled': values.isPlanIndefinite,
                                  // })}
                                  disabled={
                                    values.isPlanIndefinite ||
                                    (coachingPlanWIP
                                      ? viewOnlyField && !user?.isAdmin
                                      : viewOnlyField || showModal)
                                  }
                                />
                              </Col>
                              <Col lg={1} md={1} sm={12}>
                                <SingleInputLabel
                                  isDiscardWidth={isLGScreen ? false : true}
                                  label='Per'
                                  divClasses={classnames(
                                    'justify-content-center',
                                    {
                                      'px-1 mt-2': !isLGScreen,
                                    },
                                  )}
                                />
                              </Col>
                              <Col lg={4} md={4} sm={12}>
                                <SelectionField
                                  labelProps={labelProps}
                                  name='numberOfMonths'
                                  size='lg'
                                  type='number'
                                  options={SOWBillEvery.map((e) => ({
                                    key: e,
                                    value: e,
                                  }))}
                                  getKeyAsOption={true}
                                  // options={Labels.coachingPlanStatus}
                                  disabled={
                                    coachingPlanWIP
                                      ? viewOnlyField && !user?.isAdmin
                                      : viewOnlyField || showModal
                                  }
                                  // className={classnames({
                                  //   'cursor-disabled': values.isPlanIndefinite,
                                  // })}
                                  optionplaceholder='Number of Months'
                                />
                              </Col>
                              <Col lg={1} md={1} sm={12}>
                                <SingleInputLabel
                                  isDiscardWidth={isLGScreen ? false : true}
                                  label='Month(s)'
                                  divClasses={classnames(
                                    'justify-content-left',
                                    {
                                      'px-1 mt-2': !isLGScreen,
                                    },
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <InputField
                          label='Session Duration'
                          labelProps={labelProps}
                          name='sessionDuration'
                          type='number'
                          size='lg'
                          placeholder='Duration In Minutes'
                          sm='10'
                          disabled={
                            coachingPlanWIP
                              ? viewOnlyField && !user?.isAdmin
                              : viewOnlyField || showModal
                          }
                        />
                        {values.paymentCycle === recurringPayment &&
                        values.invoiceEvery &&
                        values.numberOfCoachingCycle ? (
                          <>
                            <SetTotalSessionDuration />
                            <InputField
                              label='Total Duration Of Contract'
                              labelProps={labelProps}
                              name='totalDurationOfContract'
                              type='text'
                              size='lg'
                              sm='10'
                              disabled={true}
                            />
                          </>
                        ) : null}
                        <Row>
                          <Col lg={2} md={0} sm={0}></Col>
                          <Col lg={7} md={12} sm={12}>
                            <Checkbox
                              name='report360'
                              type='checkbox'
                              label='360 Report'
                              disabled={
                                coachingPlanWIP
                                  ? viewOnlyField && !user?.isAdmin
                                  : viewOnlyField || showModal
                              }
                            />
                          </Col>
                        </Row>
                        {values.report360 && (
                          <>
                            <SelectionField
                              label={values.report360 && '360 REPORT PLAN'}
                              labelProps={labelProps}
                              name='reportPlan360'
                              options={Labels.ReportPlanOf360}
                              className={classnames({
                                'd-none m-0': !values.report360,
                                'bg-white': isBulkAssignSOWSection,
                              })}
                              disabled={
                                coachingPlanWIP
                                  ? viewOnlyField && !user?.isAdmin
                                  : viewOnlyField || showModal
                              }
                              size='lg'
                              getKeyAsOption={true}
                            />
                            {(user.isAdmin || !viewOnlyField) && (
                              <InputField
                                label={
                                  values.report360 && '360 REPORT HOURLY RATE'
                                }
                                labelProps={labelProps}
                                name='report360hourlyRate'
                                type='number'
                                size='lg'
                                placeholder={'Enter Report 360 Rate'}
                                sm='10'
                                className={classnames({
                                  'd-none m-0': !values.report360,
                                })}
                                disabled={
                                  coachingPlanWIP
                                    ? viewOnlyField && !user?.isAdmin
                                    : viewOnlyField || showModal
                                }
                              />
                            )}
                          </>
                        )}
                        <Row>
                          <Col lg={2} md={0} sm={0}></Col>
                          <Col lg={7} md={12} sm={12}>
                            <Checkbox
                              name='impactReport'
                              type='checkbox'
                              label='Impact Report'
                              disabled={
                                coachingPlanWIP
                                  ? viewOnlyField && !user?.isAdmin
                                  : viewOnlyField || showModal
                              }
                            />
                          </Col>
                        </Row>
                        {values.impactReport && (
                          <>
                            <SelectionField
                              label={
                                values.impactReport && 'IMPACT REPORT PLAN'
                              }
                              labelProps={labelProps}
                              name='impactReportPlan'
                              options={Labels.impactReportPlan}
                              className={classnames({
                                'd-none m-0': !values.impactReport,
                                'bg-white': isBulkAssignSOWSection,
                              })}
                              disabled={
                                coachingPlanWIP
                                  ? viewOnlyField && !user?.isAdmin
                                  : viewOnlyField || showModal
                              }
                              size='lg'
                              getKeyAsOption={true}
                            />

                            {(user.isAdmin || !viewOnlyField) && (
                              <InputField
                                label={
                                  values.impactReport &&
                                  'IMPACT REPORT HOURLY RATE'
                                }
                                labelProps={labelProps}
                                name='impactReporthourlyRate'
                                type='number'
                                size='lg'
                                placeholder={'Enter Report Impact Rate'}
                                sm='10'
                                className={classnames({
                                  'd-none m-0': !values.impactReport,
                                })}
                                disabled={
                                  coachingPlanWIP
                                    ? viewOnlyField && !user?.isAdmin
                                    : viewOnlyField || showModal
                                }
                              />
                            )}
                          </>
                        )}
                        {/* HOURLY RATE PAID TO COACH (USD) */}
                        {(user.isAdmin ||
                          !viewOnlyField ||
                          (!user.isAdmin &&
                            user?._id === values?.sowCoachId)) && (
                          <InputField
                            label='HOURLY RATE PAID TO COACH (USD)'
                            showTooltipMessage={true}
                            dataTooltip='- Tier 1 - $375 / hour</h1>(Corporation paying for Executive)<br/>- Tier 2 - $250 / hour (Senior Exec Self-Paying)<br/>- Tier 3 - $175 / hour (Emerging Leader / Manager / High potential)<br/>- Custom - Social impact discounts or custom fees as negotiated with client (should be 50% of top line hourly rate)'
                            extraIcon={
                              <svg
                                aria-hidden='true'
                                focusable='false'
                                data-prefix='fas'
                                data-icon='question-circle'
                                className='svg-inline--fa fa-question-circle fa-w-16'
                                role='img'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                                width='20'
                                height='20'>
                                <path
                                  fill='currentColor'
                                  d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z'></path>
                              </svg>
                            }
                            labelProps={labelProps}
                            name='hourlyRate'
                            type='number'
                            size='lg'
                            placeholder={'Hourly Rate'}
                            sm='10'
                            list='hourly-rate'
                            disabled={
                              coachingPlanWIP
                                ? viewOnlyField && !user?.isAdmin
                                : viewOnlyField || showModal
                            }
                          />
                        )}
                        <datalist id='hourly-rate'>
                          <option value='175'>
                            $175 - Emerging Leader (Tier 3)
                          </option>
                          <option value='250'>
                            $250 - Executive Leader (Tier 2)
                          </option>
                          <option value='375'>
                            $375 - Senior/Corporate Executive Leader (Tier 1)
                          </option>
                        </datalist>
                        {/* CLIENT TIME ZONE */}
                        <SelectionField
                          label='CLIENT TIME ZONE'
                          optionplaceholder='Select Time Zone'
                          labelProps={labelProps}
                          name='clientTimeZone'
                          options={Labels.timeZoneList.map((timezone) => ({
                            value: timezone.value,
                            label: `${timezone.label} - ${timezone.value}`,
                          }))}
                          disabled={
                            coachingPlanWIP
                              ? viewOnlyField && !user?.isAdmin
                              : !user?.isAdmin ||
                                isPlanTerminated ||
                                toBeTerminatedSOW
                          }
                          getValueAsOption={true}
                        />
                        {/* Plan Starts On */}
                        <SingleDatePicker
                          label='Plan Starts On'
                          labelProps={labelProps}
                          name='firstSessionDate'
                          sm='10'
                          className='pointer-cursor paid-session-input'
                          disabled={
                            coachingPlanWIP
                              ? viewOnlyField && !user?.isAdmin
                              : viewOnlyField || showModal
                          }
                          minimumDate={moment().subtract(1, 'years')}
                          position='up'
                          maximumDate={moment().add(1, 'years')}
                          {...(isBulkAssignSOWSection && {
                            pickerClass: 'assign-sow',
                          })}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                {(planId === 'create' || isBulkAssignSOWSection) && (
                  <ClearMonthAndReportValue
                    coaches={coaches}
                    // setIsStaffCoachSOW={setIsStaffCoachSOW}
                  />
                )}
                <div className='d-flex justify-content-end'>
                  {values.status === coachingPlanStatusConst.Draft &&
                    values.paymentStatus !==
                      paymentStatusConst.NO_BILLING_REQUIRED &&
                    isNotBulkSOWNorCreate &&
                    !isUpdatePlanLoading && (
                      <div className='px-0 mr-2'>
                        {user?.isAdmin && !showModal && (
                          <div className='text-right mt-3'>
                            <Button
                              isLoading={isUpdatePlanLoading}
                              disabled={isUpdatePlanLoading}
                              onClick={() => {
                                if (
                                  values.paymentStatus ===
                                  paymentStatusConst.NOT_INITIATED
                                ) {
                                  setShowModal(true)
                                } else {
                                  onSubmit({
                                    ...values,
                                    isReadyForPayment: true,
                                  })
                                }
                              }}
                              className='text-uppercase line-height-normal'
                              variant='saffron'>
                              Send for payment?
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  {!isPlanTerminated && !toBeTerminatedSOW && (
                    <div className='px-0'>
                      {user?.isAdmin && !showModal && (
                        <div className='text-right mt-3'>
                          <Button
                            isLoading={isUpdatePlanLoading}
                            disabled={
                              isUpdatePlanLoading ||
                              initialValues?.status ===
                                coachingPlanStatusConst.Terminate
                            }
                            type='submit'
                            className='text-uppercase line-height-normal'
                            variant='gray'>
                            SAVE
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </RBForm>
              <CoachingPlanDetailsModal
                showModal={showModal}
                setShowModal={setShowModal}
                isLoading={isUpdatePlanLoading}
                handleSubmit={onSubmit}
                values={values}
                // isStaffCoachSOW={isStaffCoachSOW}
              />
              <PausingCurrentSOWModal
                showModal={pausingCurrentSOWPopup}
                setShowModal={setPausingCurrentSOWPopup}
                isLoading={isUpdatePlanLoading}
                handleSubmit={() => {
                  onSubmit(values)
                  setPausingCurrentSOWPopup(false)
                }}
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default IndividualSOWForm
