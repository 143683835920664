import React, { useEffect, useState } from 'react'
import { Form as RBForm, Container, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  Button,
  useErrorService,
  useNotificationService,
  ABSpinner,
} from '@abroad/components'
import { MultipleDatePicker } from '../form'
import CircleFromTimeSelection from '../form/CircleFromTimeSelection'
import CircleToTimeSelection from '../form/CircleToTimeSelection'
import API from '../../utils/API'
import moment from 'moment'

const TeamCircle = ({ teamMetadata }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    fromTime: '',
    toTime: '',
    multiDate: [],
  })
  const { teamId } = useParams()
  const Notification = useNotificationService()
  const Error = useErrorService()
  useEffect(() => {
    const getTeamDate = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamData(teamId)
        setIsLoading(false)
        if (data) {
          setFormData({
            multiDate: data?.circle?.dates,
            toTime: data?.circle?.endTime,
            fromTime: data?.circle?.startTime,
          })
        }
      } catch (error) {
        setIsLoading(false)
        Error.handle(error)
      }
    }
    getTeamDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formattedFromTime = formData.fromTime
    const formattedToTime = formData.toTime

    const postData = {
      startTime: formattedFromTime,
      endTime: formattedToTime,
      dates: formData.multiDate,
    }

    try {
      setIsSubmitting(true)
      const { data } = await API.AdminAPI.teams.getAddCicle(teamId, postData)
      setIsSubmitting(false)
      if (data) {
        Notification.showNotification('Scheduled has been updated', 'success')
      }
    } catch (error) {
      setIsSubmitting(false)
      Error.showError(error)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <main className='py-3' style={{ height: '700px' }}>
        <Container
          className='pt-5 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
          fluid>
          <RBForm onSubmit={handleSubmit} className='profile'>
            <Row className='d-flex mt-3'>
              <Col
                xs={2}
                className='d-flex align-items-center justify-content-end'>
                <label
                  htmlFor='fromTime'
                  className='form-label mt-md-2 mt-sm-2 mt-2'>
                  START TIME&nbsp;&nbsp;
                </label>
              </Col>
              <Col xs={10}>
                <CircleFromTimeSelection
                  value={formData.fromTime}
                  onChange={(newFromTime) => {
                    setFormData((prev) => ({
                      ...prev,
                      fromTime: newFromTime,
                    }))
                    if (
                      formData.toTime &&
                      moment(formData.toTime, 'HH:mm A').format('HH:mm') <=
                        moment(newFromTime, 'HH:mm A').format('HH:mm')
                    ) {
                      setFormData((prev) => ({
                        ...prev,
                        toTime: '',
                      }))
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className='d-flex mt-3'>
              <Col
                xs={2}
                className='d-flex align-items-center justify-content-end'>
                <label
                  htmlFor='toTime'
                  className='form-label mt-md-2 mt-sm-2 mt-2'>
                  END TIME&nbsp;&nbsp;
                </label>
              </Col>
              <Col xs={10}>
                <CircleToTimeSelection
                  value={formData.toTime}
                  fromTime={formData.fromTime}
                  onChange={(newToTime) => {
                    if (
                      !formData.fromTime ||
                      moment(newToTime, 'HH:mm A').format('HH:mm') >
                        moment(formData.fromTime, 'HH:mm A').format('HH:mm')
                    ) {
                      setFormData((prev) => ({
                        ...prev,
                        toTime: newToTime,
                      }))
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className='d-flex mt-3'>
              <Col
                xs={2}
                className='d-flex align-items-center justify-content-end'>
                <label
                  htmlFor='multiDate'
                  className='form-label mt-md-2 mt-sm-2 mt-2'>
                  DATE&nbsp;&nbsp;
                </label>
              </Col>
              <Col xs={10}>
                <MultipleDatePicker
                  name='multiDate'
                  className='pointer-cursor user-input black-color-placeholder bg-white w-100'
                  sm='10'
                  value={formData.multiDate}
                  onChange={(dates) => {
                    setFormData((prev) => ({
                      ...prev,
                      multiDate: dates,
                    }))
                  }}
                />
              </Col>
            </Row>

            <div className='text-right mt-3'>
              <Button
                isLoading={isSubmitting}
                disabled={isSubmitting}
                type='submit'
                size='md'
                className='line-height-normal border-radius-3 text-uppercase'
                variant='gray'>
                UPDATE
              </Button>
            </div>
          </RBForm>
        </Container>
      </main>
    </>
  )
}

export default TeamCircle
