import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { QuestInputField } from '../form'

const QuestInvestment = ({ investments, setInvestments }) => {
  const handleInvestmentsBlur = (fieldName, fieldValue) => {
    setInvestments((prevInvestments) => ({
      ...prevInvestments,
      [fieldName]: fieldValue,
    }))
  }
  return (
    <>
      <div className='mt-4'>
        <h3 className='s3'>Investments</h3>
        <Row>
          <Col lg={6}>
            {' '}
            <label htmlFor='selectionField' className='s8'>
              STANDARD RATE(USD)
            </label>
            <QuestInputField
              type='text'
              name='standardRate'
              className='w-100 sa2 selection-field'
              value={investments.standardRate}
              handleBlur={handleInvestmentsBlur}
            />
          </Col>
          <Col lg={6}>
            {' '}
            <label htmlFor='selectionField' className='s8'>
              SINGLE SUPPLEMENT(USD){' '}
            </label>
            <QuestInputField
              type='text'
              name='singleSupplement'
              className='w-100 sa2 selection-field'
              value={investments.singleSupplement}
              handleBlur={handleInvestmentsBlur}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
export default QuestInvestment
