import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  WisdomComparisonReport,
  surveyType,
  useErrorService,
  PrintableArea,
} from '@abroad/components'
import { SurveyComparisonReport } from '@abroad/components/dist/survey'
import { useGroupName } from '../components'
import API from '../utils/API'

const TeamCompareAssessment = (props) => {
  let { search } = useLocation()
  let history = useHistory()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [comparisonData, setComparisonData] = useState([])
  const [instanceCount, setInstanceCount] = useState(0)
  const query = new URLSearchParams(search)
  const preDate = query.getAll('preDate')
  const postDate = query.getAll('postDate')
  const querySurveyType = query.get('surveyType')
  const { teamId } = useParams()
  const teamName = useGroupName(teamId)

  useEffect(() => {
    const getComparion = async () => {
      try {
        let data
        if (props?.apiFor === 'common-users') {
          data = await API.AdminAPI.teams.getTeamComparisonReportCommonUsers(
            teamId,
            search,
          )
        } else {
          data = await API.AdminAPI.teams.getTeamComparisonReport(
            teamId,
            search,
          )
        }
        data = data.data
        if (props?.apiFor === 'common-users') {
          setInstanceCount(data[0]?.results?.instances)
        }
        setComparisonData(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else if (e?.code === 'upgrade_plan') {
          setIsLoading(false)
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    getComparion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${
          teamName ? teamName : 'Group'
        } Compare Assessment | Abroad`}</title>
        <meta
          name='title'
          content={`${
            teamName ? teamName : 'Group'
          } Compare Assessment | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${
            teamName ? teamName : 'Group'
          } Compare Assessment | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${
            teamName ? teamName : 'Group'
          } Compare Assessment | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/compare/group/${teamId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/compare/group/${teamId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/compare/group/${teamId}`}></meta>
      </Helmet>
      <main>
        {querySurveyType === surveyType.WISDOM ? (
          <WisdomComparisonReport
            preDate={preDate}
            postDate={postDate}
            isLoading={isLoading}
            comparisonData={comparisonData}
            header={() => {
              return `Compare Results ${
                instanceCount > 0 ? `(${instanceCount} instances)` : ''
              }`
            }}
            onGoBack={() => history.goBack()}
            isRenderFromOP={true}
          />
        ) : (
          <SurveyComparisonReport
            preDate={preDate}
            postDate={postDate}
            isLoading={isLoading}
            comparisonData={comparisonData}
            header={() => {
              return `Compare Results ${
                instanceCount > 0 ? `(${instanceCount} instances)` : ''
              }`
            }}
            onGoBack={() => history.goBack()}
            isRenderFromOP={true}
          />
        )}
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}

export default TeamCompareAssessment
