import React from 'react'
import classNames from 'classnames'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { Button, SignupHeaderIcon, CloseIcon } from '@abroad/components'
import InviteLogo from './InviteLogo'
import SignupPreviewField from './SignupPreviewField'

const SignupPreviewModal = ({
  show,
  onHide,
  orgEcoId,
  logo,
  signupTitle,
  signupSubTitle,
}) => {
  const { values } = useFormikContext()
  const { companyName } = useParams()

  return (
    <Modal
      show={show}
      fullscreen
      onHide={onHide}
      dialogClassName='preview-modal'>
      <Modal.Body className='p-0'>
        <CloseIcon className='close-icon' color='#fff' onClick={onHide} />
        <Container className='px-0' fluid>
          <Row noGutters className='vh-100 row preview-onboard bg-abroad-blue'>
            <Col lg={2} className='d-lg-flex d-none bg-abroad-blue'></Col>
            <Col
              lg={3}
              className={classNames(
                'd-none d-lg-flex justify-content-center bg-abroad-blue',
                {
                  'signup-left-section': !values?.body,
                  'signup-invite-link': values?.body,
                },
              )}>
              <section>
                <div className='d-flex flex-column'>
                  <div className='signup-preview-s1 text-white word-break'>
                    {values?.header ? values?.header : <>{signupTitle}</>}
                  </div>
                  <div className='word-break signup-preview-s5 text-white opacity-75'>
                    {values?.body ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div>${values?.body}</div>`,
                        }}
                      />
                    ) : (
                      signupSubTitle
                    )}
                  </div>
                </div>
              </section>
            </Col>
            <Col lg={2} className='d-lg-flex d-none bg-abroad-blue'></Col>
            <Col className='bg-abroad-blue'>
              <div className='h-100 d-flex flex-column align-items-center'>
                <div className='d-flex align-items-center justify-content-center abroad-logo'>
                  {logo?.fileName ? (
                    <InviteLogo orgEcoId={orgEcoId} logo={logo} />
                  ) : (
                    <SignupHeaderIcon className='cursor-pointer' />
                  )}
                </div>
                <div className='signup-form d-flex w-100'>
                  <div className='d-flex flex-column mt-32px'>
                    <div className='name-fields d-flex justify-content-between'>
                      <SignupPreviewField
                        label='FIRST NAME'
                        name='firstName'
                        type='text'
                      />
                      <SignupPreviewField
                        label='LAST NAME'
                        name='lastName'
                        type='text'
                      />
                    </div>
                    <SignupPreviewField label='EMAIL' type='email' />
                    <SignupPreviewField
                      label='PASSWORD'
                      wrapperClasses='mb-0'
                    />
                    <Button
                      variant={
                        values?.buttonColorHex === '#bf9000'
                          ? 'saffron'
                          : 'none'
                      }
                      className={classNames(
                        'w-100 btn signup-preview-s9 onbording-action-btn border-radius-100',
                        {
                          'btn-custom': values?.buttonColorHex,
                        },
                      )}
                      style={{
                        backgroundColor: `${values?.buttonColorHex}`,
                        color: `${values?.fontBlackColorHex ? '#000' : '#fff'}`,
                      }}
                      disabled={true}>
                      {companyName ? 'SIGN UP' : 'NEXT'}
                    </Button>
                  </div>
                </div>
                <div className='position-fixed page-bottom-link my-3 d-flex'>
                  <span className='text-white'>
                    Already Have an Account?&nbsp;
                  </span>
                  <div>
                    <span
                      style={{
                        color: values?.buttonColorHex || '#efc030',
                      }}>
                      Log In
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={2} className='d-lg-flex d-none bg-abroad-blue'></Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default SignupPreviewModal
