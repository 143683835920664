import { mediaCategoriesConstants } from '@abroad/components'

const categories = {
  mediaCategory: [...mediaCategoriesConstants],
  programs: [
    {
      id: '5fddc237a483be3860dea6f2',
      name: 'Breakthrough',
      key: 'breakthrough',
      description:
        'The content below has been personalized based on the results of your assessments.',
    },
    {
      id: '60e885f48ea7c31048bc553d',
      name: 'Evolution',
      key: 'evolution',
      description:
        'The content below has been personalized based on the results of your assessments.',
    },
    {
      id: '639970530a71c612b0026943',
      name: 'Resilience',
      key: 'resilience',
      description:
        'The content below has been personalized based on the results of your assessments.',
      onlyRC: false,
    },
    {
      id: '656dc22e079eab484d6e3288',
      name: 'Essentials',
      key: 'essentials',
      description: '',
      onlyRC: false,
    },
  ],
}
export default categories
