import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { Link } from 'react-router-dom'
import { useResolutionService } from '@abroad/components'

const UnderMaintenance = () => {
  const mailTo = 'dev@abroad.io'
  const mailSubject = 'Bug in abroad platform'
  const mailBody =
    'Hello, Sorry for the inconvenience caused. Please add details(with screenshots/video if possible) of the issue you are facing here.'
  const { isLGScreen } = useResolutionService()
  return (
    <>
      <div className='position-relative h-100 w-100 vh-100 vw-100 not-found'>
        {isLGScreen ? (
          <Imgix
            className='lazyload w-100 h-lg-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found.png`}
          />
        ) : (
          <Imgix
            className='lazyload vh-100 h-md-100 vw-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found-sm.png`}
          />
        )}

        <Container
          fluid
          className='position-absolute inset-0 h-100 p-lg-5 p-md-5 p-2 text-md-left text-center font-montserrat under-maintenance'>
          <Row className='w-100 h-100 m-0'>
            <Col>
              <p className='pb-lg-5 tracking-md-wide'>
                Oops, Something went wrong!
              </p>
              <h1 className='pb-3 font-bold'>Oh No</h1>
              <div className='w-lg-35 w-sm-100'>
                <p className='font-lg-24 font-md-24 font-sm-14 fw-medium text-break font-weight-medium'>
                  An error occurred and your request couldn't be completed. Feel
                  free to{' '}
                  <a
                    href={`mailto:${mailTo}?subject=${mailSubject}&body=${mailBody}`}
                    className='text-saffron-600'>
                    report this issue
                  </a>
                </p>
              </div>
              <span className='font-lg-24 font-md-24 tracking-lg-wide home-link'>
                <Link
                  to='/'
                  className='text-saffron-600 font-semibold return-link'>
                  CLICK HERE TO RETURN TO HOME.
                </Link>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default UnderMaintenance
