import React, { useEffect, useState } from 'react'

const QuestInputField = ({ 
  id, 
  className, 
  type, 
  value, 
  name,
  disabled,
  handleBlur,
  handleInputChange,
  setInputFocus,
}) => {
  const [inputText, setInputText] = useState(value)

  useEffect(() => {
    setInputText(value)
  }, [value])

  const handleChange = (e) => {
    const newValue = e.target.value
    setInputText(newValue)
    
    if(handleInputChange){
      handleInputChange(newValue)
    }
  }

  const handleBlurChange = (e) => {
    const { name, value } = e.target
    handleBlur(name, value)
  }

  const handleFocusChange = () => {
    setInputFocus(true)
  }

  return (
    <input
      id={id}
      className={className}
      type={type}
      value={inputText}
      name={name}
      disabled={disabled}
      onFocus={setInputFocus ? handleFocusChange : null}
      onBlur={handleBlurChange}
      onChange={handleChange}
    />
  )
}
export default QuestInputField