import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { ShowCharsLimt } from '../common'
import { useToSetCharsLimit } from '../common/hooks'
import CKEditor from '../../components/editor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'

import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'

const CustomBody = ({
  maxCharsInBody,
  minCharsInBody,
  disabled,
  placeholder,
}) => {
  const [error, setError] = useState(null)

  const {
    totalChars: totalCharsInBodyText,
    updateCharsLength: updateCharsInBodyText,
  } = useToSetCharsLimit()
  const {
    values: { body },
    handleBlur,
    handleChange,
  } = useFormikContext()

  useEffect(() => {
    updateCharsInBodyText(body)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body])
  const editorConfiguration = {
    plugins: [Essentials, Paragraph, ListStyle],
    toolbar: ['|', 'bulletedList', 'numberedList'],
    config: {
      resize_maxHeight: 100,
    },
  }
  return (
    <>
      <label> Custom Body</label>
      <CKEditor
        name='body'
        data={body || ''}
        onChange={(e, editor) => {
          const data = editor.getData()
          handleChange({ target: { name: 'body', value: data } })
          setError(null)
        }}
        placeholder={placeholder}
        onBlur={(event, editor) => {
          const data = editor.getData()
          handleBlur({ target: { name: 'body', value: data } })
          const headerError =
            data?.length > maxCharsInBody
              ? `Body must be less than ${maxCharsInBody} characters.`
              : data?.length < minCharsInBody && data?.length !== 0
              ? `Body must be at least ${minCharsInBody} characters long.`
              : null
          setError(headerError)
        }}
        className={classNames('resize-none border-radius-5', {
          'disabled-input': disabled,
        })}
        disabled={disabled}
        config={editorConfiguration}
      />
      <div
        className={classNames({
          'd-flex justify-content-between': error,
          'd-flex justify-content-end': !error,
        })}>
        {error && <span className='font-open-sans text-error'>{error}</span>}
        <ShowCharsLimt
          limit={maxCharsInBody}
          enteredChars={totalCharsInBodyText ? totalCharsInBodyText?.length : 0}
          wrapperClasses='mb-4'
        />
      </div>
    </>
  )
}

export default CustomBody
