import React, { useState } from 'react'

const CustomTextArea = ({ 
  id, 
  value, 
  style, 
  disabled, 
  name, 
  rows, 
  className,
  handleBlur,
  handleQuestDetailsBlur,
  // handleFacultyDescriptionBlur,
}) => {
  const [inputText, setInputText] = useState(value)
  const handleChange = (e) => {
    const newValue = e.target.value
    setInputText(newValue)
  }

  const handleBlurChange = (e) => {
    const { name, value } = e.target
    handleBlur(name, value)
    // if (handleQuestDetailsBlur) {
    //   handleQuestDetailsBlur(name,value)
    // } else if (handleFacultyDescriptionBlur) {
    //   handleFacultyDescriptionBlur(e)
    // }
  }
  return (
    <textarea
      id={id}
      value={inputText}
      style={style}
      disabled={disabled}
      name={name}
      rows={rows}
      className={className}
      onBlur={handleBlurChange}
      onChange={handleChange}
    />
  )
}

export default CustomTextArea