import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import {
  ABSpinner,
  useErrorService,
  useNotificationService,
  Button,
  plans,
  sows,
} from '@abroad/components'
import SessionsTable from './SessionsTable'
import API from '../../utils/API'
import { messagesConstant } from '../../constants/messages'
import Labels from '../../constants/labels'
const { coachingPlanStatusConst } = Labels

const ClientSessions = ({
  userPlan,
  isUserPlanExpired,
  isShowActiveSessions,
}) => {
  const [sessions, setSessions] = useState([])
  const [sessionId, setSessionId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [filterSessions, setFilterSessions] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const history = useHistory()
  const { clientId } = useParams()
  const b2bGroupPlanName = sows.prefix.b2bGroup
  const normalGroupPlanName = sows.prefix.normalGroup

  const getAPIFunction = () => {
    if (isShowActiveSessions) {
      return API.CoachAPI.coachingSession.getSessionList(clientId)
    } else {
      return API.CoachAPI.coachingSession.getArchivedSessionList(clientId)
    }
  }

  useEffect(() => {
    const fetchSessionList = async () => {
      setIsLoading(true)
      try {
        const { data } = await getAPIFunction()
        if (data) {
          const individualSOWs = data?.indiSOWs
            ? data?.indiSOWs?.map((individualSOW) => ({
                ...individualSOW,
                planName: `${sows.prefix.individual}${individualSOW?.planName}`,
                type: sows.planType.individual,
              }))
            : []
          const b2bSOWs = data?.b2bSOWs
            ? data?.b2bSOWs?.map((b2bSOW) => ({
                ...b2bSOW,
                planName: `${sows.prefix.b2b}${b2bSOW?.planName}`,
                type: sows.planType.b2b,
              }))
            : []
          const b2bGroups = [
            {
              planName: b2bGroupPlanName,
              sessions: data?.b2bGroupSessions || [],
            },
          ]
          const normalGroups = [
            {
              planName: normalGroupPlanName,
              sessions: data?.normalGroupSessions || [],
            },
          ]
          setSessions([
            ...individualSOWs,
            ...b2bSOWs,
            ...normalGroups,
            ...b2bGroups,
          ])
        }
      } catch (e) {
        Error.showError(e)
      }
      setIsLoading(false)
    }
    fetchSessionList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowActiveSessions, clientId])

  useEffect(() => {
    if (filterSessions && sessionId) {
      const filteredData = sessions.map((session) => ({
        ...session,
        sessions: session.sessions.filter(
          (session) => session?._id !== sessionId,
        ),
      }))
      setSessions(filteredData)
      setSessionId(null)
      setFilterSessions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSessions, sessionId])

  const renderAddSessionBtn = (status, className) => {
    if (
      [
        coachingPlanStatusConst.InProgress,
        coachingPlanStatusConst.Draft,
      ].includes(status)
    ) {
      return (
        <Button
          variant='gray'
          size='md'
          className={className}
          onClick={() => {
            if (userPlan === plans.free.code) {
              Notification.showNotification(
                messagesConstant?.upgradeErrorMsg,
                'danger',
              )
            } else if (isUserPlanExpired) {
              Notification.showNotification(
                messagesConstant?.planExpiredErrorMsg,
                'danger',
              )
            } else {
              history.push(`/coach/clients/${clientId}/session`)
            }
          }}>
          Add
        </Button>
      )
    } else return null
  }

  if (isLoading) {
    return <ABSpinner />
  }

  if (sessions?.length === 0 && !isLoading && !filterSessions) {
    return (
      <div className='d-flex flex-column'>
        <span className='s5 my-3'>No Sessions.</span>
      </div>
    )
  }
  return (
    <section className='my-3 client-sessions'>
      {!isShowActiveSessions && !isLoading && (
        <h3 className='mb-4 s2'>Archived Sessions</h3>
      )}
      <Container fluid className='px-lg-0 pt-2'>
        <Row>
          <Col>
            {sessions.map((session, index) => {
              return (
                <React.Fragment key={index}>
                  <h3 className='s3 mb-4'>
                    {session?.planName === b2bGroupPlanName
                      ? b2bGroupPlanName
                      : session?.planName === normalGroupPlanName
                      ? normalGroupPlanName
                      : session?.planName}
                  </h3>
                  <div className='mb-2-5'>
                    <SessionsTable
                      data={session?.sessions?.sort(function (a, b) {
                        return new Date(a?.date) - new Date(b?.date)
                      })}
                      sessionId={sessionId}
                      setSessionId={setSessionId}
                      setFilterSessions={setFilterSessions}
                      type={session?.type}
                    />
                    {session?.isCoachOfCurrentPlan && (
                      <React.Fragment>
                        {session?.planName !== normalGroupPlanName &&
                          session?.planName !== b2bGroupPlanName && (
                            <React.Fragment>
                              {renderAddSessionBtn(
                                session.status,
                                'mt-2-5 line-height-normal border-radius-3',
                              )}
                            </React.Fragment>
                          )}
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )
            })}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ClientSessions
