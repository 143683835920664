import React from 'react'

const CircleToTimeSelection = ({ value, fromTime, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  const normalizeTime = (time) => {
    const [timePart, period] = time.split(' ')
    const [hour, minute] = timePart.split(':')
    let hourInt = parseInt(hour, 10)
    if (period === 'PM' && hourInt !== 12) {
      hourInt += 12
    } else if (period === 'AM' && hourInt === 12) {
      hourInt = 0
    }

    return `${hourInt.toString().padStart(2, '0')}:${minute}`
  }

  const timeIsBefore = (time1, time2) => {
    return normalizeTime(time1) <= normalizeTime(time2)
  }

  const renderTimeOptions = () => {
    const hoursOptions = Array.from({ length: 12 }, (_, i) => i)
    const minutesOptions = ['00', '15', '30', '45']
    const timeOptions = []

    const formatTime = (hour, minute, period) => {
      const formattedHour = hour === 12 ? 12 : hour % 12 || 12
      return `${formattedHour.toString().padStart(2, '0')}:${minute} ${period}`
    }

    const generateTimes = (period) => {
      return hoursOptions.forEach((hour) =>
        minutesOptions.forEach((minute) => {
          const timeString = formatTime(hour, minute, period)
          const disabled = fromTime && timeIsBefore(timeString, fromTime)
          timeOptions.push(
            <option key={timeString} value={timeString} disabled={disabled}>
              {timeString}
            </option>,
          )
        }),
      )
    }

    generateTimes('AM')
    generateTimes('PM')

    return timeOptions
  }

  return (
    <select
      className='selection-field user-input'
      value={value}
      onChange={handleChange}>
      <option value='' disabled>
        To
      </option>
      {renderTimeOptions()}
    </select>
  )
}

export default CircleToTimeSelection
