import React from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useUserService } from '@abroad/components'
import { Table } from 'react-bootstrap'
import { formatDate } from '../../utils/date'

const GrowthSessionsTable = ({ growthSOWData }) => {
  const history = useHistory()
  const { clientId } = useParams()
  const { user } = useUserService()
  const isUserComeFromMyClients = history?.location?.pathname.includes('coach')

  return (
    <>
      <Table hover responsive className='custom-table mb-0 mt-3'>
        <thead>
          <tr>
            <th width='25%'>Coach</th>
            <th width='25%'>Session</th>
            <th width='25%'>Date</th>
            <th width='25%'>Action</th>
          </tr>
        </thead>
        <tbody>
          {growthSOWData.map((item) => {
              const growthPlanCallUrl = isUserComeFromMyClients
              ? `/coach/clients/${clientId}/growth-plan/${item?._id}/create`
              : `/admin/users/${clientId}/growth-plan/${item?._id}/create`
            return (
              <tr key={item._id}>
              <td width='25%'>
                {user?.isAdmin ? (
                  <Link
                    to={`/admin/coaches/${item?.coachId?._id}`}
                    className='text-dark-900'>
                    {item?.coachId?.fullName}
                  </Link>
                ) : (
                  <>{item?.coachId?.fullName}</>
                )}
              </td>
              <td width='25%'>{item?.planName}</td>
              <td width='25%'>
                {item.session?.date
                  ? formatDate(item.session.date)
                  : '-'}
              </td>
              <td width='25%'>
                {item.coachId?._id === user?._id && !item.session ? (
                  <Link
                  to={{
                    pathname: `${growthPlanCallUrl}`,
                    state: {
                      sowId: item._id,
                    },
                  }}>
                  Add Session
                </Link>
                ) : item.coachId?._id !== user?._id && !item.session ? (
                  'Session Not Taken yet'
                ) : (
                  <Link
                    to={{
                      pathname: `${
                        isUserComeFromMyClients
                          ? '/coach/clients/'
                          : '/admin/users/'
                      }${clientId}/growth-plan/${item?._id}/${item.session._id}/view`,
                      state: {
                        isEditable: item?.coachId?._id === user?._id,
                      },
                    }}>
                    View Session
                  </Link>
                )}
              </td>
            </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default GrowthSessionsTable
