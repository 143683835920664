import React, { useState, useRef } from 'react'
import axios from 'axios'
import Imgix from 'react-imgix'
import { Row, Col, Form as RBForm } from 'react-bootstrap'
import { Button, useResolutionService } from '@abroad/components'
import API from '../../utils/API'
import { CustomTextArea, QuestInputField } from '../form'

const QuestFaculty = ({ questId, faculty, setFaculty, handleBlur }) => {
  const imageRef = useRef(null)
  const imageRefs = useRef([])
  const { isMDScreen } = useResolutionService()
  const [uploadingIndex, setUploadingIndex] = useState(null)
  const addNewRow = () => {
    const newRowData = {
      name: '',
      expertise: '',
      description: '',
      imageFileName: '',
    }
    setFaculty((prevData) => [...prevData, newRowData])
  }
  const handleFacultyBlur = (index, fieldName, fieldValue) => {
    setFaculty((prevFaculty) => {
      const updatedFaculty = [...prevFaculty]
      updatedFaculty[index] = {
        ...updatedFaculty[index],
        [fieldName]: fieldValue,
      }
      return updatedFaculty
    })
  }

  const handleFileClick = (index) => {
    if (imageRefs.current[index] && questId) {
      imageRefs.current[index].click()
    } else {
      Notification.showNotification('Title is Required', 'danger')
    }
  }
  const handleFacultyFileChange = (index, e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      uploadFacultyImage(index, imageFile)
    }
  }
  const uploadImage = async (imageFile) => {
    try {
      const contentType = imageFile.name
        .substr(imageFile.name.lastIndexOf('.') + 1)
        .toUpperCase()

      if (!questId) {
        Notification.showNotification('Title is Required', 'danger')
        return
      }

      const { data } = await API.AdminAPI.quests.getQuestImage(
        questId,
        contentType,
      )
      if (data) {
        await axios.put(data.url.url, imageFile, {
          headers: {
            'Content-Type': data.url.contentType,
          },
        })

        return data.fileName
      }
    } catch (e) {
      imageRef.current.value = ''
      Error.showError(e)
    }
  }
  const uploadFacultyImage = async (index, imageFile) => {
    try {
      setUploadingIndex(index)

      const fileName = await uploadImage(imageFile)

      setFaculty((prevFaculty) => {
        const updatedFaculty = [...prevFaculty]
        updatedFaculty[index].imageFileName = fileName
        // handleBlur('facultyImage', fileName,null, index, null, null, null)

        return updatedFaculty
      })
    } catch (e) {
    } finally {
      setTimeout(() => {
        setUploadingIndex(null)
      }, 850)
    }
  }

  return (
    <>
      <h3 className='s3 mt-4'>Faculty</h3>
      <div>
        {faculty.map((row, index) => (
          <div key={index}>
            <Row className='d-flex mt-4'>
              <Col lg={6}>
                <div>
                  <label className='s8'>NAME</label>
                  <QuestInputField
                    id={`name-${index}`}
                    type='text'
                    name='name'
                    value={row.name}
                    className='w-100 sa2 selection-field'
                    handleBlur={(name, value) =>
                      handleFacultyBlur(index, name, value)
                    }
                  />
                </div>
                <div>
                  <label className='s8'>EXPERTISE</label>
                  <QuestInputField
                    id={`expertise-${index}`}
                    type='text'
                    name='expertise'
                    value={row.expertise}
                    className='w-100 sa2 selection-field'
                    handleBlur={(name, value) =>
                      handleFacultyBlur(index, name, value)
                    }
                  />
                </div>
                <div className='mt-3 cursor-pointer'>
                  <div
                    className='mt-3 cursor-pointer'
                    onClick={() => handleFileClick(index)}>
                    <label className='s8'>HEADER IMAGE(Click to update)</label>
                    <div>
                      {uploadingIndex === index && (
                        <div
                          className={`text-saffron-700 uploading-text-quest-faculty`}>
                          Uploading...
                        </div>
                      )}
                      {row.imageFileName ? (
                        <Imgix
                          imgixParams={{
                            fit: 'crop',
                            crop: 'faces',
                            ar: isMDScreen ? '2:1' : '5:2',
                          }}
                          src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${row.imageFileName}`}
                          attributeConfig={{
                            src: 'data-src',
                            srcSet: 'data-srcset',
                            sizes: 'data-sizes',
                          }}
                          alt='image'
                          htmlAttributes={{
                            src: `${
                              process.env.REACT_APP_IMG_SOURCE
                            }/quest/${questId}/${row.imageFileName}?ar=${
                              isMDScreen ? '2:1' : '5:2'
                            }&fit=crop&crop=faces&fill=blur&blur=300&px=16&auto=format`,
                          }}
                          className={`border-radius-10 w-100 questImage-container lazyload ${
                            uploadingIndex === index ? 'itenaryImage' : ''
                          }`}
                        />
                      ) : (
                        <Imgix
                          imgixParams={{
                            fit: 'crop',
                            crop: 'format',
                            ar: isMDScreen ? '2:1' : '5:2',
                          }}
                          src={`${process.env.REACT_APP_IMG_SOURCE}/quest-placeholder.png`}
                          width={'100%'}
                          alt='image'
                          htmlAttributes={{
                            src: `${
                              process.env.REACT_APP_IMG_SOURCE
                            }/quest-placeholder.png?ar=${
                              isMDScreen ? '2:1' : '5:2'
                            }&fit=crop&crop=format&fill=blur&blur=300&px=16&auto=format`,
                          }}
                          className={`border-radius-10 w-100 lazyload`}
                        />
                      )}
                      <RBForm.Control
                        type='file'
                        accept='image/jpg, image/jpeg, image/png'
                        style={{ display: 'none' }}
                        onBlur={handleBlur}
                        onChange={(e) => handleFacultyFileChange(index, e)}
                        ref={(el) => (imageRefs.current[index] = el)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} className='d-flex flex-column'>
                <label
                  htmlFor={`description-${index}`}
                  className='s8 mt-lg-0 mt-md-2 mt-2'>
                  DESCRIPTION
                </label>
                <CustomTextArea
                  id={`description-${index}`}
                  style={{ resize: 'none' }}
                  className='w-100 selection-field flex-grow-1 mt-1'
                  value={row.description}
                  rows={7}
                  name='description'
                  handleBlur={(name, value) =>
                    handleFacultyBlur(index, name, value)
                  }
                />
              </Col>
            </Row>
          </div>
        ))}

        <Button
          variant='gray'
          className='px-4 py-3 abroad-blue mt-3'
          type='button'
          onClick={addNewRow}>
          Add New Faculty
        </Button>
      </div>
    </>
  )
}

export default QuestFaculty
