import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Form as RBForm } from 'react-bootstrap'
import { useErrorService } from '@abroad/components'
import useDebounce from '../common/useDebounce'
import API from '../../utils/API'
import { useParams } from 'react-router-dom'

const QuestBlogs = ({
  blogs = [],
  disabled = false,
  addBlog,
  onBlogUpdate,
  placeholder = 'Search for an audio from the Abroad Library to share alongside Post-Session Practices.',
}) => {
  const Error = useErrorService()
  const { questId } = useParams()
  const [questBlogs, setQuestBlogs] = useState([...blogs, {}])
  const [allBlogs, setAllBlogs] = useState([])
  const [searchData, setSearchData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [currentIndex, setCurrentIndex] = useState()
  const [localSearch, setLocalSearch] = useState()
  const debouncedSearch = useDebounce(localSearch, 1000)

  useEffect(() => {
    const getAllBlogs = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getBlogAll()
        setAllBlogs(data)
      } catch (e) {}
    }
    getAllBlogs()
  }, [])

  useEffect(() => {
    if (localSearch !== undefined) {
      const s = allBlogs.filter(
        (blog) =>
          blog?.title
            ?.toLowerCase()
            ?.includes(debouncedSearch?.toLowerCase()) ||
          blog?.subTitle
            ?.toLowerCase()
            ?.includes(debouncedSearch?.toLowerCase()),
      )
      setSearchData(s)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, allBlogs])

  const handleChange = (i, event) => {
    let values = [...questBlogs]
    values[i] = {
      ...values[i],
      title: event.target.value,
    }
    setCurrentIndex(i)
    setQuestBlogs(values)
  }

  const addClick = async (blog, type) => {
    try {
      await API.AdminAPI.quests.toggleBlogMedia(questId, blog._id, {
        attach: type === 'add' ? true : false,
      })
      if (type === 'add') {
        addBlog(blog)
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const removeClick = (i) => {
    let values = [...questBlogs]
    if (values.length !== 1) {
      addClick(values[i], 'remove')
      values.splice(i, 1)
      setQuestBlogs(values)
      if (onBlogUpdate) {
        onBlogUpdate(values)
      }
    }
  }

  return (
    <>
      {questBlogs.map((el, i) => (
        <div key={i} className='mb-2 position-relative'>
          <RBForm.Control
            type='text'
            placeholder={placeholder}
            onChange={(e) => {
              handleChange(i, e)
              setLocalSearch(e.target.value)
            }}
            onFocus={(e) => {
              setInputFocus(true)
              setCurrentIndex(i)
              setLocalSearch(e.target.value)
            }}
            onBlur={(e) => {
              setTimeout(() => {
                setInputFocus(false)
                setCurrentIndex(null)
                setLocalSearch('')
              }, 200)
            }}
            value={el?.title || ''}
            id='search-media'
            autoComplete='off'
            className={classNames(
              'd-inline user-input black-color-placeholder text-dark-900',
              {
                'w-11/12': !disabled,
              },
            )}
            disabled={el?._id || disabled}
          />
          {searchData?.length > 0 &&
          inputFocus &&
          localSearch !== '' &&
          currentIndex === i ? (
            <ul className='search-media-list p-0'>
              {searchData?.map((blog) => (
                <React.Fragment key={blog._id}>
                  <li
                    onClick={(e) => {
                      let values = [...questBlogs]
                      values[i] = {
                        ...values[i],
                        ...blog,
                      }
                      setQuestBlogs([...values, {}])
                      setCurrentIndex(null)
                      setLocalSearch('')
                      addClick(blog, 'add')
                    }}
                    className='user-input'>
                    <strong>{blog?.title}</strong> / {blog?.subTitle}
                  </li>
                  <hr className='m-0' />
                </React.Fragment>
              ))}
            </ul>
          ) : null}
          {!disabled && el?._id && (
            <div className='w-1/12 text-center d-inline-block'>
              <input
                className='add-client-team-session-btn font-weight-bold'
                type='button'
                value='x'
                onClick={() => removeClick(i)}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default QuestBlogs
