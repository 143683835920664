import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import {
  useErrorService,
  ABSpinner,
  useNotificationService,
  useUserService,
  paymentType as paymentTypeConst,
  paymentStatus as paymentStatusConst,
} from '@abroad/components'
import IndividualSOWForm from './IndividualSOWForm'
import { PageHeader } from '../common'
import Labels from '../../constants/labels'
import API from '../../utils/API'
import { maxCoachingPlans } from '../../utils/coachingPlanHelper'
import { getCoachingPlanObject, isNumber } from '../../utils/utility'

export const formInitialValues = {
  status: 'DRAFT',
  // SOW Details
  sowCoachId: null,
  planName: '',
  sowCopy: [],
  // Billing
  paymentCycle: paymentTypeConst.ONETIME,
  initialFee: '',
  invoiceEvery: '',
  numberOfCoachingCycle: '',
  amount: '',
  // Coaching
  sessionPerCoachingCycle: '',
  paymentStatus: '',
  numberOfMonths: '',
  sessionDuration: '',
  report360: false,
  reportPlan360: '',
  report360hourlyRate: '',
  impactReport: false,
  impactReportPlan: '',
  impactReporthourlyRate: '',
  hourlyRate: '',
  clientTimeZone: '',
  firstSessionDate: null,
  // Extra
  isReadyForPayment: '',
}
const { coachingPlanStatusConst } = Labels

const CoachingPlanDetails = () => {
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [isLoading, setIsLoading] = useState(true)
  const [clientName, setClientName] = useState('')
  const [isUpdatePlanLoading, setIsUpdatePlanLoading] = useState(false)
  const Error = useErrorService()
  const history = useHistory()
  const Notification = useNotificationService()
  let { clientId, planId, coachId } = useParams()
  const { user } = useUserService()
  const [showModal, setShowModal] = useState(false)
  const [coaches, setCoaches] = useState([])
  const [isPlanTerminated, setIsPlanTerminated] = useState(false)

  useEffect(() => {
    const getPlanDetails = async () => {
      try {
        const { data } = await API.AdminAPI.coachPlan.getCoachingPlan(
          clientId,
          planId,
        )
        if (data && data?.plan === null) {
          history.push(`/coach/clients/${clientId}`)
        }
        if (data) {
          const currentCoachDetails = {
            label: data?.plan?.coachId?.fullName,
            value: data?.plan?.coachId?._id,
          }
          setClientName(data?.clientName)
          setCoaches([currentCoachDetails])
          if (data?.plan?.status === Labels.coachingPlanStatus[2].value) {
            setIsPlanTerminated(true)
          }
          const values = getCoachingPlanObject(data)
          setInitialValues((prev) => ({
            ...prev,
            sowCoachId: data?.plan?.coachId?._id,
            paymentUpToDate: data?.plan?.hasPaid ? 'yes' : 'no',
            clientTimeZone: data.plan.timeZone ? data.plan.timeZone : '',
            ...values,
          }))
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const fetchClientProfile = async () => {
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['fullName', 'coachIds'],
          },
        )
        if (data) {
          const currentCoachId = coachId
          const sowCoach = data?.coachIds?.find(
            (coach) => coach?._id === currentCoachId,
          )
          const filterIndividualCoach = data?.coachIds?.filter(
            (coach) => coach?.isIndiCoach,
          )
          setCoaches(
            filterIndividualCoach?.map((coach) => ({
              label: coach?.fullName,
              value: coach?._id,
            })),
          )
          if (sowCoach?.coachingPlans?.length >= maxCoachingPlans) {
            history.push(`/coach/clients/${clientId}`)
          }
          setClientName(data?.fullName)
          setInitialValues((prev) => ({
            ...prev,
            sowCoachId: sowCoach?._id,
          }))
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (planId !== 'create') {
      getPlanDetails()
    } else {
      if (!user?.isAdmin) {
        history.push(`/coach/clients/${clientId}`)
      }
      fetchClientProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  const onSubmit = async (values = null) => {
    try {
      setIsUpdatePlanLoading(true)

      let params = {
        status: values.status,
        paymentStatus: values.paymentStatus,
        // SOW Details
        coachId: values.sowCoachId,
        planName: values.planName,
        // Billing
        paymentCycle: values.paymentCycle,
        initialFee: values.initialFee,
        ...(values.paymentCycle === paymentTypeConst.RECURRING && {
          invoiceEvery: isNumber(values.invoiceEvery)
            ? Number(values.invoiceEvery)
            : '',
          numberOfCoachingCycle: values.numberOfCoachingCycle,
          amount: values.amount,
        }),
        // Coaching
        sessionPerCoachingCycle: values.sessionPerCoachingCycle,
        numberOfMonths: Number(values.numberOfMonths),
        sessionDuration: values.sessionDuration,
        report360: values.report360 ? values.reportPlan360 : null,
        impactReport: values.impactReport ? values.impactReportPlan : null,
        report360hourlyRate: values.report360hourlyRate,
        impactReporthourlyRate: values.impactReporthourlyRate,
        hourlyRate: values.hourlyRate,
        timeZone: values.clientTimeZone,
        firstSessionDate: values?.firstSessionDate,
        // Extra
        ...(values.status === coachingPlanStatusConst.Draft && {
          isReadyForPayment: values.isReadyForPayment,
        }),
      }

      Object.keys(params).forEach((key) =>
        params[key] === '' || params[key] === null || params[key] === undefined
          ? delete params[key]
          : '',
      )

      await apiCall(planId, params, clientId, values)
    } catch (e) {
      setIsUpdatePlanLoading(false)
      Error.showError(e)
    }
  }

  const apiCall = async (planId, params, clientId, formikValues) => {
    if (planId === 'create') {
      const { data } = await API.AdminAPI.coachPlan.createCoachingPlan(
        params,
        clientId,
      )
      setIsUpdatePlanLoading(false)
      Notification.showNotification(data.message, 'success')
      if (data?.coachingPlan?._id) {
        history.replace(
          `/coach/clients/${clientId}/plans/${data?.coachingPlan._id}`,
        )
        setTimeout(() => {
          setInitialValues({
            ...formikValues,
            ...data.coachingPlan,
          })
        }, 1000)
      }
    } else {
      let updateObj = {
        status: params.status,
        paymentStatus: params.paymentStatus,
        timeZone: params.timeZone,
        isReadyForPayment: params.isReadyForPayment,
      }
      if (params.paymentStatus === paymentStatusConst.NO_BILLING_REQUIRED) {
        delete updateObj.isReadyForPayment
      }
      const { data } = await API.AdminAPI.coachPlan.updateCoachPlan(
        updateObj,
        clientId,
        planId,
      )
      setInitialValues({
        ...formikValues,
        ...data.coachingPlan,
      })
      setIsUpdatePlanLoading(false)
      Notification.showNotification(data.message, 'success')
    }
  }

  return (
    <>
      <PageHeader
        titleText={`${clientName}`}
        hasBackArrow={true}
        colClasses='px-lg-0'
        defaultUrl={`/coach/clients/${clientId}`}
      />
      <Container
        className='pt-5 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
        fluid>
        <IndividualSOWForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          viewOnlyField={planId !== 'create'}
          showModal={showModal}
          setShowModal={setShowModal}
          isPlanTerminated={isPlanTerminated}
          isUpdatePlanLoading={isUpdatePlanLoading}
          coaches={coaches}
        />
      </Container>
    </>
  )
}

export default CoachingPlanDetails
