import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form as RBForm, Spinner } from 'react-bootstrap'

const UpsertAttachKeyResources = ({
  searchKeySkill,
  setSearchKeySkill,
  searchKeySkillData,
  isSearchKeySkill,
  inputFocusOfKeySkill,
  setInputFocusOfKeySkill,
  keySkills,
  setKeySkills,
  maxLimit = 10,
  disabled = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState()
  let [prevSelectedAudios, setPrevSelectedAudios] = useState()
  let [localSearch, setLocalSearch] = useState()

  useEffect(() => {
    if (localSearch !== undefined) {
      setSearchKeySkill(localSearch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSearch])

  const handleChange = (i, event, isString, type) => {
    let values = [...keySkills.values]
    if (isString) {
      values[i] = {
        ...values[i],
        title: event.target.value,
      }
    } else {
      values[i] = { ...event, type: type }
    }
    setCurrentIndex(i)
    setKeySkills({ values })
  }

  const addClick = () => {
    if (keySkills.values.length === maxLimit) return
    setKeySkills((prevState) => ({ values: [...prevState.values, {}] }))
  }

  const removeClick = (i) => {
    let values = [...keySkills.values]
    if (values.length !== 1) {
      values.splice(i, 1)
      setKeySkills({ values })
    }
  }

  const handleCheckTitleIsCorrect = (value, index) => {
    let values = [...keySkills.values]
    let combinedData =
      searchKeySkillData && searchKeySkillData?.length > 0
        ? [...searchKeySkillData]
        : []
    if (values[currentIndex]?.programId) {
      if (combinedData.length > 1) {
        values.find((e, index) => index === currentIndex).title =
          prevSelectedAudios[index]?.title
      } else if (value !== combinedData[0]?.title) {
        values.find((e, index) => index === currentIndex).title =
          combinedData[0]?.title
      }
    }
  }

  return (
    <>
      {keySkills.values.map((el, i) => (
        <div key={i} className='mb-2'>
          <RBForm.Control
            type='text'
            placeholder='Search for key resources PDFs to attach with this post-session practice.'
            onChange={(e) => {
              handleChange(i, e, !('programId' in e))
              setLocalSearch(e.target.value)
            }}
            onFocus={(e) => {
              setPrevSelectedAudios([...keySkills.values])
              setInputFocusOfKeySkill(true)
              setCurrentIndex(i)
              setLocalSearch(e.target.value)
            }}
            onBlur={(e) => {
              handleCheckTitleIsCorrect(e.target.value, i)
              setTimeout(() => {
                setInputFocusOfKeySkill(false)
                setCurrentIndex(null)
                setLocalSearch('')
              }, 200)
            }}
            value={el?.title || ''}
            id='search-media'
            autoComplete='off'
            className={classNames(
              'd-inline user-input black-color-placeholder text-dark-900',
              {
                'w-11/12': !disabled,
              },
            )}
            disabled={disabled}
          />
          {searchKeySkillData?.length > 0 &&
          inputFocusOfKeySkill &&
          currentIndex === i ? (
            isSearchKeySkill ? (
              <div className='d-flex justify-content-center align-items-center search-media-list py-5'>
                <Spinner animation='border' />
              </div>
            ) : (
              <ul className='search-media-list p-0'>
                {searchKeySkillData?.map((keySkill) => (
                  <React.Fragment key={keySkill._id}>
                    <li
                      onClick={(e) => {
                        handleChange(i, keySkill, false)
                        setCurrentIndex(null)
                        setLocalSearch('')
                        addClick()
                      }}
                      className='user-input'>
                      <strong>{keySkill?.title}</strong> /{' '}
                      {`${keySkill?.description}`}
                      <br />
                      {/* {`(${keySkill.category.title} - Module ${
                        keySkill?.subCategory.sequence + 1
                      })`} */}
                    </li>
                    <hr className='m-0' />
                  </React.Fragment>
                ))}
              </ul>
            )
          ) : null}
          {!disabled && (
            <div className='w-1/12 text-center d-inline-block'>
              <input
                className='add-client-team-session-btn font-weight-bold'
                type='button'
                value='x'
                onClick={() => removeClick(i)}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default UpsertAttachKeyResources
