import React, { useEffect, useState } from 'react'
import { Col, Row, Table, Form, Container } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  surveyType,
  useErrorService,
  useNotificationService,
  ABSpinner,
} from '@abroad/components'
import TeamCustomDateRange from './TeamCustomDateRange'
import API from '../../utils/API'
import { isDatesOverlap, getQuarter } from '../../utils/utility'
import TeamAssessmentActionButton from './TeamAssessmentActionButton'
import moment from 'moment'

export const TeamAssessment = () => {
  const [assessmentsData, setAssessmentsData] = useState([])
  const [surveyResultData, setSurveyResultData] = useState([])
  const [teamName, setTeamName] = useState('')
  const [selectedSurveys, setSelectedSurveys] = useState([])
  const [isAssessmentLoading, setIsAssessmentLoading] = useState(true)
  const [isSurveyLoading, setIsSurveyLoading] = useState(true)
  const history = useHistory()
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { teamId } = useParams()

  useEffect(() => {
    const getAssessmentQuaterData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getAssessmentQuaterData(
          teamId,
        )
        if (data) {
          setAssessmentsData(data)
          setIsAssessmentLoading(false)
        }
      } catch (e) {
        setIsAssessmentLoading(false)
        Error.showError(e)
      }
    }
    if (setIsAssessmentLoading) {
      getAssessmentQuaterData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getTeamData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(teamId, {
          fields: ['name'],
        })
        if (data) {
          setTeamName(data?.name)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getTeamData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getSurveyResultData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getSurveyResultData(teamId)
        if (data) {
          setSurveyResultData(data)
          setIsSurveyLoading(false)
        }
      } catch (e) {
        setIsSurveyLoading(false)
        Error.showError(e)
      }
    }
    if (setIsSurveyLoading) {
      getSurveyResultData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectSurvey = (survey) => {
    if (
      selectedSurveys.filter((s) => s?.customId === survey.customId).length > 0
    ) {
      setSelectedSurveys((prev) =>
        prev.filter((i) => i.customId !== survey.customId),
      )
    } else {
      setSelectedSurveys((prev) => prev.concat(survey))
    }
  }

  const updateSelectedSurvey = (survey, remove) => {
    if (remove) {
      setSelectedSurveys((prev) =>
        prev.filter((i) => i?.customId !== survey?.customId),
      )
    } else {
      setSelectedSurveys((prev) =>
        prev.map((i) => {
          if (i?.customId === survey?.customId) {
            return { ...i, ...survey }
          }
          return i
        }),
      )
    }
  }

  const compareAssessments = (
    routePath = `/survey/compare/group/${teamId}`,
    surveyType,
  ) => {
    const filteredSelectedSurveys = selectedSurveys.filter(
      (survey) => !survey.year,
    )

    if (filteredSelectedSurveys.length === 0) {
      if (
        moment(selectedSurveys[0].year, 'YYYY')
          .quarter(selectedSurveys[0].quarter)
          .endOf('quarter')
          .valueOf() <
        moment(selectedSurveys[1].year, 'YYYY')
          .quarter(selectedSurveys[1].quarter)
          .startOf('quarter')
          .valueOf()
      ) {
        const params = `?&preDate=${moment(selectedSurveys[0].year, 'YYYY')
          .quarter(selectedSurveys[0].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[0].year, 'YYYY')
          .quarter(selectedSurveys[0].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}&postDate=${moment(
          selectedSurveys[1].year,
          'YYYY',
        )
          .quarter(selectedSurveys[1].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[1].year, 'YYYY')
          .quarter(selectedSurveys[1].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}`
        history.push(`${routePath}${params}&surveyType=${surveyType}`)
      } else {
        const params = `?&preDate=${moment(selectedSurveys[1].year, 'YYYY')
          .quarter(selectedSurveys[1].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[1].year, 'YYYY')
          .quarter(selectedSurveys[1].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}&postDate=${moment(
          selectedSurveys[0].year,
          'YYYY',
        )
          .quarter(selectedSurveys[0].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[0].year, 'YYYY')
          .quarter(selectedSurveys[0].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}`
        history.push(`${routePath}${params}&surveyType=${surveyType}`)
      }
    } else if (filteredSelectedSurveys.length === 1) {
      const quarterSurvey = selectedSurveys.filter((survey) => survey.year)
      const datesOverlap = isDatesOverlap(filteredSelectedSurveys[0], {
        startDate: moment(quarterSurvey[0].year, 'YYYY')
          .quarter(quarterSurvey[0].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD'),
        endDate: moment(quarterSurvey[0].year, 'YYYY')
          .quarter(quarterSurvey[0].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD'),
      })
      if (datesOverlap) {
        Notification.showNotification(
          `Selected time intervals overlap, please select intervals, so that the dates don't overlap.`,
        )
      } else {
        if (
          moment(filteredSelectedSurveys[0].endDate).valueOf() <
          moment(quarterSurvey[0].year, 'YYYY')
            .quarter(quarterSurvey[0].quarter)
            .startOf('quarter')
            .valueOf()
        ) {
          const params = `?&preDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}&postDate=${moment(
            quarterSurvey[0].year,
            'YYYY',
          )
            .quarter(quarterSurvey[0].quarter)
            .startOf('quarter')
            .format('YYYY-MM-DD')} - ${moment(quarterSurvey[0].year, 'YYYY')
            .quarter(quarterSurvey[0].quarter)
            .endOf('quarter')
            .format('YYYY-MM-DD')}`
          history.push(`${routePath}${params}&surveyType=${surveyType}`)
        } else {
          const params = `?&preDate=${moment(quarterSurvey[0].year, 'YYYY')
            .quarter(quarterSurvey[0].quarter)
            .startOf('quarter')
            .format('YYYY-MM-DD')} - ${moment(quarterSurvey[0].year, 'YYYY')
            .quarter(quarterSurvey[0].quarter)
            .endOf('quarter')
            .format('YYYY-MM-DD')}&postDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}`
          history.push(`${routePath}${params}&surveyType=${surveyType}`)
        }
      }
    } else {
      const datesOverlap = isDatesOverlap(
        filteredSelectedSurveys[0],
        filteredSelectedSurveys[1],
      )
      if (datesOverlap) {
        Notification.showNotification(
          `Selected time intervals overlap, please select intervals, so that the dates don't overlap.`,
        )
      } else {
        if (
          moment(filteredSelectedSurveys[0].endDate).valueOf() <
          moment(filteredSelectedSurveys[1].startDate).valueOf()
        ) {
          const params = `?&preDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}&postDate=${moment(
            filteredSelectedSurveys[1].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[1].endDate,
          ).format('YYYY-MM-DD')}`
          history.push(`${routePath}${params}&surveyType=${surveyType}`)
        } else {
          const params = `?&preDate=${moment(
            filteredSelectedSurveys[1].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[1].endDate,
          ).format('YYYY-MM-DD')}&postDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}`
          history.push(`${routePath}${params}&surveyType=${surveyType}`)
        }
      }
    }
  }

  if (isSurveyLoading || isAssessmentLoading) {
    return <ABSpinner />
  }

  return (
    <Container className='mt-5' fluid>
      {assessmentsData &&
      !isSurveyLoading &&
      !isAssessmentLoading &&
      surveyResultData['results']?.self_awareness?.percent !== 0 ? (
        <Row className='d-flex flex-column'>
          <Col className='px-0'>
            {surveyResultData?.awareness?.results && (
              <Link
                to={{
                  pathname: `/survey/group/${teamId}?surveyType=${surveyType.AWARENESS}`,
                  state: { teamName, surveyType: surveyType.AWARENESS },
                }}
                className='text-dark-600 under-line'>
                {`Awareness Result - SA: ${surveyResultData?.awareness?.results?.self_awareness?.percent}%, TC: ${surveyResultData?.awareness?.results?.team_cohesion?.percent}%, VI: ${surveyResultData?.awareness?.results?.vision__impact?.percent}%`}
              </Link>
            )}
            {surveyResultData?.awareness?.results &&
              surveyResultData?.wisdom?.results && <br />}
            {surveyResultData?.wisdom?.results && (
              <Link
                to={{
                  pathname: `/survey/group/${teamId}?surveyType=${surveyType.WISDOM}`,
                  state: { teamName, surveyType: surveyType.WISDOM },
                }}
                className='text-dark-600 under-line'>
                {`Wisdom Result - SU: ${surveyResultData?.wisdom?.results?.self_understanding?.percent}%, WC: ${surveyResultData?.wisdom?.results?.wisdom__connection?.percent}%`}
              </Link>
            )}
          </Col>
          {assessmentsData.length > 0 && (
            <Form id='TeamSurveyForm-quarter' className='my-4 pl-2'>
              <Row>
                <Col className='pl-1'>
                  <Table hover responsive className='custom-table'>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Assessments</th>
                        <th>#&nbsp;Assessments</th>
                        <th>Results</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assessmentsData?.map((data, index) => (
                        <tr key={`${data.year}-${data.quarter}`}>
                          <td className='pb-0'>
                            <Form.Group className='mb-0'>
                              <Form.Check
                                id={`custom-${data.year}-${data.quarter}`}
                                custom={true}
                                onChange={() =>
                                  onSelectSurvey({
                                    ...data,
                                    customId: `custom-${data?.affiliationId}-${data.year}-${data.quarter}`,
                                  })
                                }
                                disabled={data?.surveyCount < 1}
                                type='checkbox'
                                className='cursor-pointer'
                              />
                            </Form.Group>
                          </td>
                          <td>
                            QUARTER - {data.quarter} ({getQuarter(data.quarter)}
                            ) - {data.year}
                          </td>
                          <td>
                            Awareness - {data?.awarenessCount}
                            <br />
                            Wisdom - {data?.wisdomCount}
                          </td>
                          <td>
                            {data?.awarenessCount > 1 && (
                              // eslint-disable
                              <a
                                href={`/survey/group/${teamId}/group_combined_report?quarter=${data.quarter}&year=${data.year}&surveyType=${surveyType.AWARENESS}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-saffron-700'>
                                Awareness Aggregate
                              </a>
                              // eslint-disable
                            )}
                            <br />
                            {data?.wisdomCount > 1 && (
                              // eslint-disable
                              <a
                                href={`/survey/group/${teamId}/group_combined_report?quarter=${data.quarter}&year=${data.year}&surveyType=${surveyType.WISDOM}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-saffron-700'>
                                Wisdom Aggregate
                              </a>
                              // eslint-disable
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td colSpan='3'>
                          <h5 className='mb-0'>Custom Date Range</h5>
                        </td>
                      </tr>
                      <TeamCustomDateRange
                        id={`custom-${assessmentsData[0]?.year}-${assessmentsData[0]?.quarter}-1`}
                        onSelectSurvey={(selectedRow) =>
                          onSelectSurvey(selectedRow)
                        }
                        selectedSurveys={selectedSurveys}
                        updateSelectedSurvey={updateSelectedSurvey}
                        affiliation={assessmentsData[0]}
                      />
                      <TeamCustomDateRange
                        id={`custom-${assessmentsData[1]?.year}-${assessmentsData[1]?.quarter}-2`}
                        onSelectSurvey={(selectedRow) =>
                          onSelectSurvey(selectedRow)
                        }
                        selectedSurveys={selectedSurveys}
                        updateSelectedSurvey={updateSelectedSurvey}
                        affiliation={assessmentsData[0]}
                      />
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Form>
          )}
          <Row>
            <Col className='d-flex flex-column'>
              {assessmentsData.length > 0 && (
                <div className='d-flex'>
                  <TeamAssessmentActionButton
                    compareBtnProps={{
                      onClick: () =>
                        compareAssessments(
                          `/survey/compare/group/${teamId}`,
                          surveyType.AWARENESS,
                        ),
                      disabled: selectedSurveys.length !== 2,
                    }}
                    text='Compare Awareness'
                  />
                  <TeamAssessmentActionButton
                    compareBtnProps={{
                      onClick: () =>
                        compareAssessments(
                          `/survey/compare/group/${teamId}`,
                          surveyType.WISDOM,
                        ),
                      disabled: selectedSurveys.length !== 2,
                    }}
                    text='Compare Wisdom'
                    className='ml-3'
                  />
                </div>
              )}
              {assessmentsData.length > 0 && (
                <div className='d-flex'>
                  <TeamAssessmentActionButton
                    compareBtnProps={{
                      onClick: () =>
                        compareAssessments(
                          `/survey/group-comparison-report-common/${teamId}`,
                          surveyType.AWARENESS,
                        ),
                      disabled: selectedSurveys.length !== 2,
                    }}
                    text='Compare Awareness With Common Users'
                  />
                  <TeamAssessmentActionButton
                    compareBtnProps={{
                      onClick: () =>
                        compareAssessments(
                          `/survey/group-comparison-report-common/${teamId}`,
                          surveyType.WISDOM,
                        ),
                      disabled: selectedSurveys.length !== 2,
                    }}
                    text='Compare Wisdom With Common Users'
                    className='ml-3'
                  />
                </div>
              )}
              <div style={{ marginBottom: '90px' }}></div>
              <div style={{ marginBottom: '90px' }}></div>
            </Col>
          </Row>
        </Row>
      ) : (
        <Table hover responsive className='custom-table'>
          <thead>
            <tr>
              <th></th>
              <th>Assessments</th>
              <th>#&nbsp;Assessments</th>
              <th>Results</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan='6' className='text-center'>
                No Survey Taken.
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </Container>
  )
}
