import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import {
  Button,
  useErrorService,
  FeatherLoader,
  useNotificationService,
} from '@abroad/components'
import AddOrgCoachModel from '../userGrowthPlan/AddOrgCoachModel'
import API from '../../utils/API'
import { DeleteConfirmationPrompt } from '../common'
const OrgCoach = ({ orgEcoId }) => {
  const Error = useErrorService()
  const Notification = useNotificationService()
  const [showCoachModal, setShowCoachModal] = useState(false)
  const [coachId, setCoachId] = useState(null)
  const [coachData, setCoachData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isCoachDelete, setIsCoachDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getCoachList = async () => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.companyList.coachList(orgEcoId)
      if (data) {
        setCoachData(data)
        setIsLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCoachList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteCoach = async () => {
    setIsCoachDelete(true)
    try {
      await API.AdminAPI.companyList.deleteCoach(orgEcoId, coachId)
      setShowModal(false)
      setIsCoachDelete(false)
      setIsLoading(false)
      getCoachList()
      Notification.showNotification(
        'Coach has been unassigned from this organization.',
        'success',
      )
    } catch (e) {
      setShowModal(false)
      setIsCoachDelete(true)
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <main>
        <DeleteConfirmationPrompt
          showModal={showModal}
          setShowModal={setShowModal}
          isDeleteLoading={isCoachDelete}
          handleDelete={handleDeleteCoach}
          message='This can not be reverted. Are you sure you want to delete coach?'
        />
        <section>
          <div className='d-flex justify-content-end'>
            <Button
              variant='saffron'
              size='md'
              className='line-height-normal assign-btn ml-2'
              style={{ height: '2rem' }}
              onClick={() => setShowCoachModal(true)}>
              Assign Coach
            </Button>
            <AddOrgCoachModel
              orgEcoId={orgEcoId}
              showModal={showCoachModal}
              setShowModal={setShowCoachModal}
              onSuccess={getCoachList}
            />
          </div>

          <div>
            <Table responsive hover className='mt-4 custom-table'>
              <thead>
                <tr>
                  <th width='10%'>#</th>
                  <th width='30%'>COACH NAME</th>
                  <th width='30%' className='text-center'>
                    HOURLY RATE
                  </th>
                  <th width='30%' className='text-center'>
                    ACTIONS
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td className='text-center border-0 pt-3' colSpan='5'>
                    <FeatherLoader />
                  </td>
                </tr>
              ) : (
                <>
                  {coachData.length === 0 ? (
                    <tr>
                      <td className='text-center s6 py-3' colSpan='5'>
                        No Coach yet!
                      </td>
                    </tr>
                  ) : (
                    <tbody>
                      {coachData.map((coach, index) => (
                        <tr key={coach.id}>
                          <td>{index + 1}</td>
                          <td>{coach.coachId.fullName}</td>
                          <td className='text-center'>
                            {coach.hourlyRateForGrowthPlan}
                          </td>

                          <td className='text-center'>
                            {' '}
                            {/* eslint-disable */}
                            <span>
                              <a
                                href='javascript:void(0)'
                                onClick={() => {
                                  setCoachId(coach?.coachId._id)
                                  setShowModal(true)
                                }}
                                className='text-red-600 p-0'
                                variant='none'>
                                Delete
                              </a>
                            </span>
                            {/* eslint-enable */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </div>
        </section>
      </main>
    </>
  )
}

export default OrgCoach
