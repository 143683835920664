import React from 'react'
import CategoriesNotes from './CategoriesNotes'
import { Nav, Tab } from 'react-bootstrap'
import { surveyType } from '@abroad/components/src/helper/common'

const ReviewAssessmentResults = ({
  logs,
  sessionLogs,
  saveLogHandler = () => {},
  disabled = false,
  clientSurveyType,
}) => {
  const pages = [
    {
      name:
        clientSurveyType === surveyType.AWARENESS
          ? 'Awareness Assessment'
          : 'Wisdom Assessment',
      type: clientSurveyType === surveyType.AWARENESS ? 'awareness' : 'wisdom',
    },
    {
      name: 'Purpose & Life Alignment',
      type: 'purposeAndLife',
    },
    {
      name:
        clientSurveyType === surveyType.AWARENESS
          ? 'Wisdom Assessment'
          : 'Awareness Assessment',
      type: clientSurveyType === surveyType.AWARENESS ? 'wisdom' : 'awareness',
    },
  ]
  let defaultTab
  if (clientSurveyType && clientSurveyType === surveyType.AWARENESS) {
    defaultTab = 'Awareness Assessment'
  } else if (clientSurveyType && clientSurveyType !== surveyType.AWARENESS) {
    defaultTab = 'Wisdom Assessment'
  }
  return (
    <div>
      <h3 className='mb-point-75 font-open-sans font-22 font-bold review-title'>
        Review Assessment Results (30 minutes)
      </h3>
      <p className='s6 review-description'>
        Starting with the lowest scores, we encourage you to ask the prompt
        questions for each category to uncover the mindsets and behaviors that
        are driving these results and to input key notes. In a typical growth
        plan session, coaches normally get through 3 to 7 categories.
      </p>

      {defaultTab && (
        <Tab.Container defaultActiveKey={defaultTab}>
          <div className='header-tabs nav border-bottom-none growth-tabs'>
            <Nav>
              {pages.map((page, index) => (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={page.name}>{page.name}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <Tab.Content>
            {pages.map((page, index) => (
              <Tab.Pane key={index} eventKey={page.name}>
                <CategoriesNotes
                  logs={logs.logs}
                  sessionLogs={sessionLogs}
                  saveLogHandler={saveLogHandler}
                  disabled={disabled}
                  type={page.type}
                />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      )}
    </div>
  )
}

export default ReviewAssessmentResults
