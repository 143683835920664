import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  Button,
  FeatherLoader,
  useUserService,
  useErrorService,
} from '@abroad/components'
import AddGrowthPlanModal from './AddGrowthPlanModal'
import GrowthPlanSOWTable from './GrowthPlanSOWTable'
import API from '../../utils/API'

const placement = 'bottom'

const ClientGrowthPlan = ({ coaches }) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [growthPlanDetails, setGrowthPlanDetails] = useState({
    isGrowthPlanExists: false,
    isGrowthSessionDone: false,
  })
  const [isAnyAssessmentDone, setIsAnyAssessmentDone] = useState({
    isSurveyTaken: false,
    isWisdomTaken: false,
  })
  const { clientId } = useParams()
  const { user } = useUserService()
  const Error = useErrorService()
  const showAddSessionLink =
    isAnyAssessmentDone?.isSurveyTaken || isAnyAssessmentDone?.isWisdomTaken

  useEffect(() => {
    const getGrowthPlanDetails = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['surveyTaken', 'wisdomTaken'],
          },
        )
        if (data) {
          setGrowthPlanDetails({
            isGrowthPlanExists: data?.isGrowthPlanExists,
            isGrowthSessionDone: data?.isGrowthSessionDone,
          })
          setIsAnyAssessmentDone({
            isSurveyTaken: data?.surveyTaken,
            isWisdomTaken: data?.wisdomTaken,
          })
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getGrowthPlanDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const renderAddGPButton = (btnClasses = '') => {
    if (!user?.isAdmin) return null
    return (
      <>
        <Button
          disabled={coaches?.length === 0}
          variant='outline-dark'
          className={classNames(
            'btn s8 text-uppercase border-radius-3 line-height-normal outline-dark-btn add-gp-btn',
            btnClasses,
          )}
          onClick={() => {
            if (coaches?.length === 0) return
            setShowModal(true)
          }}>
          ADD GROWTH PLAN SOW
        </Button>
      </>
    )
  }

  const overlayButton = () => {
    return (
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip
            id={`tooltip-${placement}`}
            className='font-open-sans font-16 font-semibold leading-7 text-center header-tooltip'>
            {coaches?.length === 0
              ? 'Please assign a coach to take a growth plan'
              : ''}
          </Tooltip>
        }>
        <span className='d-inline-block'>
          {renderAddGPButton('pointer-events-none')}
        </span>
      </OverlayTrigger>
    )
  }

  return (
    <section>
      <div
        className={classNames('d-flex title', {
          'mt-2-5': !user?.isAdmin,
          'mt-4': user?.isAdmin,
        })}>
        <h3 className='s3 mb-0'>Client Growth Plan</h3>
        {isLoading ? (
          <div className='add-gp-btn align-self-center'>
            <FeatherLoader />
          </div>
        ) : (
          <>
            {coaches?.length === 0 ? overlayButton() : renderAddGPButton()}
            {!user?.isAdmin && !growthPlanDetails.isGrowthPlanExists && (
              <p className='s5 add-gp-btn mb-0 align-self-center'>
                There is no growth plan taken yet.
              </p>
            )}
            <AddGrowthPlanModal
              showModal={showModal}
              setShowModal={setShowModal}
              coaches={coaches}
              setGrowthPlanDetails={setGrowthPlanDetails}
            />
          </>
        )}
      </div>
      {growthPlanDetails.isGrowthPlanExists && (
        <GrowthPlanSOWTable
          growthPlanDetails={growthPlanDetails}
          showAddSessionLink={showAddSessionLink}
        />
      )}
    </section>
  )
}

export default ClientGrowthPlan
