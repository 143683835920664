import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { FeatherLoader, useErrorService } from '@abroad/components'
import InquiriesModal from './InquiriesModal'
import API from '../../utils/API'
import { UTCFormatDate } from '../../utils/date'

const QuestsInquiries = () => {
  const { questId } = useParams()
  const Error = useErrorService()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [inquirieDetail, setInquirieDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isShowModalLoading, setIsShowModalLoading] = useState(false)
  const [questInquiries, setQuestInquiries] = useState([])

  const handleAddNewQuestClick = async (userId) => {
    setShowModal(true)
    setIsShowModalLoading(true)
    try {
      const { data } = await API.AdminAPI.quests.getInquirieDetail(
        questId,
        userId,
      )
      setInquirieDetail(data)
      setIsShowModalLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsShowModalLoading(false)
    }
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    const getInquirie = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.quests.getInquirie(questId)
        setQuestInquiries(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getInquirie()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])
  return (
    <>
      <Helmet>
        <title>{`Quest-Inquiries | Abroad`}</title>
        <meta name='title' content={'Quest-Inquiries | Abroad'}></meta>
        <meta property='og:title' content={'Quest-Inquiries | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Quest-Inquiries | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/inquiries`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/inquiries`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/inquiries`}></meta>
      </Helmet>
      <div>
        <h3 className='mt-5 s3'>Users</h3>
        <Table responsive className='custom-table mt-3'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>DATE</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          {isLoading && (
            <tr>
              <td className='text-center border-0 pt-3' colSpan='4'>
                <FeatherLoader />
              </td>
            </tr>
          )}
          {questInquiries.length === 0 && !isLoading && (
            <tr>
              <td className='text-center s6 py-3' colSpan='4'>
                No inquiries for this quest yet.
              </td>
            </tr>
          )}
          <tbody>
            {questInquiries?.length > 0 &&
              !isLoading &&
              questInquiries?.map((inquirie, index) => (
                <tr key={index}>
                  <td className='text-saffron-700'>
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        history.push(`/admin/users/${inquirie?.userId?._id}`)
                      }>
                      {' '}
                      {inquirie?.userId?.fullName}{' '}
                    </span>
                  </td>
                  <td>{inquirie?.userId?.email}</td>
                  <td>{UTCFormatDate(inquirie?.createdAt)}</td>
                  <td
                    className='text-saffron-700 cursor-pointer'
                    onClick={() =>
                      handleAddNewQuestClick(inquirie?.userId?._id)
                    }>
                    View
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <InquiriesModal
          showModal={showModal}
          handleClose={handleCloseModal}
          inquirieDetail={inquirieDetail}
          isLoading={isShowModalLoading}
        />
      </div>
    </>
  )
}

export default QuestsInquiries
