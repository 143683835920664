import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Table, Form } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
  surveyType,
} from '@abroad/components'
import {
  CustomDateRange,
  AssessmentActionBottons,
} from '../components/assessments'
import { DeleteConfirmationPrompt } from '../components/common'
import API from '../utils/API'

export const EcoSystemAssessments = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [surveyId, setSurveyId] = useState(null)
  const [isSurveyDelete, setIsSurveyDelete] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [assessmentsData, setAssessmentsData] = useState([])
  const [selectedSurveys, setSelectedSurveys] = useState([])
  const [ids, setIds] = useState([])
  const [isParentLinkClicked, setIsParentLinkClicked] = useState(true)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { ecoSystemName } = useParams()
  let history = useHistory()

  useEffect(() => {
    const getAssessmentsData = async () => {
      try {
        const { data } =
          await API.AdminAPI.companyList.getEcoSystemAssessmentsData({
            ecosystemName: ecoSystemName,
          })
        if (data) {
          setAssessmentsData(data)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (isLoading) getAssessmentsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const handleDeleteSurvey = async () => {
    setIsSurveyDelete(true)
    try {
      const { data } = await API.AdminAPI.companyList.deleteSurvey(surveyId)
      if (data) {
        setIsSurveyDelete(false)
        setShowModal(false)
        setIsLoading(true)
      }
    } catch (e) {
      setShowModal(false)
      setIsSurveyDelete(false)
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const getQuarter = (quater) => {
    if (quater === 1) {
      return 'JAN - MARCH'
    } else if (quater === 2) {
      return 'APRIL - JUNE'
    } else if (quater === 3) {
      return 'JULY - SEPTEMBER'
    } else {
      return 'OCTOBER - DECEMBER'
    }
  }

  const isDatesOverlap = (rangeOne, rangeTwo) => {
    if (
      moment(rangeTwo.startDate).isBetween(
        rangeOne.startDate,
        rangeOne.endDate,
      ) ||
      moment(rangeTwo.endDate).isBetween(
        rangeOne.startDate,
        rangeOne.endDate,
      ) ||
      moment(rangeOne.startDate).isBetween(
        rangeTwo.startDate,
        rangeTwo.endDate,
      ) ||
      moment(rangeOne.endDate).isBetween(
        rangeTwo.startDate,
        rangeTwo.endDate,
      ) ||
      moment(rangeTwo.startDate).isSame(rangeOne.startDate) ||
      moment(rangeTwo.endDate).isSame(rangeOne.endDate) ||
      moment(rangeTwo.startDate).isSame(rangeOne.endDate) ||
      moment(rangeTwo.endDate).isSame(rangeOne.startDate)
    ) {
      return true
    }
    return false
  }

  const compareAssessments = (
    route = 'network_comparison_report',
    surveyType,
  ) => {
    const filteredSelectedSurveys = selectedSurveys.filter(
      (survey) => !survey.year,
    )

    if (filteredSelectedSurveys.length === 0) {
      if (
        moment(selectedSurveys[0].year, 'YYYY')
          .quarter(selectedSurveys[0].quarter)
          .endOf('quarter')
          .valueOf() <
        moment(selectedSurveys[1].year, 'YYYY')
          .quarter(selectedSurveys[1].quarter)
          .startOf('quarter')
          .valueOf()
      ) {
        const params = `?network=${ecoSystemName}&preDate=${moment(
          selectedSurveys[0].year,
          'YYYY',
        )
          .quarter(selectedSurveys[0].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[0].year, 'YYYY')
          .quarter(selectedSurveys[0].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}&postDate=${moment(
          selectedSurveys[1].year,
          'YYYY',
        )
          .quarter(selectedSurveys[1].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[1].year, 'YYYY')
          .quarter(selectedSurveys[1].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}`
        history.push(`/admin/survey/${route}${params}&surveyType=${surveyType}`)
      } else {
        const params = `?network=${ecoSystemName}&preDate=${moment(
          selectedSurveys[1].year,
          'YYYY',
        )
          .quarter(selectedSurveys[1].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[1].year, 'YYYY')
          .quarter(selectedSurveys[1].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}&postDate=${moment(
          selectedSurveys[0].year,
          'YYYY',
        )
          .quarter(selectedSurveys[0].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD')} - ${moment(selectedSurveys[0].year, 'YYYY')
          .quarter(selectedSurveys[0].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD')}`
        history.push(`/admin/survey/${route}${params}&surveyType=${surveyType}`)
      }
    } else if (filteredSelectedSurveys.length === 1) {
      const quarterSurvey = selectedSurveys.filter((survey) => survey.year)
      const datesOverlap = isDatesOverlap(filteredSelectedSurveys[0], {
        startDate: moment(quarterSurvey[0].year, 'YYYY')
          .quarter(quarterSurvey[0].quarter)
          .startOf('quarter')
          .format('YYYY-MM-DD'),
        endDate: moment(quarterSurvey[0].year, 'YYYY')
          .quarter(quarterSurvey[0].quarter)
          .endOf('quarter')
          .format('YYYY-MM-DD'),
      })
      if (datesOverlap) {
        Notification.showNotification(
          `Selected time intervals overlap, please select intervals, so that the dates don't overlap.`,
        )
      } else {
        if (
          moment(filteredSelectedSurveys[0].endDate).valueOf() <
          moment(quarterSurvey[0].year, 'YYYY')
            .quarter(quarterSurvey[0].quarter)
            .startOf('quarter')
            .valueOf()
        ) {
          const params = `?network=${ecoSystemName}&preDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}&postDate=${moment(
            quarterSurvey[0].year,
            'YYYY',
          )
            .quarter(quarterSurvey[0].quarter)
            .startOf('quarter')
            .format('YYYY-MM-DD')} - ${moment(quarterSurvey[0].year, 'YYYY')
            .quarter(quarterSurvey[0].quarter)
            .endOf('quarter')
            .format('YYYY-MM-DD')}`
          history.push(
            `/admin/survey/${route}${params}&surveyType=${surveyType}`,
          )
        } else {
          const params = `?network=${ecoSystemName}&preDate=${moment(
            quarterSurvey[0].year,
            'YYYY',
          )
            .quarter(quarterSurvey[0].quarter)
            .startOf('quarter')
            .format('YYYY-MM-DD')} - ${moment(quarterSurvey[0].year, 'YYYY')
            .quarter(quarterSurvey[0].quarter)
            .endOf('quarter')
            .format('YYYY-MM-DD')}&postDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}`
          history.push(
            `/admin/survey/${route}${params}&surveyType=${surveyType}`,
          )
        }
      }
    } else {
      const datesOverlap = isDatesOverlap(
        filteredSelectedSurveys[0],
        filteredSelectedSurveys[1],
      )
      if (datesOverlap) {
        Notification.showNotification(
          `Selected time intervals overlap, please select intervals, so that the dates don't overlap.`,
        )
      } else {
        if (
          moment(filteredSelectedSurveys[0].endDate).valueOf() <
          moment(filteredSelectedSurveys[1].startDate).valueOf()
        ) {
          const params = `?network=${ecoSystemName}&preDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}&postDate=${moment(
            filteredSelectedSurveys[1].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[1].endDate,
          ).format('YYYY-MM-DD')}`
          history.push(
            `/admin/survey/${route}${params}&surveyType=${surveyType}`,
          )
        } else {
          const params = `?network=${ecoSystemName}&preDate=${moment(
            filteredSelectedSurveys[1].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[1].endDate,
          ).format('YYYY-MM-DD')}&postDate=${moment(
            filteredSelectedSurveys[0].startDate,
          ).format('YYYY-MM-DD')} - ${moment(
            filteredSelectedSurveys[0].endDate,
          ).format('YYYY-MM-DD')}`
          history.push(
            `/admin/survey/${route}${params}&surveyType=${surveyType}`,
          )
        }
      }
    }
  }

  const onSelectSurvey = (survey) => {
    if (
      selectedSurveys.filter((s) => s?.customId === survey.customId).length > 0
    ) {
      setSelectedSurveys((prev) =>
        prev.filter((i) => i.customId !== survey.customId),
      )
    } else {
      setSelectedSurveys((prev) => prev.concat(survey))
    }
  }

  const updateSelectedSurvey = (survey, remove) => {
    if (remove) {
      setSelectedSurveys((prev) =>
        prev.filter((i) => i?.customId !== survey?.customId),
      )
    } else {
      setSelectedSurveys((prev) =>
        prev.map((i) => {
          if (i?.customId === survey?.customId) {
            return { ...i, ...survey }
          }
          return i
        }),
      )
    }
  }

  const onChange = (id, surveyType) => {
    if (ids?.map((surveyDetails) => surveyDetails?.id).includes(id)) {
      setIds((prev) => prev.filter((i) => i.id !== id))
    } else {
      setIds((prev) => prev.concat({ id, surveyType }))
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${ecoSystemName}'s Assessment | Abroad`}</title>
        <meta
          name='title'
          content={`${ecoSystemName}'s Assessment | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${ecoSystemName}'s Assessment | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${ecoSystemName}'s Assessment | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/network/${ecoSystemName}/survey`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/network/${ecoSystemName}/survey`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/network/${ecoSystemName}/survey`}></meta>
      </Helmet>
      <main>
        <DeleteConfirmationPrompt
          showModal={showModal}
          setShowModal={setShowModal}
          isDeleteLoading={isSurveyDelete}
          handleDelete={handleDeleteSurvey}
          message='This can not be reverted. Are you sure you want to delete this
    survey?'
        />
        <section>
          <Container className='custom-offset-lg-1 custom-col-lg-12 px-0' fluid>
            {assessmentsData[0]?.surveys?.length > 0 && (
              <Row>
                <Col className='mb-2' lg='4' md='12' sm='12'>
                  {/* eslint-disable */}
                  <a
                    href={`/survey/network/view/${ecoSystemName}?surveyType=${surveyType.AWARENESS}`}
                    className='btn btn-gray line-height-normal'
                    rel='noopener noreferrer'
                    target='_blank'>
                    Aggregated Awareness
                  </a>
                  {/* eslint-enable */}
                </Col>
                <Col className='mb-2' lg='4' md='12' sm='12'>
                  {/* eslint-disable */}
                  <a
                    href={`/survey/network/view/${ecoSystemName}?surveyType=${surveyType.WISDOM}`}
                    className='btn btn-gray line-height-normal'
                    rel='noopener noreferrer'
                    target='_blank'>
                    Aggregated Wisdom
                  </a>
                  {/* eslint-enable */}
                </Col>
                <Col className='mb-2 text-lg-right' lg='4' md='12' sm='12'>
                  <Button
                    className={classnames(
                      'btn btn-gray line-height-normal text-uppercase',
                      {
                        'cursor-disabled': ids.length < 2,
                      },
                    )}
                    onClick={() => {
                      ids.length >= 2 &&
                        history.push({
                          pathname: '/admin/survey/users_combined_report',
                          state: {
                            surveyId: ids?.map(
                              (surveyDetails) => surveyDetails?.id,
                            ),
                            ecoSystemName,
                            isNetwork: true,
                          },
                        })
                    }}
                    disabled={
                      ids?.map((surveyDetails) => surveyDetails?.id).length <
                        2 ||
                      !ids
                        .map((survey) => survey?.surveyType)
                        .every((val, i, arr) => val === arr[0])
                    }
                    variant='none'>
                    Aggregated Selected Assessment
                  </Button>
                </Col>
              </Row>
            )}
            <Row className='my-3'>
              {assessmentsData[0]?.surveys?.length === 0 && (
                <Table hover responsive className='custom-table'>
                  <thead>
                    <tr>
                      <th width='5%'></th>
                      <th width='5%'>#</th>
                      <th width='18%'>Full Name</th>
                      <th width='25%'>Email</th>
                      <th width='20%'>Assessment</th>
                      <th width='17%'>Submitted At</th>
                      <th width='10%'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan='7' className='text-center'>
                        No Survey Taken.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
              {assessmentsData[0]?.surveys?.map((data, index) => (
                <Col key={index} lg={12} md={12} sm={12}>
                  QUARTER - {data.quarter} ({getQuarter(data.quarter)}) -{' '}
                  {data.year}
                  {data?.awarenessCount > 0 && (
                    // eslint-disable
                    <a
                      href={`/survey/network/${ecoSystemName}?quarter=${data.quarter}&year=${data.year}&surveyType=${surveyType.AWARENESS}`}
                      className='mx-2 text-dark-600 underline-link'
                      rel='noopener noreferrer'
                      target='_blank'>
                      Awareness
                    </a>
                    // eslint-enable
                  )}{' '}
                  {data?.wisdomCount > 0 && (
                    // eslint-disable
                    <a
                      href={`/survey/network/${ecoSystemName}?quarter=${data.quarter}&year=${data.year}&surveyType=${surveyType.WISDOM}`}
                      className='text-dark-600 underline-link'
                      rel='noopener noreferrer'
                      target='_blank'>
                      Wisdom
                    </a>
                    // eslint-enable
                  )}
                  <Form
                    id={`EcoSystemSurveyForm-${index}-${Math.random()}`}
                    className='my-4'>
                    <Table hover responsive className='custom-table'>
                      <thead>
                        <tr>
                          <th width='5%'></th>
                          <th width='5%'>#</th>
                          <th width='18%'>Full Name</th>
                          <th width='25%'>Email</th>
                          <th width='20%'>Assessment</th>
                          <th width='17%'>Submitted At</th>
                          <th width='10%'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.surveyList?.map((survey, index) => (
                          <tr
                            key={survey.id}
                            onClick={() => {
                              if (isParentLinkClicked) {
                                window.open(
                                  `/${
                                    survey?.surveyType === surveyType?.AWARENESS
                                      ? 'survey'
                                      : 'wisdom'
                                  }/${survey.id}/clients/${survey.userId}`,
                                )
                              }
                            }}
                            className='pointer-cursor'>
                            <td width='5%'>
                              <span
                                onMouseOver={() =>
                                  setIsParentLinkClicked(false)
                                }
                                onMouseOut={() => setIsParentLinkClicked(true)}>
                                <Form.Group className='mb-0'>
                                  <Form.Check
                                    id={`custom-${survey.id}-${Math.random()}`}
                                    custom={true}
                                    onChange={() =>
                                      onChange(survey.id, survey?.surveyType)
                                    }
                                    type='checkbox'
                                    className='cursor-pointer'
                                  />
                                </Form.Group>
                              </span>
                            </td>
                            <td width='5%'>{index + 1}</td>
                            <td width='18%' className='word-breakall'>
                              {survey?.name}
                            </td>
                            <td width='25%' className='word-breakall'>
                              {survey.email}
                            </td>
                            <td width='20%'>
                              {survey?.surveyType === surveyType?.AWARENESS
                                ? 'Awareness'
                                : 'Wisdom'}{' '}
                              Assessment
                            </td>
                            <td width='17%'>
                              {moment(survey.submittedAt).format('MM/DD/YYYY')}
                            </td>
                            <td width='10%'>
                              {/* eslint-disable */}
                              <span>
                                <a
                                  href='javascript:void(0)'
                                  onClick={() => {
                                    setSurveyId(survey?.id)
                                    setShowModal(true)
                                  }}
                                  onMouseOver={() =>
                                    setIsParentLinkClicked(false)
                                  }
                                  onMouseOut={() =>
                                    setIsParentLinkClicked(true)
                                  }
                                  className='text-red-600 p-0'
                                  variant='none'>
                                  Delete
                                </a>
                              </span>
                              {/* eslint-enable */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Form>
                </Col>
              ))}
            </Row>
            {assessmentsData[0]?.surveys?.length > 0 && (
              <Row className='my-3'>
                <Col>
                  <Form
                    id={`EcoSystemSurveyForm-quarter-${Math.random()}`}
                    className='my-4'>
                    <Table hover responsive className='custom-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Assessments</th>
                          <th>#&nbsp;Assessments</th>
                          <th>Results</th>
                        </tr>
                      </thead>
                      <tbody>
                        {assessmentsData[0]?.surveys?.map((data, index) => (
                          <tr key={`${data.year}-${data.quarter}`}>
                            <td className='pb-0'>
                              <Form.Group className='mb-0'>
                                <Form.Check
                                  id={`custom-${data.year}-${
                                    data.quarter
                                  }-${Math.random()}`}
                                  custom={true}
                                  onChange={() =>
                                    onSelectSurvey({
                                      ...data,
                                      customId: `custom-${data?.affiliationId}-${data.year}-${data.quarter}`,
                                    })
                                  }
                                  disabled={data?.surveyCount < 1}
                                  type='checkbox'
                                  className='cursor-pointer'
                                />
                              </Form.Group>
                            </td>
                            <td>
                              QUARTER - {data.quarter} (
                              {getQuarter(data.quarter)}) - {data.year}
                            </td>
                            <td>
                              Awareness - {data?.awarenessCount}
                              <br />
                              Wisdom - {data?.wisdomCount}
                            </td>
                            <td>
                              {data?.awarenessCount > 1 && (
                                // eslint-disable
                                <a
                                  href={`/survey/network/${ecoSystemName}?quarter=${data.quarter}&year=${data.year}&surveyType=${surveyType.AWARENESS}`}
                                  className='text-saffron-700'
                                  rel='noopener noreferrer'
                                  target='_blank'>
                                  Awareness Aggregate
                                </a>
                                // eslint-enable
                              )}
                              <br />
                              {data?.wisdomCount > 1 && (
                                // eslint-disable
                                <a
                                  href={`/survey/network/${ecoSystemName}?quarter=${data.quarter}&year=${data.year}&surveyType=${surveyType.WISDOM}`}
                                  className='text-saffron-700'
                                  rel='noopener noreferrer'
                                  target='_blank'>
                                  Wisdom Aggregate
                                </a>
                                // eslint-enable
                              )}
                            </td>
                          </tr>
                        ))}
                        {assessmentsData?.length > 0 && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan='3'>
                                <h5 className='mb-0'>Custom Date Range</h5>
                              </td>
                            </tr>
                            <CustomDateRange
                              id={`custom-${assessmentsData[0]?.surveys[0]?.year}-${assessmentsData[0]?.surveys[0]?.quarter}-1}`}
                              onSelectSurvey={(selectedRow) =>
                                onSelectSurvey(selectedRow)
                              }
                              selectedSurveys={selectedSurveys}
                              updateSelectedSurvey={updateSelectedSurvey}
                              affiliation={assessmentsData[0]}
                            />
                            <CustomDateRange
                              id={`custom-${assessmentsData[1]?.surveys[1]?.year}-${assessmentsData[1]?.surveys[1]?.quarter}-2}`}
                              onSelectSurvey={(selectedRow) =>
                                onSelectSurvey(selectedRow)
                              }
                              selectedSurveys={selectedSurveys}
                              updateSelectedSurvey={updateSelectedSurvey}
                              affiliation={assessmentsData[0]}
                            />
                          </>
                        )}
                      </tbody>
                    </Table>
                  </Form>
                </Col>
              </Row>
            )}
            <Row>
              <Col className='d-flex flex-column'>
                {assessmentsData[0]?.surveys?.length > 0 && (
                  <div className='d-flex'>
                    <AssessmentActionBottons
                      compareBtnProps={{
                        onClick: () =>
                          compareAssessments(
                            'network_comparison_report',
                            surveyType.AWARENESS,
                          ),
                        disabled: selectedSurveys.length !== 2,
                      }}
                      text='Compare Awareness'
                    />
                    <AssessmentActionBottons
                      compareBtnProps={{
                        onClick: () =>
                          compareAssessments(
                            'network_comparison_report',
                            surveyType.WISDOM,
                          ),
                        disabled: selectedSurveys.length !== 2,
                      }}
                      text='Compare Wisdom'
                      className='ml-3'
                    />
                  </div>
                )}
                {assessmentsData[0]?.surveys?.length > 0 && (
                  <div className='d-flex'>
                    <AssessmentActionBottons
                      compareBtnProps={{
                        onClick: () =>
                          compareAssessments(
                            'network_user_comparison_report_common',
                            surveyType.AWARENESS,
                          ),
                        disabled: selectedSurveys.length !== 2,
                      }}
                      text='Compare Awareness With Common Users'
                    />
                    <AssessmentActionBottons
                      className='ml-3'
                      compareBtnProps={{
                        onClick: () =>
                          compareAssessments(
                            'network_user_comparison_report_common',
                            surveyType.WISDOM,
                          ),
                        disabled: selectedSurveys.length !== 2,
                      }}
                      text='Compare Wisdom With Common Users'
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}
