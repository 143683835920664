import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { bool, boolean, object, string } from 'yup'
import axios from 'axios'
import { ABSpinner, Button, useErrorService, plans } from '@abroad/components'
import {
  CopyInviteLink,
  CustomHeader,
  CustomBody,
  PreviewInviteLink,
  PromoSelectionField,
  SelectButtonFontColor,
  UploadLogo,
  TargetPlanDropdown,
} from '../components'
import API from '../utils/API'
import labels from '../constants/labels'

const freePlan = plans.free.code

const signupTitle = 'Your Journey Begins with Awareness'
const signupSubTitle =
  'Join thousands of leaders and their teams who have taken our awareness assessment to uncover hidden opportunities for growth.'

const initialState = {
  plan: freePlan,
  header: null,
  body: null,
  buttonColorHex: '#bf9000',
  fontBlackColorHex: false,
  fontWhiteColorHex: true,
  logoFileName: null,
  includePromocode: false,
  promo: '',
}

const maxCharsInHeader = 40
const maxCharsInBody = 450
const minCharsInHeader = 3
const minCharsInBody = 3

const InviteOrgEcoLink = ({ companyAndEcoInfo }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSaveBtn, setShowSaveBtn] = useState(true)
  const [initialValues, setIntialValues] = useState(initialState)
  const [hasInvitePlan, setHasInvitePlan] = useState(false)
  const [logo, setLogo] = useState({
    isLogoUploaded: false,
    showLogo: false,
    logoDetails: null,
    fileName: null,
    newFileName: null,
  })

  const { companyName, ecoSystemName } = useParams()
  const Error = useErrorService()
  const title = `${companyName ? companyName : ecoSystemName}'s Invite Link`
  const url = companyName
    ? `organization/${companyName}`
    : `network/${ecoSystemName}`

  const validationSchema = object().shape({
    plan: string().required(),
    header: string().min(minCharsInHeader).max(maxCharsInHeader).nullable(),
    body: string().min(minCharsInBody).max(maxCharsInBody).nullable(),
    buttonColorHex: string().required(),
    fontWhiteColorHex: bool(),
    fontBlackColorHex: bool(),
    logoFileName: string().nullable(),
    includePromocode: boolean(),
  })

  useEffect(() => {
    const getInviteLinkInfo = async () => {
      try {
        const { data } = await API.AdminAPI.common.getInviteLinkInfo(
          companyName ? 'company' : 'affiliate',
          companyAndEcoInfo?._id,
        )
        const inviteOnlyPlan = labels?.plans?.find(
          (plan) => plan?.value === companyAndEcoInfo?.planCode,
        )
        if (data) {
          if (data?.header) setShowSaveBtn(false)
          if (data?.logoFileName) {
            setLogo((prev) => ({
              ...prev,
              isLogoUploaded: true,
              fileName: data?.logoFileName,
            }))
          }
          setHasInvitePlan(
            companyAndEcoInfo?.planCode !== null &&
              companyAndEcoInfo?.planCode !== undefined,
          )
          setIntialValues({
            ...data,
            header: data?.header ? data?.header : null,
            body: data?.body ? data?.body : null,
            buttonColorHex: data?.buttonColorHex
              ? data?.buttonColorHex
              : '#bf9000',
            fontBlackColorHex: data?.fontColorHex === '#000',
            fontWhiteColorHex: data?.fontColorHex
              ? data?.fontColorHex === '#fff'
              : true,
            plan:
              companyAndEcoInfo?.planCode !== null &&
              companyAndEcoInfo?.planCode !== undefined
                ? inviteOnlyPlan?.value
                : freePlan,
          })
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getInviteLinkInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadLogoImage = async () => {
    if (logo?.logoDetails === null) return true
    const imageFile = logo?.logoDetails
    try {
      const contentType = imageFile.name
        .substr(imageFile.name.lastIndexOf('.') + 1)
        .toUpperCase()

      const { data } = await API.AdminAPI.common.getInviteLinkLogoFileURL(
        companyName ? 'company' : 'affiliate',
        companyAndEcoInfo?._id,
        contentType,
      )
      if (data) {
        let uploadImage = await axios.put(data.url.url, imageFile, {
          headers: {
            'Content-Type': data.url.contentType,
          },
        })
        if (uploadImage) {
          setLogo({
            isLogoUploaded: true,
            showLogo: true,
            logoDetails: null,
            fileName: data.fileName,
            newFileName: null,
          })
          return { fileName: data.fileName }
        }
      }
    } catch (e) {
      Error.showError(e)
      return false
    }
  }

  const onSubmit = async (values) => {
    setIsSubmitting(true)
    const status = await uploadLogoImage()
    const reqData = {
      header: values.header ? values.header : null,
      body: values.body ? values.body : null,
      buttonColorHex: values.buttonColorHex,
      fontColorHex: values.fontBlackColorHex ? '#000' : '#fff',
      ...((status?.fileName || logo?.fileName) && {
        logoFileName: status?.fileName ? status?.fileName : logo?.fileName,
      }),
    }
    if (status) {
      try {
        const { status } = await API.AdminAPI.common.updateInviteDetails(
          companyName ? 'company' : 'affiliate',
          companyAndEcoInfo?._id,
          reqData,
        )
        if (status) {
          setIsSubmitting(false)
          setShowSaveBtn(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsSubmitting(false)
      }
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${title} | Abroad`}</title>
        <meta name='title' content={`${title} | Abroad`}></meta>
        <meta property='og:title' content={`${title} | Abroad`}></meta>
        <meta property='twitter:title' content={`${title} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/${url}/invite-link`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/${url}/invite-link`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/${url}/invite-link`}></meta>
      </Helmet>
      <main>
        <section className='invite-link'>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, isValid }) => {
              return (
                <section className='profile px-0'>
                  <div className='d-flex'>
                    <PromoSelectionField
                      hasInvitePlan={hasInvitePlan}
                      isLoading={isLoading}
                      targetPlan={companyAndEcoInfo?.targetPlan}
                    />
                    {companyAndEcoInfo?.targetPlan && (
                      <TargetPlanDropdown
                        wrapperClasses='d-flex'
                        formGroupClasses='px-3'
                        selectionClasses='plan-dropdown'
                        disabled={true}
                      />
                    )}
                  </div>
                  <CustomHeader
                    maxCharsInHeader={maxCharsInHeader}
                    minCharsInHeader={minCharsInHeader}
                    disabled={isSubmitting}
                    placeholder={signupTitle}
                  />
                  <CustomBody
                    maxCharsInBody={maxCharsInBody}
                    minCharsInBody={minCharsInBody}
                    disabled={isSubmitting}
                    placeholder={signupSubTitle}
                  />
                  <SelectButtonFontColor disabled={isSubmitting} />
                  <UploadLogo
                    orgEcoId={companyAndEcoInfo?._id}
                    logo={logo}
                    setLogo={setLogo}
                    disabled={isSubmitting}
                  />
                  <span className='d-flex'>
                    <Button
                      variant='black'
                      size='md'
                      onClick={() => {
                        if (isValid) {
                          handleSubmit()
                        }
                      }}
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      className='my-4 border-radius-3 w-25'>
                      {showSaveBtn ? 'Save' : 'Update'}
                    </Button>
                  </span>
                  <PreviewInviteLink
                    disabled={isSubmitting || !isValid}
                    orgEcoId={companyAndEcoInfo?._id}
                    logo={logo}
                    signupTitle={signupTitle}
                    signupSubTitle={signupSubTitle}
                  />
                  <CopyInviteLink companyAndEcoInfo={companyAndEcoInfo} />
                </section>
              )
            }}
          </Formik>
        </section>
      </main>
    </React.Fragment>
  )
}

export default InviteOrgEcoLink
