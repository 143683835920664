import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Button } from '@abroad/components'

const MergeUserModel = ({
  showModal,
  setShowModal,
  handleSubmit,
  isLoading,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(true)
      }}
      size='lg'
      scrollable={true}
      className='sow-modal custom-merge-modal'
      centered>
      <Modal.Header>
        <h3 className='s3 mb-0'>Merge Users</h3>
      </Modal.Header>
      <Modal.Body className='pt-point-5 pb-point-5'>
        <Row className='save-sow-table'>
          <Col>Are you sure, you want to merge users?</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!isLoading && (
          <Button
            variant='none'
            className='text-uppercase line-height-normal secondary-popup-button'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => handleSubmit(false)}>
            cancel
          </Button>
        )}
        <Button
          variant='saffron'
          className='text-uppercase line-height-normal'
          onClick={() => handleSubmit(true)}
          isLoading={isLoading}
          disabled={isLoading}>
          merge
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MergeUserModel
