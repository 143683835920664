import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import {
  Switch,
  NavLink,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SwiperCore, { Controller, History } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import {
  ArrowWrapper,
  SaffronNav,
  useErrorService,
  useUserService,
  Button,
  coachRecommends,
  coachRecommendsUrl,
} from '@abroad/components'
import { ABSwiperSlider } from '@abroad/components/dist/slider'
import PrivateRoute from '../routes/PrivateRoute'
import {
  TeamInformation,
  TeamGrowthPlan,
  TeamAssessment,
  TeamSessionReport,
} from '../components/team'
import { PageHeader } from '../components/common'
import API from '../utils/API'
import { isLGScreen } from '../utils/utility'
import labels from '../constants/labels'
import TeamCircle from '../components/team/TeamCircle'

SwiperCore.use([Controller, History])
const swiperRef = React.createRef()

const UpdateTeam = () => {
  const { teamId } = useParams()
  let location = useLocation()
  let history = useHistory()
  let { user } = useUserService()
  const Error = useErrorService()
  const tabItems = useRef(null)
  const [teamMetadataLoading, setTeamMetadataLoading] = useState(false)
  const [teamMetadata, setTeamMetadata] = useState(null)
  const [teamName, setTeamName] = useState(null)
  let pages = [
    {
      path: `/admin/groups/${teamId}/information`,
      name: 'Information',
    },
    {
      path: `/admin/groups/${teamId}/assessments`,
      name: 'Assessment',
    },
    {
      path: `/admin/groups/${teamId}/session-report`,
      name: 'Session & Report',
    },
  ]

  if (
    user.isAdmin &&
    !teamMetadataLoading &&
    ![
      labels.allGroupType.COMPANY_WITH_SOW,
      labels.allGroupType.NETWORK_WITH_SOW,
    ]?.includes(teamMetadata?.groupType)
  ) {
    pages = [
      {
        path: `/admin/groups/${teamId}/information`,
        name: 'Information',
      },
      {
        path: `/admin/groups/${teamId}/growth-plan`,
        name: 'SOW',
      },
      {
        path: `/admin/groups/${teamId}/assessments`,
        name: 'Assessment',
      },
      {
        path: `/admin/groups/${teamId}/session-report`,
        name: 'Session & Report',
      },
      {
        path: `/admin/groups/${teamId}/circle`,
        name: 'Circle',
      },
    ]
  }

  useEffect(() => {
    if (location.pathname === `/admin/groups/${teamId}/information`) {
      swiperRef.current.slideTo(0)
    } else if (location.pathname === `/admin/groups/${teamId}/growth-plan`) {
      swiperRef.current.slideTo(1)
    } else if (location.pathname === `/admin/groups/${teamId}/assessments`) {
      swiperRef.current.slideTo(2)
    } else if (location.pathname === `/admin/groups/${teamId}/session-report`) {
      swiperRef.current.slideTo(3)
    } else {
      swiperRef.current.slideTo(4)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    const getTeamMetaData = async () => {
      try {
        setTeamMetadataLoading(true)
        const { data } = await API.AdminAPI.teams.getTeamMetadata(teamId)
        setTeamMetadataLoading(false)
        setTeamMetadata(data)
        setTeamName(data?.name)
      } catch (e) {
        setTeamMetadataLoading(false)
        Error.showError(e)
      }
    }
    if (teamId) getTeamMetaData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  const JourneyButton = () => (
    <div className='mr-2'>
      <Button
        className='line-height-normal border-radius-3 text-uppercase m-1'
        size='md'
        variant='gray'
        onClick={() => {
          history.push(`/admin/groups/${teamId}/${coachRecommendsUrl}`)
        }}>
        {coachRecommends}
      </Button>
    </div>
  )

  return (
    <>
      <Helmet>
        <title>{`Update ${
          teamName ? `${teamName}'s` : 'Group'
        } | Abroad`}</title>
        <meta
          name='title'
          content={`Update ${
            teamName ? `${teamName}'s` : 'Group'
          } | Abroad`}></meta>
        <meta
          property='og:title'
          content={`Update ${
            teamName ? `${teamName}'s` : 'Group'
          } | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`Update ${
            teamName ? `${teamName}'s` : 'Group'
          } | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/groups/${teamId}/information`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups/${teamId}/information`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups/${teamId}/information`}></meta>
      </Helmet>
      <main>
        <PageHeader
          titleFn={() => {
            if (teamMetadataLoading) {
              return (
                <span>
                  <Spinner animation='border' className='text-saffron-700' />
                </span>
              )
            } else {
              return (
                <div className='d-flex flex-wrap justify-content-between align-items-center'>
                  <h3 className='text-dark-600 align-self-center text-uppercase w-60 d-inline page-label mb-0'>
                    {!teamMetadata?.isArchived
                      ? `UPDATE GROUP (${teamName})`
                      : teamName}
                  </h3>
                  <JourneyButton />
                </div>
              )
            }
          }}
          descriptionFn={() => {
            return (
              <p className='font-montserrat font-medium'>
                {teamMetadata?.isArchived ? 'This group is archived' : null}
              </p>
            )
          }}
          colClasses='px-lg-0'
        />
        <section className='custom-offset-lg-1 custom-col-lg-12 px-0'>
          <Container className='mt-4 px-lg-0' fluid>
            <div id='header-tabs' className='home-tabs'>
              <ArrowWrapper
                parentRef={tabItems}
                parentId='header-tabs'
                childId='header-tab-items'
                classProps={pages.length}
                shiftValueForPrevBtn={-100}
                shiftValueForNextBtn={+100}>
                <SaffronNav
                  variant='tabs'
                  fill
                  defaultActiveKey='/admin/home'
                  className='d-lg-flex d-md-flex d-none header-tabs border-0'
                  id='header-tab-items'
                  ref={tabItems}>
                  {pages.map((page) => (
                    <Row className='d-flex' key={page?.name}>
                      <Col>
                        <SaffronNav.Item
                          key={page.path}
                          className='position-relative'>
                          <SaffronNav.Link
                            as={NavLink}
                            className='text-center'
                            to={page.path}
                            isActive={(match) => {
                              if (!match) {
                                return false
                              }
                              return true
                            }}>
                            {page.name}
                          </SaffronNav.Link>
                        </SaffronNav.Item>
                      </Col>
                    </Row>
                  ))}
                </SaffronNav>
              </ArrowWrapper>
            </div>
          </Container>
        </section>
        <section className='d-none d-md-block custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
          <Switch location={location}>
            <PrivateRoute path={`/admin/groups/:teamId/information`}>
              <TeamInformation
                teamMetadata={teamMetadata}
                setTeamName={setTeamName}
              />
            </PrivateRoute>
            {user.isAdmin &&
              ![
                labels.allGroupType.COMPANY_WITH_SOW,
                labels.allGroupType.NETWORK_WITH_SOW,
              ]?.includes(teamMetadata?.groupType) && (
                <PrivateRoute path={`/admin/groups/:teamId/growth-plan`}>
                  <TeamGrowthPlan teamMetadata={teamMetadata} />
                </PrivateRoute>
              )}
            <PrivateRoute path={`/admin/groups/:teamId/assessments`}>
              <TeamAssessment />
            </PrivateRoute>
            <PrivateRoute path={`/admin/groups/:teamId/session-report`}>
              <TeamSessionReport teamMetadata={teamMetadata} />
            </PrivateRoute>
            <PrivateRoute path={`/admin/groups/:teamId/circle`}>
              <TeamCircle />
            </PrivateRoute>
          </Switch>
        </section>
        <section className='d-block position-relative d-md-none custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
          <ABSwiperSlider
            onSlideChange={(e) => {
              if (e.activeIndex === 0) {
                history.push(`/admin/groups/${teamId}/information`)
              } else if (e.activeIndex === 1) {
                history.push(`/admin/groups/${teamId}/growth-plan`)
              } else if (e.activeIndex === 2) {
                history.push(`/admin/groups/${teamId}/assessments`)
              } else if (e.activeIndex === 3) {
                history.push(`/admin/groups/${teamId}/session-report`)
              } else {
                history.push(`/admin/groups/${teamId}/circle`)
              }
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
            arrowProps={{
              bgColor: '#646464',
              bgOpacity: 1,
              className: 'position-absolute',
              style: {
                top: '2rem',
              },
            }}>
            <SwiperSlide
              url={`/admin/groups/${teamId}/information`}
              key={`/admin/groups/${teamId}/information`}>
              <div>
                <div className='px-5 pt-1 font-medium text-saffron-700 h-auto gp-label text-center'>
                  Information
                  <hr className='w-25' />
                </div>
                {!isLGScreen && (
                  <PrivateRoute
                    exact
                    strict
                    path={`/admin/groups/:teamId/information`}>
                    <TeamInformation
                      teamMetadata={teamMetadata}
                      setTeamName={setTeamName}
                    />
                  </PrivateRoute>
                )}
              </div>
            </SwiperSlide>
            {user.isAdmin && (
              <SwiperSlide
                url={`/admin/groups/${teamId}/growth-plan`}
                key={`/admin/groups/${teamId}/growth-plan`}>
                <div>
                  <div className='px-5 pt-1 font-medium text-saffron-700 h-auto gp-label text-center'>
                    SOW
                    <hr className='w-25' />
                  </div>
                  {!isLGScreen &&
                    ![
                      labels.allGroupType.COMPANY_WITH_SOW,
                      labels.allGroupType.NETWORK_WITH_SOW,
                    ]?.includes(teamMetadata?.groupType) && (
                      <PrivateRoute
                        exact
                        strict
                        path={`/admin/groups/:teamId/growth-plan`}>
                        <TeamGrowthPlan teamMetadata={teamMetadata} />
                      </PrivateRoute>
                    )}
                </div>
              </SwiperSlide>
            )}
            <SwiperSlide
              url={`/admin/groups/${teamId}/assessments`}
              key={`/admin/groups/${teamId}/assessments`}>
              <div>
                <div className='px-5 pt-1 font-medium text-saffron-700 h-auto gp-label text-center'>
                  Assessment
                  <hr className='w-25' />
                </div>
                {!isLGScreen && (
                  <PrivateRoute
                    exact
                    strict
                    path={`/admin/groups/:teamId/assessments`}>
                    <TeamAssessment />
                  </PrivateRoute>
                )}
              </div>
            </SwiperSlide>
            <SwiperSlide
              url={`/admin/groups/${teamId}/session-report`}
              key={`/admin/groups/${teamId}/session-report`}>
              <div>
                <div className='px-5 pt-1 font-medium text-saffron-700 h-auto gp-label text-center'>
                  Session & Report
                  <hr className='w-25' />
                </div>
                {!isLGScreen && (
                  <PrivateRoute
                    exact
                    strict
                    path={`/admin/groups/:teamId/session-report`}>
                    <TeamSessionReport teamMetadata={teamMetadata} />
                  </PrivateRoute>
                )}
              </div>
            </SwiperSlide>
          </ABSwiperSlider>
        </section>
      </main>
    </>
  )
}

export default UpdateTeam
