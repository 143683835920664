import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useErrorService } from '@abroad/components'
import { Table, TableInstance } from '../components/table'
import { PageHeader } from '../components/common'
import { PaymentFilters } from '../components'
import labels from '../constants/labels'
import { formatDate } from '../utils/date'
import { formatDateInPlan } from '../utils/date'
import API from '../utils/API'
import CONSTANTS from '../utils/constants'

const initialState = {
  promoCode: '',
  preDate: '',
  postDate: '',
  organization: '',
  network: '',
  preAmount: CONSTANTS.defaultMinAmountOfPaymentRange,
  postAmount: CONSTANTS.maxAmonuntOfPaymentRange,
  status: CONSTANTS.paymentStatus.SUCCEEDED,
  type: '',
}

export const UserPaymentInformation = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [paymentData, setPaymentData] = useState([])
  const [reqData, setReqData] = useState(null)
  const Error = useErrorService()
  const hasMore = useRef(false)

  const getPaymentInfo = useCallback(async () => {
    if (reqData.page === 1) {
      setPaymentData([])
    }
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.payment.getUserPaymentInformation(
        reqData,
      )
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setPaymentData((prev) => [...prev, ...data])
      } else {
        setPaymentData(data)
      }
      setIsLoading(false)
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getPaymentInfo(reqData)
    }
  }, [getPaymentInfo, reqData])

  const columns = React.useMemo(
    () => [
      {
        id: 'number',
        Header: '#',
        width: '1%',
        accessor: (properties) => properties,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
        disableSortBy: true,
      },
      {
        id: 'user',
        Header: 'user',
        width: '20%',
        accessor: (properties) => properties?.userId,
        Cell: ({ cell }) => (
          <div>
            <Link
              to={`/admin/users/${cell?.value?._id}`}
              target='_blank'
              className='word-breakall'>
              {cell?.value?.fullName}
            </Link>
            <br />
            <Link
              to={`/admin/users/${cell?.value?._id}`}
              target='_blank'
              className='word-breakall'>
              {cell?.value?.email}
            </Link>
            <br />
            <p className='text-capitalize m-0'>
              Current Plan:{' '}
              {labels.plans.find((e) => e.value === cell?.value?.planCode)
                ?.label || ''}
            </p>
          </div>
        ),
        disableSortBy: true,
      },
      {
        id: 'plan',
        Header: 'plan',
        width: '15%',
        accessor: (properties) => ({
          plan: properties?.plan,
          sow: properties?.sow || properties?.tempSOW,
        }),
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.sow
              ? cell?.value?.sow?.planName
              : labels.plans.find((e) => e.value === cell?.value?.plan)
                  ?.label || '-'}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'payment',
        Header: 'payment',
        width: '15%',
        accessor: (properties) => ({
          totalPaid: properties?.amount,
          paid: properties?.paid,
          amount: properties?.amount,
        }),
        Cell: ({ cell }) => (
          <span
            className={classNames({
              red: cell?.value?.paid === false && cell?.value?.amount > 0,
            })}>
            {cell?.value?.totalPaid ? `$${cell?.value?.totalPaid}` : '$0'}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'promo',
        Header: 'promo',
        width: '15%',
        accessor: (properties) => ({
          promoCode: properties?.promoCode,
          discountedAmount: properties?.discountedAmount,
          totalAmount: properties?.totalAmount,
        }),

        Cell: ({ cell }) => {
          const discountedAmount = cell?.value?.discountedAmount
            ? parseFloat((cell?.value?.discountedAmount).toFixed(2))
            : cell?.value?.discountedAmount
          return (
            <div>
              {cell?.value?.promoCode ? (
                <React.Fragment>
                  <span className='text-uppercase'>
                    {cell?.value?.promoCode}
                  </span>
                  <br />
                </React.Fragment>
              ) : (
                <p className='ml-3'>-</p>
              )}
              {cell?.value?.promoCode ? (
                cell?.value?.promoCode !== 'No Code Applied' &&
                (cell?.value?.discountedAmount || cell?.value?.totalAmount) ? (
                  <span>
                    ${discountedAmount} off on ${cell?.value?.totalAmount}
                  </span>
                ) : null
              ) : null}
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        id: 'date',
        Header: 'date',
        width: '14%',
        accessor: (properties) => properties?.createAt,
        Cell: ({ cell }) => <span>{formatDate(cell?.value)}</span>,
        disableSortBy: true,
      },
      {
        id: 'stripe-link',
        Header: 'stripe link ',
        width: '20%',
        accessor: (properties) => ({
          stripeId: properties?.stripeCharges?.id,
          stripeMessage: properties?.stripeCharges?.message,
          paid: properties?.paid,
          amount: properties?.amount,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.stripeId ? (
              <a
                href={`https://dashboard.stripe.com/payments/${cell?.value?.stripeId}`}
                rel='noopener noreferrer'
                className='text-saffron-700 text-center'
                target='_blank'>
                Payment Details
              </a>
            ) : cell?.value?.paid === false &&
              cell?.value?.amount > 0 &&
              cell?.value?.stripeMessage ? (
              <p className='mb-0 text-center'>{cell?.value?.stripeMessage}</p>
            ) : (
              <p className='mb-0 text-center'>-</p>
            )}
          </React.Fragment>
        ),
        disableSortBy: true,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
      },
    ],
    [],
  )
  const fetchData = useCallback(
    ({
      promoCode,
      preDate,
      postDate,
      organization,
      network,
      preAmount,
      postAmount,
      status,
      type,
      page,
      limit,
    }) => {
      const resolvedType = type === 'coaching/SOW' ? 'coaching' : type

      const req = {
        limit,
        page,
        filter: {
          ...((preDate || postDate) && {
            paymentDate: {
              preDate: formatDateInPlan(preDate),
              postDate: formatDateInPlan(postDate),
            },
          }),
          ...((preAmount || postAmount) && {
            paymentAmount: {
              preAmount,
              postAmount,
            },
          }),
          ...(organization && { userCompany: organization }),
          ...(network && { userNetwork: network }),
          ...(promoCode && { promoCode }),
          ...(status && { status }),
          ...(resolvedType && type !== 'all' && { type: resolvedType }),
        },
      }
      setReqData(req)
    },
    [],
  )

  return (
    <>
      <Helmet>
        <title>{'User Payments | Abroad'}</title>
        <meta name='title' content={'User Payments | Abroad'}></meta>
        <meta property='og:title' content={'User Payments | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'User Payments | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/payment`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/payment`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/payment`}></meta>
      </Helmet>
      <PageHeader
        titleText='ALL PAYMENTS'
        colClasses='px-0'
        titleClasses='sentence-case-text'
      />
      <TableInstance
        columns={columns}
        data={paymentData}
        initialState={{
          globalFilter: initialState,
        }}>
        {({
          preGlobalFilteredRows,
          state: { globalFilter, ...other },
          setGlobalFilter,
          ...rest
        }) => (
          <Container
            fluid
            className='custom-offset-lg-1 custom-col-lg-12 px-0 mt-4 profile'>
            <PaymentFilters
              {...{
                globalFilter,
                setGlobalFilter,
              }}
              handleClearFilter={() => setGlobalFilter(initialState)}
              isLoading={isLoading}
            />
            <Table
              ref={hasMore}
              emptyTableMessage={`No user payment found.`}
              tableInstance={{
                preGlobalFilteredRows,
                state: { globalFilter, ...other },
                setGlobalFilter,
                ...rest,
              }}
              isLoading={isLoading}
              fetchData={fetchData}
              totalNumberOfColumns='7'
            />
          </Container>
        )}
      </TableInstance>
    </>
  )
}
