import React from 'react'

const CircleFromTimeSelection = ({ value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  const renderTimeOptions = () => {
    const hoursOptions = Array.from({ length: 12 }, (_, i) => i)
    const minutesOptions = ['00', '15', '30', '45']
    const timeOptions = []

    const formatTime = (hour, minute, period) => {
      const formattedHour = hour === 12 ? 12 : hour % 12 || 12
      return `${formattedHour.toString().padStart(2, '0')}:${minute} ${period}`
    }

    const generateTimes = (period) => {
      return hoursOptions.forEach((hour) =>
        minutesOptions.forEach((minute) => {
          const timeString = formatTime(hour, minute, period)
          timeOptions.push(
            <option key={timeString} value={timeString}>
              {timeString}
            </option>,
          )
        }),
      )
    }

    generateTimes('AM')
    generateTimes('PM')

    return timeOptions
  }

  return (
    <select
      className='selection-field user-input'
      value={value}
      onChange={handleChange}>
      <option value='' disabled>
        From
      </option>
      {renderTimeOptions()}
    </select>
  )
}

export default CircleFromTimeSelection
