import React, { useEffect, useState } from 'react'
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService } from '@abroad/components'
import {
  CategoriesTakeawaysForm,
  CreateGPAndOffering,
  GrowthPlanHeader,
  GrowthSessionPractice,
  CallOutcome,
} from '../components'
import API from '../utils/API'

const GROWTH_SESSION_STEPS = {
  SUBCATEGORIES_NOTES: 1,
  CREATE_GP_AND_EXPLORE_OFFERINGS: 2,
  PSP_AND_ATTACH_AUDIOS: 3,
  CALL_OUTCOME: 4,
}

const initialGrowthSessionData = {
  coachNotes: '',
  postSession: '',
  mediaModules: [],
  medias: [],
  keySkills: [],
  callOutcome: null,
  reviewCall: {},
}

const GrowthPlanCall = () => {
  const { clientId, sowId, planId } = useParams()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [growthSessionData, setGrowthSessionData] = useState(initialGrowthSessionData)
  const [clientInfo, setClientInfo] = useState(null)
  const { url } = useRouteMatch()
  const location = useLocation()
  const search = location.search
  const query = new URLSearchParams(search)
  const queryStep =
    query.get('planId') ||
    GROWTH_SESSION_STEPS.CREATE_GP_AND_EXPLORE_OFFERINGS
      ? GROWTH_SESSION_STEPS.SUBCATEGORIES_NOTES
      : GROWTH_SESSION_STEPS.CREATE_GP_AND_EXPLORE_OFFERINGS
  const [step, setStep] = useState(queryStep)
  const history = useHistory()
  const isMyClientPageUrl = location?.pathname.split('/').includes('coach')

  const goToFirstStep = () => {
    setStep(GROWTH_SESSION_STEPS.SUBCATEGORIES_NOTES)
  }
  const goToSecondStep = () => {
    setStep(GROWTH_SESSION_STEPS.CREATE_GP_AND_EXPLORE_OFFERINGS)
  }
  const goToThirdStep = () => {
    setStep(GROWTH_SESSION_STEPS.PSP_AND_ATTACH_AUDIOS)
  }
  const goToFourthStep = () => {
    setStep(GROWTH_SESSION_STEPS.CALL_OUTCOME)
  }

  useEffect(() => {
    const getUserGrowthPlan = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.CoachAPI.coachingSession.getClientSession(
          planId,
          clientId,
        )
        if (data) {
          const keySkillsData = data?.keySkills?.map((keySkill) => ({
            _id: keySkill?._id,
            title: keySkill?.keySkill?.title,
            description: keySkill?.keySkill?.description,
          }))
          setGrowthSessionData({ ...data, keySkills: keySkillsData })
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (planId !== 'create') {
      getUserGrowthPlan()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId, clientId, sowId])

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['fullName', 'fname'],
          },
        )
        if (data) {
          setClientInfo(data)
        }
      } catch (e) {
        setClientInfo(null)
        Error.showError(e)
      }
    }
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${
          clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
        } Growth Session | Abroad`}</title>
        <meta
          name='title'
          content={`${
            clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
          } Growth Session | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${
            clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
          } Growth Session | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${
            clientInfo?.fname ? `${clientInfo?.fname}'s` : ''
          } Growth Session | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${url}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
      </Helmet>
      <main>
        <GrowthPlanHeader
          fullName={clientInfo?.fullName}
          date={growthSessionData?.date || null}
        />
        <Container
          className='custom-offset-lg-1 custom-col-lg-12 px-lg-0'
          fluid>
          <Row>
            <Col>
              {step === GROWTH_SESSION_STEPS.SUBCATEGORIES_NOTES && (
                <CategoriesTakeawaysForm
                  coachNote={growthSessionData?.coachNotes}
                  nextStepHandler={(reviewCall, coachNotes) => {
                    setGrowthSessionData((prev) => ({
                      ...prev,
                      reviewCall,
                      coachNotes,
                    }))
                    localStorage.removeItem(`logs-${clientId}-${sowId}-${planId}`)
                    goToSecondStep()
                  }}
                />
              )}
              {step ===
                GROWTH_SESSION_STEPS.CREATE_GP_AND_EXPLORE_OFFERINGS && (
                <CreateGPAndOffering
                  nextStepHandler={goToThirdStep}
                  prevStepHandler={goToFirstStep}
                />
              )}
              {step === GROWTH_SESSION_STEPS.PSP_AND_ATTACH_AUDIOS && (
                <GrowthSessionPractice
                  growthSessionData={growthSessionData}
                  nextStepHandler={(data) => {
                    if (data) {
                      setGrowthSessionData((prev) => ({
                        ...prev,
                        ...data,
                      }))
                    }
                    goToFourthStep()
                  }}
                  prevStepHandler={goToSecondStep}
                />
              )}
              {step === GROWTH_SESSION_STEPS.CALL_OUTCOME && (
                <CallOutcome
                  nextStepHandler={() =>
                    history.push(
                      `${
                        isMyClientPageUrl ? '/coach/clients/' : '/admin/users/'
                      }${clientId}/growth-plan`,
                    )
                  }
                  prevStepHandler={goToThirdStep}
                  callOutcome={growthSessionData?.callOutcome}
                />
              )}
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default GrowthPlanCall
