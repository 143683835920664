/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import { PageHeader, SearchInput } from '../components/common'
import {
  useNotificationService,
  Button,
  useErrorService,
} from '@abroad/components'
import MergeUserModel from './MergeUserModel'
import API from '../utils/API'

const MergeUser = () => {
  const [sourceSearch, setSourceSearch] = useState('')
  const [destinationSearch, setDestinationSearch] = useState('')
  const [clientInfo, setClientInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [sourceUserId, setSourceUserId] = useState(null)
  const [destinationUserId, setDestinationUserId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const Notification = useNotificationService()
  const Error = useErrorService()

  useEffect(() => {
    if (sourceSearch === '') {
      setSourceUserId(null)
    }
    if (destinationSearch === '') {
      setDestinationUserId(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceSearch, destinationSearch])

  const handleUserSelect = (userId, isSource) => {
    if (isSource) {
      setSourceUserId(userId)
    } else {
      setDestinationUserId(userId)
    }
  }

  const handleAssignUser = async () => {
    const userInfo = clientInfo.find((e) => e.email === search)
    if (!userInfo) {
      Notification.showNotification('User not found.', 'error')
      return
    }

    Notification.showNotification(
      `User ${userInfo.email} found successfully.`,
      'success',
    )
    setSearch('')
  }

  const mergeUser = async () => {
    setIsLoading(true)
    if (sourceUserId !== destinationUserId) {
      try {
        const { data } = await API.AdminAPI.userList.mergeUser({
          sourceUserId,
          destinationUserId,
        })
        if (data) {
          setIsLoading(false)
          setSourceSearch('')
          setDestinationSearch('')
          setClientInfo([])
          Notification.showNotification('User merge successfully.', 'success')
        }
        setShowModal(false)
      } catch (e) {
        setShowModal(false)
        setIsLoading(false)
        Error.showError(e)
      }
    } else {
      setShowModal(false)
      setIsLoading(false)
      setSourceSearch('')
      setDestinationSearch('')
      setClientInfo([])
      Notification.showNotification(
        'Source and Destination users are same.',
        'danger',
      )
    }
  }

  const handleModalSubmit = (confirmMerge) => {
    if (confirmMerge) {
      mergeUser()
    } else {
      setShowModal(false)
    }
  }

  return (
    <>
      <main>
        <PageHeader
          sectionClassName='page-heading'
          titleText='Merge Users'
          hasBackArrow={false}
          titleClasses='sentence-case-text'
          colClasses='px-0'
        />
        <Container fluid className='custom-offset-lg-1 custom-col-lg-12 mt-5'>
          <Row className='d-flex justify-content-center'>
            <Col
              sm={12}
              md={5}
              className={classNames('pl-lg-0', {
                'pb-0': sourceUserId || destinationUserId,
                'merge-dropdown-section': !sourceUserId || !destinationUserId,
              })}>
              <h3 className='mt-2 mb-3 s3'>Source User</h3>
              <SearchInput
                search={sourceSearch}
                render={(data) => setSourceSearch(data)}
                setClientInfo={setClientInfo}
                setsource={setSourceSearch}
                handleSubmit={handleAssignUser}
                placeholder='Search Source User'
                wrapperClasses='d-flex flex-column'
                inputColumnClasses='align-self-center pr-0'
                isShowLoader={true}
                showUserInfo={true}
                handleUserSelect={(userId) => handleUserSelect(userId, true)}
              />
            </Col>

            <Col
              sm={12}
              md={2}
              className='pl-lg-0 d-none d-md-flex align-items-start justify-content-center mt-5 pt-2'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5 12h14M12 5l7 7-7 7'
                  stroke='#000'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </Col>

            <Col sm={12} md={5} className='mt-4 mt-sm-4 mt-md-0'>
              <h3 className='mt-2 mb-3 s3'>Destination User</h3>
              <SearchInput
                search={destinationSearch}
                render={(data) => setDestinationSearch(data)}
                setClientInfo={setClientInfo}
                setsource={setDestinationSearch}
                handleSubmit={handleAssignUser}
                placeholder='Search Destination User'
                wrapperClasses='d-flex flex-column'
                inputColumnClasses='align-self-center pr-0'
                isShowLoader={true}
                showUserInfo={true}
                handleUserSelect={(userId) => handleUserSelect(userId, false)}
              />
            </Col>
          </Row>
          <div className='d-flex justify-content-end mt-4 mr-3'>
            <Button
              variant='gray'
              className='line-height-normal'
              size='md'
              disabled={isLoading || !sourceUserId || !destinationUserId}
              isLoading={isLoading}
              onClick={() => setShowModal(true)}>
              Merge
            </Button>
            <MergeUserModel
              showModal={showModal}
              setShowModal={setShowModal}
              handleSubmit={handleModalSubmit}
              isLoading={isLoading}
            />
          </div>
        </Container>
      </main>
    </>
  )
}

export default MergeUser
