import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import {
  FeatherLoader,
  useErrorService,
  useUserService,
} from '@abroad/components'
import API from '../../utils/API'
import { formatDate } from '../../utils/date'

const GrowthPlanSOWTable = ({ growthPlanDetails, showAddSessionLink }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const { clientId } = useParams()
  const { user } = useUserService()
  const Error = useErrorService()
  const location = useLocation()

  const isUserComeFromMyClients = location?.pathname
    .split('/')
    .includes('coach')
  useEffect(() => {
    const getGrowthSOW = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachPlan.getGrowthSOW(clientId)
        if (data) {
          setTableData(data)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getGrowthSOW()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthPlanDetails, clientId])

  return (
    <>
      <Table hover responsive className='custom-table mb-0'>
        <thead>
          <tr>
            <th width={user?.isAdmin ? '20%' : '25%'}>Coach</th>
            <th width='25%'>SOW Name</th>
            <th width={user?.isAdmin ? '15%' : '25%'}>Date</th>
            {user?.isAdmin && <th width='20%'>Coach Payment</th>}
            <th width={user?.isAdmin ? '20%' : '25%'}>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td
                colSpan={user?.isAdmin ? '5' : '3'}
                className='text-center border-0'>
                <FeatherLoader />
              </td>
            </tr>
          )}
          {!isLoading && (
            <>
              {!user?.isAdmin && tableData.length === 0 ? (
                <tr>
                  <td
                    colSpan={user?.isAdmin ? '5' : '3'}
                    className='text-center border-0'>
                    There is no growth plan taken yet.
                  </td>
                </tr>
              ) : (
                <>
                  {tableData.length > 0 &&
                    tableData.map((dataItem, index) => {
                      const isSessionDone = dataItem?.session?.date
                      const sowId = dataItem?.session?._id
                      const growthPlanCallUrl = isUserComeFromMyClients
                        ? `/coach/clients/${clientId}/growth-plan/${dataItem?._id}/create`
                        : `/admin/users/${clientId}/growth-plan/${dataItem?._id}/create`

                      return (
                        <tr key={index}>
                          <td width={user?.isAdmin ? '20%' : '25%'}>
                            {user?.isAdmin ? (
                              <Link
                                to={`/admin/coaches/${dataItem?.coachId?._id}`}
                                className='text-dark-900'>
                                {dataItem?.coachId?.fullName}
                              </Link>
                            ) : (
                              <>{dataItem?.coachId?.fullName}</>
                            )}
                          </td>
                          <td width='25%'>{dataItem?.planName}</td>
                          {!isSessionDone ? (
                            <td width={user?.isAdmin ? '15%' : '20%'}> - </td>
                          ) : (
                            <td width={user?.isAdmin ? '15%' : '20%'}>
                              {formatDate(dataItem?.session?.date)}
                            </td>
                          )}
                          {user?.isAdmin && (
                            <td width='20%'>
                              {`$${dataItem?.rate}/${
                                dataItem?.rateType === 'hourly'
                                  ? 'Hour'
                                  : 'Session'
                              }`}
                            </td>
                          )}
                          <td width={user?.isAdmin ? '20%' : '25%'}>
                            {dataItem?.coachId?._id === user?._id &&
                            !isSessionDone ? (
                              showAddSessionLink ? (
                                <Link
                                  to={{
                                    pathname: `${growthPlanCallUrl}`,
                                  }}>
                                  {' '}
                                  Add Session
                                </Link>
                              ) : (
                                'Assessment not taken yet'
                              )
                            ) : dataItem?.coachId?._id !== user?._id &&
                              !isSessionDone ? (
                              'Session Not Taken yet'
                            ) : (
                              <Link
                                to={{
                                  pathname: `${
                                    isUserComeFromMyClients
                                      ? '/coach/clients/'
                                      : '/admin/users/'
                                  }${clientId}/growth-plan/${
                                    dataItem?._id
                                  }/${sowId}/view`,
                                  state: {
                                    isEditable:
                                      dataItem?.coachId?._id === user?._id,
                                  },
                                }}>
                                View Session
                              </Link>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </>
              )}
            </>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default GrowthPlanSOWTable
