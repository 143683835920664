import React from 'react'
import { Row, Col } from 'react-bootstrap'
import FilterSelection from '../common/FilterSelection'
import ClearFilters from '../common/ClearFilters'
import classNames from 'classnames'
import labels from '../../constants/labels'
const SubscriptionFilter = ({
  globalFilter,
  setGlobalFilter,
  handleClearFilter,
  isLoading,
  coachList,
}) => {
  const coachingPlanStatus = labels?.coachingPlanStatus.filter(
    (status) => status.value !== 'RESUMED',
  )
  const subscriptionPaymentStatus = labels?.subscriptionPaymentStatus

  return (
    <section className='mb-2-5'>
      <Row className='user-payment-filters'>
        <Col className='col-4 mb-3'>
          <FilterSelection
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            name='coachingStatus'
            placeholder='Select Coaching Status'
            inputClasses={classNames('user-input', {
              'pointer-cursor': !isLoading,
              'cursor-disabled': isLoading,
            })}
            options={coachingPlanStatus.map((status) => ({
              key: status.value,
              value: status.value === 'STOPPED' ? 'Terminated' : status.label,
            }))}
            size='md'
            disabled={isLoading}
            key={globalFilter?.coachingStatus}
          />
        </Col>
        <Col className='col-4 mb-3'>
          <FilterSelection
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            name='paymentStatus'
            placeholder='Select Payment Status'
            inputClasses={classNames('user-input', {
              'pointer-cursor': !isLoading,
              'cursor-disabled': isLoading,
            })}
            options={subscriptionPaymentStatus.map((status) => ({
              key: status.value,
              value: status.label,
            }))}
            size='md'
            disabled={isLoading}
            key={globalFilter?.paymentStatus}
          />
        </Col>

        <Col className='col-4 mb-3'>
          <FilterSelection
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            name='coachName'
            placeholder='Select Coach'
            inputClasses={classNames('user-input', {
              'pointer-cursor': !isLoading,
              'cursor-disabled': isLoading,
            })}
            options={coachList.map((coach) => ({
              key: coach.fullName,
              value: coach.fullName,
            }))}
            size='md'
            disabled={isLoading}
            key={globalFilter?.coachName}
          />
        </Col>
      </Row>
      {(globalFilter.paymentStatus !== '' ||
        globalFilter.coachingStatus !== '' ||
        globalFilter.coachName !== '') && (
        <ClearFilters
          wrapperClasses='payment-clear-filter text-right'
          onClick={handleClearFilter}
          disabled={isLoading}
        />
      )}
    </section>
  )
}

export default SubscriptionFilter
