import React from 'react'
import { PageHeader } from '../components'
import { useLocation, NavLink, Switch, Route } from 'react-router-dom'
import { NavTabs } from '@abroad/components'
import SOWSubscription from '../components/payment/SOWSubscription'
import SOWPayment from '../components/payment/SOWPayment'
const SubScriptionPayment = () => {
  let location = useLocation()

  const pages = [
    {
      path: '/admin/subscription-payments/sows',
      name: 'SOWs',
      defaultUrl: '/admin/subscription-payments',
    },
    {
      path: '/admin/subscription-payments/payments',
      name: 'Payments',
    },
  ]
  return (
    <>
      <PageHeader titleText='Subscriptions' />
      <div className='custom-offset-lg-1 custom-col-lg-12 px-0 container-fluid'>
        <div className='mt-5'>
          <NavTabs
            pages={pages}
            pathname={location?.pathname}
            hash={location?.hash}
            NavLink={NavLink}
          />
          <Switch location={location}>
            <Route
              exact
              strict
              path={[
                '/admin/subscription-payments/sows',
                '/admin/subscription-payments',
              ]}>
              <SOWSubscription />
            </Route>
            <Route exact strict path='/admin/subscription-payments/payments'>
              <SOWPayment />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default SubScriptionPayment
