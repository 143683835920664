import React, { useState, useEffect } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap'
import {
  Button,
  paymentType as paymentTypeConst,
  paymentStatus as paymentStatusConst,
  paymentStatusLabel,
} from '@abroad/components'
import { useParams } from 'react-router-dom'
import Labels from '../../constants/labels'

const CoachingPlanDetailsModal = ({
  values,
  showModal,
  setShowModal,
  handleSubmit,
  isLoading,
  isBulkAssignSOWSection = false,
}) => {
  const { planId } = useParams()
  const [readyPayButton, setReadyPayButton] = useState(false)
  const [readyPayInfoPopup, setReadyPayInfoPopup] = useState(
    planId === 'create' || isBulkAssignSOWSection ? false : true,
  )
  // const { values } = useFormikContext()
  useEffect(() => {
    if (showModal) {
      if (planId === 'create' || isBulkAssignSOWSection) {
        setReadyPayInfoPopup(false)
      } else {
        setReadyPayInfoPopup(true)
      }
    }
  }, [planId, showModal, isBulkAssignSOWSection])

  if (values && Object.keys(values).length === 0) return null

  const tableHeaderClasses = 's6'

  const renderTable = () => {
    return (
      <Table hover className='custom-table'>
        <tbody className='overflow-y'>
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Name:
            </th>
            <td width='50%'>{values?.planName}</td>
          </tr>
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Payment Status:
            </th>
            <td width='50%'>
              {
                paymentStatusLabel.find(
                  (e) => e.value === values?.paymentStatus,
                )?.label
              }
            </td>
          </tr>
          {values?.paymentStatus !== paymentStatusConst.NO_BILLING_REQUIRED && (
            <>
              <tr>
                <th width='50%' className={tableHeaderClasses}>
                  Payment Cycle:
                </th>
                <td width='50%'>
                  {values?.paymentCycle === paymentTypeConst.ONETIME
                    ? 'One Time'
                    : 'Recurring'}
                </td>
              </tr>
              <tr>
                <th width='50%' className={tableHeaderClasses}>
                  {values?.paymentCycle === paymentTypeConst.RECURRING
                    ? 'Upfront/Setup Fee (USD)'
                    : 'One Time Fee (USD)'}
                  :
                </th>
                <td width='50%'>{values?.initialFee}</td>
              </tr>
              {values?.paymentCycle === paymentTypeConst.RECURRING && (
                <>
                  <tr>
                    <th width='50%' className={tableHeaderClasses}>
                      Bill Every (Month(s)):
                    </th>
                    <td width='50%'>{values?.invoiceEvery}</td>
                  </tr>
                  <tr>
                    <th width='50%' className={tableHeaderClasses}>
                      Subscription Amount (USD):
                    </th>
                    <td width='50%'>{values?.amount}</td>
                  </tr>
                  <tr>
                    <th width='50%' className={tableHeaderClasses}>
                      Number Of Billing Cycle:
                    </th>
                    <td width='50%'>
                      {values?.numberOfCoachingCycle || 'Indifinite'}
                    </td>
                  </tr>
                </>
              )}
            </>
          )}
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Number of sessions:
            </th>
            <td width='50%'>
              {values?.sessionPerCoachingCycle} per {values?.numberOfMonths}{' '}
              Month(s)
            </td>
          </tr>
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Session Duration:
            </th>
            <td width='50%'>{values?.sessionDuration}</td>
          </tr>
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              360 Report:
            </th>
            <td width='50%'>{values?.report360 ? 'Yes' : 'No'}</td>
          </tr>
          {values?.report360 && (
            <React.Fragment>
              <tr>
                <th width='50%' className={tableHeaderClasses}>
                  360 Report Plan:
                </th>
                <td width='50%'>
                  {
                    Labels.ReportPlanOf360.find(
                      (e) => e.key === parseInt(values?.reportPlan360),
                    )?.value
                  }
                </td>
              </tr>
              <tr>
                <th width='50%' className={tableHeaderClasses}>
                  360 Report Hourly Rate:
                </th>
                <td width='50%'>{values?.report360hourlyRate}</td>
              </tr>
            </React.Fragment>
          )}
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Impact Report:
            </th>
            <td width='50%'>{values?.impactReport ? 'Yes' : 'No'}</td>
          </tr>
          {values?.impactReport && (
            <React.Fragment>
              <tr>
                <th width='50%' className={tableHeaderClasses}>
                  Impact Report Plan:
                </th>
                <td width='50%'>
                  {
                    Labels.impactReportPlan.find(
                      (e) => e.key === parseInt(values?.impactReportPlan),
                    )?.value
                  }
                </td>
              </tr>
              <tr>
                <th width='50%' className={tableHeaderClasses}>
                  Impact Report Hourly Rate:
                </th>
                <td width='50%'>{values?.impactReporthourlyRate}</td>
              </tr>
            </React.Fragment>
          )}
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Hourly Rate Paid To Coach (USD):
            </th>
            <td width='50%'>{values?.hourlyRate}</td>
          </tr>
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Client Time Zone:
            </th>
            <td width='50%'>{values?.clientTimeZone}</td>
          </tr>
          <tr>
            <th width='50%' className={tableHeaderClasses}>
              Date Of First Paid Session:
            </th>
            <td width='50%'>{values?.firstSessionDate}</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(true)
      }}
      size='lg'
      scrollable={true}
      className='sow-modal custom__modal'
      centered>
      <Modal.Header>
        <h3 className='s3 mb-0'>
          {readyPayInfoPopup
            ? 'Send for payment?'
            : ' Once plan is saved, you can not edit it apart from timezone and status.'}
        </h3>
      </Modal.Header>
      <Modal.Body className='pt-point-5 pb-point-5'>
        <Row className='save-sow-table'>
          <Col>
            {readyPayInfoPopup
              ? 'Once you click send, we will send an email to the client with a payment link.'
              : renderTable()}
          </Col>
        </Row>
      </Modal.Body>
      {readyPayInfoPopup ? (
        <>
          <Modal.Footer>
            {!isLoading && (
              <Button
                variant='none'
                className='text-uppercase line-height-normal secondary-popup-button'
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => {
                  handleSubmit({ ...values, isReadyForPayment: false })
                  setShowModal(false)
                }}>
                Save As Draft
              </Button>
            )}
            <Button
              variant='saffron'
              className='text-uppercase line-height-normal'
              onClick={() => {
                handleSubmit({ ...values, isReadyForPayment: true })
                setShowModal(false)
              }}
              isLoading={isLoading}
              disabled={isLoading}>
              Send
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Footer>
            {!readyPayButton && (
              <>
                <Button
                  variant='none'
                  className='text-uppercase line-height-normal secondary-popup-button'
                  onClick={() => setShowModal(false)}>
                  Re-edit
                </Button>
                <Button
                  variant='saffron'
                  className='text-uppercase line-height-normal'
                  onClick={() => {
                    if (
                      values?.paymentStatus ===
                      paymentStatusConst.NO_BILLING_REQUIRED
                    ) {
                      handleSubmit(values)
                      setShowModal(false)
                    } else {
                      setReadyPayButton(true)
                    }
                  }}
                  isLoading={isLoading}
                  disabled={isLoading}>
                  Save
                </Button>
              </>
            )}
            {readyPayButton && (
              <>
                {!isLoading && (
                  <Button
                    variant='none'
                    className='text-uppercase line-height-normal secondary-popup-button'
                    onClick={() => {
                      handleSubmit({ ...values, isReadyForPayment: false })
                      setShowModal(false)
                    }}
                    isLoading={isLoading}
                    disabled={isLoading}>
                    Save As Draft
                  </Button>
                )}
                <Button
                  variant='saffron'
                  className='text-uppercase line-height-normal'
                  onClick={() => {
                    if (planId === 'create' || isBulkAssignSOWSection) {
                      setReadyPayInfoPopup(true)
                    } else {
                      handleSubmit({
                        ...values,
                        isReadyForPayment: true,
                      })
                      setShowModal(false)
                    }
                  }}
                  isLoading={isLoading}
                  disabled={isLoading}>
                  Send for Payment?
                </Button>
              </>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default CoachingPlanDetailsModal
