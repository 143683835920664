import React from 'react'
import { Modal } from 'react-bootstrap'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { Button } from '@abroad/components'
import { CustomTextArea } from '../form'

const InquiriesModal = ({
  showModal,
  handleClose,
  inquirieDetail,
  isLoading,
}) => {
  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='quest-modal inquiries-modal'>
        <Modal.Header className='payment-modal-header d-flex justify-content-center'>
          <h3 className='payment-modal-title mb-0'>
            <span className='text-capitalize'> Inquiry -</span>
            <span className='text-capitalize'>
              {' '}
              {inquirieDetail.userId ? inquirieDetail.userId.fullName : ''}
            </span>
            <span className='text-lowercase'>
              ({inquirieDetail.userId ? inquirieDetail.userId.email : ''})
            </span>
          </h3>
        </Modal.Header>
        <Modal.Body className='payment-modal-body px-4'>
          <div>
            <h4 className='s3'>Current Professional Standing</h4>
            <p className='s5'>
              Please tell us about your current role where you work. If you are
              in a career transition, please tell share a little about where you
              are in life right now.
            </p>
            {isLoading ? (
              <ShimmerThumbnail
                className='shimmer-thumbnail-mediaplayer border-radius-10'
                rounded
                width={'100%'}
                height={120}
              />
            ) : (
              <CustomTextArea
                style={{
                  resize: 'none',
                  border: '2px solid #000',
                  // overflow: 'hidden',
                }}
                disabled={true}
                value={inquirieDetail.currentProfessionalStanding}
                className='w-100 border-radius-5 mt-3 focus-visible py-1 px-2'
                rows={5}
              />
            )}
          </div>
          <div className='mt-4'>
            <h4 className='s3'>Motivation for Joining the Trip</h4>
            <p className='s5'>
              What motivates you to join this trip to Bhutan, and how do you
              believe it will impact your personal and professional development?
            </p>
            {isLoading ? (
              <ShimmerThumbnail
                className='shimmer-thumbnail-mediaplayer border-radius-10'
                rounded
                width={'100%'}
                height={120}
              />
            ) : (
              <CustomTextArea
                style={{
                  resize: 'none',
                  border: '2px solid #000',
                  // overflow: 'hidden',
                }}
                disabled={true}
                value={inquirieDetail.motivationForJoinTrip}
                className='w-100 border-radius-5 mt-3 focus-visible py-1 px-2'
                rows={5}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
          <Button
            variant='secondary-light'
            onClick={handleClose}
            // disabled={isLoading}
            className='mr-3 secondary-popup-button'
            size='sm'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default InquiriesModal
