import React, { useEffect, useState } from 'react'
import { Form as RBForm, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { string, number, object, array, date } from 'yup'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
} from '@abroad/components'
import GetTeamMemberData from './GetTeamMemberData'
import GetGroupType from './GetGroupType'
import FreeSelectTeamMembers from './FreeSelectTeamMembers'
import { CustomSelectionField, InputField, SingleDatePicker } from '../form'
import { SelectionField } from '../common'
import {
  getNameArrayFromCompanies,
  isLGScreen,
  participantLevelsForGroup,
} from '../../utils/utility'
import API from '../../utils/API'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const groupType = ['Organization', 'Network', 'Promo Code', 'Free Select']

const formInitialValues = {
  teamName: '',
  groupType: '',
  group: '',
  participantLevel: '',
  freeSelectteamMembers: [],
  teamMembers: [],
  email: '',
  agreementSignedAt: null,
  sessionsPerMonth: '',
  paymentToCoachPerHour: '',
  contractDuration: '',
  totalPlan: '',
  paymentUpToDate: 'no',
  coaches: [],
}

const validationSchema = object().shape({
  teamName: string().required().min(2).max(100),
  groupType: string().required(),
  group: string().when('groupType', {
    is: 'Free Select',
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  email: string().required(),
  agreementSignedAt: date()
    .required()
    .test('valid-date', 'Please enter valid date!', function (value) {
      const checkDate = moment(value, 'MM/DD/YYYY')
      return checkDate.isValid()
    }),
  sessionsPerMonth: number().min(1).max(1000).required(),
  paymentToCoachPerHour: number().min(0).max(1000).required(),
  contractDuration: number().min(1).max(36).required(),
  totalPlan: number().min(0).max(100000).required(),
  coaches: array().required(),
  paymentUpToDate: string().required(),
})

export const CreateTeamForm = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isTeamCreateLoading, setIsTeamCreateLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [ecoSystems, setEcoSystems] = useState([])
  const [promoCodes, setPromoCodes] = useState([])
  const [coachesData, setCoachesData] = useState([])
  const [membersData, setMembersData] = useState([])
  const [groupTypeValue, setGroupTypeValue] = useState('')
  const location = useLocation()
  const [initialValues, setInitialValues] = useState({
    ...formInitialValues,
    ...(location.state?.groupType && {
      groupType: location.state?.groupType,
      group: location.state?.companyName,
    }),
  })

  const [currentValue, setCurrentValue] = useState(initialValues)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const history = useHistory()

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        let { data } = await API.AdminAPI.companyList.getCompanyData(
          '?excludeEnterprise=true',
        )
        data = getNameArrayFromCompanies(data)
        if (data) {
          setCompanies(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getCompanyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getEcoSystemsData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getEcoSystemsData(
          '?excludeEnterprise=true',
        )
        if (data) {
          setEcoSystems(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getEcoSystemsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getPromocodeData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getPromocodeData()
        if (data) {
          const sortedPromo = data?.sort((a, b) =>
            a?.code?.localeCompare(b?.code),
          )
          setPromoCodes(
            sortedPromo.map((data) => ({
              label: data?.code?.toUpperCase(),
              value: data?.code,
            })),
          )
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getPromocodeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getCoachData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getCoachData()
        if (data) {
          setCoachesData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getCoachData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (groupTypeValue !== '') {
      setInitialValues((prev) => ({
        ...prev,
        ...currentValue,
        groupType: groupTypeValue,
        group: '',
        freeSelectteamMembers: '',
        teamMembers: null,
        ...(location.state?.groupType && {
          groupType: location.state?.groupType,
          group: location.state?.companyName,
        }),
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupTypeValue, location])

  const onSubmit = async (values, { resetForm }) => {
    let findGroupType =
      values.groupType === 'Organization'
        ? 1
        : values.groupType === 'Network'
        ? 2
        : values.groupType === 'Promo Code'
        ? 3
        : values.groupType === 'Free Select'
        ? 4
        : 5

    let params = {
      name: values.teamName,
      groupType: findGroupType,
      groupName: values.group,
      members:
        values.groupType === 'Free Select'
          ? values?.freeSelectteamMembers?.map((member) => member?.value) || []
          : values?.teamMembers?.map((member) => member?.value) || [],
      emailName: values.email,
      groupLevel:
        participantLevelsForGroup[values?.participantLevel]?.toLowerCase(),
      agreementSignedAt: values.agreementSignedAt,
      sessionsPerMonth: values.sessionsPerMonth,
      hourlyPayToCoach: values.paymentToCoachPerHour,
      contractDuration: values.contractDuration,
      planTotal: values.totalPlan,
      hasPaid: values.paymentUpToDate === 'yes' ? true : false,
      coaches: values.coaches.map((coach) => coach.value),
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      setIsTeamCreateLoading(true)
      const { data } = await API.AdminAPI.teams.addTeam(params)
      if (data) {
        setIsTeamCreateLoading(false)
        Notification.showNotification('Group has been created!', 'success')
        resetForm()
        if (location.state?.groupType) {
          history.goBack()
        } else {
          history.push('/admin/groups')
        }
      }
    } catch (e) {
      setIsTeamCreateLoading(false)
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Formik
      displayName='profile-form'
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={onSubmit}>
      {({ values, handleSubmit }) => {
        return (
          <RBForm
            onSubmit={handleSubmit}
            as={Form}
            className='profile px-4 px-lg-0'>
            <Row noGutters className='mb-3'>
              <Col className='col-sm-2 d-none d-lg-block' />
              <Col className='col-lg-offset-2'>
                <h5>Group Information</h5>
              </Col>
            </Row>
            <InputField
              label='Name'
              labelProps={labelProps}
              name='teamName'
              type='string'
              size='md'
              placeholder='Group Name'
              sm='10'
            />
            <SelectionField
              label='Group Type'
              labelProps={labelProps}
              name='groupType'
              id='groupType'
              className='black-color-placeholder'
              optionplaceholder='Select one'
              size='md'
              options={groupType}
              {...(location.state?.groupType && { disabled: true })}
            />
            <GetTeamMemberData
              render={(teamMemberData) => {
                setMembersData(teamMemberData)
              }}
            />
            <GetGroupType
              render={(values) => {
                setGroupTypeValue(values.groupType)
                setCurrentValue(values)
              }}
            />
            <SelectionField
              label='Group'
              labelProps={labelProps}
              name='group'
              optionplaceholder='Select one'
              options={
                values.groupType === 'Organization'
                  ? companies
                  : values.groupType === 'Network'
                  ? ecoSystems
                  : values.groupType === 'Promo Code'
                  ? promoCodes
                  : []
              }
              size='md'
              className={classNames('black-color-placeholder', {
                'cursor-disabled': values.groupType === 'Free Select',
              })}
              {...(values.groupType === 'Promo Code' && {
                getValueAsOption: true,
              })}
              disabled={values.groupType === 'Free Select'}
              {...(location.state?.groupType && { disabled: true })}
            />

            {values.groupType !== 'Free Select' && (
              <CustomSelectionField
                name={'teamMembers'}
                placeholder='Select Group Members'
                label='THE GROUP MEMBERS'
                className='mb-0'
                classNamePrefix='selection-input'
                options={membersData?.map((data) => ({
                  value: data._id,
                  label: `${data.fullName}(${data.email})`,
                }))}
                maxMenuHeight={140}
              />
            )}
            {values.groupType === 'Free Select' && (
              <FreeSelectTeamMembers
                name='freeSelectteamMembers'
                placeholder='Search Group Member.'
                className='mb-0'
                label='THE GROUP MEMBERS'
                options={[]}
              />
            )}

            <CustomSelectionField
              label='THE GROUP COACHES'
              name='coaches'
              classNamePrefix='selection-input'
              placeholder='Select Group Coaches'
              options={coachesData?.map((coach) => ({
                value: coach._id,
                label: coach.fullName,
              }))}
              maxMenuHeight={140}
            />
            <InputField
              label='Email Salutation'
              labelProps={labelProps}
              name='email'
              type='text'
              size='md'
              placeholder='Email here.'
              showTooltipMessage={true}
              dataTooltip='This is the text that &#013;
                gets inserted into the &#013;
                email body "Dear ____", &#013;
                this is an automatic email that &#013;
                notifies this group &#013;
                when you log Post-Session &#013;
                Practices. &#013;
                '
              extraIcon={
                <svg
                  aria-hidden='true'
                  focusable='false'
                  data-prefix='fas'
                  data-icon='question-circle'
                  className='svg-inline--fa fa-question-circle fa-w-16'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  width='20'
                  height='20'>
                  <path
                    fill='currentColor'
                    d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z'></path>
                </svg>
              }
              sm='10'
            />
            <Row noGutters className='mb-3'>
              <Col className='col-sm-2 d-none d-lg-block' />
              <Col className='col-lg-offset-2'>
                <h5>Group SOW</h5>
              </Col>
            </Row>
            <SingleDatePicker
              label='Group agreement Sign'
              labelProps={labelProps}
              name='agreementSignedAt'
              sm='10'
              minimumDate={moment().subtract(1, 'years')}
              maximumDate={moment().add(1, 'years')}
              className='pointer-cursor user-input black-color-placeholder bg-white'
              spanClassName='mb-3'
            />
            <InputField
              label='SESSIONS PER MONTH'
              labelProps={labelProps}
              name='sessionsPerMonth'
              type='number'
              size='md'
              sm='10'
            />
            <InputField
              label='PAYMENT TO COACH PER HOUR (USD)'
              labelProps={labelProps}
              name='paymentToCoachPerHour'
              type='number'
              size='md'
              sm='10'
            />
            <InputField
              label='CONTRACT DURATION (MONTHS)'
              labelProps={labelProps}
              name='contractDuration'
              type='number'
              size='md'
              sm='10'
            />
            <InputField
              label='PLAN TOTAL (USD)'
              labelProps={labelProps}
              name='totalPlan'
              type='number'
              size='md'
              sm='10'
            />
            <Row className='d-flex'>
              <Col lg={2} md={2} sm={2} className='p-0 pl-lg-1'>
                <RBForm.Label className='text-right pl-lg-4'>
                  PAYMENT UP TO DATE?
                </RBForm.Label>
              </Col>
              <Col className='pl-lg-0'>
                <label>
                  <Field
                    type='radio'
                    name='paymentUpToDate'
                    value='yes'
                    className='ml-2'
                  />
                  YES
                </label>
                <label>
                  <Field
                    type='radio'
                    name='paymentUpToDate'
                    value='no'
                    className='ml-2'
                  />
                  NO
                </label>
              </Col>
            </Row>

            <div className='text-right'>
              <Button
                isLoading={isTeamCreateLoading}
                disabled={isTeamCreateLoading}
                size='md'
                className='line-height-normal border-radius-3 text-uppercase'
                type='submit'
                variant='gray'>
                CREATE
              </Button>
            </div>
          </RBForm>
        )
      }}
    </Formik>
  )
}
