import React, { useState } from 'react'

const TimeRangePicker = ({
  initialFromTime,
  initialToTime,
  onTimeChange,
  handleBlur,
}) => {
  const [fromTime, setFromTime] = useState(initialFromTime)
  const [toTime, setToTime] = useState(initialToTime)

  const handleFromTimeChange = (event) => {
    const newFromTime = event.target.value
    setFromTime(newFromTime)
    const newToTime = toTime ? (toTime <= newFromTime ? '' : toTime) : ''

    onTimeChange(newFromTime, newToTime)
    handleBlur()
  }

  const handleToTimeChange = (event) => {
    const newToTime = event.target.value

    if (newToTime > fromTime) {
      setToTime(newToTime)
      onTimeChange(fromTime, newToTime)
      handleBlur()
    }
  }

  const renderTimeOptions = (minHour, isFromTime) => {
    const hoursOptions = Array.from({ length: 24 }, (_, i) =>
      i.toString().padStart(2, '0'),
    )
    const minutesOptions = ['00', '15', '30', '45']

    const timeOptions = []
    timeOptions.push(
      <option key={isFromTime ? 'from' : 'to'} value='' disabled>
        {isFromTime ? 'From' : 'To'}
      </option>,
    )

    hoursOptions.forEach((hour) => {
      minutesOptions.forEach((minute) => {
        const timeString = `${hour}:${minute}`

        const disabled = minHour && timeString <= minHour

        timeOptions.push(
          <option key={timeString} value={timeString} disabled={disabled}>
            {timeString}
          </option>,
        )
      })
    })
    return timeOptions
  }

  return (
    <div className='d-flex'>
      <select
        className='selection-field mr-5'
        value={fromTime}
        onChange={handleFromTimeChange}>
        {renderTimeOptions('', true)}
      </select>

      <select
        className='selection-field'
        value={toTime}
        onChange={handleToTimeChange}>
        {renderTimeOptions(fromTime)}
      </select>
    </div>
  )
}

export default TimeRangePicker
