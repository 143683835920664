import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'
import { useErrorService } from '@abroad/components'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const ToggleNetworkWisdomAssessmentCheckbox = () => {
  const { companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)
  const [isWisdomAssessmentEnabled, setIsWisdomAssessmentEnabled] = useState(
    companyAndEcoInfo?.isWisdomEnabled || false,
  )
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()

  useEffect(() => {
    if (companyAndEcoInfo?.isWisdomEnabled) {
      setIsWisdomAssessmentEnabled(true)
    } else {
      setIsWisdomAssessmentEnabled(false)
    }
  }, [companyAndEcoInfo])

  const toggleWisdomAssessmentEnabled = async () => {
    setIsLoading(true)
    try {
      const { status } =
        await API.AdminAPI.ecoSystem.toggleNetworkWisdomAssessmentEnabled(
          companyAndEcoInfo?._id,
          {
            isWisdomEnabled: !isWisdomAssessmentEnabled,
          },
        )
      if (status) {
        setIsWisdomAssessmentEnabled(!isWisdomAssessmentEnabled)
        setCompanyAndEcoInfo((prev) => ({
          ...prev,
          isWisdomEnabled: !isWisdomAssessmentEnabled,
        }))
        setIsLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  return (
    <div className='wisdom-enabled-checkbox mb-point-75'>
      <Form.Check
        id='isWisdomAssessmentEnabled'
        custom={true}
        onChange={toggleWisdomAssessmentEnabled}
        checked={isWisdomAssessmentEnabled ? true : false}
        type='checkbox'
        label='Is Wisdom Assessment Enabled'
        className={classNames('s6', {
          'cursor-pointer': !isLoading,
          'pointer-events-none': isLoading,
        })}
        disabled={isLoading}
      />
      {isLoading && <span className='s7'>Updating...</span>}
    </div>
  )
}

export default ToggleNetworkWisdomAssessmentCheckbox
