import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@abroad/components'

const BeginGrowthPlan = ({sowId}) => {
  const history = useHistory()
  const { clientId } = useParams()
  const isUserComeFromMyClients = history?.location?.pathname.includes('coach')
  const growthPlanCallUrl = isUserComeFromMyClients
    ? `/coach/clients/${clientId}/growth-plan/${sowId}/create`
    : `/admin/users/${clientId}/growth-plan/${sowId}/create`

  return (
    <section className='d-flex flex-column'>
      <span className='s5 mt-4'>
        This user has not yet completed their growth plan call. If you are
        beginning your session with them <br /> now, please select the button
        below to begin. In total, this process should take 45 minutes.
      </span>
      <div>
        <Button
          className='line-height-normal text-uppercase growth-plan-btn'
          size='lg'
          onClick={() => {
            history.push(growthPlanCallUrl)
          }}
          variant='black'>
          BEGIN GROWTH PLAN
        </Button>
      </div>
    </section>
  )
}

export default BeginGrowthPlan
