import React, { useState } from 'react'
import { Form as RBForm, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import { string, object } from 'yup'
import { Formik, Form } from 'formik'
import { InputField } from '../form'
import API from '../../utils/API'

const formInitialValues = {
  name: '',
}

const validationSchema = object().shape({
  name: string()
    .required()
    .max(100)
    .matches(
      /^[aA-zZ0-9\s_().&]+$/,
      '* This field cannot contain special character except underscore and space',
    )
    .matches(/[^\\]+$/)
    .matches(/^[a-zA-Z0-9]{1}/),
})

const UpsertCompanyEcoModal = ({ show, onHide, companyEcoName = '', type }) => {
  const Error = useErrorService()
  const Notification = useNotificationService()
  let history = useHistory()

  const [companyEcoModalLoading, setCompanyEcoModalLoading] = useState(false)

  const labelValue =
    type?.toUpperCase() === 'ORGANIZATION' || type === 'company'
      ? 'Organization'
      : 'Network'

  const onSubmit = async (values) => {
    try {
      setCompanyEcoModalLoading(true)
      let data
      if (type === 'organization') {
        data = await API.AdminAPI.companyList.createCompany(values)
      } else if (type === 'network') {
        data = await API.AdminAPI.ecoSystem.createEcoSystem(values)
      }
      data = data.data
      Notification.showNotification(data?.message, 'success')
      history.push(
        `/admin/${type}/${data.name}/${type === 'network' ? 'users' : 'users'}`,
      )
    } catch (e) {
      setCompanyEcoModalLoading(false)
      Error.showError(e)
    }
  }

  return (
    <Modal
      show={show}
      size='lg'
      onHide={onHide}
      className='custom-modal'
      centered>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValues}
          onSubmit={onSubmit}>
          {({ values, handleSubmit }) => {
            return (
              <RBForm onSubmit={handleSubmit} as={Form} className='profile'>
                <h3 className='s2 mb-4'>{`Add New ${labelValue}`}</h3>
                <InputField
                  name='name'
                  type='text'
                  placeholder={`${labelValue} Name`}
                  className='s6 border-radius-3'
                  size='lg'
                  sm='10'
                  disabled={!!companyEcoName}
                />
                <div className='text-right mt-5'>
                  <Button
                    variant='saffron'
                    size='md'
                    className='text-uppercase border-radius-3 line-height-normal'
                    isLoading={companyEcoModalLoading}
                    disabled={companyEcoModalLoading}
                    type='submit'>
                    Done
                  </Button>
                </div>
              </RBForm>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default UpsertCompanyEcoModal
