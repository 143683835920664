import React from 'react'
import { Row, Col } from 'react-bootstrap'
import FilterSelection from '../common/FilterSelection'
import ClearFilters from '../common/ClearFilters'
import classNames from 'classnames'
import labels from '../../constants/labels'
const SubscriptionPaymentFilter = ({
  globalFilter,
  setGlobalFilter,
  handleClearFilter,
  isLoading,
}) => {
  const coachingPlanStatus = labels?.coachingPlanStatus.filter(
    (status) => status.value !== 'RESUMED',
  )
  const subscriptionPaymentStatus = labels?.subscriptionPaymentStatus.filter(
    (status) =>
      status.value !== 'NO_BILLING_REQUIRED' && status.value !== 'PENDING',
  )

  return (
    <section className='mb-2-5'>
      <Row className='user-payment-filters'>
        <Col className='col-4 mb-3'>
          <FilterSelection
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            name='coachingStatus'
            placeholder='Select Coaching Status'
            inputClasses={classNames('user-input', {
              'pointer-cursor': !isLoading,
              'cursor-disabled': isLoading,
            })}
            options={coachingPlanStatus.map((status) => ({
              key: status.value,
              value:
                status.value === 'DRAFT'
                  ? 'Payment Pending'
                  : status.value === 'STOPPED'
                  ? 'Terminated'
                  : status.label,
            }))}
            size='md'
            disabled={isLoading}
            key={globalFilter?.coachingStatus}
          />
        </Col>
        <Col className='col-4 mb-3'>
          <FilterSelection
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            name='paymentStatus'
            placeholder='Select Payment Status'
            inputClasses={classNames('user-input', {
              'pointer-cursor': !isLoading,
              'cursor-disabled': isLoading,
            })}
            options={subscriptionPaymentStatus.map((status) => ({
              key: status.value,
              value: status.label,
            }))}
            size='md'
            disabled={isLoading}
            key={globalFilter?.paymentStatus}
          />
        </Col>
      </Row>
      {(globalFilter.paymentStatus !== '' ||
        globalFilter.coachingStatus !== '') && (
        <ClearFilters
          wrapperClasses='payment-clear-filter text-right'
          onClick={handleClearFilter}
          disabled={isLoading}
        />
      )}
    </section>
  )
}

export default SubscriptionPaymentFilter
