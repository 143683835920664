import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import {
  Button,
  FeatherLoader,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import { formatDate, formatYear } from '../../utils/date'
import API from '../../utils/API'

const AddUserModal = ({
  showModal,
  handleClose,
  updateClientProfile,
  questList,
}) => {
  const { clientId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedQuestId, setSelectedQuestId] = useState('')
  const Error = useErrorService()
  const Notification = useNotificationService()

  const handleSelectChange = (e) => {
    const selectedQuestId = e.target.value

    const selectedQuestObject = questList.find(
      (quest) => quest._id === selectedQuestId,
    )
    selectedQuestObject
      ? setSelectedQuestId(selectedQuestId)
      : selectedQuestId('')
  }

  const handelForceAssignUser = async () => {
    try {
      const { data } = await API.AdminAPI.quests.addUserQuest(selectedQuestId, {
        userId: clientId,
      })

      if (data) {
        Notification.showNotification(
          'Quest has been added successfully',
          'success',
        )
        setIsLoading(false)
        updateClientProfile()
        handleClose()
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (questList.length > 0) {
      setSelectedQuestId(questList[0]._id)
    }
  }, [questList])

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='payment-modal'>
        <Modal.Header className='payment-modal-header d-flex justify-content-center'>
          <h3 className='payment-modal-title mb-0'>
            Search users to add them to quest
          </h3>
        </Modal.Header>
        <Modal.Body className='payment-modal-body'>
          <>
            <label htmlFor='selectionField' className='s8'>
              Quest
            </label>

            <div className='position-relative w-100'>
              <select
                name='questList'
                value={selectedQuestId}
                onChange={handleSelectChange}
                className='w-100 sa2 quest-selection-field'>
                {isLoading && (
                  <option>
                    {' '}
                    <FeatherLoader />
                  </option>
                )}
                {questList.map((quest) => {
                  const combinedText = `${quest.name} : ${
                    quest.month
                  } ${formatYear(quest.startDate)}(${formatDate(
                    quest.startDate,
                  )})`
                  return (
                    <option
                      key={quest._id}
                      value={quest._id}
                      className='position-absolute w-100'>
                      {combinedText.length > 50
                        ? combinedText.slice(0, 50) + '...'
                        : combinedText}
                    </option>
                  )
                })}
              </select>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
          <Button
            variant='secondary-light'
            onClick={handleClose}
            className='px-3 secondary-popup-button'
            size='sm'>
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            onClick={handelForceAssignUser}
            className='text-uppercase border-radius-normal text-white hover:text-white'>
            ADD
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddUserModal
