import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useErrorService, useUserService } from '@abroad/components'
import useDebounce from '../common/useDebounce'
import API from '../../utils/API'

const GlobalSearchInput = () => {
  const [searchData, setSearchData] = useState({})
  const [isMatchSearchResult, setIsMatchSearchResult] = useState(false)
  const [search, setSearch] = useState('')
  const [isSiblingElementClicked, setIsSiblingElementClicked] = useState(false)
  const [isHoverSearchInput, setIsHoverSearchInput] = useState(false)
  const [isInputActive, setIsInputActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const debouncedSearch = useDebounce(search, 150)
  const Error = useErrorService()
  const { user } = useUserService()
  const searchInputRef = useRef(null)
  const searchResultTitle = {
    companies: 'Organization',
    ecosystems: 'Networks',
    users: 'Users',
    teams: 'Group',
  }
  const keyCodesArray = [9, 17, 18] // 9 - tab, 17 - ctrl, 18 - Alt.

  useEffect(() => {
    if (!isInputActive) {
      setIsSiblingElementClicked(false)
    }
  }, [isInputActive])

  useEffect(() => {
    const getSiteWideSearchResult = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.common.getSiteWideSearcResulth(
          debouncedSearch,
        )
        if (data) {
          setSearchData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (debouncedSearch !== '' && debouncedSearch?.length >= 3)
      getSiteWideSearchResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  useEffect(() => {
    if (!searchData) return
    if (
      searchData?.companies?.length > 0 ||
      searchData?.ecosystems?.length > 0 ||
      searchData?.users?.length > 0 ||
      searchData?.teams?.length > 0
    ) {
      setIsMatchSearchResult(true)
    } else {
      setIsMatchSearchResult(false)
    }
  }, [searchData])

  const renderSearchIcon = (wrapperClasses = '') => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='13'
        height='13'
        viewBox='0 0 13 13'
        fill='none'
        className={classnames(
          'magnifying-glass-icon cursor-pointer',
          {
            hover: isHoverSearchInput,
            'hover active': isInputActive,
          },
          wrapperClasses,
        )}
        onClick={() => {
          searchInputRef.current.focus()
          setIsInputActive(true)
        }}>
        <g>
          <line
            x1='8.06066'
            y1='8'
            x2='12'
            y2='11.9393'
            stroke='black'
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <circle cx='5' cy='5' r='4.25' stroke='black' strokeWidth='1.5' />
        </g>
      </svg>
    )
  }

  const renderCrossIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        className='pointer-cursor'
        onMouseOver={() => setIsSiblingElementClicked(true)}
        onMouseOut={() => setIsSiblingElementClicked(false)}
        onClick={() => {
          setSearch('')
          setIsInputActive(false)
          setIsLoading(false)
          setIsHoverSearchInput(false)
        }}>
        <g clipPath='url(#clip0_2_115)'>
          <path
            d='M8.93996 8.00004L11.8066 5.14004C11.9322 5.01451 12.0027 4.84424 12.0027 4.66671C12.0027 4.48917 11.9322 4.31891 11.8066 4.19338C11.6811 4.06784 11.5108 3.99731 11.3333 3.99731C11.1558 3.99731 10.9855 4.06784 10.86 4.19338L7.99996 7.06004L5.13996 4.19338C5.01442 4.06784 4.84416 3.99731 4.66663 3.99731C4.48909 3.99731 4.31883 4.06784 4.19329 4.19338C4.06776 4.31891 3.99723 4.48917 3.99723 4.66671C3.99723 4.84424 4.06776 5.01451 4.19329 5.14004L7.05996 8.00004L4.19329 10.86C4.13081 10.922 4.08121 10.9958 4.04737 11.077C4.01352 11.1582 3.99609 11.2454 3.99609 11.3334C3.99609 11.4214 4.01352 11.5085 4.04737 11.5898C4.08121 11.671 4.13081 11.7447 4.19329 11.8067C4.25527 11.8692 4.329 11.9188 4.41024 11.9526C4.49148 11.9865 4.57862 12.0039 4.66663 12.0039C4.75463 12.0039 4.84177 11.9865 4.92301 11.9526C5.00425 11.9188 5.07798 11.8692 5.13996 11.8067L7.99996 8.94004L10.86 11.8067C10.9219 11.8692 10.9957 11.9188 11.0769 11.9526C11.1581 11.9865 11.2453 12.0039 11.3333 12.0039C11.4213 12.0039 11.5084 11.9865 11.5897 11.9526C11.6709 11.9188 11.7447 11.8692 11.8066 11.8067C11.8691 11.7447 11.9187 11.671 11.9526 11.5898C11.9864 11.5085 12.0038 11.4214 12.0038 11.3334C12.0038 11.2454 11.9864 11.1582 11.9526 11.077C11.9187 10.9958 11.8691 10.922 11.8066 10.86L8.93996 8.00004Z'
            fill='black'
          />
        </g>
        <defs>
          <clipPath id='clip0_2_115'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )
  }

  const renderFeatherIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='52'
        height='26'
        viewBox='0 0 52 26'
        fill='none'>
        <path
          d='M23.2943 24.3282V24.0291C23.0728 23.9875 22.8763 23.9496 22.6422 23.8928C20.9494 23.4271 19.1981 23.0826 17.5763 22.4692C13.2836 20.8676 9.99828 18.1036 7.29812 14.7603C4.39733 11.1595 2.28235 7.18768 0.61042 3.02275C0.511135 2.84002 0.390633 2.6674 0.250956 2.50781L0.000166997 2.55325C-0.00268249 2.86731 0.0309409 3.18077 0.100482 3.48846C0.760893 5.29453 1.45892 7.08923 2.12351 8.8953C2.21938 9.12634 2.23827 9.3778 2.17785 9.61848C1.02422 12.1137 1.34189 14.5028 2.74631 16.8124C2.93858 17.1267 3.19355 17.4069 3.41926 17.706C3.64497 17.4183 3.87904 17.1419 4.07131 16.8465C4.31792 16.4679 4.53945 16.0893 4.76934 15.7106L5.00341 15.7826C5.0452 16.873 5.11626 17.9635 5.11626 19.0539C5.09585 19.2859 5.1421 19.5188 5.25051 19.7298C5.35892 19.9408 5.52579 20.1227 5.73487 20.258C6.98882 21.038 8.16753 21.9202 9.49671 22.5298C12.7682 24.1229 16.4526 24.8893 20.1636 24.7485C21.2149 24.661 22.2601 24.5207 23.2943 24.3282ZM28.8409 21.5529C28.7306 21.4943 28.6161 21.4424 28.4981 21.3977C26.4333 20.8751 24.3183 20.4738 22.312 19.8036C19.6453 18.9252 17.0413 17.7893 14.8636 16.0022C14.7082 15.8719 14.5225 15.7749 14.3202 15.7182C13.1415 15.3926 11.9461 15.0859 10.7632 14.7641C10.6154 14.7081 10.4751 14.637 10.3452 14.552C10.3452 14.5028 10.387 14.4536 10.4121 14.4044L12.3264 14.2908C9.50089 12.2235 6.80073 10.2584 4.02115 8.2327C4.05213 8.4265 4.10536 8.61685 4.17998 8.80064C4.95743 10.0766 5.67636 11.3905 6.53322 12.6172C10.2282 17.8612 15.1771 21.5983 21.94 23.2189C24.4479 23.8171 26.588 23.1848 28.5901 21.8785C28.6872 21.779 28.7713 21.6698 28.8409 21.5529Z'
          fill='black'
        />
        <path
          d='M20.0625 25.7855C21.7715 26.0715 23.5239 26.0715 25.2329 25.7855C28.6478 25.2706 31.6907 23.9378 34.5205 22.1583C36.8054 20.7203 38.9081 19.0581 40.7902 17.202C41.948 16.0661 43.0682 14.8734 44.109 13.6239C45.0954 12.4464 45.9732 11.2083 46.83 9.96259C46.9304 9.81492 47.0349 9.62939 47.248 9.73541C47.4612 9.84142 47.248 10.008 47.2104 10.114C46.2174 11.9657 45.0006 13.7118 43.5823 15.3202C40.7786 18.6084 37.3193 21.3914 33.3836 23.5251C33.2164 23.616 32.8987 23.6652 32.999 23.9037C33.0993 24.1423 33.417 24.04 33.6135 23.9757C34.2488 23.7788 34.8674 23.5365 35.5069 23.3358C38.6834 22.3502 41.5876 20.7552 44.0212 18.6597C45.1868 17.6399 46.2603 16.5371 47.2313 15.3618C48.5585 13.7701 49.6438 12.0242 50.4581 10.1708C51.2968 8.24868 51.8016 6.2204 51.9545 4.15819C52.0548 2.93522 51.9545 1.70088 51.9545 0.474123C51.9545 0.292381 51.9545 0.0462716 51.6995 0.00462229C51.4446 -0.037027 51.4655 0.212869 51.386 0.349175C50.4961 1.8334 49.352 3.18038 47.9962 4.33993C46.2323 5.79009 44.2344 6.95248 42.2949 8.18303C39.6073 9.89065 37.9396 12.0564 37.764 15.093C37.7306 15.6913 37.8016 16.3425 37.346 16.9862C37.1601 16.4434 37.0726 15.877 37.0869 15.3088C37.0869 15.1309 37.1621 14.8886 36.8904 14.8242C36.6188 14.7598 36.5143 14.9984 36.3889 15.1801C33.9729 18.7884 30.93 21.8288 26.6833 23.7864C24.8964 24.6166 22.9722 25.178 20.9862 25.4485C20.815 25.4446 20.6447 25.4725 20.4859 25.5304C20.327 25.5884 20.1829 25.6752 20.0625 25.7855Z'
          fill='black'
        />
      </svg>
    )
  }

  const renderNoResultFoundSection = () => {
    return (
      <div className='d-flex flex-column align-items-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='72'
          height='79'
          viewBox='0 0 72 79'
          fill='none'>
          <g clipPath='url(#clip0_2_292)'>
            <path
              d='M29.6202 68.4607H3.61868C1.62 68.4607 0 66.9873 0 65.171V3.28967C0 1.46877 1.6166 0 3.61868 0H37.7406C38.3587 0 38.9242 0.256378 39.2943 0.673379L57.0974 16.4406C58.0551 17.3117 58.5611 17.9959 58.5611 18.2059L58.5577 39.11C57.2689 38.6158 55.9206 38.2266 54.523 37.9532L54.5213 20.4052H37.7406C36.613 20.4052 35.713 19.5697 35.713 18.5596V3.65879H4.04151V64.7849H27.8592C28.3177 66.0699 28.9087 67.2993 29.6202 68.4607ZM49.6698 42.3441C54.5247 42.3441 58.9211 44.1341 62.1034 47.0284C65.2874 49.9227 67.2538 53.9213 67.2538 58.3384C67.2538 61.5694 66.2009 64.5764 64.3891 67.0923L72 74.6369L66.7528 79L59.4119 71.6546C56.6236 73.3458 53.2732 74.3327 49.6698 74.3327C44.8132 74.3327 40.4168 72.5411 37.2362 69.6468C34.0523 66.7525 32.0842 62.754 32.0842 58.3384C32.0842 53.9213 34.0523 49.9227 37.2345 47.0284C40.4168 44.1341 44.8132 42.3441 49.6698 42.3441V42.3441ZM59.794 49.1289C57.2043 46.772 53.6247 45.3141 49.6698 45.3141C45.7149 45.3141 42.1353 46.772 39.5457 49.1289C36.9543 51.4857 35.3513 54.7414 35.3513 58.3384C35.3513 61.9339 36.9543 65.1911 39.5457 67.5464C42.1353 69.9032 45.7149 71.3596 49.6698 71.3596C53.6247 71.3596 57.2043 69.9032 59.794 67.5464C62.3853 65.1896 63.9883 61.9339 63.9883 58.3384C63.9883 54.7414 62.3853 51.4857 59.794 49.1289ZM53.6213 52.4324L56.0819 54.6827L52.1134 58.3384L56.0853 61.9972L53.6179 64.2351L49.6698 60.5995L45.7183 64.2413L43.2577 61.991L47.2245 58.3353L43.2543 54.6765L45.7217 52.4386L49.6681 56.0742L53.6213 52.4324Z'
              fill='#AAAAAA'
            />
          </g>
          <defs>
            <clipPath id='clip0_2_292'>
              <rect width='72' height='79' fill='white' />
            </clipPath>
          </defs>
        </svg>
        <span className='font-open-sans font-15 leading-5 tracking-normal text-center'>
          Oops! No result found
        </span>
      </div>
    )
  }

  const getSearchTextLink = (key, data) => {
    const convertedKey = searchResultTitle[key]

    switch (convertedKey) {
      case 'Organization':
        return `/admin/organization/${data?.name}/users`
      case 'Networks':
        return `/admin/network/${data?.affiliation}/users`
      case 'Users':
        return user?.isAdmin
          ? `/admin/users/${data?._id}`
          : `/coach/clients/${data?._id}`
      case 'Group':
        return `/admin/groups/${data?._id}/information`
      default:
        return '/'
    }
  }

  const getViewAllTextLink = (key, searchTearm) => {
    const convertedKey = searchResultTitle[key]

    switch (convertedKey) {
      case 'Organization':
        return `/admin/organizations?search=${searchTearm}`
      case 'Networks':
        return `/admin/networks?search=${searchTearm}`
      case 'Users':
        return user?.isAdmin
          ? `/admin/users?search=${searchTearm}`
          : `/coach/clients?search=${searchTearm}`
      case 'Group':
        return `/admin/groups?search=${searchTearm}`
      default:
        return '/'
    }
  }

  const renderSearchResultSection = () => {
    const renderList = []
    // Taken as constant for correct order of search result
    const searchDataKeysForAdmin = ['users', 'companies', 'ecosystems', 'teams']
    const searchDataKeysForCoach = ['users', 'teams']
    const searchDataKeys = user?.isAdmin
      ? searchDataKeysForAdmin
      : searchDataKeysForCoach
    searchDataKeys.forEach((searchKey) =>
      renderList.push(renderDropdownList(searchData[searchKey], searchKey)),
    )
    return renderList
  }

  const renderDropdownList = (data, key) => {
    const convertedKey = searchResultTitle[key]
    if (data?.length === 0) return null
    return (
      <React.Fragment key={key}>
        <div className='d-flex justify-content-between'>
          <p className='pb-5px mb-0 font-open-sans font-12 font-bold leading-5 tracking-normal text-dark-900'>
            {searchResultTitle[key]}
          </p>
          <Link
            to={getViewAllTextLink(key, search)}
            onClick={() => {
              setIsInputActive(false)
              setIsLoading(false)
              setIsHoverSearchInput(false)
            }}
            onMouseOver={() => setIsSiblingElementClicked(true)}
            onMouseOut={() => setIsSiblingElementClicked(false)}
            className={classnames(
              'font-open-sans font-12 font-semibold leading-5 tracking-normal text-dark-900 underline-link mr-1',
              {
                'd-none': convertedKey === 'Group' && !user?.isAdmin,
              },
            )}>
            View All &gt;
          </Link>
        </div>
        <div className='d-flex flex-column'>
          {data?.map((search, index) => (
            <Link
              to={getSearchTextLink(key, search)}
              onClick={() => {
                setIsInputActive(false)
                setIsLoading(false)
                setIsHoverSearchInput(false)
              }}
              onMouseOver={() => setIsSiblingElementClicked(true)}
              onMouseOut={() => setIsSiblingElementClicked(false)}
              key={index}
              className='pb-5px font-open-sans font-12 font-semibold leading-5 ml-2 search-link text-dark-900'>
              {searchResultTitle[key] === 'Networks'
                ? search?.affiliation
                : searchResultTitle[key] === 'Users'
                ? search?.fullName
                : search?.name}
            </Link>
          ))}
        </div>
      </React.Fragment>
    )
  }

  const shouldBlur = (e) => {
    if (keyCodesArray.includes(e.keyCode)) {
      setIsInputActive(false)
      e.target.blur()
    }
  }

  return (
    <div
      className={classnames(
        'platform-search-input d-flex align-items-center mr-1',
        {
          hover: isHoverSearchInput,
          'hover active': isInputActive,
          unActive: !isInputActive,
          'show-search-result border-bottom-none':
            debouncedSearch?.length >= 3 && isInputActive,
        },
      )}
      onMouseEnter={() => setIsHoverSearchInput(true)}
      onMouseLeave={() => {
        setIsHoverSearchInput(false)
      }}>
      {isInputActive && renderSearchIcon('mr-2')}
      <Form.Control
        type='text'
        ref={searchInputRef}
        placeholder={
          isInputActive
            ? !user?.isAdmin && user?.isCoach
              ? 'Search Across the Coach Platform'
              : 'Search Across the Admin Platform'
            : 'Search'
        }
        className='pr-2 pl-0 py-0 font-open-sans font-12 font-semibold tracking-normal text-dark-900 black-color-placeholder'
        value={search}
        onChange={(event) => {
          const searchTerm = event.target.value
          if (searchTerm?.length > 30) return
          setSearch(searchTerm)
        }}
        onClick={() => setIsInputActive(true)}
        onKeyDown={shouldBlur}
        onBlur={() => {
          if (isSiblingElementClicked) return
          setTimeout(() => setIsInputActive(false), 200)
        }}
      />
      {isInputActive ? renderCrossIcon() : renderSearchIcon()}
      {debouncedSearch !== '' && debouncedSearch?.length >= 3 && isInputActive && (
        <div className='px-2 search-dropdown'>
          <div
            className={classnames('search-section show-dropdown-items py-2', {
              'd-flex': isLoading || !isMatchSearchResult,
            })}>
            <Container
              className={classnames('px-2 search-container', {
                'd-flex align-items-center justify-content-center':
                  isLoading || !isMatchSearchResult,
                'align-content-start': !isLoading && isMatchSearchResult,
              })}>
              <Row className='w-100'>
                {isLoading && (
                  <Col className='d-flex align-items-center justify-content-center px-1'>
                    {renderFeatherIcon()}
                  </Col>
                )}
                {!isLoading && !isMatchSearchResult && (
                  <Col className='px-1'>{renderNoResultFoundSection()}</Col>
                )}
                {!isLoading && isMatchSearchResult && (
                  <Col className='px-1'>{renderSearchResultSection()}</Col>
                )}
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  )
}

export default GlobalSearchInput
