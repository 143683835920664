import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import {
  WisdomAssessmentResult as WisdomAssessmentResultSection,
  useResolutionService,
  surveyType,
  PrintableArea,
} from '@abroad/components'
import API from '../utils/API'

const WisdomAssessmentResult = () => {
  const { clientId, id } = useParams()
  const { isLGScreen } = useResolutionService()
  return (
    <>
      <Helmet>
        <title>Wisdom Result | Abroad</title>
        <meta name='title' content='Wisdom Result | Abroad' />
        <meta property='og:title' content='Wisdom Result | Abroad' />
        <meta property='twitter:title' content='Wisdom Result | Abroad' />
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/wisdom/${id}/clients/${clientId}`}
        />
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/wisdom/${id}/clients/${clientId}`}
        />
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/wisdom/${id}/clients/${clientId}`}
        />
      </Helmet>
      <WisdomAssessmentResultSection
        isRenderOnAdmin={true}
        isLGScreen={isLGScreen}
        API={API.ClientAPI}
        renderHeader={() => {
          return null
        }}
        AssessmentEvent={'AssessmentEvent'}
        apiFunction={() => {
          return API.CoachAPI.coachClient.getUserSurveyResult(
            id,
            clientId,
            surveyType.WISDOM,
          ) // (surveyId, clientId, surveyType)
        }}
      />
      <PrintableArea printSelector='main' />
    </>
  )
}

export default WisdomAssessmentResult
